import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { CollectionCardFragment } from "../../../graphql/fragments/types/CollectionCardFragment";
import CollectionForm, {
  Props as CollectionFormProps,
} from "../../composites/CollectionForm/CollectionForm";
import Menu, { MenuLabelTypeEnum } from "../../composites/Menu/Menu";
import LoadingOverlay from "../../elements/LoadingIndicator/LoadingOverlay";
import Modal from "../../elements/Modal/Modal";
import DELETE_COLLECTION from "./mutations/DeleteCollection";
import { DeleteCollection, DeleteCollectionVariables } from "./mutations/types/DeleteCollection";
import { UpdateCollection, UpdateCollectionVariables } from "./mutations/types/UpdateCollection";
import UPDATE_COLLECTION from "./mutations/UpdateCollection";
import { GetCollectionListings_collection } from "../../composites/CollectionListingsBlock/queries/types/GetCollectionListings";

interface Props {
  collection: CollectionCardFragment | GetCollectionListings_collection;
  className?: string;
  labelClassName?: string;
  afterUpdate?: () => void;
  afterDelete?: () => void;
  label: string;
  labelType: MenuLabelTypeEnum;
}

type FormMode = null | CollectionFormProps["mode"];

export default function CollectionMenu({
  collection,
  className,
  labelClassName,
  afterUpdate,
  afterDelete,
  label,
  labelType,
}: Props) {
  const [visibleForm, setVisibleForm] = useState<FormMode>(null);
  const closeForm = () => setVisibleForm(null);

  const [updateCollection, { data, loading }] = useMutation<
    UpdateCollection,
    UpdateCollectionVariables
  >(UPDATE_COLLECTION, {
    onCompleted: ({ updateCollection: response }) => {
      if (response.collection) {
        closeForm();
        afterUpdate();
      }
    },
  });

  const [deleteCollection] = useMutation<DeleteCollection, DeleteCollectionVariables>(
    DELETE_COLLECTION,
    {
      variables: { input: { id: collection.id } },
      onCompleted: (response) => {
        if (!response.deleteCollection.error && afterDelete) {
          afterDelete();
        }
      },
    }
  );

  const handleSubmit = ({ title, file }) => {
    const variables = { input: { id: collection.id, title, coverImage: file } };
    updateCollection({ variables });
  };

  function makeMenuItems() {
    const changeNameItem = {
      type: "menuitem",
      content: "Change name",
      onClick: () => setVisibleForm("name"),
    };
    const setCoverImageItem = {
      type: "menuitem",
      content: "Set cover image",
      onClick: () => setVisibleForm("coverImage"),
    };
    const deleteItem = {
      type: "menuitem",
      content: "Delete",
      onClick: () => deleteCollection(),
    };
    if (collection.isDefault) {
      return [setCoverImageItem];
    } else {
      return [changeNameItem, setCoverImageItem, deleteItem];
    }
  }

  return (
    <>
      <Menu
        className={className}
        labelClassName={labelClassName}
        key={"languageMenu"}
        name={label}
        identifier={"LanguageMenu"}
        menuLabelType={labelType}
        content={makeMenuItems()}
      />
      {visibleForm ? (
        <Modal handleClose={closeForm}>
          <CollectionForm
            collection={collection}
            error={data?.updateCollection.error}
            mode={visibleForm}
            handleSubmit={handleSubmit}
            handleCancel={closeForm}
          />
        </Modal>
      ) : null}
      {loading ? <LoadingOverlay /> : null}
    </>
  );
}
