import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      fontWeight: 600,
    },
  })
);

export interface Props {
  paragraphs: string[];
}

const HelpText = ({ paragraphs }: Props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} direction="column">
      {paragraphs.map((p, idx) => (
        <Grid item key={idx}>
          <Typography variant="subtitle1" color="textPrimary">
            {p}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default HelpText;
