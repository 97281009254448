import React from "react";
import MuiButton from "@material-ui/core/Button";
import styled, { css } from "styled-components";
import { ButtonProps as MuiButtonProps } from "@material-ui/core/Button/Button";
import { A, withTextContent } from "../Typography/Typography";
import { $fullWidth, breakpoints, ifSize } from "../../../../utils/styledHelpers";

export enum BUTTON_VARIANTS {
  TEXT = "text",
  OUTLINED = "outlined",
  CONTAINED = "contained",
}

export enum BUTTON_SIZES {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export interface ButtonProps extends MuiButtonProps {
  variant?: BUTTON_VARIANTS;
  loading?: boolean;
  disabled?: boolean;
  size?: BUTTON_SIZES;
  $inverse?: boolean;
}

const buttonSize = css`
  height: 56px;
  padding: 8px 24px;
  font-size: 14px;

  ${ifSize(BUTTON_SIZES.SMALL)`
    height: 32px;
    padding: 8px 14px;
    font-size: 12px;
  `}

  ${ifSize(BUTTON_SIZES.MEDIUM)`
    height: 40px;
    padding: 8px 16px;
  `}

  ${ifSize(BUTTON_SIZES.LARGE)`
    font-size: 16px;
  `}

  ${breakpoints.down("sm")} {
    ${ifSize(BUTTON_SIZES.LARGE, true)`
      height: 46px;
    `}
  }
`;

const ButtonBase = ({
  variant = BUTTON_VARIANTS.CONTAINED,
  loading,
  disabled,
  size,
  ...props
}: ButtonProps) => (
  <MuiButton disabled={disabled || loading} variant={variant} {...props} disableRipple />
);

const Button = styled(withTextContent(ButtonBase))`
  border-radius: 0;
  letter-spacing: 0.1em;
  font-weight: 500;
  .MuiButton-label {
    color: #111111;
    white-space: nowrap;
  }
  ${buttonSize}
  ${$fullWidth}

  ${({ variant = BUTTON_VARIANTS.CONTAINED, $inverse }) =>
    variant === BUTTON_VARIANTS.CONTAINED &&
    `
    background-color: #111111;
    .MuiButton-label {
      color: white;
    }
  
    ${
      $inverse &&
      `
      background-color: white;
      .MuiButton-label {
        color: #111111;
      }
      `
    }
  `}
  
  ${({ variant, $inverse }) =>
    variant === BUTTON_VARIANTS.TEXT &&
    `
    height: auto;
    padding: 0;
    min-width: auto;
    justify-content: flex-start;
    .MuiButton-label {
      line-height: 1;
      text-decoration: underline;
    }
    &:hover {
      background: none;
    }
    ${
      $inverse &&
      `
      .MuiButton-label {
        color: white;
      }
      `
    }
  `}
  
  ${({ variant, $inverse }) =>
    variant === BUTTON_VARIANTS.OUTLINED &&
    `
    border: 1px solid #111111;
    letter-spacing: 0.05em;
    font-weight: 600;
    ${
      $inverse &&
      `
      border: 1px solid white;
      .MuiButton-label {
        color: white;
      }
      `
    }
  `}
`;

export const LinkButton = styled(A)`
  display: flex;
  justify-content: center;
  align-items: center;
  ${buttonSize}
  ${$fullWidth}
`;

export default Button;

export const TextButton = (props) => <Button variant={BUTTON_VARIANTS.TEXT} {...props} />;

export const OutlineButton = (props) => <Button variant={BUTTON_VARIANTS.OUTLINED} {...props} />;
