import { Sorting } from "@sajari/react-search-ui";
import React from "react";
import "cross-fetch/polyfill";
import FlexBox from "../../../elements/v2/Box/FlexBox";
import styled from "styled-components";
import { SearchSortingClassNames } from "../../../../utils/search/SearchContext";
import { breakpoints } from "../../../../utils/styledHelpers";
import { useSearchPageContext } from "../SearchPageContext";
import { BUTTON_SIZES, OutlineButton } from "../../../elements/v2/Button/Button";
import {
  CONCIERGE_LISTINGS_PATH,
  getUserPath,
  USER_PROFILE_SORT_PATH,
} from "../../../../utils/paths";
import { SellerType } from "../../../../types/graphql-global-types";

const sortOptions = [
  { name: "Most relevant", value: "" },
  { name: "Price: High to Low", value: "-price_aud" },
  { name: "Price: Low to High", value: "price_aud" },
  { name: "Newest to Oldest", value: "-published_at" },
  { name: "Oldest to Newest", value: "published_at" },
  { name: "Best Deals", value: "-price_reduced_percentage" },
];

export default function SearchSort({ ...props }) {
  const { isProfilePage, profilePage, conciergePage } = useSearchPageContext();

  if (isProfilePage) {
    const isAdmin = profilePage.me?.isAdmin;
    const isMe = profilePage.person.isMe;
    const isProfessionalSeller = profilePage.person.sellerType === SellerType.professional_seller;
    if (isAdmin || (isMe && isProfessionalSeller))
      return (
        <SortListingsButton href={getUserPath(profilePage.person.username, USER_PROFILE_SORT_PATH)}>
          Sort Listings
        </SortListingsButton>
      );

    return null;
  }

  if (conciergePage?.isConciergePage) {
    return (
      <SortListingsButton href={`${CONCIERGE_LISTINGS_PATH}${USER_PROFILE_SORT_PATH}`}>
        Sort Listings
      </SortListingsButton>
    );
  }

  return (
    <SearchSorting {...props}>
      <Sorting options={sortOptions} size="sm" />
    </SearchSorting>
  );
}

const SearchSorting = styled(FlexBox)`
  .${SearchSortingClassNames} label {
    display: none;
  }

  .${SearchSortingClassNames.select} button {
    letter-spacing: normal;
    border: none;
    &:hover {
      background: none;
    }
  }

  .${SearchSortingClassNames.select} button span {
    font-weight: 500;
    font-size: 13px;
    color: #222222;
  }

  .${SearchSortingClassNames.select} ul {
    left: auto;
    max-height: 25em;
  }

  .${SearchSortingClassNames.select} ul li {
    font-family: "Montserrat", serif;
    color: #222222;
    font-size: 15px;
    border-bottom: 1px solid #dddddd;
    padding: 1rem;
    border-radius: 0;
  }

  .${SearchSortingClassNames.select} li:hover {
    background-color: white;
  }

  ${breakpoints.down("tablet")} {
    width: 100%;
    .${SearchSortingClassNames} div {
      width: 100%;
    }

    .${SearchSortingClassNames.container} {
      width: 100% !important;
    }

    .${SearchSortingClassNames.select} {
      margin-left: 0 !important;
      width: 100% !important;
    }

    .${SearchSortingClassNames.select} button {
      height: 40px;
      border: 1px solid #222222;
      border-radius: 0;
      padding: 8px 16px;
    }

    .${SearchSortingClassNames.select} button span {
      justify-content: center;
      font-size: 14px;
      font-weight: 600;
    }

    .${SearchSortingClassNames.select} ul {
      width: 100%;
    }

    .${SearchSortingClassNames.select} ul li {
      border-radius: 0;
    }
  }
`;

const SortListingsButton = styled(OutlineButton).attrs({
  size: BUTTON_SIZES.SMALL,
})`
  ${breakpoints.down("tablet")} {
    width: 100%;
    height: 42px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 600;
  }
`;
