import React, { useState } from "react";
import { createEntryPage } from "../FullPageLayout/FullPageLayout";
import { PriceBreakDownProps } from "./components/PriceBreakdown/PriceBreakdown";
import Box from "../../elements/v2/Box/Box";
import styled from "styled-components";
import ListingDescription, { ListingDescriptionProps } from "./components/ListingDescription";
import SectionDivide from "./elements/SectionDivide";
import { DiscountCodeDetails } from "./components/PriceBreakdown/PriceBreakdown";
import StripeWrapper, { StripeProps } from "./components/StripeWrapper";
import CheckoutForm, { StripeFormProps } from "./components/CheckoutForm";

interface Address {
  country: string;
  street1: string;
  street2?: string;
  city: string;
  state_or_province: string;
  postal_code: string;
}

export interface UserProps {
  name: string;
  address?: Address;
}

interface CheckoutProps {
  listingDescription: ListingDescriptionProps;
  deliveryMethod: string;
  stripe: StripeProps & StripeFormProps;
  countryCode: string;
  priceBreakDown: PriceBreakDownProps;
  authorName: string;
  currentUser: UserProps;
}

type ShippingAddress = Address & {
  name: string;
};

export interface FormValues {
  shipping_address: ShippingAddress;
  start_on: string;
  end_on: string;
  offer_price: string;
  offer_currency_code: string;
  quantity: string;
  delivery: string;
  discount_code: string;
  payment_type: string;
}

export function CheckoutPage({
  deliveryMethod,
  listingDescription,
  priceBreakDown,
  stripe,
  authorName,
  currentUser,
}: CheckoutProps) {
  const [discountCodeDetails, setDiscountCodeDetails] = useState<DiscountCodeDetails>({
    isDiscountCodeSet: false,
    discountCode: "",
  });

  return (
    <Main>
      <Article display="flex" flexDirection="column">
        <ListingDescription listingDescriptionProps={listingDescription} />
        <SectionDivide mb={0} />
        <StripeWrapper {...stripe}>
          <CheckoutForm
            {...stripe}
            orderSummaryProps={{
              priceBreakDownProps: priceBreakDown,
              listingId: listingDescription.listingId,
              discountCodeDetails,
              setDiscountCodeDetails,
              shippingRequired: stripe.shippingRequired,
            }}
            deliveryMethod={deliveryMethod}
            authorName={authorName}
            currentUser={currentUser}
          />
        </StripeWrapper>
      </Article>
    </Main>
  );
}

const Main = styled(Box).attrs({
  as: "main",
})`
  padding-top: 1.5em;
  padding-bottom: 6em;
`;

const Article = styled(Box).attrs({
  as: "article",
})`
  margin: 0 auto;
  max-width: 380px;
  padding: 0.5rem 1rem;
`;

interface Args {
  topbar: any; // This will remain a black box to typescript until we rewrite it
  checkoutProps: any;
}

const mapEntryProps = ({ topbar, checkoutProps }: Args) => {
  return {
    pageProps: {
      topbarProps: topbar,
    },
    componentProps: checkoutProps,
  };
};

export default createEntryPage(mapEntryProps)(CheckoutPage);
