import { useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { RoutesContext } from "../../../../utils/routes";
import StatusRow from "../components/StatusRow";
import SKIP_FEEDBACK from "../mutations/SkipFeedback";
import { SkipFeedback } from "../mutations/types/SkipFeedback";
import { GetTransaction_me, GetTransaction_transaction } from "../queries/types/GetTransaction";
import css from "../TransactionStatus.module.css";
import { GreyTransactionActionButton, TransactionActionButton } from "../TransactionStatus";

export interface Props {
  me: GetTransaction_me;
  transaction: GetTransaction_transaction;
}

function Confirmed({ me, transaction }: Props) {
  const routes = useContext(RoutesContext) as any;
  const iAmSeller = transaction.seller.id === me.id;
  const iAmBuyer = transaction.buyer.id === me.id;
  const buyerSkippedFeedback = transaction.buyerSkippedFeedback;
  const sellerSkippedFeedback = transaction.sellerSkippedFeedback;
  const buyerProvidedFeedback = transaction.buyerTestimonials.length > 0;
  const sellerProvidedFeedback = transaction.sellerTestimonials.length > 0;
  const feedbackGiven =
    (iAmBuyer && buyerProvidedFeedback) || (iAmSeller && sellerProvidedFeedback);
  const feedbackSkipped =
    (iAmBuyer && buyerSkippedFeedback) || (iAmSeller && sellerSkippedFeedback);

  const [error, setError] = useState<string>(null);

  const [skipFeedback, { data: skipData, loading: skipLoading }] = useMutation<SkipFeedback>(
    SKIP_FEEDBACK,
    {
      onCompleted: (response) => setError(response.skipFeedback.error),
      onError: (e) => setError(e.message),
    }
  );

  useEffect(() => {
    if (error) {
      (window as any).FlashMessage.error(`Sorry, that didn't work: ${error}`);
    }
  }, [error]);

  return (
    <React.Fragment>
      <StatusRow key={0}>
        <div>
          <p>
            <b>Product Received:</b>
          </p>
          <p>Order completed.</p>
          <p>
            Thank you for using AirRobe and giving{" "}
            <a href={transaction.listing.url}>{transaction.listing.title}</a> a longer life.
          </p>
        </div>
      </StatusRow>
      {feedbackGiven && (
        <StatusRow key={1} iconName="icon-star">
          "Feedback given"
        </StatusRow>
      )}
      {feedbackSkipped && (
        <StatusRow key={2} iconName="ss-skipforward">
          "Feedback skipped"
        </StatusRow>
      )}
      <div className={css.actions}>
        {iAmBuyer && !buyerSkippedFeedback && !buyerProvidedFeedback && (
          <React.Fragment>
            <TransactionActionButton
              text="Give feedback"
              href={routes.new_person_message_feedback_path(transaction.buyer.username, {
                id: transaction.id,
              })}
            />
            <GreyTransactionActionButton
              text="Skip feedback"
              loading={skipLoading}
              onClick={() => {
                skipFeedback({
                  variables: {
                    input: {
                      txId: transaction.id,
                    },
                  },
                });
              }}
            />
          </React.Fragment>
        )}

        {iAmSeller && !sellerSkippedFeedback && !sellerProvidedFeedback && (
          <React.Fragment>
            <TransactionActionButton
              text="Give feedback"
              href={routes.new_person_message_feedback_path(transaction.seller.username, {
                id: transaction.id,
              })}
            />
            <GreyTransactionActionButton
              text="Skip feedback"
              loading={skipLoading}
              onClick={() => {
                skipFeedback({
                  variables: {
                    input: {
                      txId: transaction.id,
                    },
                  },
                });
              }}
            />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}

export default Confirmed;
