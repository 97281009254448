import { useContext } from "react";
import { useLocation as reactUseLocation } from "react-router-dom";
import RorpContext from "../contexts/RorpContext";
import isSSR from "./isSSR";

// In this custom hook, "location" refers to the window location, or URL. We extract search params
// from the query string, for example ?size=small. In the client, we can use the useLocation()
// hook provided by react-router-dom, but on the server, that will not work. Instead, we can get
// the location from the RubyOnRailsPro context (RorpContext). We should make sure we
// always provide a RorpContext to our components. Note: if / when we
// migrate off RORP, we can simply replace the location lookup with a server/client-friendly
// equivalent means of finding location in whatever replacement system we go with (Next.js etc)
export default () =>
  (isSSR() ? useContext(RorpContext).location.search : reactUseLocation()).search;
