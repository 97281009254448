import { Grid } from "@material-ui/core";
import React from "react";
import ConditionSection from "../../ResellForm/ConditionSection/ConditionSection";
import AvailabilitySection from "../AvailabilitySection/AvailabilitySection";
import PriceSection from "../PriceSection/PriceSection";
import ShippingSection from "../../Shared/ShippingSection";
import styled from "styled-components";

const ConditionStep = (s3Props, listing, conditionWarning) => (props) => (
  <Grid container spacing={2} direction="column" wrap="nowrap">
    <ConditionSection
      s3Props={s3Props}
      listing={listing}
      conditionWarning={conditionWarning}
      {...props}
    />
    <PriceSection {...props} />
    <ShippingSectionContainer container direction="column">
      <ShippingSection {...props} />
    </ShippingSectionContainer>
    <AvailabilitySection {...props} />
  </Grid>
);
const withProps = (s3Props, listing, conditionWarning) =>
  ConditionStep(s3Props, listing, conditionWarning);

export default withProps;

const ShippingSectionContainer = styled(Grid)`
  margin: 8px;
  background: #ffffff;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
`;
