import classNames from "classnames";
import React from "react";
import css from "./Button.module.css";

export interface Props {
  text: string;
  color: "black" | "grey";
  loading?: boolean;
  onClick?: () => void;
  href?: string;
  disabled?: boolean;
  round?: boolean;
  className?: string;
}

export default function Button({
  text,
  color,
  loading,
  onClick,
  href,
  round,
  className,
  disabled = false,
}: Props) {
  const button = (
    <button
      className={classNames(css.root, css[color], className, {
        [css.loading]: loading,
        [css.disabled]: disabled,
        [css.round]: round,
      })}
      onClick={onClick}
      disabled={disabled || loading}
    >
      <span className={classNames(css.text, { [css.invisible]: loading })}>{text}</span>
    </button>
  );

  return href ? <a href={href}>{button}</a> : button;
}
