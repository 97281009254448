import React from "react";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import theme from "../config/theme";

export const withThemeProvider = (children: any) => (
  <MuiThemeProvider theme={theme}>
    <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
  </MuiThemeProvider>
);

export const wrapWithThemeProvider = (Component) => () => withThemeProvider(<Component />);
