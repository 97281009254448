// Everything that's needed to render a collection card

import { gql } from "@apollo/client";

// Everything needed to render a collection card
export const CollectionCardFragment = gql`
  fragment CollectionCardFragment on Collection {
    id
    displayTitle
    coverImageUrl
    coverImageFallbackUrl
    isDefault
    url
    profileCollectionUrl
    owner {
      id
      profileUrl
      isMe
    }
  }
`;
