import styled from "styled-components";
import { Pre } from "../../../elements/v2/Typography/Typography";

export const Description = styled(Pre)`
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #000000;
`;
