import { MenuItem, Select } from "@material-ui/core";
import { FieldInputProps } from "formik";
import React from "react";

export type DropdownOptions = { value: string; label: string }[];

export interface Props {
  field: FieldInputProps<string>;
  options: DropdownOptions;
  placeholder?: string;
  [x: string]: any;
}

const Dropdown = ({ field, options, placeholder, ...rest }: Props) => {
  return (
    <Select
      displayEmpty
      variant="outlined"
      {...field}
      {...rest}
      inputProps={{ "data-cy": `dropdown-${field.name}` }}
    >
      {placeholder && (
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
      )}
      {options.map((option, i) => (
        <MenuItem key={i} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};
export default Dropdown;
