import { Box, createStyles, makeStyles, TextField } from "@material-ui/core";
import { Autocomplete, GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import { FieldInputProps, useField } from "formik";
import React, { useState } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    location: {
      width: "100%",
    },
    map: {
      paddingTop: 8,
      width: "100%",
      height: "400px",
      [theme.breakpoints.down("sm")]: {
        height: "200px",
      },
    },
  })
);

const containerStyle = {
  width: "100%",
  height: "100%",
};

// TODO: somehow make this the user current location
const defaultMarkerLocation = {
  lat: -3.745,
  lng: -38.523,
};

const libraries: Libraries = ["places"];

export interface GoogleMapsProps {
  key: string;
}

export interface Props {
  field: FieldInputProps<string>;
  googleMapsProps: GoogleMapsProps;
}

const AddressAutoComplete = ({ field, googleMapsProps }: Props) => {
  const classes = useStyles();
  const [autoComplete, setAutoComplete] = useState();

  const [{ value }, __, { setValue }] = useField(field);
  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      const place = autoComplete.getPlace();

      if (place.geometry !== undefined) {
        setValue({
          address: place.formatted_address,
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
          rawGeocodeData: JSON.stringify({
            address_components: place.address_components,
            formatted_address: place.formatted_address,
            geometry: place.geometry,
            place_id: place.place_id,
            types: place.types,
          }),
        });
      }
    }
  };

  const markerLocation =
    value && value.latitude ? { lat: value.latitude, lng: value.longitude } : defaultMarkerLocation;

  return (
    <LoadScript googleMapsApiKey={googleMapsProps.key} libraries={libraries} language="en">
      <Box className={classes.root}>
        <Box className={classes.location}>
          <Autocomplete onLoad={setAutoComplete} onPlaceChanged={onPlaceChanged}>
            <TextField
              variant="outlined"
              fullWidth
              defaultValue={value && value.address}
              inputProps={{
                "data-cy": `location`,
              }}
            />
          </Autocomplete>
        </Box>
        <Box className={classes.map}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={markerLocation}
            zoom={13}
            options={{
              fullscreenControl: false,
              mapTypeControl: false,
              streetViewControl: false,
            }}
            clickableIcons={false}
          >
            <Marker position={markerLocation} />
          </GoogleMap>
        </Box>
      </Box>
    </LoadScript>
  );
};
export default AddressAutoComplete;
