import { GetCountryPickerDropdownProps_countries } from "../../CountryPickerDropdown/queries/types/GetCountryPickerDropdownProps";

type Country = GetCountryPickerDropdownProps_countries;

export function filterCountries(countries: Array<Country>, query = "") {
  return countries.filter((country) => {
    if (query.length === 0) {
      return true;
    }

    // This is a pretty naive search implementation, but it's probably ok as a start
    const searchTerms = [country.code, country.name];
    return searchTerms.some((term) => term.toUpperCase().indexOf(query.toUpperCase()) > -1);
  });
}
