import { useFilter } from "@sajari/react-hooks";
import { map, xor } from "lodash";
import React from "react";
import FilterChip from "../FilterChip";
import { FilterNames } from "../../../../../utils/filters";

type Props = {
  filterName: FilterNames;
  labelRender?: (label: string) => string;
};
export default function ChipFilter({ filterName, labelRender }: Props) {
  const { setSelected, options, selected } = useFilter(filterName);

  function handleToggle(option) {
    setSelected(xor(selected, [option.label]));
  }

  return (
    <div className="t-searchFilterGroup">
      {map(options, (option) => (
        <FilterChip
          key={option.value}
          label={labelRender ? labelRender(option.label) : option.label}
          onClick={() => handleToggle(option)}
          $selected={selected.includes(option.label)}
          size="small"
        />
      ))}
    </div>
  );
}
