import classNames from "classnames";
import React from "react";
import css from "./LoadingIndicator.module.css";
import Spinner from "./Spinner";

export interface Props {
  fullscreen?: boolean;
}

export default function LoadingIndicator({ fullscreen }: Props) {
  return (
    <div className={classNames(css.root, { [css.fullscreen]: fullscreen })}>
      <div className={css.spinner}>
        <Spinner />
      </div>
    </div>
  );
}
