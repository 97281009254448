/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ChartTypeEnum {
  bar = "bar",
  line = "line",
}

export enum Condition {
  gently_used = "gently_used",
  like_new = "like_new",
  new_with_tags = "new_with_tags",
  new_without_tags = "new_without_tags",
  vintage = "vintage",
  well_loved = "well_loved",
}

export enum ConversationStartingPage {
  listing = "listing",
  payment = "payment",
  profile = "profile",
}

export enum CountryCode {
  AD = "AD",
  AE = "AE",
  AF = "AF",
  AG = "AG",
  AI = "AI",
  AL = "AL",
  AM = "AM",
  AO = "AO",
  AQ = "AQ",
  AR = "AR",
  AS = "AS",
  AT = "AT",
  AU = "AU",
  AW = "AW",
  AX = "AX",
  AZ = "AZ",
  BA = "BA",
  BB = "BB",
  BD = "BD",
  BE = "BE",
  BF = "BF",
  BG = "BG",
  BH = "BH",
  BI = "BI",
  BJ = "BJ",
  BL = "BL",
  BM = "BM",
  BN = "BN",
  BO = "BO",
  BQ = "BQ",
  BR = "BR",
  BS = "BS",
  BT = "BT",
  BV = "BV",
  BW = "BW",
  BY = "BY",
  BZ = "BZ",
  CA = "CA",
  CC = "CC",
  CD = "CD",
  CF = "CF",
  CG = "CG",
  CH = "CH",
  CI = "CI",
  CK = "CK",
  CL = "CL",
  CM = "CM",
  CN = "CN",
  CO = "CO",
  CR = "CR",
  CU = "CU",
  CV = "CV",
  CW = "CW",
  CX = "CX",
  CY = "CY",
  CZ = "CZ",
  DE = "DE",
  DJ = "DJ",
  DK = "DK",
  DM = "DM",
  DO = "DO",
  DZ = "DZ",
  EC = "EC",
  EE = "EE",
  EG = "EG",
  EH = "EH",
  ER = "ER",
  ES = "ES",
  ET = "ET",
  FI = "FI",
  FJ = "FJ",
  FK = "FK",
  FM = "FM",
  FO = "FO",
  FR = "FR",
  GA = "GA",
  GB = "GB",
  GD = "GD",
  GE = "GE",
  GF = "GF",
  GG = "GG",
  GH = "GH",
  GI = "GI",
  GL = "GL",
  GM = "GM",
  GN = "GN",
  GP = "GP",
  GQ = "GQ",
  GR = "GR",
  GS = "GS",
  GT = "GT",
  GU = "GU",
  GW = "GW",
  GY = "GY",
  HK = "HK",
  HM = "HM",
  HN = "HN",
  HR = "HR",
  HT = "HT",
  HU = "HU",
  ID = "ID",
  IE = "IE",
  IL = "IL",
  IM = "IM",
  IN = "IN",
  IO = "IO",
  IQ = "IQ",
  IR = "IR",
  IS = "IS",
  IT = "IT",
  JE = "JE",
  JM = "JM",
  JO = "JO",
  JP = "JP",
  KE = "KE",
  KG = "KG",
  KH = "KH",
  KI = "KI",
  KM = "KM",
  KN = "KN",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KY = "KY",
  KZ = "KZ",
  LA = "LA",
  LB = "LB",
  LC = "LC",
  LI = "LI",
  LK = "LK",
  LR = "LR",
  LS = "LS",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  LY = "LY",
  MA = "MA",
  MC = "MC",
  MD = "MD",
  ME = "ME",
  MF = "MF",
  MG = "MG",
  MH = "MH",
  MK = "MK",
  ML = "ML",
  MM = "MM",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MQ = "MQ",
  MR = "MR",
  MS = "MS",
  MT = "MT",
  MU = "MU",
  MV = "MV",
  MW = "MW",
  MX = "MX",
  MY = "MY",
  MZ = "MZ",
  NA = "NA",
  NC = "NC",
  NE = "NE",
  NF = "NF",
  NG = "NG",
  NI = "NI",
  NL = "NL",
  NO = "NO",
  NP = "NP",
  NR = "NR",
  NU = "NU",
  NZ = "NZ",
  OM = "OM",
  PA = "PA",
  PE = "PE",
  PF = "PF",
  PG = "PG",
  PH = "PH",
  PK = "PK",
  PL = "PL",
  PM = "PM",
  PN = "PN",
  PR = "PR",
  PS = "PS",
  PT = "PT",
  PW = "PW",
  PY = "PY",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RS = "RS",
  RU = "RU",
  RW = "RW",
  SA = "SA",
  SB = "SB",
  SC = "SC",
  SD = "SD",
  SE = "SE",
  SG = "SG",
  SH = "SH",
  SI = "SI",
  SJ = "SJ",
  SK = "SK",
  SL = "SL",
  SM = "SM",
  SN = "SN",
  SO = "SO",
  SR = "SR",
  SS = "SS",
  ST = "ST",
  SV = "SV",
  SX = "SX",
  SY = "SY",
  SZ = "SZ",
  TC = "TC",
  TD = "TD",
  TF = "TF",
  TG = "TG",
  TH = "TH",
  TJ = "TJ",
  TK = "TK",
  TL = "TL",
  TM = "TM",
  TN = "TN",
  TO = "TO",
  TR = "TR",
  TT = "TT",
  TV = "TV",
  TW = "TW",
  TZ = "TZ",
  UA = "UA",
  UG = "UG",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VA = "VA",
  VC = "VC",
  VE = "VE",
  VG = "VG",
  VI = "VI",
  VN = "VN",
  VU = "VU",
  WF = "WF",
  WS = "WS",
  YE = "YE",
  YT = "YT",
  ZA = "ZA",
  ZM = "ZM",
  ZW = "ZW",
}

export enum CurrencyCode {
  AED = "AED",
  AFN = "AFN",
  ALL = "ALL",
  AMD = "AMD",
  ANG = "ANG",
  AOA = "AOA",
  ARS = "ARS",
  AUD = "AUD",
  AWG = "AWG",
  AZN = "AZN",
  BAM = "BAM",
  BBD = "BBD",
  BCH = "BCH",
  BDT = "BDT",
  BGN = "BGN",
  BHD = "BHD",
  BIF = "BIF",
  BMD = "BMD",
  BND = "BND",
  BOB = "BOB",
  BRL = "BRL",
  BSD = "BSD",
  BTC = "BTC",
  BTN = "BTN",
  BWP = "BWP",
  BYN = "BYN",
  BYR = "BYR",
  BZD = "BZD",
  CAD = "CAD",
  CDF = "CDF",
  CHF = "CHF",
  CLF = "CLF",
  CLP = "CLP",
  CNH = "CNH",
  CNY = "CNY",
  COP = "COP",
  CRC = "CRC",
  CUC = "CUC",
  CUP = "CUP",
  CVE = "CVE",
  CZK = "CZK",
  DJF = "DJF",
  DKK = "DKK",
  DOP = "DOP",
  DZD = "DZD",
  EEK = "EEK",
  EGP = "EGP",
  ERN = "ERN",
  ETB = "ETB",
  EUR = "EUR",
  FJD = "FJD",
  FKP = "FKP",
  GBP = "GBP",
  GBX = "GBX",
  GEL = "GEL",
  GGP = "GGP",
  GHS = "GHS",
  GIP = "GIP",
  GMD = "GMD",
  GNF = "GNF",
  GTQ = "GTQ",
  GYD = "GYD",
  HKD = "HKD",
  HNL = "HNL",
  HRK = "HRK",
  HTG = "HTG",
  HUF = "HUF",
  IDR = "IDR",
  ILS = "ILS",
  IMP = "IMP",
  INR = "INR",
  IQD = "IQD",
  IRR = "IRR",
  ISK = "ISK",
  JEP = "JEP",
  JMD = "JMD",
  JOD = "JOD",
  JPY = "JPY",
  KES = "KES",
  KGS = "KGS",
  KHR = "KHR",
  KMF = "KMF",
  KPW = "KPW",
  KRW = "KRW",
  KWD = "KWD",
  KYD = "KYD",
  KZT = "KZT",
  LAK = "LAK",
  LBP = "LBP",
  LKR = "LKR",
  LRD = "LRD",
  LSL = "LSL",
  LTL = "LTL",
  LVL = "LVL",
  LYD = "LYD",
  MAD = "MAD",
  MDL = "MDL",
  MGA = "MGA",
  MKD = "MKD",
  MMK = "MMK",
  MNT = "MNT",
  MOP = "MOP",
  MRO = "MRO",
  MRU = "MRU",
  MTL = "MTL",
  MUR = "MUR",
  MVR = "MVR",
  MWK = "MWK",
  MXN = "MXN",
  MYR = "MYR",
  MZN = "MZN",
  NAD = "NAD",
  NGN = "NGN",
  NIO = "NIO",
  NOK = "NOK",
  NPR = "NPR",
  NZD = "NZD",
  OMR = "OMR",
  PAB = "PAB",
  PEN = "PEN",
  PGK = "PGK",
  PHP = "PHP",
  PKR = "PKR",
  PLN = "PLN",
  PYG = "PYG",
  QAR = "QAR",
  RON = "RON",
  RSD = "RSD",
  RUB = "RUB",
  RWF = "RWF",
  SAR = "SAR",
  SBD = "SBD",
  SCR = "SCR",
  SDG = "SDG",
  SEK = "SEK",
  SGD = "SGD",
  SHP = "SHP",
  SKK = "SKK",
  SLL = "SLL",
  SOS = "SOS",
  SRD = "SRD",
  SSP = "SSP",
  STD = "STD",
  SVC = "SVC",
  SYP = "SYP",
  SZL = "SZL",
  THB = "THB",
  TJS = "TJS",
  TMM = "TMM",
  TMT = "TMT",
  TND = "TND",
  TOP = "TOP",
  TRY = "TRY",
  TTD = "TTD",
  TWD = "TWD",
  TZS = "TZS",
  UAH = "UAH",
  UGX = "UGX",
  USD = "USD",
  UYU = "UYU",
  UZS = "UZS",
  VEF = "VEF",
  VES = "VES",
  VND = "VND",
  VUV = "VUV",
  WST = "WST",
  XAF = "XAF",
  XAG = "XAG",
  XAU = "XAU",
  XBA = "XBA",
  XBB = "XBB",
  XBC = "XBC",
  XBD = "XBD",
  XCD = "XCD",
  XDR = "XDR",
  XFU = "XFU",
  XOF = "XOF",
  XPD = "XPD",
  XPF = "XPF",
  XPT = "XPT",
  XTS = "XTS",
  YER = "YER",
  ZAR = "ZAR",
  ZMK = "ZMK",
  ZMW = "ZMW",
  ZWD = "ZWD",
  ZWL = "ZWL",
  ZWN = "ZWN",
  ZWR = "ZWR",
}

export enum CustomFieldEnum {
  CheckboxField = "CheckboxField",
  DateField = "DateField",
  NumericField = "NumericField",
  TextField = "TextField",
}

export enum DeliveryMethod {
  none = "none",
  pickup = "pickup",
  shipping = "shipping",
}

export enum DryCleaning {
  borrower = "borrower",
  lender = "lender",
}

export enum ListingShape {
  recycle = "recycle",
  rent = "rent",
  resell = "resell",
}

export enum SellerType {
  default_seller = "default_seller",
  professional_seller = "professional_seller",
  trusted_seller = "trusted_seller",
}

export enum SortDirection {
  asc = "asc",
  desc = "desc",
}

export enum TransactionState {
  accepted = "accepted",
  canceled = "canceled",
  confirmed = "confirmed",
  dismissed = "dismissed",
  disputed = "disputed",
  errored = "errored",
  free = "free",
  initiated = "initiated",
  not_started = "not_started",
  paid = "paid",
  paid_out = "paid_out",
  payment_intent_action_expired = "payment_intent_action_expired",
  payment_intent_failed = "payment_intent_failed",
  payment_intent_requires_action = "payment_intent_requires_action",
  pending = "pending",
  pending_ext = "pending_ext",
  preauthorized = "preauthorized",
  refunded = "refunded",
  rejected = "rejected",
}

export enum TryOnAvailability {
  available = "available",
  unavailable = "unavailable",
}

export enum UnitType {
  day = "day",
  month = "month",
  night = "night",
  occasion = "occasion",
}

/**
 * Autogenerated input type of AcceptTransactionMutation
 */
export interface AcceptTransactionMutationInput {
  txId: number;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of ConfirmTransactionMutation
 */
export interface ConfirmTransactionMutationInput {
  txId: number;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of CreateCollectionMutation
 */
export interface CreateCollectionMutationInput {
  title: string;
  listingIds?: number[] | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of CreateListingImageMutation
 */
export interface CreateListingImageMutationInput {
  image: ListingImageInput;
  listingId?: number | null;
  clientMutationId?: string | null;
}

export interface CustomFieldValueInput {
  textValue?: string | null;
  numValue?: number | null;
  selectedOptions?: string[] | null;
}

/**
 * Autogenerated input type of DeleteCollectionMutation
 */
export interface DeleteCollectionMutationInput {
  id: number;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of DeleteListingImageMutation
 */
export interface DeleteListingImageMutationInput {
  id: number;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of DonateListingMutation
 */
export interface DonateListingMutationInput {
  id: number;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of FollowPersonMutation
 */
export interface FollowPersonMutationInput {
  personId: string;
  follow: boolean;
  clientMutationId?: string | null;
}

export interface HistoricalOrderItemInput {
  externalOrderId: string;
  externalId: string;
}

export interface ListingCustomFieldInput {
  key: string;
  value: CustomFieldValueInput;
}

export interface ListingImageInput {
  uuid: string;
  path: string;
  filename: string;
}

export interface ListingInput {
  listingShapeId: number;
  condition: Condition;
  conditionNotes?: string | null;
  price: PriceInput;
  shippingPrice?: PriceInput | null;
  shippingPriceInternational?: PriceInput | null;
  floorPrice?: PriceInput | null;
  imageIds: number[];
  location: LocationInput;
  deliveryMethods: DeliveryMethod[];
  tryOnAvailability?: TryOnAvailability | null;
  dryCleaning?: DryCleaning | null;
  unitType?: UnitType | null;
}

export interface LocationInput {
  address: string;
  longitude: number;
  latitude: number;
  rawGeocodeData?: string | null;
}

/**
 * Autogenerated input type of MoveProfileListingOrderMutation
 */
export interface MoveProfileListingOrderMutationInput {
  authorIds: string[];
  listingId: number;
  position: number;
  sortField?: string | null;
  clientMutationId?: string | null;
}

export interface PriceInput {
  formatted: string;
  currencyCode: CurrencyCode;
}

export interface ProductInput {
  title: string;
  imageIds: number[];
  description: string;
  brand: string;
  rrp?: PriceInput | null;
  categoryId: number;
  customFields: ListingCustomFieldInput[];
}

/**
 * Autogenerated input type of RefetchMyStripeVerificationStatusMutation
 */
export interface RefetchMyStripeVerificationStatusMutationInput {
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of RejectTransactionMutation
 */
export interface RejectTransactionMutationInput {
  txId: number;
  rejectionReason?: string | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of ReorderListingImagesMutation
 */
export interface ReorderListingImagesMutationInput {
  listingId?: number | null;
  listingImageIds: number[];
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of RequestHistoricalOrdersMutation
 */
export interface RequestHistoricalOrdersMutationInput {
  emailAddress: string;
  affiliateAppId?: string | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of SelectHistoricalOrdersMutation
 */
export interface SelectHistoricalOrdersMutationInput {
  emailAddress: string;
  affiliateAppId?: string | null;
  items: HistoricalOrderItemInput[];
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of SendEmailConfirmationMutation
 */
export interface SendEmailConfirmationMutationInput {
  email: string;
  resend?: boolean | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of SetMyCountryMutation
 */
export interface SetMyCountryMutationInput {
  countryCode: CountryCode;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of SetProfileListingOrderMutation
 */
export interface SetProfileListingOrderMutationInput {
  authorIds: string[];
  page: number;
  perPage: number;
  listingIds: number[];
  sortField?: string | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of SetTransactionShippedMutation
 */
export interface SetTransactionShippedMutationInput {
  id: number;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of SkipFeedbackMutation
 */
export interface SkipFeedbackMutationInput {
  txId: number;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of StartConversationMutation
 */
export interface StartConversationMutationInput {
  personId: string;
  message: string;
  startingPage: ConversationStartingPage;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of ToggleListingCollectionMutation
 */
export interface ToggleListingCollectionMutationInput {
  listingId: number;
  collectionId?: number | null;
  userEmail?: string | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of UpdateCollectionMutation
 */
export interface UpdateCollectionMutationInput {
  id: number;
  title?: string | null;
  coverImage?: any | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of UpsertListingMutation
 */
export interface UpsertListingMutationInput {
  id?: number | null;
  listing: ListingInput;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of UpsertProductMutation
 */
export interface UpsertProductMutationInput {
  id?: number | null;
  product: ProductInput;
  clientMutationId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
