import { Box, Button, Card, createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { RoutesContext } from "../../../../utils/routes";
import OffsetInfo from "../OffsetInfo/OffsetInfo";
import { GetListingFormData_listing } from "../queries/types/GetListingFormData";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "#E0E0E0",
    },
    helloCard: {
      padding: 16,
    },
    bold: {
      fontWeight: 600,
    },
    offsetList: {
      display: "flex",
      flexDirection: "column",
    },
    offsetItem: {
      display: "flex",
    },
    offsetText: {
      paddingLeft: 8,
    },
    button: {
      width: "80%",
    },
  })
);

interface Props {
  listing: GetListingFormData_listing;
}
function ListingSuccess({ listing }: Props) {
  const classes = useStyles();
  const routes = useContext(RoutesContext);

  return (
    <Card square className={classes.root}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Card square className={classes.helloCard} style={{ width: "100%" }}>
          <Typography variant="h6" className={classes.bold} align="center">
            YOUR LISTING IS READY!
          </Typography>
        </Card>
        <Box style={{ maxWidth: 1000, padding: 8 }}>
          <Grid container spacing={4} direction="column">
            <Grid item>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <Typography variant="subtitle1" align="center">
                    The AirRobe team will review your listing for approval in the next 48 hours. It
                    will then automatically be posted!
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" align="center">
                    You're giving your item a longer life and reducing its environmental footprint.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <OffsetInfo />
            </Grid>
            <Grid item>
              <Grid container spacing={2} direction="column" alignItems="center">
                <Grid item className={classes.button}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    href={listing.url}
                    data-cy="view-listing"
                  >
                    View your listing
                  </Button>
                </Grid>
                <Grid item className={classes.button}>
                  <Button variant="outlined" fullWidth href={routes.search_path()}>
                    Shop Airrobe
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  );
}

export default ListingSuccess;
