import React from "react";
import { Route } from "react-router-dom";
import { createEntryPage } from "../FullPageLayout/FullPageLayout";
import { HISTORICAL_ORDERS_PATH } from "../../../utils/paths";
import styled from "styled-components";
import FlexBox from "../../elements/v2/Box/FlexBox";
import { GetAffiliate_affiliate } from "./queries/types/GetAffiliate";
import { AffiliateRoutes } from "./AffiliateRoutes";
import { SelectAffiliateStep } from "./Steps/SelectAffiliateStep";
import { breakpoints } from "../../../utils/styledHelpers";

export type AffiliateType = GetAffiliate_affiliate | null;

const BASE_PATH = `*${HISTORICAL_ORDERS_PATH}`;
export const AFFILIATE_PATH = `${BASE_PATH}/:affiliateAppId`;

export type HistoricalOrdersPageProps = {
  username: string;
};

const HistoricalOrdersPage = ({ username }: HistoricalOrdersPageProps) => {
  return (
    <Container>
      <Route exact path={BASE_PATH}>
        <SelectAffiliateStep />
      </Route>
      <Route path={AFFILIATE_PATH}>
        <AffiliateRoutes username={username} />
      </Route>
    </Container>
  );
};

const Container = styled(FlexBox)`
  justify-content: center;
  background: #fafafa;
  text-align: center;
  padding: 32px 32px 64px 32px;
  min-height: calc(100vh - 100px);
  ${breakpoints.down("sm")} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

type Args = {
  topbar: any;
  historicalOrdersPage: HistoricalOrdersPageProps;
};

const mapEntryProps = ({ topbar, historicalOrdersPage }: Args) => ({
  pageProps: {
    topbarProps: topbar,
  },
  componentProps: historicalOrdersPage,
});

export default createEntryPage(mapEntryProps)(HistoricalOrdersPage);
