import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button, { BUTTON_SIZES } from "../../../elements/v2/Button/Button";
import { Input } from "../../../elements/v2/Form/Input";
import { ListingCardFragment } from "../../../../graphql/fragments/types/ListingCardFragment";
import { ListingData } from "../../../../utils/listings";
import { SortField } from "../SortListingsPage";

type Props = {
  selectedListing: Partial<ListingCardFragment> | ListingData;
  onConfirm: (position: number) => void;
  onCancel: () => void;
  sortField?: SortField;
};

// Convert to 1-based index for position editing, then convert to 0-based index on confirm
export default function MoveDialog({ selectedListing, onConfirm, onCancel, sortField }: Props) {
  const selectedSortField =
    sortField === SortField.PROFILE_SORT
      ? selectedListing?.profileSort
      : selectedListing?.conciergeSort;
  const defaultPosition = Number(selectedSortField || 0) + 1;
  const [position, setPosition] = useState<number>(defaultPosition);

  function handleClickYes() {
    onConfirm(position - 1);
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newPosition = Math.max(1, Number(e.target.value));
    setPosition(newPosition);
  }

  function handleFocus(event: React.FocusEvent<HTMLInputElement>) {
    event.target.select();
  }

  return (
    <Dialog open={!!selectedListing} onClose={() => onCancel()}>
      <DialogTitle>Move to position</DialogTitle>
      <DialogContent>
        <Input
          type="number"
          placeholder="Position"
          defaultValue={defaultPosition}
          onChange={handleChange}
          onFocus={handleFocus}
          autoFocus
        />
      </DialogContent>
      <DialogActions>
        <Button size={BUTTON_SIZES.SMALL} onClick={onCancel}>
          Cancel
        </Button>
        <Button size={BUTTON_SIZES.SMALL} onClick={handleClickYes}>
          Move
        </Button>
      </DialogActions>
    </Dialog>
  );
}
