import React, { useState } from "react";
import CampaignModal from "./CampaignModal";
import styled from "styled-components";
import { GMAIL_CONNECT_CAMPAIGN } from "../../../constants/CampaignModalConstants";
import { Svg } from "../../elements/Svg/Svg";
import NewIcon from "../../../assets/images/gmail/new.svg";
import { GapBox, ImportGmailOrdersProps } from "../ImportGmailOrders/ImportGmailOrders";
import { A, Span } from "../../elements/v2/Typography/Typography";
import GmailConnectButton from "../ImportGmailOrders/GmailConnectButton";
import { EmailCaptureConnectStartedFrom } from "../../../utils/analytics";
import GmailHowItWorksModal from "./GmailHowItWorksModal";
import ModalGif from "../../../assets/images/gmail/dressBagShoes.gif";

export default function GmailConnectModal({ gmailSuccess, currentUser }: ImportGmailOrdersProps) {
  const accessGrantSuccessful = gmailSuccess === "true";
  const accessGrantFailed = gmailSuccess === "false";

  if (!currentUser.featureEnabled) return null;
  if (currentUser.gmailLinked || accessGrantSuccessful || accessGrantFailed) return null;

  const [showHowItWorks, setShowHowItWorks] = useState(false);
  const handleHowItWorks = () => {
    setShowHowItWorks(true);
  };
  const onHowItWorksClose = () => {
    setShowHowItWorks(false);
  };

  return (
    <Modal
      name={GMAIL_CONNECT_CAMPAIGN.NAME}
      renderDelaySeconds={GMAIL_CONNECT_CAMPAIGN.RENDER_DELAY_SECONDS}
      hideForDaysOnClose={GMAIL_CONNECT_CAMPAIGN.HIDE_ON_CLOSE_DAYS}
      maxWidth={"391px"}
    >
      <GmailHowItWorksModal
        open={showHowItWorks}
        from={EmailCaptureConnectStartedFrom.MODAL_HOW_IT_WORKS}
        onClose={onHowItWorksClose}
        currentUser={currentUser}
        minHeight={"607px"}
      />
      <Content>
        <TitleText>{<Svg content={NewIcon} />} Sync your past purchases</TitleText>
        <img src={ModalGif} alt={"modal image"} height={355} />
        <GapBox $gap={16}>
          <Text>Instantly import all your past email purchases to see how much you could earn</Text>
          <GmailConnectButton
            from={EmailCaptureConnectStartedFrom.MODAL}
            currentUser={currentUser}
            $transparent
          />
          <CenteredLinkText $underline={true} onClick={handleHowItWorks} textAlign={"center"}>
            How it works
          </CenteredLinkText>
        </GapBox>
      </Content>
    </Modal>
  );
}

const Modal = styled(CampaignModal)`
  .MuiPaper-root {
    border-radius: 15px;
  }
  .MuiDialogContent-root {
    padding: 0;
    background-color: #fcebe5;
  }
  font-size: 16px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  margin-bottom: 23px;
  min-height: 60vh;
`;

const TitleText = styled(Span)`
  text-align: center;
  font-weight: 400;
  font-size: 40px;
  font-family: "Lora", serif;
  line-height: 110%;
`;

const Text = styled(Span)`
  font-size: 14px;
  text-align: center;
  margin-left: 0;
  line-height: 20px;
`;

const CenteredLinkText = styled(A)`
  text-align: center;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
`;
