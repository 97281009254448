import React from "react";
import { GetListing_listing } from "../../queries/types/GetListing";
import Box, { BoxProps } from "../../../../elements/v2/Box/Box";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";

type Props = BoxProps & {
  listing?: GetListing_listing;
  googleMapsApiKey: string;
};

const libraries: Libraries = ["places"];

export default function ListingLocationMap({ listing, googleMapsApiKey, ...props }: Props) {
  const { location } = listing;

  if (!location?.latitude || !location?.longitude) return null;

  const position = {
    lat: location.latitude,
    lng: location.longitude,
  };

  return (
    <Box {...props}>
      <LoadScript googleMapsApiKey={googleMapsApiKey} libraries={libraries} language="en">
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "100%",
          }}
          center={position}
          zoom={13}
          options={{
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false,
          }}
          clickableIcons={false}
        >
          <Marker position={position} />
        </GoogleMap>
      </LoadScript>
    </Box>
  );
}
