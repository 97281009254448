import { gql } from "@apollo/client";

const AcceptTransaction = gql`
  mutation AcceptTransaction($input: AcceptTransactionMutationInput!) {
    acceptTransaction(input: $input) {
      transaction {
        id
        currentState
        shipped
        deliveryMethod
      }
      error
    }
  }
`;

export default AcceptTransaction;
