import React from "react";
import styled from "styled-components";
import GridBox from "../../../elements/v2/Box/GridBox";
import { A, Span } from "../../../elements/v2/Typography/Typography";
import { Section } from "../elements/Section";
import FlexBox from "../../../elements/v2/Box/FlexBox";
import { removeBrandText, removeSizeText } from "../../../../utils/listingHelper";

export interface ListingDescriptionProps {
  seoPath: string;
  title: string;
  previewUrl: string;
  listingId: string;
  brandName: string;
  size: string;
}

interface Props {
  listingDescriptionProps: ListingDescriptionProps;
}

export default function ListingDescription({ listingDescriptionProps }: Props) {
  const { seoPath, title, previewUrl, brandName, size } = listingDescriptionProps;
  return (
    <Section>
      <GridBox gridTemplateColumns="1fr 2fr" gridGap="16px">
        <LinkedImageContainer href={seoPath}>
          <DescriptionImage src={previewUrl} alt={title} />
        </LinkedImageContainer>
        <FlexBox flexDirection="column" $gap={1}>
          <BrandText>{brandName}</BrandText>
          <A href={seoPath}>
            <DescriptionText>
              {size
                ? removeSizeText(removeBrandText(title, brandName))
                : removeBrandText(title, brandName)}
            </DescriptionText>
          </A>
          {size && <Text>Size: {size}</Text>}
        </FlexBox>
      </GridBox>
    </Section>
  );
}

const DescriptionImage = styled.img`
  width: 80px;
`;

const Text = styled(Span)`
  color: #222;
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
`;

const DescriptionText = styled(Text)`
  text-transform: capitalize;
`;

const LinkedImageContainer = styled.a`
  width: 80px;
  padding: 0;
`;

const BrandText = styled(Text)`
  text-transform: uppercase;
  letter-spacing: 1.04px;
`;
