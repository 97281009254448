import React from "react";
import { Divider } from "@material-ui/core";
import styled from "styled-components";
import stringHumanize from "string-humanize";
import Typography from "../../../elements/v2/Typography/Typography";
import { useSearchPageContext } from "../SearchPageContext";
import { FilterNames } from "../../../../utils/filters";
import { DepartmentFilter } from "./filters/DepartmentFilter";
import FilterSection from "./FilterSection";
import FiltersWrapper from "./FiltersWrapper";
import ChipFilter from "./filters/ChipFilter";
import CategoryFilter from "./filters/CategoryFilter";
import BrandFilter from "./filters/BrandFilter";
import SubFieldFilter from "./filters/SubFieldFilter";
import ColourFilter from "./filters/ColourFilter";
import PriceFilter from "./filters/PriceFilter";
import SizeGuide from "../../../composites/v2/SizeGuide/SizeGuide";
import AdaptiveFilter from "./filters/AdaptiveFilter";

export function humanizeDealsLabel(label: string) {
  return label.replace(/\|/, " ");
}
function SearchFilters({ countryCode }: { countryCode?: string }) {
  const { collectionId, categories, isProfilePage } = useSearchPageContext();
  const showDepartmentFilter = !collectionId && !isProfilePage;

  return (
    <FiltersWrapper>
      <div className="t-searchFilters">
        <Text>Filter</Text>
        <Divider variant="middle" component="p" />
        {showDepartmentFilter && (
          <FilterSection label="Department">
            <DepartmentFilter />
          </FilterSection>
        )}
        <FilterSection label="Category" filterName={FilterNames.category} minOptionsWithCount={3}>
          <CategoryFilter categories={categories} />
        </FilterSection>
        <FilterSection label="Brand" filterName={FilterNames.brand}>
          <BrandFilter />
        </FilterSection>
        <FilterSection label="Size" filterName={FilterNames.size}>
          <SubFieldFilter filterName={FilterNames.size} countryCode={countryCode} />
          <SizeGuide icon={<UnderlinedText>Size Guide</UnderlinedText>} />
        </FilterSection>
        <FilterSection label="Length" filterName={FilterNames.length}>
          <ChipFilter filterName={FilterNames.length} />
        </FilterSection>
        <FilterSection label="Colour" filterName={FilterNames.colour}>
          <ColourFilter />
        </FilterSection>
        <FilterSection label="Pattern" filterName={FilterNames.pattern}>
          <ChipFilter filterName={FilterNames.pattern} />
        </FilterSection>
        <FilterSection label="Price" filterName={FilterNames.price}>
          <PriceFilter />
        </FilterSection>
        <FilterSection label="Deals" filterName={FilterNames.deals}>
          <ChipFilter filterName={FilterNames.deals} labelRender={humanizeDealsLabel} />
        </FilterSection>
        <FilterSection label="Buy/Rent" filterName={FilterNames.itemType}>
          <ChipFilter filterName={FilterNames.itemType} />
        </FilterSection>
        <FilterSection label="Condition" filterName={FilterNames.condition}>
          <ChipFilter filterName={FilterNames.condition} labelRender={stringHumanize} />
        </FilterSection>
        <AdaptiveFilter />
        <FilterSection label="Comes with" filterName={FilterNames.comesWith}>
          <ChipFilter filterName={FilterNames.comesWith} />
        </FilterSection>
        <FilterSection label="Material" filterName={FilterNames.material}>
          <SubFieldFilter filterName={FilterNames.material} />
        </FilterSection>
        <FilterSection label="Occasion" filterName={FilterNames.occasion}>
          <ChipFilter filterName={FilterNames.occasion} />
        </FilterSection>
        <FilterSection label="Style" filterName={FilterNames.style}>
          <SubFieldFilter filterName={FilterNames.style} />
        </FilterSection>
      </div>
    </FiltersWrapper>
  );
}

export default SearchFilters;

const Text = styled(Typography)`
  font-size: 9px;
  margin-left: 16px;
  color: #555555;
  text-transform: uppercase;
  margin-bottom: 1rem;
`;

const UnderlinedText = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;
