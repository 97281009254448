import React from "react";
import Section from "./Section";
import { t } from "../../../../utils/i18n";
import { TopbarUserProps } from "../types";
import {
  USER_ACCOUNT_PATH,
  USER_PAYMENTS_PATH,
  USER_SETTINGS_PATH,
  USER_TRANSACTIONS_PATH,
} from "../../../../utils/paths";
import { MobileMenuItemUser } from "./MobileMenuItem";

type MenuMobileUserSettingsProps = {
  user: TopbarUserProps;
};

export default function SectionUserSettings({ user }: MenuMobileUserSettingsProps) {
  const { loggedInUsername } = user;

  if (!loggedInUsername) return null;

  return (
    <Section title={t("web.topbar.settings")}>
      <MobileMenuItemUser path={USER_SETTINGS_PATH} username={loggedInUsername} exact>
        {t("web.topbar.profile_setting")}
      </MobileMenuItemUser>
      <MobileMenuItemUser path={USER_TRANSACTIONS_PATH} username={loggedInUsername}>
        {t("web.topbar.transactions")}
      </MobileMenuItemUser>
      <MobileMenuItemUser path={USER_ACCOUNT_PATH} username={loggedInUsername}>
        {t("web.topbar.account_settings")}
      </MobileMenuItemUser>
      <MobileMenuItemUser path={USER_PAYMENTS_PATH} username={loggedInUsername}>
        {t("web.topbar.payment_settings")}
      </MobileMenuItemUser>
    </Section>
  );
}
