import React from "react";
import classNames from "classnames";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { t } from "../../../utils/i18n";
import { upsertSearchQueryParam } from "../../../utils/url";
import { ArrowButton, ArrowButton_Direction } from "../../elements/RoundButton/RoundButton";
import css from "./PageSelection.module.css";

interface Props {
  className?: string;
  currentPage: number;
  totalPages: number;
  location: any;
  pageParam: string;
  handleClick?: () => void;
}

function PageSelection({
  className,
  currentPage,
  totalPages,
  pageParam,
  location,
  handleClick,
}: Props) {
  const hasNext = totalPages > currentPage;
  const hasPrev = currentPage > 1;
  const history = useHistory();

  const getLocation = (num) => {
    const newParams = upsertSearchQueryParam(location, pageParam, num);
    const locationBase = location.split("?")[0];
    return `${locationBase}?${newParams}`;
  };

  const pageDropdown = () => {
    return (
      <span className={css.selectContainer}>
        <select
          className={css.select}
          value={currentPage}
          onChange={(event) => {
            const num = parseInt(event.target.value, 10);
            history.push(getLocation(num));
          }}
        >
          {_.range(1, totalPages + 1).map((page, idx) => {
            return (
              <option value={page} key={idx}>
                {page}
              </option>
            );
          })}
        </select>
      </span>
    );
  };

  return (
    <div className={classNames(className, css.pageSelection)}>
      <p className={css.pageOf}>
        {t("web.search.page")}
        {pageDropdown()}
        {t("web.search.page_of_pages", {
          total_number_of_pages: totalPages,
        })}
      </p>
      <div className={css.arrowButtonsWide}>
        <ArrowButton
          link={getLocation(currentPage - 1)}
          direction={ArrowButton_Direction.LEFT}
          isDisabled={!hasPrev}
          handleClick={handleClick}
        />
        <ArrowButton
          link={getLocation(currentPage + 1)}
          direction={ArrowButton_Direction.RIGHT}
          isDisabled={!hasNext}
          handleClick={handleClick}
        />
      </div>
    </div>
  );
}

export default PageSelection;
