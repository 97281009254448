import classNames from "classnames";
import React from "react";
import css from "./FavouriteIcon.module.css";

export interface Props {
  handleClick: (event: MouseEvent<any>) => void;
  isActive: boolean;
  size: number;
  className?: string;
}

// Our favourite icon is a little heart that, when clicked, opens a dropdown box for a user to add
// the listing to a collection.
function FavouriteIcon({ handleClick, className, isActive, size = 23 }: Props) {
  return (
    <div
      className={classNames(className, { "t-favouriteIcon--active": isActive })}
      onClick={handleClick}
      style={{ width: size }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 201.16 201.16">
        <g>
          <path
            className={classNames(css.icon, {
              [css.active]: isActive,
            })}
            d="M101.28,95c-8-6.57-13.86-12.44-20.69-16.73-14-8.81-30.63-6-41.25,6-11.26,12.73-12,27.65.6,40.91,19.22,20.15,39.84,39,60.82,59.25,20.37-19.73,39.89-38,58.64-57,14.15-14.36,14.52-30.22,2.42-43.57C150,70.84,130.67,69.76,116,81.57,111.06,85.52,106.64,90.09,101.28,95Z"
          />
        </g>
      </svg>
    </div>
  );
}

export default FavouriteIcon;
