import classNames from "classnames";
import React from "react";
import SmartImage from "../../composites/SmartImage/SmartImage";
import css from "./Avatar.module.css";
import { TopbarAvatarProps } from "../../widgets/Topbar/types";

export type AvatarProps = TopbarAvatarProps & {
  url?: string;
  imageHeight?: number;
  className?: string;
  color?: string;
  round?: boolean;
};

export default function Avatar({
  image,
  imageHeight,
  className,
  url,
  givenName,
  familyName,
  color,
  round = true,
}: AvatarProps) {
  const displayName = [givenName, familyName].join(" ").trim();
  const initials = [givenName, familyName]
    .filter((n) => typeof n === "string")
    .map((n) => n.substring(0, 1))
    .join("");
  const height = imageHeight ? imageHeight : "100%";

  const textEl = (
    <div
      className={classNames("Avatar", className, css.textAvatar, {
        [css.round]: round,
      })}
      style={{ height, width: height, backgroundColor: color }}
      title={displayName}
    >
      {initials}
    </div>
  );
  const imageEl = (
    <SmartImage
      className={classNames("Avatar", className, css.Avatar, {
        [css.round]: round,
      })}
      imageUrl={image?.url}
      fallbackElement={textEl}
      style={{ height, width: height }}
    />
  );
  const displayEl = imageEl ? imageEl : textEl;

  return url ? (
    <a href={url} className={css.link}>
      {displayEl}
    </a>
  ) : (
    displayEl
  );
}
