import React from "react";
import { formatMoney } from "../../../../../utils/numbers";
import { mapPriceToMoneyParts } from "../../../../../utils/money";
import { PriceSpan, BoxRow, Money, RentalProps } from "./PriceBreakdown";
import { formatRentalDate } from "../../../../../utils/date";

interface Props {
  rental: RentalProps;
  salePrice: Money;
  subtotalPrice: Money;
  totalPrice: Money;
  shippingPrice: Money;
}

export enum UnitType {
  month = "month",
  occasion = "night",
}

export default function Rental({
  rental: { startOn, endOn, duration, unitType },
  salePrice,
}: Props) {
  if (!unitType) return null;

  if (unitType === UnitType.month)
    return (
      <>
        <BoxRow>
          <PriceSpan>Price per month</PriceSpan>
          <PriceSpan>{formatMoney(mapPriceToMoneyParts(salePrice))}</PriceSpan>
        </BoxRow>
        <BoxRow>
          <PriceSpan>Number of months</PriceSpan>
          <PriceSpan>{duration}</PriceSpan>
        </BoxRow>
      </>
    );

  if (unitType === UnitType.occasion)
    return (
      <>
        <BoxRow>
          <PriceSpan>Price per occasion</PriceSpan>
          <PriceSpan>{formatMoney(mapPriceToMoneyParts(salePrice))}</PriceSpan>
        </BoxRow>
        <BoxRow>
          <PriceSpan>Start rental</PriceSpan>
          <PriceSpan>{formatRentalDate(startOn)}</PriceSpan>
        </BoxRow>
        <BoxRow>
          <PriceSpan>End rental</PriceSpan>
          <PriceSpan>{formatRentalDate(endOn)}</PriceSpan>
        </BoxRow>
        <BoxRow>
          <PriceSpan>Occasion</PriceSpan>
          <PriceSpan>{duration}</PriceSpan>
        </BoxRow>
      </>
    );
}
