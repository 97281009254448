import { gql, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import Dropdown from "../../composites/Dropdown/Dropdown";
import LoadingOverlay from "../../elements/LoadingIndicator/LoadingOverlay";
import { countryLabel } from "../CountryPicker/components/Country";
import CountryPicker from "../CountryPicker/CountryPicker";
import css from "./CountryPickerDropdown.module.css";
import SET_USER_COUNTRY_CODE_MUTATION from "./mutations/SetUserCountryCodeMutation.graphql";
import {
  GetCountryPickerDropdownProps,
  GetCountryPickerDropdownProps_countries,
} from "./queries/types/GetCountryPickerDropdownProps";

export type CountryPickerDropdownProps = GetCountryPickerDropdownProps;
type Country = GetCountryPickerDropdownProps_countries;

export default function CountryPickerDropdown({
  countries,
  myCountry,
}: CountryPickerDropdownProps) {
  const [selectedCountry, setSelectedCountry] = useState<Country>(myCountry);

  // We set this to true when the user chooses a country, and don't set it to false if the response
  // is successful, because we also trigger a page reload at this point, and we want the loading
  // animation to continue until the page has reloaded.
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [sendCountryChoiceToAPI, { loading, error }] = useMutation(
    gql(SET_USER_COUNTRY_CODE_MUTATION),
    {
      onCompleted({ setMyCountry: { success } }) {
        if (success) {
          window.location.reload();
        } else {
          setIsSubmitting(false);
        }
      },
    }
  );

  useEffect(() => {
    if (loading) {
      setIsSubmitting(true);
    }
  }, [loading]);

  useEffect(() => {
    if (error) {
      (window as any).FlashMessage.error(`Sorry, that didn't work: ${error.message}`);
      setIsSubmitting(false);
    }
  }, [error]);

  const handleCountryClick = (country: GetCountryPickerDropdownProps_countries) => {
    setSelectedCountry(country);
    sendCountryChoiceToAPI({
      variables: { input: { countryCode: country.code } },
    });
  };

  const handleComponent = <div className={css.handle}>{countryLabel(selectedCountry)}</div>;

  return (
    <Dropdown
      className={css.dropdown}
      contentClassName={css.dropdownContent}
      handleComponent={handleComponent}
    >
      {isSubmitting ? <LoadingOverlay /> : null}
      <CountryPicker
        {...{
          countries,
          selectedCountry,
          handleCountryClick,
          isSubmitting,
        }}
      />
    </Dropdown>
  );
}
