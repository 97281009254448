import React from "react";
import styled from "styled-components";
import {
  GetListing_listing,
  GetListing_listing_customFields_value_CustomCheckboxFieldValue,
  GetListing_listing_customFields_value_CustomCheckboxFieldValue_selectedOptions,
} from "../../queries/types/GetListing";
import Box, { BoxProps } from "../../../../elements/v2/Box/Box";
import Typography from "../../../../elements/v2/Typography/Typography";
import { CUSTOM_FIELD_KEYS } from "../../../../../constants/CustomFieldConstants";

type Props = BoxProps & {
  listing?: GetListing_listing;
};

type ColourOption = GetListing_listing_customFields_value_CustomCheckboxFieldValue_selectedOptions;
type CheckboxFieldValue = GetListing_listing_customFields_value_CustomCheckboxFieldValue;

export function getCustomFieldColours(listing: GetListing_listing): string[] {
  const value = listing.customFields.find((f) => f.key === CUSTOM_FIELD_KEYS.COLOUR)
    ?.value as CheckboxFieldValue;
  if (!value) return null;

  return value.selectedOptions.map((option: ColourOption) => option.title);
}

export function getCustomFieldColourFirst(listing: GetListing_listing): string {
  return getCustomFieldColours(listing)?.splice(0, 1)[0] || null;
}

export default function Colour({ listing, ...props }: Props) {
  const colourValue = getCustomFieldColours(listing)?.join(", ");
  if (!colourValue) return null;

  return (
    <Box {...props}>
      <Content>Colour: {colourValue}</Content>
    </Box>
  );
}

const Content = styled(Typography)`
  display: inline-flex;
  gap: 5px;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #222222;
`;
