import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import GET_CURRENT_USER from "./queries/GetCurrentUser";
import { GetCurrentUser } from "./queries/types/GetCurrentUser";
import { Sidetab } from "@typeform/embed-react";
import TypeformConstants from "../../../constants/TypeformConstants";
import { useMobileMediaQuery } from "../../../utils/useMediaQueries";
import { isBefore } from "../../../utils/date";

export default function ProductFeedbackSurvey() {
  const formId = TypeformConstants.PROD_FEEDBACK_SURVEY_FORM_ID;
  const [showSurvey, setShowSurvey] = useState(false);
  const { data, loading, error } = useQuery<GetCurrentUser>(GET_CURRENT_USER);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [yearOfBirth, setYearOfBirth] = useState("");
  const { isMobile } = useMobileMediaQuery();

  if (error) {
    console.error(error);
  }

  const moveZendeskBehindSurvey = () => {
    window.zE("messenger:set", "zIndex", 9999);
  };

  const hideSurveyForNinetyDays = () => {
    const currentDate = new Date();
    const futureDate = new Date();
    futureDate.setDate(currentDate.getDate() + 90);

    localStorage.setItem(
      `ar-prod-survey:next-show-${formId}`,
      futureDate.toISOString().slice(0, 10)
    );
  };

  useEffect(() => {
    const nextShowDate = localStorage.getItem(`ar-prod-survey:next-show-${formId}`);
    const isDismissed = nextShowDate && isBefore(new Date(), nextShowDate);
    const user = data?.currentUser;

    if (isDismissed || error || loading || isMobile || !user) {
      setShowSurvey(false);
    } else {
      setName(user.givenName);
      setEmail(user.primaryEmail?.address);
      setYearOfBirth(user.yearOfBirth?.toString());
      setShowSurvey(true);
    }
  }, [isMobile, data, error, loading]);

  useEffect(() => {
    if (!showSurvey) return;

    // add click event listener to the ⓧ icon of sidetab once it loads
    const closeButton = document.querySelector('[data-testid="default-icon"]');
    closeButton.addEventListener("click", () => {
      hideSurveyForNinetyDays();
      setShowSurvey(false);
    });
  }, [showSurvey]);

  return (
    <>
      {showSurvey ? (
        <Sidetab
          id={formId}
          opacity={98}
          iframeProps={{ title: TypeformConstants.PROD_FEEDBACK_SURVEY_TITLE }}
          hidden={{ email, name, yob: yearOfBirth }}
          medium="snippet"
          onReady={moveZendeskBehindSurvey}
          onSubmit={hideSurveyForNinetyDays}
          buttonText="Feedback?"
          buttonColor="#040404"
          customIcon="ⓧ"
        />
      ) : null}
    </>
  );
}
