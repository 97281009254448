import React from "react";
import styled from "styled-components";
import FlexBox from "../../elements/v2/Box/FlexBox";
import {
  HOME_ABOUT_URL,
  HOME_CIRCULAR_WARDROBE_URL,
  HOME_HOW_TO_BUY_URL,
  HOME_HOW_TO_SELL_URL,
  HOME_PARTNER_BRANDS_URL,
  HOME_URL,
  NAV_KIDS_PATH,
  NAV_MEN_PATH,
  NAV_WOMEN_PATH,
  SIGNUP_PATH,
  SUPPORT_CONTACT_URL,
  SUPPORT_URL,
} from "../../../utils/paths";
import { breakpoints } from "../../../utils/styledHelpers";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { ExpandMore as MuiExpandMoreIcon } from "@material-ui/icons";
import TypographyBase from "../../elements/v2/Typography/Typography";
import { TextButton } from "../../elements/v2/Button/Button";

type ItemProps = {
  t: string;
  href: string;
};

type SectionProps = {
  section_t: string;
  items: ItemProps[];
};

const sections: SectionProps[] = [
  {
    section_t: "web.footer.menu.about",
    items: [
      { t: "web.footer.menu.about", href: HOME_ABOUT_URL },
      { t: "web.footer.menu.create_account", href: SIGNUP_PATH },
      { t: "web.footer.menu.businesses", href: HOME_URL },
    ],
  },
  {
    section_t: "web.footer.menu.circular_wardrobe",
    items: [
      { t: "web.footer.menu.circular_wardrobe_info", href: HOME_CIRCULAR_WARDROBE_URL },
      { t: "web.footer.menu.brand_partners", href: HOME_PARTNER_BRANDS_URL },
    ],
  },
  {
    section_t: "web.footer.menu.shop",
    items: [
      { t: "web.footer.menu.shop_women", href: NAV_WOMEN_PATH },
      { t: "web.footer.menu.shop_men", href: NAV_MEN_PATH },
      { t: "web.footer.menu.shop_kids", href: NAV_KIDS_PATH },
    ],
  },
  {
    section_t: "web.footer.menu.how_to",
    items: [
      { t: "web.footer.menu.how_to_sell", href: HOME_HOW_TO_SELL_URL },
      { t: "web.footer.menu.how_to_buy", href: HOME_HOW_TO_BUY_URL },
      { t: "web.footer.menu.create_listing", href: HOME_HOW_TO_SELL_URL },
      { t: "web.footer.menu.create_circular_wardrobe", href: HOME_CIRCULAR_WARDROBE_URL },
    ],
  },
  {
    section_t: "web.footer.menu.help",
    items: [
      { t: "web.footer.menu.faqs", href: SUPPORT_URL },
      { t: "web.footer.menu.contact_us", href: SUPPORT_CONTACT_URL },
    ],
  },
];

export default function FooterMenu() {
  return (
    <Container>
      {sections.map(({ section_t, items }) => (
        <Section key={section_t}>
          <Header expandIcon={<ExpandMoreIcon />}>
            <Title t={section_t} />
          </Header>
          <Content>
            {items.map(({ t, href }) => (
              <Item key={t} t={t} href={href} />
            ))}
          </Content>
        </Section>
      ))}
    </Container>
  );
}

const Container = styled(FlexBox)`
  flex-direction: column;
  gap: 0;
  ${breakpoints.up("sm")} {
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
  }
  ${breakpoints.up("md")} {
    justify-content: flex-start;
    gap: 32px;
  }
  ${breakpoints.up("lg")} {
    gap: 64px;
  }
`;

const Section = styled(Accordion)`
  background: transparent;
  border-top: 1px solid #e2e2e2;
  &:last-child {
    border-bottom: 1px solid #e2e2e2;
  }
  ${breakpoints.up("sm")} {
    border: none;
    &:last-child {
      border: none;
    }
    .MuiCollapse-root {
      height: auto !important;
      visibility: visible !important;
    }
  }
`;

const Header = styled(AccordionSummary)`
  padding: 0;
  position: relative;
  width: 100%;
  height: 56px;
  align-items: center;
  justify-content: space-between;
  ${breakpoints.up("sm")} {
    align-items: flex-start;
    height: 70px;
    pointer-events: none;
  }
`;

const Title = styled(TypographyBase)`
  font-size: 15px;
  color: #111111;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1.5em;
  ${breakpoints.up("sm")} {
    white-space: pre-line;
  }
`;

const Content = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px 0 44px;
  ${breakpoints.up("sm")} {
    gap: 24px;
    padding: 0;
    margin-top: 32px;
  }
`;

export const Item = styled(TextButton)`
  .MuiButton-label {
    color: #111111;
    text-transform: initial;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.5em;
    text-decoration: initial;
    letter-spacing: initial;
    ${breakpoints.up("sm")} {
      white-space: pre-line;
    }
  }
  &:hover .MuiButton-label {
    color: black;
  }
`;

const ExpandMoreIcon = styled(MuiExpandMoreIcon)`
  color: #111111;
  ${breakpoints.up("sm")} {
    display: none;
  }
`;
