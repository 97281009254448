import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button, { BUTTON_SIZES } from "../../../elements/v2/Button/Button";
import styled from "styled-components";

type Props = {
  title?: string;
  children: React.ReactNode;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  onConfirm: () => void;
};

const ConfirmDialog = ({ title, children, open, setOpen, onConfirm }: Props) => {
  function handleClickNo() {
    setOpen(false);
  }

  function handleClickYes() {
    setOpen(false);
    onConfirm();
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <Content>{children}</Content>
      <DialogActions>
        <Button size={BUTTON_SIZES.SMALL} onClick={handleClickNo}>
          No
        </Button>
        <Button size={BUTTON_SIZES.SMALL} onClick={handleClickYes}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;

const Content = styled(DialogContent)`
  white-space: pre-wrap;
`;
