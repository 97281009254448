import { gql } from "@apollo/client";

export default gql`
  fragment MoneyParts on Money {
    fractional
    currency {
      symbol
      code
      symbolFirst
    }
  }
`;
