import pluralize from "pluralize";
import React from "react";
import styled from "styled-components";
import { A, Span } from "../../../elements/v2/Typography/Typography";

export interface Props {
  number?: number;
  word: string;
  link?: string;
}

export default function PersonSummaryItem({ link, number, word }: Props) {
  const text = pluralize(word, number || 0);

  return (
    <LinkBoxStyled href={link}>
      <SpanNumber>{number}</SpanNumber>
      <SpanText>{text}</SpanText>
    </LinkBoxStyled>
  );
}

const SpanNumber = styled(Span)`
  display: inline;
  margin-right: 4px;
  font-weight: 500;
`;

const SpanText = styled(Span)`
  color: #555;
  margin-right: 10px;
`;

const LinkBoxStyled = styled(A)`
  &:last-child ${SpanText} {
    margin-right: 0;
  }
`;
