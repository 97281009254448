import React from "react";
import { Route, Switch } from "react-router-dom";
import { CurrencyCode } from "../../../types/graphql-global-types";
import { GoogleMapsProps } from "../../composites/FormControls/AddressAutoComplete/AddressAutoComplete";
import { S3Props } from "../../composites/FormControls/ImageUploader/ImageUploader";
import DonateListingForm from "../../composites/ListingForm/DonateListingForm";
import ListingDonationSuccess from "../../composites/ListingForm/ListingSuccess/ListingDonationSuccess";
import ListingSuccess from "../../composites/ListingForm/ListingSuccess/ListingSuccess";
import ProductForm from "../../composites/ListingForm/ProductForm";
import ProductSuccess, { ListingShape } from "../../composites/ListingForm/ProductSuccess";
import { GetListingFormData_listing } from "../../composites/ListingForm/queries/types/GetListingFormData";
import RentListingForm from "../../composites/ListingForm/RentListingForm";
import ResellListingForm from "../../composites/ListingForm/ResellListingForm";
import css from "./ListingFormPage.module.css";
import { createEntryPage } from "../FullPageLayout/FullPageLayout";

export interface ConditionWarning {
  message: string;
  url: string;
  anchorText: string;
}

interface Props {
  listing: GetListingFormData_listing;
  s3Props: S3Props;
  googleMapsProps: GoogleMapsProps;
  listingShapeId: number;
  listingShapes: Record<ListingShape, number>;
  listingShape: "rent" | "resell";
  initialCurrency: CurrencyCode;
  minimumPrice: number;
  conditionWarning: ConditionWarning;
}

function ListingFormPage({
  listing,
  s3Props,
  googleMapsProps,
  listingShapeId,
  listingShapes,
  listingShape,
  initialCurrency,
  minimumPrice,
  conditionWarning,
}: Props) {
  return (
    <div className={css.root}>
      <Switch>
        <Route exact path="*/donate/success">
          <ListingDonationSuccess listing={listing} />
        </Route>
        <Route path="*/list/success">
          <ListingSuccess listing={listing} />
        </Route>
        <Route exact path="*/list">
          {listingShape == "resell" ? (
            <ResellListingForm
              listing={listing}
              s3Props={s3Props}
              googleMapsProps={googleMapsProps}
              listingShapeId={listingShapeId}
              initialCurrency={initialCurrency}
              minimumPrice={minimumPrice}
              conditionWarning={conditionWarning}
            />
          ) : (
            <RentListingForm
              listing={listing}
              s3Props={s3Props}
              googleMapsProps={googleMapsProps}
              listingShapeId={listingShapeId}
              initialCurrency={initialCurrency}
              minimumPrice={minimumPrice}
              conditionWarning={conditionWarning}
            />
          )}
        </Route>
        <Route exact path="*/donate">
          <DonateListingForm listing={listing} />
        </Route>
        <Route path="*/products/*/success">
          <ProductSuccess listingShapes={listingShapes} listing={listing} />
        </Route>
        <Route path="*/products/new">
          <ProductForm s3Props={s3Props} initialCurrency={initialCurrency} />
        </Route>
        <Route path="*/products/*/edit">
          <ProductForm listing={listing} s3Props={s3Props} initialCurrency={initialCurrency} />
        </Route>
      </Switch>
    </div>
  );
}

type Args = {
  listingFormPage: Props;
  topbar: any; // This will remain a black box to typescript until we rewrite it
};

const mapEntryProps = ({ topbar, listingFormPage }: Args, context) => ({
  pageProps: { topbarProps: topbar },
  componentProps: listingFormPage,
});

export default createEntryPage(mapEntryProps)(ListingFormPage);
