import styled from "styled-components";
import { Span } from "./v2/Typography/Typography";
import { TextButton } from "./v2/Button/Button";

export const PdpSpecialInfoText = styled(Span)`
  padding-left: 4px;
  padding-right: 4px;
  vertical-align: super;
  font-size: medium;
  font-weight: bold;
`;

export const PdpMoreInfoText = styled(TextButton)`
  vertical-align: super;

  .MuiButton-label {
    text-decoration: underline;
    text-decoration-style: dotted;
    text-transform: none;
  }
`;
