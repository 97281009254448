import React from "react";
import { Section } from "../../elements/Section";
import DiscountCode from "./DiscountCode";
import { formatMoney } from "../../../../../utils/numbers";
import { mapPriceToMoneyParts } from "../../../../../utils/money";
import { BoldSpan, Span } from "../../../../elements/v2/Typography/Typography";
import styled from "styled-components";
import Rental, { UnitType } from "./Rental";
import Box from "../../../../elements/v2/Box/Box";
import { CurrencyCode } from "../../../../../types/graphql-global-types";

export interface Money {
  cents?: number;
  currency_iso?: CurrencyCode;
  fractional?: number;
  currency?: {
    code?: CurrencyCode;
    symbol?: string;
    symbolFirst?: boolean;
  };
}

export interface OfferProps {
  customOfferProvided: boolean;
  priceCents: number;
  currencyCode: CurrencyCode;
  listing: {
    id: string;
    priceCents: number;
    currency: CurrencyCode;
  };
  usableOffer: boolean;
  transactionUnitPrice: number;
  transactionOriginalPrice: number;
}

export interface RentalProps {
  startOn: string;
  endOn: string;
  duration: number;
  unitType: string;
  quantity: number;
}

export interface PriceBreakDownProps {
  salePrice: Money;
  originalListingPrice: Money;
  subtotalPrice: Money;
  totalPrice: Money;
  shippingPrice: Money;
  offer: OfferProps;
  rental: RentalProps;
}

export interface OrderSummaryProps {
  priceBreakDownProps: PriceBreakDownProps;
  listingId: string;
  discountCodeDetails: DiscountCodeDetails;
  setDiscountCodeDetails: (values: DiscountCodeDetails) => void;
  shippingRequired: boolean;
}

export interface DiscountCodeDetails {
  isDiscountCodeSet: boolean;
  discountCode: string;
  discountValue?: Money | undefined;
  discountedTotalValue?: Money | undefined;
}

function calaculateMonthlySubtotal(salePrice: Money, numberOfMonths: number) {
  return formatMoney(
    mapPriceToMoneyParts({
      cents: salePrice.cents * numberOfMonths,
      currency_iso: salePrice.currency_iso,
    })
  );
}

export default function PriceBreakdown({
  priceBreakDownProps: {
    shippingPrice,
    totalPrice,
    salePrice,
    subtotalPrice,
    originalListingPrice,
    rental,
    offer,
  },
  listingId,
  discountCodeDetails,
  setDiscountCodeDetails,
  shippingRequired,
}: OrderSummaryProps) {
  const showDiscountCode = listingId && !rental.unitType;
  const subtotal =
    rental.unitType === UnitType.month
      ? calaculateMonthlySubtotal(salePrice, rental.quantity)
      : formatMoney(mapPriceToMoneyParts(salePrice));

  return (
    <Section>
      {showDiscountCode && (
        <DiscountCode
          listingId={listingId}
          shippingPrice={shippingPrice}
          salePrice={salePrice}
          subtotalPrice={subtotalPrice}
          discountCodeDetails={discountCodeDetails}
          setDiscountCodeDetails={setDiscountCodeDetails}
        />
      )}
      {offer.customOfferProvided && (
        <>
          <BoxRow>
            <PriceSpan>Listed price</PriceSpan>
            <PriceSpan>{formatMoney(mapPriceToMoneyParts(originalListingPrice))}</PriceSpan>
          </BoxRow>
          <OfferRow>
            <OfferPriceSpan>Offer</OfferPriceSpan>
            <OfferPriceSpan>{formatMoney(mapPriceToMoneyParts(salePrice))}</OfferPriceSpan>
          </OfferRow>
        </>
      )}
      <Rental
        rental={rental}
        shippingPrice={shippingPrice}
        totalPrice={totalPrice}
        salePrice={salePrice}
        subtotalPrice={subtotalPrice}
      />
      {!offer.customOfferProvided && (
        <BoxRow mt={2}>
          <OfferPriceSpan>Subtotal</OfferPriceSpan>
          <OfferPriceSpan>{subtotal}</OfferPriceSpan>
        </BoxRow>
      )}
      {shippingRequired && (
        <BoxRow>
          <PriceSpan>Shipping</PriceSpan>
          <PriceSpan>
            {shippingPrice?.cents > 0 ? formatMoney(mapPriceToMoneyParts(shippingPrice)) : "Free"}
          </PriceSpan>
        </BoxRow>
      )}
      {discountCodeDetails.isDiscountCodeSet && (
        <BoxRow>
          <PriceSpan>Discount</PriceSpan>
          <PriceSpan>
            -{formatMoney(mapPriceToMoneyParts(discountCodeDetails.discountValue))}
          </PriceSpan>
        </BoxRow>
      )}
      <TotalRow mt={offer.customOfferProvided ? 0 : 2}>
        <TotalPriceSpan>Total</TotalPriceSpan>
        <TotalPriceSpan>
          {discountCodeDetails.isDiscountCodeSet
            ? formatMoney(mapPriceToMoneyParts(discountCodeDetails.discountedTotalValue))
            : formatMoney(mapPriceToMoneyParts(totalPrice))}
        </TotalPriceSpan>
      </TotalRow>
    </Section>
  );
}

const TotalPriceSpan = styled(BoldSpan)`
  font-family: Arial, Verdana, sans-serif;
  font-size: 15px;
`;

const OfferPriceSpan = styled(BoldSpan)`
  font-family: Arial, Verdana, sans-serif;
`;

export const PriceSpan = styled(Span)`
  font-family: Arial, Verdana, sans-serif;
`;

export const BoxRow = styled(Box).attrs({
  display: "flex",
  justifyContent: "space-between",
})`
  margin-bottom: 0.15rem;
`;

const OfferRow = styled(BoxRow)`
  margin-bottom: 1.75rem;
`;

const TotalRow = styled(BoxRow)`
  margin-bottom: 0;
`;
