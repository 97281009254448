import { Swatch } from "@sajari/react-components";
import { useFilter } from "@sajari/react-hooks";
import React, { useMemo } from "react";

type SwatchColour = {
  id: string;
  bg: string;
};

export const colours: SwatchColour[] = [
  { id: "White", bg: "#ffffff" },
  { id: "Black", bg: "#000000" },
  { id: "Silver", bg: "#d2d6dc" },
  { id: "Brown", bg: "#b43403" },
  { id: "Red", bg: "#f05252" },
  { id: "Pink", bg: "#f17eb8" },
  { id: "Orange", bg: "#ff8a4c" },
  { id: "Yellow", bg: "#faca15" },
  { id: "Green", bg: "#31c48d" },
  { id: "Beige", bg: "#fcd9bd" },
  { id: "Grey", bg: "#808080" },
  { id: "Blue", bg: "#76a9fa" },
  { id: "Purple", bg: "#ac94fa" },
  { id: "Gold", bg: "#FFD700" },
  { id: "Khaki", bg: "#F0E68C" },
  { id: "Metallic", bg: "#aaa9ad" },
  { id: "Navy", bg: "#000080" },
  { id: "Cream", bg: "#F6F0BC" },
];

export default function ColourFilter() {
  const { setSelected, options, selected } = useFilter("colour");
  const optionLabels = useMemo<string[]>(() => options.map(({ label }) => label), [options]);
  const optionsToRender = useMemo<SwatchColour[]>(
    () => colours.filter(({ id }) => optionLabels.includes(id)),
    [optionLabels]
  );

  return (
    <Swatch checkedColors={selected} onChange={setSelected}>
      {optionsToRender.map(({ id, bg }) => (
        <Swatch.Color key={id} id={id} bg={bg} />
      ))}
    </Swatch>
  );
}
