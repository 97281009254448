import React from "react";
import MuiMenu, { MenuProps as MuiMenuProps } from "@material-ui/core/Menu";
import styled from "styled-components";

export type MenuProps = MuiMenuProps & {
  arrowTop?: boolean;
};

const Menu = ({ arrowTop = false, ...props }: MenuProps) => (
  <MenuBase
    variant="menu"
    disableAutoFocusItem
    PaperProps={{
      square: true,
      className: "MuiPaper-root",
    }}
    BackdropProps={{
      className: "MenuBackdrop",
      invisible: true,
    }}
    $arrowTop={arrowTop}
    {...props}
  />
);

export default Menu;

const MenuBase = styled(MuiMenu)<{ $arrowTop: boolean }>`
  .MuiPaper-root {
    display: flex;
    flex-direction: column;
    border: 1px solid #d4d5d7;
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.1);
    ${({ $arrowTop }) =>
      $arrowTop &&
      `
      position: relative;
      overflow: visible;
      .MuiList-root {
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
      }
      &::before {
        background-color: white;
        content: "";
        display: block;
        position: absolute;
        width: 12px;
        height: 12px;
        top: -7px;
        transform: rotate(45deg) skew(10deg, 10deg);
        border-style: solid;
        border-color: #d4d5d7;
        border-width: 1px 0 0 1px;
      }
    `}
  }
`;

export const arrowTopLeftStyles = (offset: number = 21) => `
  .MuiPaper-root::before {
    left: ${offset - 6}px;
  }
`;

export const arrowTopRightStyles = (offset: number = 21) => `
  .MuiPaper-root::before {
    right: ${offset - 6}px;
  }
`;
