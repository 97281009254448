import React, { useEffect, useState } from "react";
import Button, { BUTTON_SIZES, BUTTON_VARIANTS } from "../../../../elements/v2/Button/Button";
import styled from "styled-components";
import FlexBox from "../../../../elements/v2/Box/FlexBox";
import { A, P, Span } from "../../../../elements/v2/Typography/Typography";
import { Input } from "../../../../elements/v2/Form/Input";
import ssrComponent from "../../../../../utils/ssr";
import { flowRight, isNil, omitBy } from "lodash";
import { wrapWithRorpContext } from "../../../../../contexts/RorpContext";
import { wrapWithThemeProvider } from "../../../../../utils/themeProvider";
import wrapWithStylesProvider from "../../../../../utils/wrapWithStylesProvider";
import { formatMoney, parseMoney } from "../../../../../utils/numbers";
import { CurrencyCode } from "../../../../../types/graphql-global-types";
import Events from "../../../../../utils/analytics";
import { useMobileMediaQuery } from "../../../../../utils/useMediaQueries";
import { GetListing_listing } from "../../queries/types/GetListing";
import MobileCompatibleOfferForm from "./MobileCompatibleOfferForm";
import { mapPriceToMoneyParts } from "../../../../../utils/money";

export const PARAM_OFFER_VALUE = "offer_value";
export const PARAM_OFFER_CURRENCY = "offer_currency_code";

const FLOOR_PRICE_MULTIPLIER = 0.7;
const offerInputPadding = 8;
const errorColor = "#BF2828";
const initialInputColor = "#cccccc";

export type MakeAnOfferProps = {
  startingPrice?: {
    cents: number;
    currency: {
      code: CurrencyCode;
      symbol: string;
    };
  };
  listing?: GetListing_listing;
  formData?: {
    onSubmit: Function;
    element: HTMLElement;
    startTransactionPath: string;
  };
  isPrimaryCta?: boolean;
};

export function MakeAnOffer({
  startingPrice,
  listing,
  formData,
  isPrimaryCta = false,
}: MakeAnOfferProps) {
  if (!startingPrice) {
    console.log("Starting price is not given. Not rendering MMaO component.");
    return null;
  }

  const startingPriceFormatted = formatMoney(mapPriceToMoneyParts(startingPrice));
  const startingPriceDollars = parseMoney(startingPriceFormatted);
  const [isInitialState, setIsInitialState] = useState<boolean>(true);
  const [offerPrice, setOfferPrice] = useState<number>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [inputSectionColor, setInputSectionColor] = useState<string>(null);
  const floorPrice = Math.ceil(startingPriceDollars * FLOOR_PRICE_MULTIPLIER * 100) / 100;
  const { isMobile } = useMobileMediaQuery();
  const [offerSubmittable, setOfferSubmittable] = useState(false);

  const isOfferPriceValid = () => {
    if (offerPrice < floorPrice) {
      setErrorMessage(
        `Your offer is too low. Must be ${startingPrice.currency.symbol}${floorPrice.toFixed(
          2
        )} or higher.`
      );
      return false;
    } else setErrorMessage("");
    return true;
  };

  const handleOfferSubmit = (e: any) => {
    if (!isOfferPriceValid()) {
      e.preventDefault();
      Events.sendMmaoOfferInvalid({
        offerPrice: offerPrice,
        floorPrice: floorPrice,
        startingPriceDollars: startingPriceDollars,
      });
      return;
    }
    // because Make An Offer turns into a modal in mobile view, it doesn't link to the original form
    // to make it work, we can directly action it to the form link with its params
    if (isMobile) {
      // passing the form element to onSubmit as it uses it for validation
      const formParams = formData.onSubmit({ currentTarget: formData.element });
      const urlParams = new URLSearchParams(
        omitBy(
          {
            ...formParams,
            [PARAM_OFFER_CURRENCY]: startingPrice.currency.code,
            [PARAM_OFFER_VALUE]: offerPrice,
          },
          isNil
        )
      ).toString();
      if (urlParams) {
        window.location.assign(`${formData.startTransactionPath}?${urlParams}`);
      }
    }
    return;
  };

  const handleInitiation = () => {
    setInputSectionColor(initialInputColor);
    setErrorMessage("");
    setIsInitialState(false);
    Events.sendMmaoOfferInitiated({
      floorPrice: floorPrice,
      startingPriceDollars: startingPriceDollars,
    });
  };

  useEffect(() => {
    if (offerPrice === undefined || offerPrice.toString() === "") {
      setInputSectionColor(initialInputColor);
      setErrorMessage("");
      setOfferSubmittable(false);
      return;
    }
    if (isOfferPriceValid()) {
      setInputSectionColor("black");
      setOfferSubmittable(true);
    } else {
      setInputSectionColor(errorColor);
      setOfferSubmittable(false);
    }
  }, [offerPrice]);

  if (isInitialState) {
    const variant = isPrimaryCta ? BUTTON_VARIANTS.CONTAINED : BUTTON_VARIANTS.OUTLINED;

    return (
      <Button
        data-testid="initial-button"
        variant={variant}
        $fullWidth
        size={BUTTON_SIZES.LARGE}
        onClick={handleInitiation}
      >
        Make an Offer
      </Button>
    );
  }

  return (
    <MobileCompatibleOfferForm
      listing={listing}
      handleModalClose={() => setIsInitialState(true)}
      isMobile={isMobile}
      startingPriceFormatted={startingPriceFormatted}
    >
      <Title>Make an Offer</Title>
      <Subtitle>
        <Link
          href="https://airrobe.zendesk.com/hc/en-us/articles/19831438297625-Making-an-Offer"
          target="_blank"
        >
          How Offers Work
        </Link>
      </Subtitle>
      {!isMobile && <Subtitle>Starting Price: {startingPriceFormatted}</Subtitle>}
      <InputSection $color={inputSectionColor}>
        <CurrencySymbol $color={inputSectionColor}>{startingPrice.currency.symbol}</CurrencySymbol>
        <input
          hidden={true}
          id={PARAM_OFFER_CURRENCY}
          name={PARAM_OFFER_CURRENCY}
          value={startingPrice.currency.code}
          readOnly={true}
        />
        <OfferInput
          placeholderColor={inputSectionColor}
          placeholder={"Your offer"}
          label=""
          $color={inputSectionColor}
          name={PARAM_OFFER_VALUE}
          data-testid={PARAM_OFFER_VALUE}
          id={PARAM_OFFER_VALUE}
          type="number"
          onChange={(e: any) => {
            setOfferPrice(e.target.value);
          }}
          inputProps={{
            step: 0.01,
          }}
        />
      </InputSection>
      <Error data-testid="error">{errorMessage}</Error>
      <SubmitButton onClick={handleOfferSubmit} disabled={!offerSubmittable}>
        Make Offer
      </SubmitButton>
    </MobileCompatibleOfferForm>
  );
}

export default function MakeAnOfferApp(props, context) {
  return ssrComponent(
    flowRight(
      wrapWithRorpContext(context),
      wrapWithStylesProvider,
      wrapWithThemeProvider
    )(() => <MakeAnOffer {...props} />)
  );
}

const Title = styled(FlexBox)`
  letter-spacing: 0.05em;
  font-weight: 500;
  height: 56px;
  padding: 8px 24px;
  font-size: 24px;
  color: #111111;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Subtitle = styled(P)`
  letter-spacing: 0.05em;
  line-height: 19.5px;
  font-weight: 500;
  padding: 0px 24px 10px 24px;
  font-size: 16px;
  color: #111111;
  width: 100%;
  text-align: center;
`;

const Error = styled(Subtitle)`
  color: ${errorColor};
  margin-top: 5px;
  font-size: 14px;
  min-height: 50px;
`;

const Link = styled(A)`
  letter-spacing: 0.05em;
  font-weight: 500;
  font-size: 13px;
  text-decoration: underline;
`;

const InputSection = styled(FlexBox).attrs({ $gap: 1 })<{ $color?: string }>`
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 40px;
  padding-bottom: 10px;
  padding-right: 20px;
  border-bottom: 1px solid ${({ $color }) => ($color ? $color : initialInputColor)};
`;

const CurrencySymbol = styled(Span)<{ $color?: string }>`
  position: relative;
  font-weight: 500;
  font-size: 24px;
  left: 0;
  top: 2px;
  line-height: 19px;
  color: ${({ $color }) => ($color ? $color : initialInputColor)};
`;

const OfferInput = styled(Input)<{ $color?: string }>`
  border: none;
  .MuiInput-input {
    min-width: 56px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    ${({ $color }) => {
      if ($color) {
        return `
          color: ${$color};
          font-size: ${$color === initialInputColor ? `28px` : `40px`};
        `;
      } else {
        return `font-size: 28px;`;
      }
    }}
    color: ${({ $color }) => ($color ? $color : initialInputColor)};
    padding: ${offerInputPadding}px ${offerInputPadding}px;

    /* Hiding the arrows */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    -moz-appearance: textfield;
  }
`;

const SubmitButton = styled(Button).attrs({
  size: BUTTON_SIZES.LARGE,
  type: "submit",
})`
  margin-top: 5px;
  width: 100%;
  height: 50px;
  font-size: 16px;
`;
