import React from "react";
import styled from "styled-components";
import { Span } from "../../../../../elements/v2/Typography/Typography";
import Box, { BoxProps } from "../../../../../elements/v2/Box/Box";
import FontAwesomeIcon, {
  FontAwesomeIconName,
} from "../../../../../elements/FontAwesomeIcon/FontAwesomeIcon";

type Props = BoxProps & {
  visible?: boolean;
  iconName?: FontAwesomeIconName;
  children: React.ReactNode;
};

export default function ListingStatus({ visible = true, iconName, children, ...props }: Props) {
  if (!visible) return null;

  return (
    <Badge {...props}>
      {iconName && <FontAwesomeIcon iconName={iconName} />}
      <Label>{children}</Label>
    </Badge>
  );
}

const Badge = styled(Box)`
  border-radius: 24px;
  background: #eee;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: fit-content;
`;

const Label = styled(Span)`
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px; /* 126.667% */
  letter-spacing: 1.5px;
  text-transform: uppercase;
`;
