import React from "react";
import { Svg } from "../../Svg/Svg";
import LoadingSvg from "../../../../assets/icons/loading.svg";
import styled from "styled-components";
import FlexBox from "../Box/FlexBox";

export const LOADING_SPINNER_COLOR_DEFAULT = "#28A3FF";

type Props = {
  color?: string;
};

export const LoadingSpinner = ({ color }: Props) => (
  <ColoredSVG content={LoadingSvg} $color={color} />
);

export const LoadingSpinnerCentered = ({ color }: Props) => (
  <FlexBox justifyContent="center" alignItems="center" p={2}>
    <ColoredSVG content={LoadingSvg} $color={color} />
  </FlexBox>
);

export const LoadingSpinnerFullPage = ({ color }: Props) => (
  <FlexBox
    justifyContent="center"
    alignItems="center"
    p={2}
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 9999,
      background: "rgba(255, 255, 255, 0.6)",
    }}
  >
    <ColoredSVG content={LoadingSvg} $color={color} />
  </FlexBox>
);

const ColoredSVG = styled(Svg)<{ $color?: string }>`
  circle.colored-stroke {
    stroke: ${({ $color }) => $color || LOADING_SPINNER_COLOR_DEFAULT};
  }
`;
