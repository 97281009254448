import { createStyles, makeStyles, ThemeProvider } from "@material-ui/core";
import React from "react";
import theme from "../../../config/theme";
import plantIcon from "./Plant.svg";

export interface Props {
  title: string;
  text: string;
}

export default function PlantBox({ title, text }: Props) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <div className={classes.boxHeader}>
          <h4 className={classes.title}>{title}</h4>
          <div>
            <slot name="icon"></slot>
          </div>
        </div>
        <p className={classes.boxBody}>{text}</p>
        <div className={classes.plant} dangerouslySetInnerHTML={{ __html: plantIcon }}></div>
      </div>
    </ThemeProvider>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "block",
      position: "relative",
      border: "2px solid black",
      borderRadius: 12,
      background: "white",
      boxShadow: "7px 7px black",
      overflow: "hidden",
      marginBottom: 20,
      transition: "1s box-shadow ease",
      marginRight: 25,
    },
    title: {
      color: "black",
      fontSize: 12,
      fontWeight: "bold",
      textTransform: "uppercase",
      margin: 0,
    },
    p: {
      fontWeight: 300,
      margin: 0,
      marginBottom: 10,
    },
    boxBody: {
      padding: 10,
      paddingBottom: 16,
    },
    boxHeader: {
      padding: 10,
      borderBottom: "2px solid black",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    plant: {
      position: "absolute",
      right: "6%",
      bottom: -16,
      transition: "1s transform ease",
      // @media(max-width: $mobileBreakpoint) {
      //   right: 8px;
      // }
      "&.sm": {
        bottom: -23,
      },
      "&.lg": {
        bottom: -12,
      },
    },
  })
);
