import React from "react";
import ReactDOM from "react-dom";
import ReactOnRails from "react-on-rails";
import "../../app/assets/javascripts/flash-notifications";
import "../../app/assets/javascripts/flash.min";
import "../assets/styles/global.css";
import ListingPage from "../components/pages/ListingPage/ListingPage";
import "../config/airbrake";
import { TopbarApp } from "../components/widgets/Topbar/Topbar";
import { FooterApp } from "../components/widgets/Footer/Footer";
import { ImportPastOrdersApp } from "../components/widgets/ImportPastOrders/ImportPastOrders";
import PlantBox from "../components/composites/PlantBox/entry";
import DiscoveryPage from "../components/pages/DiscoveryPage/DiscoveryPage";
import Status410Page from "../components/pages/Status410Page/Status410Page";
import SearchPage from "../components/pages/SearchPage/SearchPage";
import AccountVerificationPendingApp from "../startup/AccountVerificationPendingApp";
import AdminActivationReportApp from "../startup/AdminActivationReportApp";
import AdminActiveUsersReportApp from "../startup/AdminActiveUsersReportApp";
import ExternalOrderPreviewApp from "../startup/ExternalOrderPreviewApp";
import ListingCollectionsDropdownApp from "../startup/ListingCollectionsDropdownApp";
import ListingFormPage from "../components/pages/ListingFormPage/ListingFormPage";
import ManageAvailabilityApp from "../startup/ManageAvailabilityApp";
import PersonAvatarApp from "../startup/PersonAvatarApp";
import TransactionStatusApp from "../startup/TransactionStatusApp";
import CheckoutLoginPage from "../components/pages/./CheckoutLoginPage/CheckoutLoginPage";
import HistoricalOrdersPage from "../components/pages/HistoricalOrdersPage/HistoricalOrdersPage";
import MakeAnOfferApp from "../components/pages/ListingPage/components/MakeAnOffer/MakeAnOffer";
import { BuyersPromiseApp } from "../components/pages/ListingPage/components/BuyersPromise/BuyersPromise";
import { GmailOrdersResultApp } from "../components/widgets/ImportGmailOrders/GmailOrdersResult";
import { WardrobeStatsApp } from "../components/widgets/WardrobeStats/WardrobeStats";
import SortListingsPage from "../components/pages/SortListingsPage/SortListingsPage";
import InfoTooltip from "../components/composites/FormControls/InfoTooltip/InfoTooltip";
import CheckoutPage from "../components/pages/CheckoutPage/CheckoutPage";

ReactOnRails.register({
  CheckoutPage,
  InfoTooltip,
  TopbarApp,
  FooterApp,
  PlantBox,
  SearchPage,
  ListingPage,
  ManageAvailabilityApp,
  ListingCollectionsDropdownApp,
  BuyersPromiseApp,
  PersonAvatarApp,
  TransactionStatusApp,
  DiscoveryPage,
  AccountVerificationPendingApp,
  AdminActivationReportApp,
  AdminActiveUsersReportApp,
  ListingFormPage,
  ExternalOrderPreviewApp,
  HistoricalOrdersPage,
  ImportPastOrdersApp,
  GmailOrdersResultApp,
  WardrobeStatsApp,
  CheckoutLoginPage,
  MakeAnOfferApp,
  Status410Page,
  SortListingsPage,
});

if (typeof window !== "undefined") {
  window.React = React;
  window.ReactDOM = ReactDOM;
}
