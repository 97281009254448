import { gql } from "@apollo/client";

const ConfirmTransaction = gql`
  mutation ConfirmTransaction($input: ConfirmTransactionMutationInput!) {
    confirmTransaction(input: $input) {
      transaction {
        id
        currentState
        shipped
      }
      error
    }
  }
`;

export default ConfirmTransaction;
