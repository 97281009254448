import React from "react";
import ReactMarkdown from "react-markdown";
import pluralize from "pluralize";
import {
  ProfilePageProps,
  ProfilePageProps_me,
  ProfilePageProps_person,
} from "../../../../queries/types/ProfilePageProps";
import FollowButton from "../FollowButton";
import styled from "styled-components";
import FlexBox from "../../../../../../elements/v2/Box/FlexBox";
import Avatar from "../../../../../../elements/Avatar/Avatar";
import { breakpoints } from "../../../../../../../utils/styledHelpers";
import { BUTTON_SIZES, OutlineButton } from "../../../../../../elements/v2/Button/Button";
import LinkBox from "../../../../../../elements/v2/Box/LinkBox";
import { BoldSpan, MedSpan } from "../../../../../../elements/v2/Typography/Typography";
import SellerTypeChip from "../../../../../../elements/SellerTypeChip";
import Box from "../../../../../../elements/v2/Box/Box";
import { SellerType } from "../../../../../../../types/graphql-global-types";
import { H2 } from "../../../../../../elements/v2/Typography/Heading";
import { ConciergePage } from "../../../../../../../utils/filters";

export interface Props {
  profilePage?: ProfilePageProps;
  conciergePage?: ConciergePage;
}

export default function InfoBox({ profilePage, conciergePage }: Props) {
  let person: ProfilePageProps_person, me: ProfilePageProps_me;
  if (conciergePage) {
    person = conciergePage.airrobeConciergePerson.person;
    me = conciergePage.airrobeConciergePerson.me;
  } else {
    person = profilePage?.person;
    me = profilePage?.me;
  }
  const { followers, receivedTestimonials, collections, username } = person;
  const canEdit = person?.isMe || me?.isAdmin;
  const showDescription =
    person.sellerType === SellerType.professional_seller && person.description;

  return (
    <Container>
      <FlexBox $gap={2} alignItems="center">
        <AvatarWrapper>
          <Avatar
            image={{ url: person?.avatarImageUrl }}
            givenName={person?.givenName}
            url={person?.profileUrl}
          />
        </AvatarWrapper>
        <FlexBox $gap={3} flexDirection="column">
          <NameLink href={person?.profileUrl}>
            <Name>{person?.displayName}</Name>
          </NameLink>
          <FlexBox $gap={2}>
            {!person?.isMe && <FollowButtonMedium person={person} whichFollowButton="Listing" />}
            {!person?.isMe && (
              <OutlineButton size={BUTTON_SIZES.MEDIUM} href={person?.contactUrl}>
                Contact
              </OutlineButton>
            )}
            {canEdit && (
              <OutlineButton size={BUTTON_SIZES.MEDIUM} href={person?.settingsUrl}>
                Edit Profile
              </OutlineButton>
            )}
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <Info>
        <FlexBox alignItems="center" flexWrap="wrap" $rowGap={3} $colGap={5}>
          <Stats $colGap={5} $rowGap={1} alignItems="center" flexWrap="wrap">
            <StatItem number={followers?.totalCount} word={"Follower"} />
            <StatItem
              number={receivedTestimonials.totalCount}
              word={"Review"}
              link={person?.reviewsUrl}
            />
            <StatItem
              number={collections.totalCount}
              word={"Collection"}
              link={person?.collectionsUrl}
            />
          </Stats>
          <FlexBox $gap={5} alignItems="center">
            <SellerTypeBadge sellerType={person?.sellerType} />
          </FlexBox>
        </FlexBox>
        {showDescription && (
          <Description>
            <ReactMarkdown>{person?.description}</ReactMarkdown>
          </Description>
        )}
      </Info>
    </Container>
  );
}

type StatItemProps = {
  number?: number;
  word: string;
  link?: string;
};

function StatItem({ link, number, word }: StatItemProps) {
  const text = pluralize(word, number || 0);

  return (
    <LinkBox href={link}>
      <BoldSpan>{number}</BoldSpan>
      <span> </span>
      <MedSpan>{text}</MedSpan>
    </LinkBox>
  );
}

const Stats = styled(FlexBox)`
  > * {
    color: black;
    font-size: 16px;
    line-height: 1.5;
    white-space: nowrap;
  }
`;

const Container = styled(FlexBox)`
  flex-direction: column;
  background: white;
  z-index: 1;
  flex-wrap: nowrap;
  gap: 24px;
`;

const AvatarWrapper = styled(FlexBox)`
  flex: 0 0 144px;
  height: 144px;
  width: 144px;
  justify-content: center;
  position: relative;
  border: 1px solid #ececec;
  border-radius: 50%;
  ${breakpoints.down("sm")} {
    flex: 0 0 88px;
    height: 88px;
    width: 88px;
  }
`;

const Info = styled(FlexBox)`
  flex: 1 0 100%;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 660px) {
    flex: 1 1 auto;
    padding: 0;
  }
`;

const NameLink = styled(LinkBox)`
  margin: 5px 0;
  flex-grow: 1;

  @media (max-width: 660px) {
    flex-basis: 100%;
  }
`;

const Description = styled(Box)`
  p {
    margin: 0;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
  }
`;

const Name = styled(H2)`
  color: #000;
  font-weight: 600;
  letter-spacing: 1.2px;
  text-transform: uppercase;
`;

const SellerTypeBadge = styled(SellerTypeChip).attrs({
  variant: "default",
})`
  padding: 0;
  .MuiChip-label {
    color: #111;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
  }
  .MuiChip-avatar {
    margin-left: -4px;
  }
`;

const FollowButtonMedium = styled(FollowButton)`
  font-size: 14px;
  height: 40px;
  padding: 8px 16px;
  letter-spacing: 0.05em;
  font-weight: 600;
  margin-right: 0;
  min-width: 130px;
`;
