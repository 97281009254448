import { gql } from "@apollo/client";

export default gql`
  mutation RefetchMyStripeVerificationStatus(
    $input: RefetchMyStripeVerificationStatusMutationInput!
  ) {
    refetchMyStripeVerificationStatus(input: $input) {
      stripeAccount {
        isPendingVerification
      }
    }
  }
`;
