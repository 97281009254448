import React from "react";
import styled from "styled-components";
import { GetListing_listing, GetListing_listing_author } from "../../queries/types/GetListing";
import Box, { BoxProps } from "../../../../elements/v2/Box/Box";
import FlexBox from "../../../../elements/v2/Box/FlexBox";
import PersonAvatar from "../../../../widgets/PersonAvatar/PersonAvatar";
import {
  GetPersonAvatarProps_person,
  GetPersonAvatarProps_person_image,
} from "../../../../widgets/PersonAvatar/queries/types/GetPersonAvatarProps";
import { A, P } from "../../../../elements/v2/Typography/Typography";
import { BUTTON_SIZES, OutlineButton } from "../../../../elements/v2/Button/Button";
import PersonSummaryBlock from "../../../../composites/v2/PersonSummaryBlock/PersonSummaryBlock";
import FollowButton from "../../../../pages/SearchPage/components/ProfilePage/components/FollowButton";
import { SellerTypeChipOutline } from "../../../../elements/SellerTypeChip";

type Props = BoxProps & {
  listing?: GetListing_listing;
};

const mapAuthorToPersonAvatarProps = (
  author: GetListing_listing_author
): GetPersonAvatarProps_person => {
  const { avatarImageUrl } = author;
  const image: GetPersonAvatarProps_person_image = {
    __typename: "Image",
    url: avatarImageUrl,
  };

  return {
    ...author,
    image,
  };
};

export default function SellerProfile({ listing, ...props }: Props) {
  const { author, location, isMine } = listing;
  const person = mapAuthorToPersonAvatarProps(author);
  const address = location?.localityAddress;
  const contactUrl = `/listings/${listing.id}/contact`;

  return (
    <Box {...props}>
      <Container>
        <FlexBoxStyled $gap={2} alignItems="center" width="100%">
          <AvatarContainer>
            <PersonAvatar person={person}></PersonAvatar>
          </AvatarContainer>
          <BoxStyled>
            <BoxPersonName>
              <Name href={author.profileUrl}>{author.displayName}</Name>
              <SellerTypeChipOutline sellerType={author.sellerType} />
            </BoxPersonName>
            {address && (
              <Box mt={1}>
                <Location>{address}</Location>
              </Box>
            )}
            <PersonSummaryBlock person={author} />
          </BoxStyled>
          {!isMine && (
            <FlexBoxButtons>
              <FollowButton person={author} whichFollowButton="Listing" />
              <ContactButton href={contactUrl}>Contact</ContactButton>
            </FlexBoxButtons>
          )}
        </FlexBoxStyled>
      </Container>
    </Box>
  );
}

const Container = styled(FlexBox)`
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  gap: 16px;
  border: 1px solid #e6e8ec;
  flex-wrap: wrap;
`;

const AvatarContainer = styled(Box)`
  flex-shrink: 0;
  width: 70px;
  height: 70px;
  .Avatar {
    border: none;
    background: #e2e2e2;
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
  }
`;

const Name = styled(A).attrs({
  $underline: true,
})`
  font-weight: 700;
  font-size: 17px;
  line-height: 25px;
`;

const Location = styled(P)`
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
`;

const ContactButton = styled(OutlineButton).attrs({
  size: BUTTON_SIZES.SMALL,
})``;

const BoxStyled = styled(Box)`
  margin-right: auto;
  @media (max-width: 670px) {
    margin-right: 0;
  }
`;

const FlexBoxStyled = styled(FlexBox)`
  @media (max-width: 670px) {
    flex-direction: column;
  }
`;

const FlexBoxButtons = styled(FlexBox)`
  @media (max-width: 670px) {
    width: 100%;
    justify-content: space-evenly;
  }
`;

const BoxPersonName = styled(FlexBox)`
  @media (max-width: 670px) {
    text-align: center;
  }
`;
