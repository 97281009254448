import React from "react";
import wrapWithStylesProvider from "../../../utils/wrapWithStylesProvider";
import { wrapWithThemeProvider } from "../../../utils/themeProvider";
import ssrComponent from "../../../utils/ssr";
import { flowRight } from "lodash";
import { wrapWithRorpContext } from "../../../contexts/RorpContext";
import { wrapWithApollo } from "../../../graphql/client";
import { useMobileMediaQuery } from "../../../utils/useMediaQueries";
import GmailConnectButton from "./GmailConnectButton";
import { Container, GapBox } from "./ImportGmailOrders";
import styled from "styled-components";
import { Span } from "../../elements/v2/Typography/Typography";
import { EmailCaptureConnectStartedFrom } from "../../../utils/analytics";

export interface GmailOrdersResultProps {
  gmailSuccess?: string;
}

function GmailOrdersResult({ gmailSuccess }: GmailOrdersResultProps) {
  const accessGrantSuccessful = gmailSuccess === "true";

  const { isMobile } = useMobileMediaQuery();

  const containerProps = {
    success: accessGrantSuccessful,
    isMobile,
  };

  if (accessGrantSuccessful)
    return (
      <Container {...containerProps}>
        <GapBox $gap={8} m="auto">
          <Text $bold={true}>Congratulations we’ve synced your email</Text>
          <Text>You should start seeing listings appear soon.</Text>
        </GapBox>
      </Container>
    );
  else
    return (
      <Container {...containerProps}>
        <GapBox $gap={24} m="auto">
          <GapBox $gap={8} m="auto">
            <Text $bold={true}>Oh no, something went wrong...</Text>
            <Text>Please try again.</Text>
          </GapBox>
          <GmailConnectButton from={EmailCaptureConnectStartedFrom.RESULT} currentUser={null} />
        </GapBox>
      </Container>
    );
}

export const GmailOrdersResultApp = (props: GmailOrdersResultProps, context) =>
  ssrComponent(
    flowRight(
      wrapWithRorpContext(context),
      wrapWithStylesProvider,
      wrapWithThemeProvider,
      wrapWithApollo
    )(() => <GmailOrdersResult {...props} />)
  );

const Text = styled(Span)<{ bold?: boolean }>`
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  ${({ $bold }) => $bold && `font-weight: 700;`}
`;
