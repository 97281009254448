import React from "react";
import BrandChanelImage from "../../../../assets/images/discovery/brands/chanel.lqip.png";
import BrandGucciImage from "../../../../assets/images/discovery/brands/gucci.lqip.png";
import BrandZimmermanImage from "../../../../assets/images/discovery/brands/zimmerman.lqip.png";
import BrandLouisVuittonImage from "../../../../assets/images/discovery/brands/louis_vuitton.lqip.png";
import BrandPradaImage from "../../../../assets/images/discovery/brands/prada.lqip.png";
import BrandBalenciagaImage from "../../../../assets/images/discovery/brands/balenciaga.lqip.png";
import BrandSaintLaurentImage from "../../../../assets/images/discovery/brands/saint_laurent.lqip.png";
import BrandChristopherEsberImage from "../../../../assets/images/discovery/brands/christopher_esber.lqip.png";
import Section from "../components/Section";
import Grid from "../components/Grid";
import { getLqipBackgroundImageUrl } from "../../../../utils/images";
import { translate } from "../../../../utils/i18n";
import LinkBox from "../../../elements/v2/Box/LinkBox";
import { H4 } from "../../../elements/v2/Typography/Heading";
import { breakpoints } from "../../../../utils/styledHelpers";
import styled from "styled-components";
import Box from "../../../elements/v2/Box/Box";

const brands = [
  {
    title: "Louis Vuitton",
    url: "/b/1729-louis-vuitton",
    image: getLqipBackgroundImageUrl(BrandLouisVuittonImage),
  },
  {
    title: "Gucci",
    url: "/b/1216-gucci",
    image: getLqipBackgroundImageUrl(BrandGucciImage),
  },
  {
    title: "Christopher Esber",
    url: "/b/666-christopher-esber",
    image: getLqipBackgroundImageUrl(BrandChristopherEsberImage),
  },
  {
    title: "Prada",
    url: "/b/2391-prada",
    image: getLqipBackgroundImageUrl(BrandPradaImage),
  },
  {
    title: "Chanel",
    url: "/b/621-chanel",
    image: getLqipBackgroundImageUrl(BrandChanelImage),
  },
  {
    title: "Balenciaga",
    url: "/b/347-balenciaga",
    image: getLqipBackgroundImageUrl(BrandBalenciagaImage),
  },
  {
    title: "Saint Laurent",
    url: "/b/3286-ysl",
    image: getLqipBackgroundImageUrl(BrandSaintLaurentImage),
  },
  {
    title: "Zimmermann",
    url: "/b/3315-zimmermann",
    image: getLqipBackgroundImageUrl(BrandZimmermanImage),
  },
];

const section = {
  id: "shop_by_brand",
  title: translate("web.discovery.sections.shop_by_brand.title"),
  subtitle: translate("web.discovery.sections.shop_by_brand.subtitle"),
  midProps: {
    mt: { xs: 8, laptop: 14 },
  },
  links: [
    {
      tid: "web.discovery.sections.shop_by_brand.links.all_brands",
      href: "/brands",
    },
  ],
};

export default function BrandsSection() {
  return (
    <Section {...section}>
      <Grid
        style={{ gap: "24px", gridAutoRows: "154px" }}
        breakpoints={{
          xs: "grid-template-columns: repeat(2, minmax(0, 1fr))",
          md: "grid-template-columns: repeat(4, minmax(0, 1fr))",
        }}
      >
        {brands.map((brand) => (
          <BrandGridItem key={brand.title} href={brand.url}>
            <BrandName>{brand.title}</BrandName>
            <BrandImage style={{ backgroundImage: brand.image }} />
          </BrandGridItem>
        ))}
      </Grid>
    </Section>
  );
}

const BrandGridItem = styled(LinkBox)`
  width: 100%;
  height: 100%;
`;

const BrandName = styled(H4)`
  line-height: 20px;
  font-weight: 500;
  color: lightgray;
  ${breakpoints.down("laptop")} {
    font-size: 15px;
  }
`;

const BrandImage = styled(Box)`
  width: 100%;
  background-size: cover;
  background-position: center;
  padding-bottom: 44%;
  margin-top: 8px;
`;
