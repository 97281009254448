/*
  # Portal

  Portal is a React component that is rendered outside the current
  render tree. It takes a parent element and children as props and
  creates a container element with the given children. This container
  element is then rendered to the given parent element. The children
  should be a single element.

  ## Example:

  const Modal = (props) => r(Portal, {
    parentElement: document.body,
  }, div({
    style: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  }, props.children));

*/

// TODO: this approach is incompatible with SSR due to the lack of a document object. I think we can
// probably use the Portal element exported from the react-dom package as a drop-in replacement, but
// this would need testing.

import PropTypes from "prop-types";
import { Component } from "react";
import ReactDOM from "react-dom";
import css from "./Portal.module.css";

class Portal extends Component {
  constructor(props) {
    super(props);

    this.element = document.createElement("div");
    this.element.className = css.root;
    this.props.parentElement.appendChild(this.element);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.element);
  }
}

Portal.propTypes = {
  parentElement: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  children: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default Portal;
