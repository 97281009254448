import MuiMenuItem, { MenuItemProps as MuiMenuItemProps } from "@material-ui/core/MenuItem";
import React, { MouseEvent } from "react";
import styled from "styled-components";
import { WebTarget } from "styled-components/primitives/dist/types";
import { Divider } from "@material-ui/core";

type RouteAction = string | ((event: MouseEvent) => any);

export type MenuItemProps = MuiMenuItemProps & {
  routeAction?: RouteAction;
  onClick?: (event: MouseEvent) => any;
  href?: string;
  component?: WebTarget;
  active?: boolean;
};

const MenuItem = ({ routeAction, href, active = false, children, ...props }: MenuItemProps) => {
  const actionProps: MenuItemProps = {};

  if (typeof routeAction === "function") actionProps.onClick = routeAction;
  else if (routeAction && typeof routeAction === "string") {
    actionProps.href = routeAction;
    actionProps.component = "a";
  } else if (href) {
    actionProps.href = href;
    actionProps.component = "a";
  }

  return (
    <MenuItemBase $active={active} {...actionProps} {...props} button>
      {children}
    </MenuItemBase>
  );
};

export default MenuItem;

const MenuItemBase = styled(MuiMenuItem)<{ $active: boolean }>`
  color: #222222;
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  min-height: 45px;
  padding-left: 20px;
  padding-right: 20px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  ${({ $active }) =>
    $active &&
    `
    font-weight: 700;
    color: black;
  `}
`;

export const MenuItemDivider = styled(Divider).attrs({
  component: "li",
})`
  margin-left: 16px;
  margin-right: 16px;
  background-color: #dddddd;
  list-style: none;
`;
