import React, { useState } from "react";
import { GetListing_listing } from "../../queries/types/GetListing";
import Box, { BoxProps } from "../../../../elements/v2/Box/Box";
import { DeliveryMethod, ListingShape, UnitType } from "../../../../../types/graphql-global-types";
import styled from "styled-components";
import Typography from "../../../../elements/v2/Typography/Typography";
import FlexBox from "../../../../elements/v2/Box/FlexBox";
import DatePicker from "../../../../composites/FormControls/DatePicker/DatePicker";
import { getOccasionEndDate } from "../../../../../utils/date";
import moment from "moment";

type Props = BoxProps & {
  listing?: GetListing_listing;
  selectedDeliveryMethod: DeliveryMethod;
  showValidation: boolean;
};

export const validateRentalPeriod = (unitType, params) => {
  if (unitType === UnitType.occasion) {
    return Boolean(params["start_on"] && params["end_on"]);
  }

  return Number(params["quantity"]) > 0;
};

export default function SelectRentalPeriod({
  listing,
  selectedDeliveryMethod,
  showValidation,
  ...props
}: Props) {
  const { listingShape, unitType, sold } = listing;
  const isRental = listingShape === ListingShape.rent;
  const isPerOccasion = unitType === UnitType.occasion;
  const isPerMonth = unitType === UnitType.month;

  if (!isRental || sold) return null;

  const isDelivery = selectedDeliveryMethod === DeliveryMethod.shipping;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const startOnValue = startDate && moment(startDate).format("YYYY-MM-DD");
  const endOnValue = endDate && moment(endDate).format("YYYY-MM-DD");
  const isError =
    showValidation &&
    isPerOccasion &&
    !validateRentalPeriod(unitType, { start_on: startOnValue, end_on: endOnValue });

  const handleChangeDate = (startDate) => {
    if (startDate) {
      const endDate = getOccasionEndDate(startDate);
      setStartDate(startDate);
      setEndDate(endDate);
    }
  };

  return (
    <Box {...props}>
      <Box data-testid="select-rental-period-label">
        {isPerOccasion && <Bold>Please select the {isDelivery ? "delivery" : "pickup"} date.</Bold>}
        {isPerMonth && <Bold>Please enter the number of months to rent this item.</Bold>}
      </Box>
      <Container mt={1} $isError={isError}>
        {isPerOccasion && (
          <FlexBox justifyContent="space-between">
            <Box>
              <Small>Receive-by date:</Small>
              <DatePicker
                mt={1}
                DatePickerProps={{
                  id: "start-date-picker",
                  selected: startDate,
                  startDate,
                  endDate,
                  minDate: moment().add(2, "days").toDate(),
                  onChange: handleChangeDate,
                  placeholderText: "mm/dd/yyyy",
                }}
                data-testid="select-rental-period-occasion-datepicker"
              />
            </Box>
            <Box>
              <Small>Return-by date:</Small>
              <Box mt={1}>
                <input
                  type="text"
                  disabled
                  placeholder="4 days later"
                  value={endDate ? moment(endDate).format("MM/DD/YYYY") : ""}
                  style={{ width: 150 }}
                />
              </Box>
            </Box>
            <input
              hidden={true}
              id="start_on"
              name="start_on"
              value={startOnValue || ""}
              readOnly={true}
            />
            <input
              hidden={true}
              id="end_on"
              name="end_on"
              value={endOnValue || ""}
              readOnly={true}
            />
          </FlexBox>
        )}
        {isPerMonth && (
          <Box>
            <input
              id="quantity"
              name="quantity"
              type="number"
              min={1}
              width="100%"
              defaultValue={1}
              data-testid="select-rental-period-month-input"
            />
          </Box>
        )}
        {isPerOccasion && isDelivery && (
          <Box mt={1}>
            <Small>Note: The Lender will send the item a few days ahead of this date.</Small>
          </Box>
        )}
      </Container>
    </Box>
  );
}

const Bold = styled(Typography)`
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
`;

const Small = styled(Typography)`
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #222222;
`;

const Container = styled(Box)<{ $isError: boolean }>`
  padding: 24px;
  background: #f6f6f6;
  border: 1px solid #cccccc;

  input {
    padding: 8px 16px;
    border: 1px solid #cccccc;
    border-radius: 2px;
    font-size: 17px;
    line-height: 20px;
    width: 150px;
    &#start-date-picker {
      ${({ $isError }) => $isError && `border-color: red;`}
    }
    &:not(:disabled) {
      background: #ffffff;
      color: #111;
    }
  }
`;
