import { createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import Box from "../../../../elements/v2/Box/Box";
import React from "react";
import CustomFormikField from "../../../FormControls/CustomFormikField/CustomFormikField";
import Dropdown, { DropdownOptions } from "../../../FormControls/Dropdown/Dropdown";
import FormSection from "../../../FormControls/FormSection/FormSection";
import Money from "../../../FormControls/Money/Money";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    occasion: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    per: {
      paddingLeft: 4,
      paddingRight: 4,
    },
  })
);

export default function Price({ values, formField: { currency, price, rentalPeriod } }: any) {
  const classes = useStyles();
  const rentalPeriodOptions: DropdownOptions = [
    { value: "night", label: "Occasion" },
    { value: "month", label: "Month" },
  ];

  return (
    <Grid item>
      <FormSection
        title="Rental Price"
        helpText={[
          "To allow a buffer for delayed shipping times, we suggest that 'per occasion' rentals are for a period of 5 days. Please set a price that assumes the Borrower will have the item for a period of 5 days.",
          "Once someone makes an order, you'll receive this amount minus Airrobe fee and a payment processing fee.",
        ]}
      >
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Money currencyField={currency} amountField={price} />
          </Grid>
          <Grid item>
            <Box className={classes.occasion}>
              <Box className={classes.per}>
                <Typography variant="subtitle1" color="textPrimary">
                  per
                </Typography>
              </Box>
              <Box>
                <CustomFormikField
                  fieldName={rentalPeriod.name}
                  InnerComponent={Dropdown}
                  options={rentalPeriodOptions}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </FormSection>
    </Grid>
  );
}
