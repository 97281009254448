import withQueryProvider from "../../utils/withQueryProvider";
import GET_MY_COLLECTIONS from "./queries/GetMyCollections";
import { GetMyCollections } from "./queries/types/GetMyCollections";

// This component fetches collections and makes them available inside MyCollectionsContext
const {
  Context: MyCollectionsContext,
  Provider: MyCollectionsProvider,
} = withQueryProvider<GetMyCollections>(GET_MY_COLLECTIONS, {
  networkPolicy: "network-only",
  ssr: false,
});
export { MyCollectionsContext, MyCollectionsProvider };
