/*
   Common utilities for handling JavaScript native Date objects
*/
import moment from "moment";
/* Takes a UTC midnight date and converts it to local midnight date */
export const fromMidnightUTCDate = (d: Date) =>
  new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), 0, 0, 0, 0);

/* Takes a JavaScript Date in local timezone and converts it to UTC
midnight date */
export const toMidnightUTCDate = (d: Date) =>
  new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0));

/* given a sparse array of week numbers anywhere from 1 - 52
 * find the latest week number in the list to occur before the current week
 * or return the first week number in the list if all weeks are in the future.
 */
export const findClosestWeekNumber = (searchWeeks: number[]): number => {
  const currentWeek = moment().week();

  searchWeeks.sort();

  let week = searchWeeks[0];
  for (let i = 0; i < searchWeeks.length; i++) {
    if (currentWeek < searchWeeks[i]) return week;
    week = searchWeeks[i];
  }

  return week;
};

export const formatRentalDate = (date) => moment(date).format("dddd, MMMM Do YYYY");

export const getOccasionEndDate = (startDate) => moment(startDate).add(4, "days").toDate();

export const isBefore = (date1, date2) => moment(date1).isBefore(moment(date2));
