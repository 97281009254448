import { useFilter } from "@sajari/react-hooks";
import { xor } from "lodash";
import React from "react";
import FilterChip from "../FilterChip";

export default function PriceFilter() {
  const { setSelected, options, selected } = useFilter("price");

  function handlePriceClick(option) {
    setSelected(xor(selected, [option.label]));
  }

  return (
    <div className="t-searchFilterGroup">
      {options.map((option) => (
        <FilterChip
          key={option.value}
          label={option.label}
          onClick={() => handlePriceClick(option)}
          $selected={selected.includes(option.label)}
          size="small"
        />
      ))}
    </div>
  );
}
