import React from "react";
import { createContext } from "react";

const RorpContext = createContext<any>({});

// The Ruby on Rails library provides "context" as a second prop to rendered components. This
// context object exposes this context value to consumers.
export default RorpContext;

export const wrapWithRorpContext = (rorpContext) => (Component) => (props) => (
  <RorpContext.Provider value={rorpContext}>
    <Component {...props} />
  </RorpContext.Provider>
);
