import React from "react";
import styled from "styled-components";
import {
  InputProps as MUIInputProps,
  Select as MuiSelect,
  InputLabel as MuiInputLabel,
} from "@material-ui/core";
import Typography from "../../Typography/Typography";
import Box from "../../Box/Box";

export interface InputProps extends MUIInputProps {
  label?: string;
  error?: boolean;
  errorMessage?: any;
  children?: React.ReactNode;
  renderValue?: (selected: any) => React.ReactNode;
  displayEmpty?: boolean;
}

export const Select = ({ label, error, errorMessage, renderValue, ...props }: InputProps) => {
  return (
    <Box flexDirection="column" display="flex">
      {label && <Label>{label}</Label>}
      <StyledSelect $error={error} renderValue={renderValue} {...props}>
        {props.children}
      </StyledSelect>
      {error && errorMessage && (
        <Box display="flex" flexDirection="row" marginTop="4px">
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Box>
      )}
    </Box>
  );
};

const StyledSelect = styled(MuiSelect)<any>`
  &.MuiInputBase-root {
    &.MuiInput-underline:hover:not(.Mui-disabled) {
      &::before,
      &::after {
        border-bottom: none;
      }
    }

    &.MuiInput-underline::before {
      border-bottom: none;
    }

    &.MuiInput-underline::after {
      border-bottom: none;
    }

    .MuiInputBase-input {
      height: 48px;
      background: white;
      padding: 16px 16px;
      border: 1px solid #cccccc;
      border-radius: 2px;
      ${({ $error }) =>
        $error &&
        `
        border-color: #FF0000;
      `}
      box-sizing: border-box;

      &:focus {
        border-color: #cccccc;
      }
    }

    .MuiSelect-select {
      display: flex;
      align-items: center;
      padding: 16px 13px;
      font-size: 16px;
      color: #111111;
      background-color: white;
    }

    .MuiSelect-icon {
      color: #111111;
    }
  }
`;

const Label = styled(MuiInputLabel)`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 4px;
  color: #111111;
`;

const ErrorMessage = styled(Typography)`
  font-size: 15px;
  font-weight: 500;
  color: #ff0000;
  margin-left: 5px;
`;
