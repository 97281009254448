import { Span } from "../../../../elements/v2/Typography/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React from "react";
import { Dialog, DialogContent, DialogContentText } from "@material-ui/core";
import styled from "styled-components";
import Box from "../../../../elements/v2/Box/Box";
import { TextButton } from "../../../../elements/v2/Button/Button";
import { H2 } from "../../../../elements/v2/Typography/Heading";
import {
  PdpMoreInfoText as AuthenticationMoreInfoText,
  PdpSpecialInfoText as AuthenticationTypeText,
} from "../../../../elements/PdpTypography";

export enum ProductAuthenticationEnum {
  UNAUTHENTICATED = "unauthenticated",
  PROFESSIONALLY_AUTHENTICATED = "professionally_authenticated",
  DIGITALLY_AUTHENTICATED = "digitally_authenticated",
}

function getProductAuthenticationTypeTitle(
  productAuthenticationType: ProductAuthenticationEnum
): string {
  if (productAuthenticationType === ProductAuthenticationEnum.DIGITALLY_AUTHENTICATED)
    return "Digitally Authenticated";

  if (productAuthenticationType === ProductAuthenticationEnum.PROFESSIONALLY_AUTHENTICATED)
    return "Professionally Authenticated";

  return "";
}

function getProductAuthenticationTypeTooltipText(
  productAuthenticationType: ProductAuthenticationEnum
): string {
  if (productAuthenticationType === ProductAuthenticationEnum.DIGITALLY_AUTHENTICATED)
    return "Digitally Authenticated products are imported directly from AirRobe merchant partners.";

  if (productAuthenticationType === ProductAuthenticationEnum.PROFESSIONALLY_AUTHENTICATED)
    return "Professionally Authenticated products are listings from professional AirRobe partner-sellers, who physically review all products for authenticity and condition before listing on AirRobe.";

  return "";
}

export default function ProductAuthenticationText({
  productAuthenticationType,
}: {
  productAuthenticationType: ProductAuthenticationEnum;
}) {
  const [open, setOpen] = React.useState(false);

  if (productAuthenticationType === ProductAuthenticationEnum.UNAUTHENTICATED) return null;

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleModalOpen = () => {
    setOpen(true);
  };

  return (
    <Box>
      <CheckCircleIcon style={{ color: "black", width: "1.25rem" }} />
      <AuthenticationTypeText>
        {getProductAuthenticationTypeTitle(productAuthenticationType)}
      </AuthenticationTypeText>
      <AuthenticationMoreInfoText onClick={handleModalOpen}>More Info</AuthenticationMoreInfoText>
      <DialogStyled open={open} onClose={handleModalClose} maxWidth="xs">
        <Box display="flex" justifyContent="space-between">
          <DialogTitleText>Authenticity Check</DialogTitleText>
          <CloseButton onClick={handleModalClose}>
            <CloseButtonLeft />
            <CloseButtonRight />
          </CloseButton>
        </Box>
        <DialogContent>
          <DialogContentText>
            {getProductAuthenticationTypeTooltipText(productAuthenticationType)}
          </DialogContentText>
        </DialogContent>
      </DialogStyled>
    </Box>
  );
}

const DialogTitleText = styled(H2)`
  margin-left: 25px;
  margin-top: 15px;
`;

const CloseButton = styled(TextButton)`
  margin-right: 20px;
  margin-top: 15px;
  cursor: pointer;
  width: 11px;
  height: 11px;
`;

const CloseButtonLeft = styled(Span)`
  display: block;
  position: absolute;
  top: 50%;
  width: 1px;
  height: 1rem;
  border: none;
  background-color: #000;
  color: inherit;
  font: inherit;
  line-height: inherit;
  transform: rotate(45deg) translate3d(-50%, -55%, 0);
  left: calc(50% - 0.25rem);
`;

const CloseButtonRight = styled(Span)`
  display: block;
  position: absolute;
  top: 50%;
  width: 1px;
  height: 1rem;
  border: none;
  background-color: #000;
  color: inherit;
  font: inherit;
  line-height: inherit;
  -webkit-appearance: none;
  transform: rotate(-45deg) translate3d(50%, -55%, 0);
  left: calc(50% + 0.5rem);
`;

const DialogStyled = styled(Dialog)`
  .MuiPaper-rounded {
    border-radius: 0;
  }
`;
