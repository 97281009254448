import { FieldInputProps, useField } from "formik";
import React from "react";
import styled from "styled-components";
import { Checkbox as MuiCheckbox, FormControlLabel as DefaultLabel } from "@material-ui/core";

export interface Props {
  field: FieldInputProps<boolean>;
  label: string;
  value: boolean;
}

export const Checkbox = ({ field, label, ...rest }: Props) => {
  const [_, meta] = useField(field.name);

  return (
    <Label control={<MuiCheckbox checked={field.value} {...field} {...rest} />} label={label} />
  );
};

export default Checkbox;

const Label = styled(DefaultLabel)`
  .MuiFormControlLabel-label {
    color: #111;
    font-size: 11px;
    font-style: normal;
    line-height: 16px;
  }
  margin: 0;
`;
