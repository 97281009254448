import r from "r-dom";
import isSSR from "./isSSR";
import { create } from "jss";
import { jssPreset, StylesProvider } from "@material-ui/core/styles";

export default (Component) => {
  if (isSSR()) return () => r(Component);

  const jss = create({
    ...jssPreset(),
    insertionPoint: document.getElementById("jss-insertion-point"),
  });

  return () => r(StylesProvider, { jss }, r(Component));
};
