import { gql } from "@apollo/client";

export default gql`
  query GetActiveUsersReport {
    activeUsersReport {
      labels
      datasets {
        data
        yAxisID
        type
        label
        order
        tooltipSuffix
        borderColor
        pointBorderColor
        pointBackgroundColor
        pointBorderWidth
        pointHoverRadius
        pointHoverBorderWidth
        pointRadius
        fill
        borderWidth
      }
    }
  }
`;
