import React from "react";
import Typography, { TypographyProps } from "./Typography";
import styled from "styled-components";

export type HeadingProps = TypographyProps & {
  $inverse?: boolean;
};

const Heading = styled(({ variant, ...props }: HeadingProps) => (
  <Typography variant={variant} {...props} />
))`
  ${({ $inverse }) => $inverse && `color: white;`}
`;

export const H1 = styled(Heading).attrs({ variant: "h1" })`
  font-family: Lora, serif;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 40px;
  line-height: 1.2;
`;

export const H2 = styled(Heading).attrs({ variant: "h2" })`
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
`;

export const H3 = styled(Heading).attrs({ variant: "h3" })`
  font-size: 18px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`;

export const H4 = styled(Heading).attrs({ variant: "h4" })`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: 0.15em;
  text-transform: uppercase;
`;

export const H5 = styled(Heading).attrs({ variant: "h5" })`
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.1em;
`;

export const H6 = styled(Heading).attrs({ variant: "h6" })`
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`;
