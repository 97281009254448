import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useState } from "react";
import styled from "styled-components";
import Typography from "../../../elements/v2/Typography/Typography";
import FlexBox from "../../../elements/v2/Box/FlexBox";
import { H4 } from "../../../elements/v2/Typography/Heading";

type Props = {
  title: string;
  children: React.ReactNode;
};

export default function AccordionShowHide({ title, children, ...props }: Props) {
  const [expanded, setExpanded] = useState(false);
  function handleChange(event, expanded) {
    setExpanded(expanded);
  }

  return (
    <Accordion onChange={handleChange} {...props}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <FlexBox justifyContent="space-between" width="100%">
          <SummaryTitle>{title}</SummaryTitle>
          <ExpanderLabel>{expanded ? "Hide" : "Show"}</ExpanderLabel>
        </FlexBox>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

const SummaryTitle = styled(H4)`
  font-weight: 600;
  color: #222222;
`;

const ExpanderLabel = styled(Typography)`
  font-weight: 500;
  text-decoration: underline;
`;
