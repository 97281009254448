import {
  createStyles,
  FormControl as MUIFormControl,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import { Field } from "formik";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
    },
  })
);

export interface Props {
  fieldName: string;
  description?: string;
  InnerComponent: React.ReactNode;
  [x: string]: any;
}

const CustomFormikField = ({ fieldName, description, InnerComponent, ...rest }: Props) => {
  const classes = useStyles();

  return (
    <Field name={fieldName}>
      {({ field, form, meta }) => (
        <MUIFormControl
          component="fieldset"
          error={meta.touched && meta.error && true}
          className={classes.root}
        >
          <div className={classes.inner}>
            {<InnerComponent {...rest} field={field} form={form} meta={meta} />}
          </div>
          <FormHelperText>{meta.touched && meta.error}</FormHelperText>
        </MUIFormControl>
      )}
    </Field>
  );
};
export default CustomFormikField;
