import React from "react";
import { t } from "../../../utils/i18n";
import styled from "styled-components";
import Button, { BUTTON_SIZES, TextButton } from "../../elements/v2/Button/Button";
import FlexBox from "../../elements/v2/Box/FlexBox";
import { LOGIN_PATH } from "../../../utils/paths";

interface LoginLinkProps {
  compact?: boolean;
  openSignupModal: () => void;
}

export default function LoginLinks({ compact = true, openSignupModal }: LoginLinkProps) {
  return (
    <LoginLinksContainer $gap={1}>
      <SignupButton size={BUTTON_SIZES.SMALL} onClick={openSignupModal} $compact={compact}>
        {t("web.topbar.signup")}
      </SignupButton>
      {!compact && (
        <Button size={BUTTON_SIZES.SMALL} href={LOGIN_PATH}>
          {t("web.topbar.login")}
        </Button>
      )}
    </LoginLinksContainer>
  );
}

const LoginLinksContainer = styled(FlexBox)`
  height: 100%;
  align-items: center;
`;

const SignupButton = styled(TextButton)<{ $compact: boolean }>`
  .MuiButton-label {
    letter-spacing: 0.05em;
    text-decoration: none;
    padding: 8px 14px;
    &:hover {
      font-weight: 600;
    }
    ${({ $compact }) =>
      $compact &&
      `
       text-transform: none;
       padding: 0;
       font-weight: 400;
       &:hover {
        font-weight: 500;
       }
    `}
  }
`;
