import React from "react";
import { useLazyQuery } from "@apollo/client";
import Box from "../../../../elements/v2/Box/Box";
import { Input } from "../../../../elements/v2/Form/Input";
import { TextButton } from "../../../../elements/v2/Button/Button";
import styled from "styled-components";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { GetDiscountDetails } from "./queries/types/GetDiscountDetails";
import GET_DISCOUNT_DETAILS from "./queries/GetDiscountDetails";
import { DiscountCodeDetails, Money } from "./PriceBreakdown";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Events from "../../../../../utils/analytics";

interface Props {
  listingId: string;
  shippingPrice: Money;
  salePrice: Money;
  subtotalPrice: Money;
  setDiscountCodeDetails: (values: DiscountCodeDetails) => void;
  discountCodeDetails: DiscountCodeDetails;
}

interface FormValues {
  discountCode: string;
}

const validate = (values: any) => {
  const errors: any = {};

  if (!values.discountCode) {
    errors.discountCode = "Required";
  }

  return errors;
};

export default function DiscountCode({
  listingId,
  shippingPrice,
  salePrice,
  setDiscountCodeDetails,
  discountCodeDetails,
}: Props) {
  const [getDiscountCodeValidity, { loading, error, data }] = useLazyQuery<GetDiscountDetails>(
    GET_DISCOUNT_DETAILS
  );

  async function handleSubmit(formValues: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    const discountCodeValue = formValues.discountCode;
    Events.sendDiscountCodeAttempt({
      discountCode: discountCodeValue,
      listingId,
      salePrice,
    });
    const res = await getDiscountCodeValidity({
      variables: { discountCode: discountCodeValue, listingId: listingId },
    });
    const { isDiscountCodeValid, discountCode } = res.data;

    if (isDiscountCodeValid) {
      const discount = salePrice.cents * (discountCode.percentOff / 100);
      const discountedSubtotalValue = salePrice?.cents - discount;
      let shippingPriceCents = 0;
      if (shippingPrice?.cents) {
        shippingPriceCents = shippingPrice.cents;
      }
      const discountedTotalValue = discountedSubtotalValue + shippingPriceCents;

      setDiscountCodeDetails({
        discountCode: discountCodeValue,
        isDiscountCodeSet: true,
        discountValue: {
          cents: discount,
          currency_iso: salePrice.currency_iso,
        },
        discountedTotalValue: {
          cents: discountedTotalValue,
          currency_iso: salePrice.currency_iso,
        },
      });
    } else {
      formikHelpers.setErrors({
        discountCode: "Discount code invalid!",
      });
      formikHelpers.setFieldValue("discountCode", "");
    }
  }

  function handleChangeDiscountCode(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    formikProps: FormikHelpers<FormValues>
  ) {
    const { name, value } = event.target;
    const { discountCode } = discountCodeDetails;

    if (discountCode !== value && discountCode.length !== 0) {
      setDiscountCodeDetails({
        isDiscountCodeSet: false,
        discountCode: "",
        discountValue: undefined,
        discountedTotalValue: undefined,
      });
    }

    formikProps.setFieldValue(name, value);
  }

  return (
    <Box mb={2}>
      <DiscountAccordion>
        <DiscountAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Discount code or gift card
        </DiscountAccordionSummary>
        <AccordionDetails>
          <Formik
            initialValues={{
              discountCode: "",
            }}
            validateOnBlur={false}
            validateOnChange={false}
            validate={validate}
            onSubmit={handleSubmit}
          >
            {(formikProps: FormikProps<FormValues>) => (
              <Form>
                <DiscountCodeBox width="100%">
                  <DiscountCodeInput
                    type="text"
                    name="discountCode"
                    placeholder="Discount code or gift card"
                    placeholderColor="#666"
                    value={formikProps.values.discountCode}
                    onChange={(event) => handleChangeDiscountCode(event, formikProps)}
                    error={
                      formikProps.touched.discountCode && Boolean(formikProps.errors.discountCode)
                    }
                    errorMessage={formikProps.errors.discountCode}
                    success={
                      !Boolean(formikProps.errors.discountCode) &&
                      data?.isDiscountCodeValid &&
                      discountCodeDetails.isDiscountCodeSet
                    }
                    successMessage="Discount applied!"
                    fullWidth={true}
                  />
                  <DiscountCodeButton
                    name="button"
                    className="has-ripple"
                    type="submit"
                    disabled={discountCodeDetails.isDiscountCodeSet}
                  >
                    Apply
                  </DiscountCodeButton>
                </DiscountCodeBox>
              </Form>
            )}
          </Formik>
        </AccordionDetails>
      </DiscountAccordion>
    </Box>
  );
}

const DiscountCodeInput = styled(Input)`
  &.MuiInputBase-root {
    border: none;
    padding: 0;
  }

  height: 40px;

  .MuiInputBase-input {
    padding: 0 16px;
    height: 40px;
    border-radius: 2px;
    border: 1px solid #ccc;
    background: #fff;
  }
`;

const DiscountCodeButton = styled(TextButton)`
  height: 40px;
  text-transform: none;
  font-family: Arial, sans-serif;
  font-weight: 500;
  padding: 16px;
`;

const DiscountCodeBox = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;
`;

const DiscountAccordion = styled(Accordion)`
  .MuiAccordionSummary-root {
    padding: 0;
    justify-content: left;
    margin-top: 0;
  }
  .MuiAccordionDetails-root {
    padding: 8px 0;
  }
  .MuiAccordionSummary-expandIcon {
    transform: rotate3d(1, 0, 0, 0deg);
    padding: 0 12px 12px 12px;
    &.Mui-expanded {
      transform: rotate3d(1, 0, 0, 180deg);
      padding: 12px 12px 0 12px;
    }
  }
`;

const DiscountAccordionSummary = styled(AccordionSummary)`
  .MuiAccordionSummary-content {
    font-family: Arial, sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 28px;
    text-decoration: underline;
    flex-grow: 0;
    margin-top: 0;
  }
`;
