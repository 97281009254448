import _ from "lodash";
import numbro from "numbro";
import currencies from "../assets/json/currency_iso.json";
import { MoneyParts } from "../graphql/fragments/types/MoneyParts";

// Locales, which are not yet supported by numbro, are not included.
// So, these languages default to en-US formatting.
// el-GR, es-CL, ca-ES, hr-HR, id-ID, is-IS, km-KH, ms-MY,
// ro-RO, sw-KE, vi-VN, bg-BG, mn-MN, zh-HK, ka-GE, sl-SI, ti-ER
const DEFAULT_LOCALE = "en-US";
const KNOWN_LOCALES = [
  "en-US",
  "da-DK",
  "de-DE",
  "en-AU",
  "en-GB",
  "es-ES",
  "fi-FI",
  "fr-FR",
  "fr-CA",
  "it-IT",
  "ja-JP",
  "nb-NO",
  "nl-NL",
  "pt-BR",
  "pt-PT",
  "ru-RU",
  "sv-SE",
  "tr-TR",
  "zh-CN",
  "en-NZ",
  "et-EE",
  "pl-PL",
  "hu-HU",
  "cs-CZ",
  "th-TH",
  "zh-TW",
  "sk-SK",
];

const initializeNumbro = _.memoize(
  (numbroInstance, locale) => {
    if (locale && _.includes(KNOWN_LOCALES, locale) && locale !== "en-US") {
      numbroInstance.culture(locale, require(`numbro/languages/${locale}`));
    } else {
      KNOWN_LOCALES.forEach((localeCode) => {
        if (localeCode !== "en-US") {
          numbroInstance.culture(localeCode, require(`numbro/languages/${localeCode}`));
        }
      });
    }
    return numbroInstance;
  },
  (numbroInstance, locale) => (locale ? locale : "all")
);

const localizeNumbro = function localizeNumbro(locale) {
  // Ensure that numbro has required all the localization languages and select correct locale
  if (_.includes(KNOWN_LOCALES, locale)) {
    const isServer = typeof window === "undefined";
    const numbroMultiLang = initializeNumbro(numbro, isServer ? null : locale);
    numbroMultiLang.culture(locale);
    return numbroMultiLang;
  }
  numbro.culture(DEFAULT_LOCALE);
  return numbro;
};

// TODO: this is super complicated. I think we can massively simplify this using currency code,
// symbol and symbolFirst fields from graphql.
const formatMoney = (price: MoneyParts, locale = DEFAULT_LOCALE) => {
  if (price == null) {
    return null;
  }
  const currencyData = currencies[price?.currency?.code?.toLowerCase()];
  if (!currencyData) {
    throw new Error("Unknown currency");
  }

  const localizedNumbro = localizeNumbro(locale);
  const amount = price.fractional / currencyData.subunit_to_unit;
  const cultureSpecificFormat = localizedNumbro
    .cultureData()
    .formats.fullWithTwoDecimals.replace(" ", "");

  return localizedNumbro(amount, price.currency.code).formatForeignCurrency(
    currencyData.symbol,
    cultureSpecificFormat
  );
};

const parseMoney = (moneyString: string): number => numbro().unformat(moneyString);

export { formatMoney, parseMoney };
