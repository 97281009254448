import styled from "styled-components";
import { P } from "../../../elements/v2/Typography/Typography";
import FlexBox from "../../../elements/v2/Box/FlexBox";
import React, { RefObject } from "react";
import Box from "../../../elements/v2/Box/Box";
import { RadioButton } from "../../../elements/v2/Form/RadioButton";
import { NEW_EMAIL_OPTION } from "../Steps/SelectEmailStep";
import { Input } from "../../../elements/v2/Form/Input";

type Props = {
  emailRef: RefObject<HTMLInputElement>;
  isSelected: boolean;
  onSelectEmail: (String) => void;
};

export const NewEmailOption = ({ emailRef, isSelected, onSelectEmail }: Props) => {
  function select() {
    onSelectEmail(NEW_EMAIL_OPTION);
  }

  return (
    <Container mt={4} onClick={select}>
      <NewEmailLabel>Or enter a new email address:</NewEmailLabel>
      <FlexBox mt={1} alignItems="center">
        <RadioButton checked={isSelected} value={NEW_EMAIL_OPTION} />
        <NewEmailInput inputRef={emailRef} />
      </FlexBox>
    </Container>
  );
};

const Container = styled(Box)`
  text-align: left;
`;

const NewEmailInput = styled(Input).attrs({
  type: "email",
})`
  width: 100%;
  flex-grow: 1;
`;

const NewEmailLabel = styled(P)`
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: #222222;
`;
