import React, { useEffect } from "react";
import styled from "styled-components";
import Box from "../../elements/v2/Box/Box";
import FlexBox from "../../elements/v2/Box/FlexBox";
import AirRobeLogoSVG from "../../../assets/airrobe.svg";
import { A, Span } from "../../elements/v2/Typography/Typography";
import {
  HOME_PRIVACY_POLICY_URL,
  HOME_TERMS_CONDITIONS_URL,
  SOCIALS_INSTAGRAM_URL,
} from "../../../utils/paths";
import { breakpoints } from "../../../utils/styledHelpers";
import SubscribeForm from "./SubscribeForm";
import FooterMenu from "./FooterMenu";
import wrapWithStylesProvider from "../../../utils/wrapWithStylesProvider";
import { wrapWithThemeProvider } from "../../../utils/themeProvider";
import ssrComponent from "../../../utils/ssr";
import { flowRight } from "lodash";
import { initializeLocale } from "../../../utils/locale";
import { wrapWithRorpContext } from "../../../contexts/RorpContext";

const YEAR = new Date().getFullYear();

export default function Footer() {
  useEffect(() => {
    // show zendesk chat when the footer is in view
    const handleScroll = () => {
      const footer = document.getElementById("footer");
      const zendesk = document.getElementById("launcher");
      const distanceOfBaseFromTopOfScreen = window.innerHeight;
      const distanceOfFooterFromTopOfScreen = footer?.getBoundingClientRect()?.y;
      const zendeskIsInView = distanceOfFooterFromTopOfScreen < distanceOfBaseFromTopOfScreen;
      if (zendeskIsInView) {
        if (zendesk) zendesk.parentElement.style.display = "block";
      } else {
        if (zendesk) zendesk.parentElement.style.display = "none";
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  return (
    <Box id="footer">
      <FooterOne px={[3, 3, 4, 7]} py={[3, 3, 4, 7]}>
        <FooterColumn>
          <FooterMenu />
        </FooterColumn>
        <FooterColumn>
          <SubscribeForm />
        </FooterColumn>
      </FooterOne>
      <FooterTwo px={[3, 3, 4, 7]} py={3}>
        <FooterColumn>
          <Box>
            <AirRobeLogo />
          </Box>
          <Box>
            <FooterTypography t="web.footer.tag_line" />
          </Box>
        </FooterColumn>
        <FooterColumn>
          <Copyright>
            <FooterTypography>
              <Span t="web.footer.copyright" /> <Span>{YEAR}</Span>{" "}
              <Span t="web.footer.company_name" />
            </FooterTypography>
          </Copyright>
          <FlexBox $gap={3}>
            <A href={HOME_PRIVACY_POLICY_URL} $underline>
              <FooterTypography t="web.footer.privacy_policy" />
            </A>
            <A href={HOME_TERMS_CONDITIONS_URL} $underline>
              <FooterTypography t="web.footer.terms_conditions" />
            </A>
            <A href={SOCIALS_INSTAGRAM_URL} $underline>
              <FooterTypography t="web.footer.follow" />
            </A>
          </FlexBox>
        </FooterColumn>
      </FooterTwo>
    </Box>
  );
}

export const FooterApp = (props, context) => {
  initializeLocale(props.i18n);

  return ssrComponent(
    flowRight(
      wrapWithRorpContext(context),
      wrapWithStylesProvider,
      wrapWithThemeProvider
    )(() => <Footer />)
  );
};

const FooterOne = styled(FlexBox)`
  background: #f6f6f6;
  justify-content: space-between;
  color: white;
  gap: 40px;
  flex-direction: column;
  ${breakpoints.up("md")} {
    flex-direction: row;
    gap: 16px;
  }
`;

const FooterTwo = styled(FlexBox)`
  background: #eeeeee;
  justify-content: space-between;
  gap: 40px;
  ${breakpoints.down("sm")} {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    text-align: center;
  }
`;

const FooterColumn = styled(FlexBox)`
  flex-direction: column;
  gap: 8px;
  ${breakpoints.down("sm")} {
    gap: 0;
  }
`;

const AirRobeLogo = styled.span.attrs({
  dangerouslySetInnerHTML: {
    __html: AirRobeLogoSVG,
  },
})`
  > svg {
    width: 70px;
    fill: black;
  }
`;

const FooterTypography = styled(Span)`
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
`;

const Copyright = styled(Box)`
  text-align: right;
  ${breakpoints.down("sm")} {
    text-align: center;
    margin-bottom: 16px;
  }
`;
