import React from "react";
import PersonAvatar from "../components/widgets/PersonAvatar/PersonAvatar";
import ssrComponent from "../utils/ssr";

// We put each of our react entry points in this "startup" directory. Often we use these files to
// configure localisation and routes, but in the case of this component, we just pass on the
// component as-is.
export default (props, context) => {
  function PersonAvatarApp() {
    return <PersonAvatar {...props} />;
  }

  if (typeof window !== "undefined") return PersonAvatarApp;
  return ssrComponent(PersonAvatarApp);
};
