import React, { FormEvent, useEffect, useState } from "react";
import { CollectionCardFragment } from "../../../graphql/fragments/types/CollectionCardFragment";
import { GetCollectionListings_collection } from "../CollectionListingsBlock/queries/types/GetCollectionListings";
import Button, { BUTTON_SIZES, OutlineButton } from "../../elements/v2/Button/Button";
import Box from "../../elements/v2/Box/Box";
import styled from "styled-components";
import { H2 } from "../../elements/v2/Typography/Heading";
import FlexBox from "../../elements/v2/Box/FlexBox";
import { Input } from "../../elements/v2/Form/Input";

export interface Props {
  collection?: CollectionCardFragment | GetCollectionListings_collection;
  handleSubmit: (args: { title: string; file: any }) => void;
  handleCancel?: () => void;
  mode?: "name" | "coverImage";
  error?: string;
}

// Form for a collection
export default function CollectionForm({
  collection,
  handleSubmit,
  handleCancel,
  mode,
  error,
}: Props) {
  const [title, setTitle] = useState(collection?.displayTitle || "");

  // We keep both the file and an image preview URL as they are independent
  const [file, setFile] = useState(undefined); // eslint-disable-line no-undefined
  const [imagePreviewUrl, setImagePreviewUrl] = useState(collection?.coverImageUrl);

  function handleInputChange(event) {
    setTitle(event.target.value);
  }

  function handleFormSubmit(event: FormEvent) {
    const args = { title, file };
    handleSubmit(args);
    event.preventDefault();
  }

  function handleFileChange({ target: { validity, files } }) {
    if (validity.valid) {
      setFile(files[0]);
    }
  }

  function handleResetImageClick() {
    setFile(null);
    setImagePreviewUrl(null);
  }

  function getTitle() {
    if (mode === "coverImage") {
      return "Choose cover image";
    }
    if (collection) {
      return "Edit collection";
    }
    return "Create a new collection";
  }

  useEffect(() => {
    if (file) {
      setImagePreviewUrl(URL.createObjectURL(file));
    }
  }, [file]);

  const showResetCoverImageButton = imagePreviewUrl && mode === "coverImage";

  return (
    <Box py={3} px={5}>
      <form onSubmit={handleFormSubmit}>
        <Box mb={2}>
          <H2>{getTitle()}</H2>
        </Box>
        {mode === "name" ? (
          <Box my={1}>
            <Input
              label="Enter a name"
              placeholder="Collection Name"
              name="collection_title"
              type="text"
              value={title}
              onChange={handleInputChange}
            />
          </Box>
        ) : null}
        {mode === "coverImage" ? (
          <>
            {imagePreviewUrl ? (
              <ImagePreview style={{ backgroundImage: `url("${imagePreviewUrl}")` }} />
            ) : null}
            <input name="collection[coverImage]" type="file" onChange={handleFileChange} />
            {showResetCoverImageButton ? <a onClick={handleResetImageClick}>Remove image</a> : null}
          </>
        ) : null}
        {error ? <label className="error">{error}</label> : null}
        <FlexBox mt={2} py={1} justifyContent="space-between">
          <Button type="submit" size={BUTTON_SIZES.SMALL}>
            Submit
          </Button>
          <OutlineButton onClick={handleCancel} size={BUTTON_SIZES.SMALL}>
            Cancel
          </OutlineButton>
        </FlexBox>
      </form>
    </Box>
  );
}

const ImagePreview = styled(Box)`
  width: 100%;
  padding-top: 60%;
  background-size: cover;
  margin-bottom: 20px;
`;
