import { createContext, useContext, useState } from "react";
import React from "react";
import {
  GetSearchProps,
  GetSearchProps_categories,
  GetSearchProps_myCountry,
} from "./queries/types/GetSearchProps";
import { gql, useQuery } from "@apollo/client";
import GET_SEARCH_PROPS from "./queries/GetSearchProps.graphql";
import {
  GetCollection,
  GetCollection_collection,
  GetCollectionVariables,
} from "./queries/types/GetCollection";
import GET_COLLECTION from "./queries/GetCollection";
import { useMobileMediaQuery } from "../../../utils/useMediaQueries";
import { ProfilePageProps } from "./queries/types/ProfilePageProps";
import {
  ConciergePage,
  DiscountEligibilityPage,
  LongTailPage,
  PageType,
} from "../../../utils/filters";

export type SearchPageContextProps = {
  isMobile: boolean;
  isMobileSidebarOpen: boolean;
  setMobileSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  categories: GetSearchProps_categories[];
  myCountry: GetSearchProps_myCountry;
  collectionId?: number;
  collection: GetCollection_collection;
  refetchCollection: () => void;
  isProfilePage: boolean;
  profilePage?: ProfilePageProps;
  longTailPage: LongTailPage;
  isProductLongTailPage: boolean;
  discountEligibilityPage?: DiscountEligibilityPage;
  conciergePage?: ConciergePage;
};

export const defaultSearchPageContext = {
  isMobile: false,
  isMobileSidebarOpen: false,
  setMobileSidebarOpen: () => {},
  categories: [],
  myCountry: null,
  collectionId: null,
  collection: null,
  refetchCollection: () => {},
  isProfilePage: false,
  profilePage: null,
  longTailPage: null,
  isProductLongTailPage: false,
  discountEligibilityPage: null,
  conciergePage: null,
};

export const SearchPageContext = createContext<SearchPageContextProps>(defaultSearchPageContext);

type Props = {
  collectionId?: number;
  isProfilePage?: boolean;
  profilePage?: ProfilePageProps;
  children: React.ReactNode;
  longTailPage: LongTailPage;
  discountEligibilityPage?: DiscountEligibilityPage;
  conciergePage?: ConciergePage;
};

export function SearchPageProvider({
  collectionId,
  isProfilePage,
  profilePage,
  longTailPage,
  children,
  discountEligibilityPage,
  conciergePage,
}: Props) {
  const { isMobile } = useMobileMediaQuery();
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const { data: searchPropsData } = useQuery<GetSearchProps>(gql(GET_SEARCH_PROPS));
  const { data: collectionData, refetch: refetchCollection } = collectionId
    ? useQuery<GetCollection, GetCollectionVariables>(GET_COLLECTION, {
        variables: { id: Number(collectionId) },
      })
    : { data: null, refetch: null };

  const isProductLongTailPage = longTailPage?.pageType === PageType.product;

  const value = {
    isMobile,
    isMobileSidebarOpen,
    setMobileSidebarOpen: (val: boolean) => setMobileSidebarOpen(val),
    categories: searchPropsData?.categories || [],
    myCountry: searchPropsData?.myCountry || null,
    collectionId: collectionId || null,
    collection: collectionData?.collection || null,
    refetchCollection,
    isProfilePage: !!profilePage,
    profilePage,
    longTailPage,
    isProductLongTailPage,
    discountEligibilityPage,
    conciergePage,
  };

  return <SearchPageContext.Provider value={value}>{children}</SearchPageContext.Provider>;
}

export const useSearchPageContext = () => useContext(SearchPageContext);
