import React, { AnchorHTMLAttributes } from "react";
import styled, { AnyStyledComponent } from "styled-components";
import Box, { BoxProps } from "./Box";

export type LinkBoxProps = BoxProps & AnchorHTMLAttributes<any>;

const LinkBox = ({ href, ...props }: LinkBoxProps) =>
  href ? <A href={href} {...props} /> : <Box {...props} />;

export default LinkBox;

const A: AnyStyledComponent = styled(Box).attrs({
  as: "a",
})`
  color: inherit;
  text-decoration: inherit;
`;
