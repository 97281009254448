import React from "react";
import BagsImage from "../../../../assets/images/discovery/categories/bags.lqip.png";
import JacketsImage from "../../../../assets/images/discovery/categories/jackets.lqip.png";
import DressesImage from "../../../../assets/images/discovery/categories/dresses.lqip.png";
import ShoesImage from "../../../../assets/images/discovery/categories/shoes.lqip.png";
import Section from "../components/Section";
import Grid, { Tile, TileImage, TileTitle } from "../components/Grid";
import { getLqipBackgroundImageUrl } from "../../../../utils/images";
import { translate } from "../../../../utils/i18n";

const categories = [
  {
    title: "Bags",
    url: "/designer?q=bags",
    image: getLqipBackgroundImageUrl(BagsImage),
  },
  {
    title: "Dresses",
    url: "/designer?q=dresses",
    image: getLqipBackgroundImageUrl(DressesImage),
  },
  {
    title: "Jackets",
    url: "/designer?q=jackets",
    image: getLqipBackgroundImageUrl(JacketsImage),
  },
  {
    title: "Shoes",
    url: "/designer?q=shoes",
    image: getLqipBackgroundImageUrl(ShoesImage),
  },
];

const section = {
  id: "shop_by_category",
  title: translate("web.discovery.sections.shop_by_category.title"),
  subtitle: translate("web.discovery.sections.shop_by_category.subtitle"),
};

export default function CategoriesSection() {
  return (
    <Section {...section}>
      <Grid style={{ gap: "20px" }}>
        {categories.map((category) => (
          <Tile key={category.title} href={category.url}>
            <TileImage style={{ backgroundImage: category.image }} />
            <TileTitle>{category.title}</TileTitle>
          </Tile>
        ))}
      </Grid>
    </Section>
  );
}
