import React from "react";
import styled from "styled-components";
import { GetListing_listing } from "../../queries/types/GetListing";
import Box, { BoxProps } from "../../../../elements/v2/Box/Box";
import Typography from "../../../../elements/v2/Typography/Typography";
import { ListingShape, UnitType } from "../../../../../types/graphql-global-types";

type Props = BoxProps & {
  listing?: GetListing_listing;
};

export default function RentalInfo({ listing, ...props }: Props) {
  const { listingShape, unitType } = listing;
  const isRental = listingShape === ListingShape.rent;
  const isPerOccasion = unitType === UnitType.occasion;

  return (
    isRental &&
    isPerOccasion && (
      <Box {...props}>
        <Content>
          &lsquo;Per occasion&rsquo; rentals are for a period of 5 days. The price above has been
          set on the basis of you having the item for 5 days before sending back to the Lender.
          Please message the seller if you wish to request a longer rental period.
        </Content>
      </Box>
    )
  );
}

const Content = styled(Typography)`
  display: inline-flex;
  gap: 5px;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #222222;
`;
