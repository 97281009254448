import React, { useState } from "react";
import Button, { BUTTON_SIZES, OutlineButton } from "../../elements/v2/Button/Button";
import Dropdown from "../../composites/Dropdown/Dropdown";
import styled from "styled-components";
import FlexBox from "../../elements/v2/Box/FlexBox";
import Typography, { P, Span } from "../../elements/v2/Typography/Typography";
import Card from "../../elements/v2/Card/Card";
import { TextField } from "@material-ui/core";
import DownArrowSvg from "../../../assets/downArrow.svg";

export interface Props {
  text?: string;
  loading?: boolean;
  isCustomOffer?: boolean;
  onClick?: (string) => void;
}

export default function SellerRejectButton({
  text = "Reject",
  loading,
  onClick,
  isCustomOffer,
}: Props) {
  const [isInitialState, setIsInitialState] = useState<boolean>(true);
  const defaultReason = "Please Select";
  const [otherReason, setOtherReason] = useState<string>(undefined);
  const [selectedReason, setSelectedReason] = useState<string>(defaultReason);

  const CHANGED_MIND = "Rejected - Changed Mind";
  const CHANGED_MIND_MESSAGE = "I've changed my mind";
  const STILL_NEEDED = "Rejected - Still Need Item";
  const STILL_NEEDED_MESSAGE = "I still want or need this item";
  const NOT_AVAILABLE = "Rejected - Item Not Available";
  const NOT_AVAILABLE_MESSAGE = "I can’t find or no longer have this item";
  const CONDITION_CHANGED = "Rejected - Item Condition";
  const CONDITION_CHANGED_MESSAGE = "The item condition has changed (or is now damaged)";
  const SHIPMENT_ISSUE = "Rejected - Unable to Ship";
  const SHIPMENT_ISSUE_MESSAGE = "I can’t ship the item right now";
  const CHANGE_PRICE = "Rejected - Price Fix Required";
  const CHANGE_PRICE_MESSAGE = "I want or need to change the price";
  const LOW_PRICE = "Rejected - Price Too Low";
  const LOW_PRICE_MESSAGE = "The offered price is too low";
  const BAD_EXPERIENCE = "Rejected - Bad Experience";
  const BAD_EXPERIENCE_MESSAGE = "My last sale did not go well";
  const OTHER = "Rejected - Other";
  const OTHER_MESSAGE = "Other";

  let reasons = [
    {
      message: CHANGED_MIND_MESSAGE,
      backend: CHANGED_MIND,
    },
    {
      message: STILL_NEEDED_MESSAGE,
      backend: STILL_NEEDED,
    },
    {
      message: NOT_AVAILABLE_MESSAGE,
      backend: NOT_AVAILABLE,
    },
    {
      message: CONDITION_CHANGED_MESSAGE,
      backend: CONDITION_CHANGED,
    },
    {
      message: SHIPMENT_ISSUE_MESSAGE,
      backend: SHIPMENT_ISSUE,
    },
    {
      message: CHANGE_PRICE_MESSAGE,
      backend: CHANGE_PRICE,
    },
    {
      message: BAD_EXPERIENCE_MESSAGE,
      backend: BAD_EXPERIENCE,
    },
    {
      message: OTHER_MESSAGE,
      backend: OTHER,
    },
  ];

  //these reasons are only applicable for when rejection is of a custom offer
  const customOfferReasons = [
    {
      message: LOW_PRICE_MESSAGE,
      backend: LOW_PRICE,
    },
  ];

  if (isCustomOffer) reasons = [...customOfferReasons, ...reasons];

  const handleReject = (e: any) => {
    if (selectedReason == defaultReason) {
      e.preventDefault();
      return;
    }
    let rejectReason = reasons.find((reason) => reason.message == selectedReason).backend;
    if (rejectReason.includes("Other")) rejectReason += `: ${otherReason}`;
    onClick(rejectReason);
  };

  return (
    <div>
      {isInitialState ? (
        <InitialButton
          data-testid="initial-button"
          onClick={() => setIsInitialState(false)}
          loading={loading}
        >
          {text}
        </InitialButton>
      ) : (
        <RejectForm data-testid="offer-form">
          <Title>{text}</Title>
          <Subtitle>Please help us understand why you are rejecting this transaction</Subtitle>
          <ReasonSelector
            closeOnClick={true}
            handleComponent={
              <SelectedReason>
                <Span>{selectedReason}</Span>
                <DownArrow />
              </SelectedReason>
            }
          >
            {reasons.map((reason, i) => (
              <Reason key={i} onClick={() => setSelectedReason(reason.message)}>
                <Span>{reason.message}</Span>
              </Reason>
            ))}
          </ReasonSelector>
          {selectedReason == "Other" ? (
            <OtherReasonInput
              color={"primary"}
              size={"small"}
              fullWidth
              placeholder={"Type here"}
              multiline
              maxRows={3}
              onChange={(e: any) => setOtherReason(e.target.value)}
            />
          ) : null}
          <Subtitle>Your response will help us serve you better.</Subtitle>
          <SubmitContainer>
            <SubmitButton disabled={selectedReason == defaultReason} onClick={handleReject}>
              Reject
            </SubmitButton>
          </SubmitContainer>
        </RejectForm>
      )}
    </div>
  );
}

const DownArrow = styled.span.attrs({
  dangerouslySetInnerHTML: {
    __html: DownArrowSvg,
  },
})``;

const InitialButton = styled(OutlineButton)`
  width: 100%;
  height: 42px;
`;

const ReasonSelector = styled(Dropdown)`
  font-size: 14px;
  margin-bottom: 10px;
  width: 100%;
  [class*="Dropdown-module__dropdownContent"] {
    top: 45px;
    width: 100%;
  }
  position: relative;
`;

const RejectForm = styled(FlexBox)`
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  border: 1px solid #111111;
  background-color: #f3f3f3;
  padding: 0 16px 16px;
`;

const OtherReasonInput = styled(TextField)`
  background-color: white;
  padding: 10px 20px;
  margin-bottom: 10px;
`;

const SubmitContainer = styled(FlexBox)`
  justify-content: right;
  align-items: center;
  width: 100%;
`;

const Title = styled(Typography)`
  letter-spacing: 0.05em;
  font-weight: 600;
  height: 56px;
  padding: 8px 24px 0px 24px;
  margin-bottom: 0px !important;
  font-size: 14px;
  color: #111111;
  width: 100%;
  text-transform: uppercase;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

const Subtitle = styled(P)`
  letter-spacing: 0.05em;
  font-weight: 500;
  padding: 4px 0px;
  font-size: 13px;
  color: #111111;
  width: 100%;
  text-align: left;
`;

const Reason = styled(Card)`
  height: 100%;
  width: 100%;
  cursor: pointer;
  padding: 10px 20px;
  text-align: left;
  background-color: white;
  &:hover {
    background-color: rgb(250, 250, 250);
  }
`;

const SelectedReason = styled(Reason)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface SubmitProps {
  disabled?: boolean;
  onClick?: (string) => void;
  children?: any;
}

const StyledButton = styled(Button).attrs({
  size: BUTTON_SIZES.SMALL,
  type: "submit",
})`
  margin-top: 10px;
  height: 36px;
  font-size: 14px;
`;

function SubmitButton({ disabled = false, onClick, children }: SubmitProps) {
  return (
    <StyledButton disabled={disabled} onClick={onClick}>
      {children}
    </StyledButton>
  );
}
