import React from "react";
import Avatar from "../../elements/Avatar/Avatar";
import { GetPersonAvatarProps } from "./queries/types/GetPersonAvatarProps";

// We only include this component while we render it as a standalone "App" on the listing page
// (which is Rails-rendered).
export default function PersonAvatar({ person, ...props }: GetPersonAvatarProps) {
  return (
    <Avatar
      givenName={person.givenName}
      familyName={person.familyName}
      url={person.profileUrl}
      image={person.image}
      {...props}
    />
  );
}
