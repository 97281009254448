import React from "react";
import styled from "styled-components";
import OutlineButton from "../../../elements/v2/Button/Button";
import Box, { BoxProps } from "../../../elements/v2/Box/Box";
import { Span } from "../../../elements/v2/Typography/Typography";
import { Svg } from "../../../elements/Svg/Svg";
import GoogleIcon from "../../../../assets/icons/google-icon.svg";
import { AuthenticityToken } from "../../../../utils/csrfHelper";

export default function GoogleSignUpButton(props: BoxProps) {
  return (
    <Box {...props}>
      <GoogleSignupForm>
        <GoogleSubmitButton>
          <SvgStyled content={GoogleIcon} />
          <GoogleButtonText>Continue with Google</GoogleButtonText>
        </GoogleSubmitButton>
      </GoogleSignupForm>
      <SignupHorizontalLine>
        <SignupHorizontalLineText>or</SignupHorizontalLineText>
      </SignupHorizontalLine>
    </Box>
  );
}

export function GoogleSignupForm(props: any) {
  const { children, ...formProps } = props;
  return (
    <form method="post" action="/people/auth/google_oauth2?locale=en" {...formProps}>
      {children}
      <input type="hidden" name="_method" value="post" />
      <AuthenticityToken />
    </form>
  );
}

export const GoogleSubmitButton = styled(OutlineButton).attrs({ type: "submit" })`
  display: flex;
  justify-content: center;
  background: #ffffff;
  width: 100%;
  border: 1px solid #1f1e23;
  height: 44px;
  padding: 0 2px;

  &:hover {
    background: #f7f7f7;
    color: #5b5b66;
  }

  .MuiButton-label {
    text-transform: none;
    color: #222222;
  }
`;

const SvgStyled = styled(Svg)`
  align-self: center;
  height: 40px;
  width: 40px;
`;

export const GoogleButtonText = styled(Span)`
  text-align: center;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
`;

export const SignupHorizontalLine = styled.div`
  margin-top: 24px;
  text-align: center;
  position: relative;
  width: 100%;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-top: solid 1px #1f1e23;
  }
`;

export const SignupHorizontalLineText = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  font-size: 13px;
  font-weight: 500;
  display: inline;
  text-align: center;
  background-color: white;
  color: #474747;
  position: relative;
`;
