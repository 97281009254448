import React from "react";
import wrapWithStylesProvider from "../../../utils/wrapWithStylesProvider";
import { wrapWithThemeProvider } from "../../../utils/themeProvider";
import ssrComponent from "../../../utils/ssr";
import { flowRight } from "lodash";
import { wrapWithRorpContext } from "../../../contexts/RorpContext";
import { wrapWithApollo } from "../../../graphql/client";
import SyncSvg from "./assets/sync.svg";
import styled from "styled-components";
import Box from "../../elements/v2/Box/Box";
import { Svg } from "../../elements/Svg/Svg";
import { Span } from "../../elements/v2/Typography/Typography";
import InfoTooltip from "../../composites/FormControls/InfoTooltip/InfoTooltip";

interface Props {
  addAccountPath: string;
  lastSyncedText?: string;
}

function ImportPastOrders({ addAccountPath, lastSyncedText }: Props) {
  return (
    <Container mt={2}>
      <SyncIcon content={SyncSvg} />
      <Text>
        We’re automatically syncing your past orders where available.&nbsp;
        <Link href={addAccountPath}>Add accounts</Link>&nbsp;
        {lastSyncedText ? (
          <InfoTooltip title={<ToolTipText>{lastSyncedText}</ToolTipText>} size={"14px"} />
        ) : null}
      </Text>
    </Container>
  );
}

export const ImportPastOrdersApp = (props, context) =>
  ssrComponent(
    flowRight(
      wrapWithRorpContext(context),
      wrapWithStylesProvider,
      wrapWithThemeProvider,
      wrapWithApollo
    )(() => <ImportPastOrders {...props} />)
  );

const Container = styled(Box)`
  display: flex;
  padding: 16px 20px 16px 20px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 15px;
  border: 1px solid #ccc;
`;

const SyncIcon = styled(Svg)`
  height: 48px;
`;

const Text = styled(Span)`
  color: #111;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Link = styled(Text).attrs({ component: "a" })`
  white-space: nowrap;
  text-decoration: underline;
`;

const ToolTipText = styled(Span)`
  font-size: 12px;
`;
