import {
  isPathActive,
  NAV_KIDS_PATH,
  NAV_MEN_PATH,
  NAV_SHOP_ALL_PATH,
  NAV_WOMEN_PATH,
} from "./paths";
import { useContext } from "react";
import RorpContext from "../contexts/RorpContext";
import { values } from "lodash";

export const departments = {
  s: {
    searchFilterValue: null,
    label: "Shop All",
    navPath: NAV_SHOP_ALL_PATH,
  },
  womens: {
    searchFilterValue: "Womenswear",
    label: "Womens",
    navPath: NAV_WOMEN_PATH,
  },
  mens: {
    searchFilterValue: "Menswear",
    label: "Mens",
    navPath: NAV_MEN_PATH,
  },
  kids: {
    searchFilterValue: "Kidswear",
    label: "Kids",
    navPath: NAV_KIDS_PATH,
  },
};

export type DepartmentsUnion = keyof typeof departments;
export default departments;

export function isAnyDepartmentActive() {
  const { pathname } = useContext(RorpContext);
  return values(departments).some((department) => isPathActive(pathname, department.navPath));
}
