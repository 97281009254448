import { div } from "r-dom";
import { Component } from "react";
import css from "./Menu.module.css";

interface Props {
  name: string;
  extraClasses?: string;
}

export default class MenuLabelPlain extends Component<Props> {
  render() {
    const extraClasses = this.props.extraClasses ? this.props.extraClasses : "";
    return div(
      {
        className: `MenuLabel ${css.menuLabel} ${extraClasses}`,
      },
      this.props.name
    );
  }
}
