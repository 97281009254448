import { useQuery } from "@apollo/client";
import { Grid } from "@material-ui/core";
import Fuse from "fuse.js";
import React from "react";
import CustomFormikField from "../../../FormControls/CustomFormikField/CustomFormikField";
import FormSection from "../../../FormControls/FormSection/FormSection";
import Money from "../../../FormControls/Money/Money";
import TextAutocomplete from "../../../FormControls/TextAutocomplete/TextAutocomplete";
import GET_BRAND_SYNONYMS from "../queries/GetBrandSynonyms";
import { GetBrandSynonyms } from "../queries/types/GetBrandSynonyms";

const BrandRRPStep = (props) => {
  const {
    formField: { brand, rrp, currency },
  } = props;

  const { error, data, loading } = useQuery<GetBrandSynonyms>(GET_BRAND_SYNONYMS);

  if (loading) return "...";
  if (!data) return null;
  if (error) return "Error!";

  const deduplicateBrandSynonyms = (synonyms) =>
    // If more than one brand synonym with the same canonical name
    // matches the user's input, show only one of them.
    synonyms.reduce((acc, current) => {
      const x = acc.find((item) => {
        return item.canonical === current.canonical;
      });
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

  const filterOptions = (options, { inputValue }) => {
    const fuse = new Fuse(options, { keys: ["name", "canonical"] });
    let results = fuse.search(inputValue).map((obj) => obj.item);
    return results.slice(0, 10);
  };

  const doFilterOptions = (options, state) =>
    // This function is executed everytime Autocomplete does a search
    // from the user's input in the brand field, giving us a chance to
    // filter the results before displaying them.
    deduplicateBrandSynonyms(filterOptions(options, state));

  return (
    <Grid container spacing={2} direction="column" wrap="nowrap">
      <Grid item>
        <FormSection title="Brand">
          <CustomFormikField
            fieldName={brand.name}
            InnerComponent={TextAutocomplete}
            options={data.brandSynonyms}
            warning="Entering a new brand may cause your listing to have a longer approval time. 
            We suggest selecting an existing brand from the dropdown."
            placeholder="Type brand name (adds new brand if it doesn't exist in the list)"
            getOptionLabel={(option) => option.canonical}
            getOptionSelected={(option, value) => option.canonical === value.canonical}
            getValueFromOption={(option) => option.canonical}
            filterOptions={doFilterOptions}
          />
        </FormSection>
      </Grid>
      <Grid item>
        <FormSection
          title="Retail Price"
          description="Do you know the original full-price when the item was new?"
        >
          <Money currencyField={currency} amountField={rrp} />
        </FormSection>
      </Grid>
    </Grid>
  );
};

export default BrandRRPStep;
