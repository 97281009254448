import React from "react";
import styled from "styled-components";
import Typography from "../../../elements/v2/Typography/Typography";
import FlexBox from "../../../elements/v2/Box/FlexBox";
import { H6 } from "../../../elements/v2/Typography/Heading";
import Box from "../../../elements/v2/Box/Box";
import { removeSizeText } from "../../../../utils/listingHelper";
import SmartImage from "../../../composites/SmartImage/SmartImage";
import { BUTTON_SIZES, OutlineButton } from "../../../elements/v2/Button/Button";
import { Chip } from "@material-ui/core";
import { GetPersonListings_person_listings_results } from "../queries/types/GetPersonListings";

export interface Props {
  listing: GetPersonListings_person_listings_results;
  onClickMove: (listing: GetPersonListings_person_listings_results) => void;
}

function ListingSortCard({ listing, onClickMove, ...props }: Props) {
  return (
    <Container $closed={listing.closed} {...props}>
      <SquareWrapper $sold={Boolean(listing.sold)}>
        <Box pb="100%">
          <StyledSmartImage imageUrl={listing.previewUrl} />
        </Box>
      </SquareWrapper>
      <Info>
        <Brand>{listing.brand}</Brand>
        <Title>{removeSizeText(listing.title)}</Title>
      </Info>
      <FlexBox mt={1} $gap={1} justifyContent="space-evenly">
        <Action href={listing.url} target="_blank">
          View
        </Action>
        <Action onClick={() => onClickMove(listing)}>Move</Action>
      </FlexBox>
      {listing.sold && <SoldChip />}
    </Container>
  );
}

export default ListingSortCard;

const Container = styled(Box)<{ $closed: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  padding: 8px 0;
  ${({ $closed }) =>
    $closed &&
    `
    border: 1px dashed #ccc;
  `}
`;

const SquareWrapper = styled(Box)<{ $sold?: boolean }>`
  position: relative;
  height: 100%;
  flex-shrink: 0;
  ${({ $sold }) =>
    $sold &&
    `
    opacity: 0.5;
  `}
`;

const StyledSmartImage = styled(SmartImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
`;

const Info = styled(Box)`
  height: 65px;
  margin-top: 4px;
  text-align: center;
  overflow: hidden;
`;

const Brand = styled(H6)`
  font-weight: 700;
  letter-spacing: 0.05em;
  margin-bottom: 4px;
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Title = styled(Typography)`
  line-height: 1.4;
  font-weight: 300;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Action = styled(OutlineButton).attrs({
  size: BUTTON_SIZES.SMALL,
})`
  height: 24px;
  padding: 0 8px;
  font-size: 10px;
  letter-spacing: 0;
  font-weight: 500;
  min-width: initial;
`;

const SoldChip = styled(Chip).attrs({
  label: "SOLD",
})`
  position: absolute;
  top: 10px;
  left: 10px;
  opacity: 0.8;
`;
