import { keys } from "lodash";

const getBreakpointValue = ({ breakpoints }, key) =>
  typeof key === "string" ? breakpoints.values[key] : key;

export const breakpoints = {
  down: (key) => ({ theme }) => theme.breakpoints?.down(getBreakpointValue(theme, key)),
  up: (key) => ({ theme }) => theme.breakpoints?.up(getBreakpointValue(theme, key)),
};

export const $breakpoints = ({ $breakpoints, theme }: { $breakpoints: any; theme: any }): string =>
  keys($breakpoints)
    .filter((key) => theme.breakpoints.keys.includes(key))
    .map((key) => `${theme.breakpoints.up(key)} { ${$breakpoints[key]}; }`)
    .join("\n");

export const ifSize = (target, isDefault = false) => (...args) => ({ size }) =>
  ((isDefault && typeof size === "undefined") || size == target) && [...args];

export const ifTrue = (propName) => (...args) => (props) => props[propName] && [...args];

export const ifFalse = (propName) => (...args) => (props) => !props[propName] && [...args];

export const $inverse = (...args) => ({ $inverse }) => $inverse && [...args];

export const $gap = ({ $gap, theme }: { $gap?: number; theme: any }) =>
  $gap && `gap: ${theme.spacing($gap)}px;`;

export const $rowGap = ({ $rowGap, theme }: { $rowGap?: number; theme: any }) =>
  $rowGap && `row-gap: ${theme.spacing($rowGap)}px;`;

export const $colGap = ({ $colGap, theme }: { $colGap?: number; theme: any }) =>
  $colGap && `column-gap: ${theme.spacing($colGap)}px;`;

export const $active = (...args) => ({ $active }) => $active && [...args];

export const $fullWidth = ({ $fullWidth }: { $fullWidth: boolean }) => $fullWidth && `width: 100%;`;
