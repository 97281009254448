// We still render listing cards using rails. This app mounts the listing favourite icon with
// collections behaviour. We should remove the Rails-side listing card rendering (once we've updated
// the homepage to react), and then delete this file.

import r from "r-dom";
import ListingCollectionsDropdown from "../components/widgets/ListingCollectionsDropdown/ListingCollectionsDropdown";
import { withApollo } from "../graphql/client";
import { initialize as initializeI18n } from "../utils/i18n";
import { railsRoutesSubset, subset, withRoutes } from "../utils/routes";
import ssrComponent from "../utils/ssr";

export default (props, marketplaceContext) => {
  initializeI18n(
    marketplaceContext.i18nLocale,
    marketplaceContext.i18nDefaultLocale,
    process.env.NODE_ENV,
    {
      region: "Unknown",
      language: "English",
    }
  );

  const routes = subset(railsRoutesSubset, {
    locale: marketplaceContext.i18nLocale,
  });

  function ListingCollectionsDropdownApp() {
    return withRoutes(routes)(withApollo(r(ListingCollectionsDropdown, props)));
  }

  if (typeof window !== "undefined") return ListingCollectionsDropdownApp;
  return ssrComponent(ListingCollectionsDropdownApp);
};
