import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Box from "../../../elements/v2/Box/Box";
import { gql, useQuery } from "@apollo/client";
import GET_MY_EMAILS_WITH_CONSENTS from "../queries/GetMyEmailsWithConsents.graphql";
import { emailMatches } from "../../../../utils/emails";
import { Heading } from "../components/Heading";
import { Description } from "../components/Description";
import { getHistoricalOrdersWithEmailPath } from "../../../../utils/paths";
import {
  GetMyEmailsWithConsents,
  GetMyEmailsWithConsentsVariables,
} from "../queries/types/GetMyEmailsWithConsents";
import { WhiteBox } from "../components/WhiteBox";
import { LoadingSpinner } from "../../../elements/v2/LoadingSpinner/LoadingSpinner";
import Events from "../../../../utils/analytics";

const ConfirmEmailStep = () => {
  const history = useHistory();
  const { email, affiliateAppId } = useParams();
  const [isEmailConfirmed, setEmailConfirmed] = useState<boolean>(false);

  const variables: GetMyEmailsWithConsentsVariables = { affiliateAppId };
  const { stopPolling } = useQuery<GetMyEmailsWithConsents>(gql(GET_MY_EMAILS_WITH_CONSENTS), {
    variables,
    fetchPolicy: "network-only",
    pollInterval: 3000,
    onCompleted: ({ me }) => {
      me?.emails.forEach(({ address, isConfirmed }) => {
        if (emailMatches(address, email) && isConfirmed) setEmailConfirmed(true);
      });
    },
  });

  useEffect(() => {
    if (isEmailConfirmed) {
      stopPolling();
      Events.sendHoStep2EmailConfirmed({
        email: email,
        confirmed: isEmailConfirmed,
        affiliateAppId: affiliateAppId,
      });
      history.push(getHistoricalOrdersWithEmailPath(affiliateAppId, email));
    }
  }, [stopPolling, isEmailConfirmed]);

  return (
    <Box>
      <WhiteBox>
        <Heading>Check your email to confirm</Heading>
        <Box mt={2}>
          <Description>
            Please check your inbox for &apos;{email}&apos; and click the link to validate your
            email address with AirRobe.
          </Description>
        </Box>
      </WhiteBox>
      <Box mt={4}>
        <LoadingSpinner />
      </Box>
      <Box mt={1.5}>
        <Description>Waiting for email confirmation</Description>
      </Box>
    </Box>
  );
};

export default ConfirmEmailStep;
