import { useMutation } from "@apollo/client";
import React from "react";
import CollectionForm from "../../composites/CollectionForm/CollectionForm";
import CREATE_COLLECTION_MUTATION from "./mutations/CreateCollection";
import { CreateCollection, CreateCollectionVariables } from "./mutations/types/CreateCollection";

interface Props {
  listingIds?: Array<number>;
  afterCreate?: (response: CreateCollection) => void;
  handleCancel: () => void;
}

// Create a new collection, and add listings to it if ids are provided
export default function NewCollectionForm({ listingIds, afterCreate, handleCancel }: Props) {
  const [createCollection, { data }] = useMutation<CreateCollection, CreateCollectionVariables>(
    CREATE_COLLECTION_MUTATION,
    {
      onCompleted: (response) => {
        if (response.createCollection.collection) {
          afterCreate(response);
        }
      },
    }
  );

  const handleSubmit = ({ title }) => {
    const variables = { input: { title, listingIds } };
    createCollection({ variables });
  };

  return (
    <CollectionForm
      error={data?.createCollection.error}
      mode="name"
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
    />
  );
}
