import { gql } from "@apollo/client";

const REORDER_LISTING_IMAGES = gql`
  mutation ReorderListingImages($input: ReorderListingImagesMutationInput!) {
    reorderListingImages(input: $input) {
      success
    }
  }
`;

export default REORDER_LISTING_IMAGES;
