import React from "react";
import styled from "styled-components";
import LinkBox from "../v2/Box/LinkBox";
import { SITE_SHOP_PATH } from "../../../utils/paths";
import { breakpoints } from "../../../utils/styledHelpers";

type LogoContentProps = {
  image?: string;
  image_highres?: string;
  text: string;
};

export type LogoProps = LogoContentProps & {
  image?: string;
  image_highres?: string;
  text: string;
};

export default function Logo(props: LogoProps) {
  const { image, image_highres, text, ...otherProps } = props;
  const higherRes = image_highres != null ? { srcSet: `${image_highres} 2x` } : null;

  return (
    <LogoContainer href={SITE_SHOP_PATH} {...otherProps}>
      {image ? <LogoImg src={image} alt={text} {...higherRes} /> : <LogoText>{text}</LogoText>}
    </LogoContainer>
  );
}

const LogoContainer = styled(LinkBox).attrs({ className: "Logo" })`
  height: 27px;
  display: flex;
  align-items: center;
  color: #1f1e23;
  text-align: center;

  ${breakpoints.down("tablet")} {
    height: 20px;
  }

  ${breakpoints.down("sm")} {
    height: 12px;
  }
`;

const LogoImg = styled.img`
  height: 100%;
`;

const LogoText = styled.span.attrs({
  className: "textLogo",
})`
  min-width: 0;
`;
