import flashNotificationReducer from "./FlashNotificationReducer";
import manageAvailabilityReducer from "./ManageAvailabilityReducer";
import routesReducer from "./RoutesReducer";

// This is how you do a directory of reducers.
// The `import * as reducers` does not work for a directory, but only with a single file
export default {
  flashNotifications: flashNotificationReducer,
  marketplace: (state = {}) => state,
  routes: routesReducer,
  listings: (state = {}) => state,
  topbar: (state = {}) => state,
  user: (state = {}) => state,
  manageAvailability: manageAvailabilityReducer,
};
