import React from "react";
import styled from "styled-components";
import { GetListing_listing } from "../../queries/types/GetListing";
import Box, { BoxProps } from "../../../../elements/v2/Box/Box";
import Typography from "../../../../elements/v2/Typography/Typography";
import { formatMoney } from "../../../../../utils/numbers";

type Props = BoxProps & {
  listing?: GetListing_listing;
};

export default function RetailPrice({ listing, ...props }: Props) {
  if (!listing.rrp) return null;

  return (
    <Box {...props}>
      <Content>Retail Price: {formatMoney(listing.rrp)}</Content>
    </Box>
  );
}

const Content = styled(Typography)`
  display: inline-flex;
  gap: 5px;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #888888;
`;
