import { Box, createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import CloudIcon from "@material-ui/icons/Cloud";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import waterDrop from "./assets/water-drop.svg";

const useStyles = makeStyles((theme) =>
  createStyles({
    bold: {
      fontWeight: 600,
    },
    offsetList: {
      display: "flex",
      flexDirection: "column",
    },
    offsetItem: {
      display: "flex",
    },
    offsetText: {
      paddingLeft: 8,
    },
    icon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      "& svg": {
        width: "1em",
        height: "1em",
        fill: "currentcolor",
        display: "inline-block",
        fontSize: "1.5rem",
        flexShrink: 0,
      },
    },
  })
);

export default function OffsetInfo() {
  const classes = useStyles();
  return (
    <Grid container spacing={1} justify="center" alignItems="center" direction="column">
      <Grid item>
        <Box className={classes.offsetItem}>
          <Box>
            <CloudIcon />
          </Box>
          <Box className={classes.offsetText}>
            <Typography className={classes.bold}>21kg of CO2 emissions</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <Box className={classes.offsetItem}>
          <Box>
            <div className={classes.icon} dangerouslySetInnerHTML={{ __html: waterDrop }} />
          </Box>
          <Box className={classes.offsetText}>
            <Typography className={classes.bold}>95 litres of water</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <Box className={classes.offsetItem}>
          <Box>
            <DeleteIcon />
          </Box>
          <Box className={classes.offsetText}>
            <Typography className={classes.bold}>2kg of textile waste</Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
