import { Grid } from "@material-ui/core";
import React from "react";
import AddressAutoComplete, {
  GoogleMapsProps,
} from "../../../FormControls/AddressAutoComplete/AddressAutoComplete";
import CheckboxGroup, { CheckboxGroupOptions } from "../../../FormControls/Checkbox/CheckboxGroup";
import CustomFormikField from "../../../FormControls/CustomFormikField/CustomFormikField";
import FormSection from "../../../FormControls/FormSection/FormSection";

const deliveryMethodOptions: CheckboxGroupOptions = [
  { value: "shipping", label: "I'll post it" },
  { value: "pickup", label: "They can pick it up" },
];

const DeliveryStep = (googleMapsProps: GoogleMapsProps) => (props) => {
  const {
    formField: { location, deliveryMethods },
  } = props;

  return (
    <Grid container spacing={2} direction="column" wrap="nowrap">
      <Grid item>
        <FormSection
          title="Delivery method"
          description="How will you get your item to the new owner?"
        >
          <CustomFormikField
            fieldName={deliveryMethods.name}
            InnerComponent={CheckboxGroup}
            options={deliveryMethodOptions}
          />
        </FormSection>
      </Grid>
      <Grid item>
        <FormSection
          title="Your location"
          description="Let buyers know where you're located by entering your city and postcode."
          classesOverrides={{
            innerFormSection: {
              padding: 16,
            },
          }}
        >
          <CustomFormikField
            fieldName={location.name}
            InnerComponent={AddressAutoComplete}
            googleMapsProps={googleMapsProps}
          />
        </FormSection>
      </Grid>
    </Grid>
  );
};

const withProps = (googleMapsProps: GoogleMapsProps) => DeliveryStep(googleMapsProps);

export default withProps;
