import { Box, Button, Card, createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { RoutesContext } from "../../../utils/routes";
import ListingCardHorizontal from "../ListingCardHorizontal/ListingCardHorizontal";
import { GetListingFormData_listing } from "./queries/types/GetListingFormData";

export enum ListingShape {
  Rent = "rent",
  Resell = "resell",
}

interface Props {
  listing: GetListingFormData_listing;
  listingShapes: Record<ListingShape, number>;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "#E0E0E0",
    },
    helloCard: {
      padding: 16,
    },
    bold: {
      fontWeight: 600,
    },
    button: {
      width: "80%",
    },
  })
);

export default function ProductSuccess({ listing, listingShapes }: Props) {
  const classes = useStyles();
  const history = useHistory();
  const routes = useContext(RoutesContext);

  return (
    <Card square className={classes.root}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Card square className={classes.helloCard} style={{ width: "100%" }}>
          <Typography variant="h6" className={classes.bold} align="center">
            CHOOSE HOW TO REPURPOSE THIS ITEM
          </Typography>
        </Card>
        <Box style={{ maxWidth: 1000 }}>
          <Grid container spacing={4} direction="column">
            <Grid item>
              <ListingCardHorizontal
                listing={listing}
                includeActionButtons
                listingShapes={listingShapes}
              />
            </Grid>
            <Grid item>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <Typography
                    variant="h6"
                    gutterBottom
                    color="textPrimary"
                    className={classes.bold}
                    align="center"
                  >
                    THIS ITEM HAS NOW BEEN ADDED TO YOUR CIRCULAR WARDROBE
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={2} direction="column" alignItems="center">
                <Grid item className={classes.button}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    href={routes.new_product_path()}
                    data-cy="add-item-button"
                  >
                    Add another item
                  </Button>
                </Grid>
                <Grid item className={classes.button}>
                  <Button variant="outlined" fullWidth href={routes.search_path()}>
                    Shop
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  );
}
