import { useQuery } from "@apollo/client";
import { ChartOptions } from "chart.js";
import React from "react";
import { useChartJSChart } from "../../../utils/chartJs";
import GET_ACTIVATION_REPORT from "./queries/GetActivationReport";
import { GetActivationReport } from "./queries/types/GetActivationReport";

export interface Props {
  options: ChartOptions;
}

export default function AdminActivationReport({ options }: Props) {
  const { data } = useQuery<GetActivationReport>(GET_ACTIVATION_REPORT);
  const chartRef = useChartJSChart({ options, data: data?.activationReport });

  return <canvas ref={chartRef} width="500" height="500"></canvas>;
}
