import { Grid } from "@material-ui/core";
import { Dictionary } from "lodash";
import React from "react";
import CustomFormikField from "../../../FormControls/CustomFormikField/CustomFormikField";
import FormSection from "../../../FormControls/FormSection/FormSection";
import TextField from "../../../FormControls/TextField/TextField";
import { GetCategories_categories } from "../queries/types/GetCategories";
import CustomProductFieldSection from "../Sections/CustomProductFieldSection";

type Props = {
  values: any;
  formField: any;
};

const OptionalCustomProductFieldsStep = (categoriesMap: Dictionary<GetCategories_categories>) => ({
  values,
  formField: { description },
}: Props) => {
  console.log("OptionalCustomProductFieldsStep", values, description, categoriesMap);

  if (!values.category) return null;

  const optionalCustomFields = categoriesMap[values.category].customFields.filter(
    (c) => !c.required
  );

  return (
    <Grid container spacing={2} direction="column" wrap="nowrap">
      <Grid item>
        <FormSection title="Description">
          <CustomFormikField
            fieldName={description.name}
            InnerComponent={TextField}
            fullWidth
            multiline
            rows={4}
            placeholder="Tell us more about the item you're listing. Add additional details about the genre, style or fit, too."
          />
        </FormSection>
      </Grid>
      <Grid item>
        <FormSection
          title="Optional details"
          description="Help your item get noticed by tagging it with extra details."
        >
          {optionalCustomFields.map((customField, i) => (
            <Grid item key={i}>
              <CustomProductFieldSection
                customField={customField}
                formSectionOverrides={{
                  formSection: {
                    padding: 0,
                    borderTop: "unset",
                    borderBottom: "unset",
                  },
                }}
              />
            </Grid>
          ))}
        </FormSection>
      </Grid>
    </Grid>
  );
};

const withProps = (categoriesMap: Dictionary<GetCategories_categories>) =>
  OptionalCustomProductFieldsStep(categoriesMap);

export default withProps;
