import styled from "styled-components";
import Chip from "../../../elements/v2/Chip/Chip";

const FilterChip = styled(Chip).attrs({
  component: "a",
  clickable: true,
  className: "t-filterChip",
})`
  padding: 0;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 14px;
`;

export default FilterChip;
