import classNames from "classnames";
import React, { ButtonHTMLAttributes } from "react";
import css from "./ActionButton.module.css";
import circle from "./circle.svg";

interface Props {
  children: React.ReactNode;
  isDark?: boolean;
  isActive?: boolean;
  className?: string;
  onClick?: (e: any) => void;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  withCircle?: boolean;
}

// Ideally these buttons would be generalised, but I'm not sure if the same behaviour will be used
// in other places, so for now this component lives in the Profile page scope and not in our
// higher-up component library.
export default function ActionButton({
  children,
  isDark,
  isActive,
  className,
  onClick,
  type,
  withCircle,
}: Props) {
  return (
    <button
      type={type}
      className={classNames(css.root, className, "has-ripple", {
        [css.dark]: isDark,
        [css.active]: isActive,
        [css.withCircle]: withCircle,
      })}
      onClick={(e) => {
        onClick && onClick(e);
      }}
    >
      {withCircle ? (
        <span className={css.circle} dangerouslySetInnerHTML={{ __html: circle }} />
      ) : null}
      <span>{children}</span>
    </button>
  );
}

// Render both a mobile version (ActionButton) and a plain version for desktop, showing and hiding
// them for the appropriate screen sizes. This is definitelty not ideal, but it will do for now
// until we get a bit more clarity about what our basic button elements are, and how they behave on
// web and mobile.
interface MobileVariantActionButtonProps {
  children: React.ReactNode;
}
export function MobileVariantActionButton({ children }: MobileVariantActionButtonProps) {
  return (
    <>
      <span className={css.hideMobile}>{children}</span>
      <ActionButton className={css.showMobile}>{children}</ActionButton>
    </>
  );
}
