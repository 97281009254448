import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import StatusRow from "../components/StatusRow";
import ACCEPT_TRANSACTION from "../mutations/AcceptTransaction";
import REJECT_TRANSACTION from "../mutations/RejectTransaction";
import { AcceptTransaction } from "../mutations/types/AcceptTransaction";
import { RejectTransaction } from "../mutations/types/RejectTransaction";
import { GetTransaction_me, GetTransaction_transaction } from "../queries/types/GetTransaction";
import css from "../TransactionStatus.module.css";
import SellerRejectButton from "../../SellerRejectButton/SellerRejectButton";
import { TransactionActionButton } from "../TransactionStatus";

export interface Props {
  me: GetTransaction_me;
  transaction: GetTransaction_transaction;
}

function Preauthorized({ me, transaction }: Props) {
  const iAmSeller = transaction.seller.id === me.id;
  const iAmBuyer = transaction.buyer.id === me.id;

  const [error, setError] = useState<string>(null);

  const [
    acceptTransaction,
    { data: acceptData, loading: acceptLoading },
  ] = useMutation<AcceptTransaction>(ACCEPT_TRANSACTION, {
    onCompleted: (response) => {
      setError(response.acceptTransaction.error);
    },
    onError: (e) => setError(e.message),
  });

  const [
    rejectTransaction,
    { data: rejectData, loading: rejectLoading },
  ] = useMutation<RejectTransaction>(REJECT_TRANSACTION, {
    onCompleted: (response) => {
      setError(response.rejectTransaction.error);
    },
    onError: (e) => setError(e.message),
  });

  useEffect(() => {
    if (error) {
      (window as any).FlashMessage.error(`Sorry, that didn't work: ${error}`);
    }
  }, [error]);

  return (
    <React.Fragment>
      {iAmBuyer && (
        <StatusRow>
          <div>
            <p>
              <b>Current stage:</b>
            </p>
            <p>
              Please wait while{" "}
              <a href={transaction.seller.profileUrl}>{transaction.seller.displayName}</a> accepts
              your order.
            </p>
            <p>
              If <a href={transaction.seller.profileUrl}>{transaction.seller.displayName}</a>{" "}
              declines, or does not respond within 3 days, no charge is made.
            </p>
            <p>
              Your payment stays secure in escrow until you have received the item and approve the
              payment transfer. AirRobe does not store your credit/debit card information.
            </p>
          </div>
        </StatusRow>
      )}

      {iAmSeller && (
        <StatusRow>
          <div>
            <p>
              <b>Item Requested:</b>
            </p>
            <p>
              <a href={transaction.buyer.profileUrl}>{transaction.buyer.displayName}</a> has
              requested to purchase{" "}
              <a href={transaction.listing.url}>{transaction.listing.title}</a>. Do you wish to
              accept the transaction?
            </p>
            <p>
              NOTE: Payment will be held securely in escrow until the item has arrived and been
              confirmed by{" "}
              <a href={transaction.buyer.profileUrl}>{transaction.buyer.displayName}</a>{" "}
            </p>
          </div>
        </StatusRow>
      )}

      <div className={css.actions}>
        {iAmSeller && (
          <React.Fragment>
            <TransactionActionButton
              text="Accept Request"
              loading={acceptLoading}
              onClick={() => {
                acceptTransaction({
                  variables: {
                    input: {
                      txId: transaction.id,
                    },
                  },
                });
              }}
            />
            <SellerRejectButton
              text="Reject"
              loading={rejectLoading}
              isCustomOffer={transaction.customOfferProvided}
              onClick={(reason) => {
                rejectTransaction({
                  variables: {
                    input: {
                      txId: transaction.id,
                      rejectionReason: reason,
                    },
                  },
                });
              }}
            />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}

export default Preauthorized;
