import React from "react";
import { Input } from "@sajari/react-search-ui";
import styled from "styled-components";
import Box from "../../elements/v2/Box/Box";
import searchIcon from "./images/search-icon.svg";
import { breakpoints } from "../../../utils/styledHelpers";
import { t } from "../../../utils/i18n";
import { isAnyDepartmentActive } from "../../../utils/departments";

export default function SearchBar() {
  const formRef = React.createRef<HTMLFormElement>();
  const isOnSearchPage = isAnyDepartmentActive();

  function handleSelect() {
    if (!isOnSearchPage) formRef.current.submit();
  }

  function handleSubmit(e) {
    if (isOnSearchPage) e.preventDefault();
  }

  return (
    <Container>
      <SearchForm action="/s" ref={formRef} onSubmit={handleSubmit}>
        <SearchInput
          name="q"
          mode="suggestions"
          placeholder={t("web.topbar.search_placeholder")}
          showPoweredBy={false}
          onSelect={handleSelect}
          autoComplete="off"
        />
        <SearchIcon dangerouslySetInnerHTML={{ __html: searchIcon }} />
      </SearchForm>
    </Container>
  );
}

const Container = styled(Box)`
  width: 100%;
  line-height: 1em;
`;

const SearchForm = styled.form`
  position: relative;
  vertical-align: center;
`;

const SearchInput = styled(Input)`
  > div[role="combobox"] {
    width: 100%;
    height: 40px;
    border: 1px solid #222222;
    border-radius: 2px;
    :focus-within {
      box-shadow: none;
    }
  }
  .SearchInput-input {
    padding: 0 30px 0 40px;
    -webkit-appearance: none;
    font-size: 13px;
    &:focus,
    &:focus-visible {
      outline-offset: 1px;
      outline: black solid 1px;
    }
    ${breakpoints.down("sm")} {
      padding-left: 28px;
      padding-right: 32px;
    }
  }
  .SearchInput-iconContainer {
    display: none;
  }
`;

const SearchIcon = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  padding: 12px;
  svg g {
    stroke: black;
  }
  &:hover svg g {
    stroke-width: 2px;
  }

  ${breakpoints.down("sm")} {
    padding: 12px 8px;
  }
`;
