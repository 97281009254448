import { gql } from "@apollo/client";

export default gql`
  query GetDiscountDetails($discountCode: String!, $listingId: Int!) {
    discountCode(discountCode: $discountCode) {
      percentOff
    }
    isDiscountCodeValid(discountCode: $discountCode, listingId: $listingId)
  }
`;
