import React from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import Typography, { A } from "../../../elements/v2/Typography/Typography";
import Box from "../../../elements/v2/Box/Box";
import { Input } from "../../../elements/v2/Form/Input";
import Button, { BUTTON_SIZES } from "../../../elements/v2/Button/Button";
import { isValidEmail } from "../../../../utils/emails";
import FlexBox from "../../../elements/v2/Box/FlexBox";
import { FORGOT_PASSWORD_PATH } from "../../../../utils/paths";

const validate = (values: any) => {
  const errors: any = {};

  if (!values.email) {
    errors.email = "Required";
  } else if (!isValidEmail(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.email) {
    errors.password = "Required";
  }

  return errors;
};

type Props = {
  onLoginPress: (values) => void;
  onGoToSignup: () => void;
};

const getForgotPasswordUrl = (email) => {
  const params = email ? `?q=${email}` : "";
  return `${FORGOT_PASSWORD_PATH}${params}`;
};

export const LoginForm = ({ onLoginPress, onGoToSignup }: Props) => {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: (values) => {
      onLoginPress(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box px={4}>
        <FlexBox justifyContent="center" mb={4}>
          <PageTitle>Log in to Complete your purchase</PageTitle>
        </FlexBox>
        <Box mt={2}>
          <Input
            id="email"
            name="email"
            type="text"
            label="Email"
            onChange={formik.handleChange}
            value={formik.values.email}
            errorMessage={formik.errors.email as string}
          />
        </Box>
        <Box mt={2}>
          <Input
            label="Password"
            id="password"
            name="password"
            type="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            errorMessage={formik.errors?.password as string}
          />
        </Box>
        <Box mt={5.5}>
          <LoginButton
            disabled={!formik.values.email || !formik.values.password}
            text="Log In"
            $width="100%"
          />
        </Box>

        <FlexBox justifyContent="center" mt={2}>
          <TextLink href={getForgotPasswordUrl(formik.values.email)}>Forgot Password</TextLink>
        </FlexBox>
        <FlexBox justifyContent="center" mt={4}>
          <TextLink onClick={onGoToSignup}>Not a member yet? Register</TextLink>
        </FlexBox>
      </Box>
    </form>
  );
};

const PageTitle = styled(Typography)`
  font-size: 13px;
  font-weight: 500;
  color: #222222;
  text-transform: uppercase;
`;

const LoginButton = styled(Button).attrs({
  size: BUTTON_SIZES.LARGE,
  type: "submit",
})`
  width: 100%;
  color: black;
`;

const TextLink = styled(A)`
  text-decoration: underline;
  font-size: 13px;
  cursor: pointer;
`;
