const DATA_LOADED = "AVAILABILITY::DATA_LOADED";
const BLOCK_DAY = "AVAILABILITY::BLOCK_DAY";
const UNBLOCK_DAY = "AVAILABILITY::UNBLOCK_DAY";
const CHANGE_MONTH = "AVAILABILITY::CHANGE_MONTH";
const START_SAVING = "AVAILABILITY::START_SAVING";
const CHANGES_SAVED = "AVAILABILITY::CHANGES_SAVED";
const SAVING_FAILED = "AVAILABILITY::SAVING_FAILED";
const OPEN_EDIT_VIEW = "AVAILABILITY::OPEN_EDIT_VIEW";
const CLOSE_EDIT_VIEW = "AVAILABILITY::CLOSE_EDIT_VIEW";
const DATA_BLOCKED_DATES_LOADED = "AVAILABILITY::DATA_BLOCKED_DATES_LOADED";
const DATA_BOOKED_DATES_LOADED = "AVAILABILITY::DATA_BOOKED_DATES_LOADED";

export {
  DATA_LOADED,
  BLOCK_DAY,
  UNBLOCK_DAY,
  CHANGE_MONTH,
  START_SAVING,
  CHANGES_SAVED,
  SAVING_FAILED,
  OPEN_EDIT_VIEW,
  CLOSE_EDIT_VIEW,
  DATA_BLOCKED_DATES_LOADED,
  DATA_BOOKED_DATES_LOADED,
};
