import { gql } from "@apollo/client";

const RejectTransaction = gql`
  mutation RejectTransaction($input: RejectTransactionMutationInput!) {
    rejectTransaction(input: $input) {
      transaction {
        id
        currentState
        shipped
      }
      error
    }
  }
`;

export default RejectTransaction;
