import React, { useContext } from "react";
import PersonSummaryItem from "./PersonSummaryItem";
import { RoutesContext } from "../../../../utils/routes";
import { GetListing_listing_author } from "../../../pages/ListingPage/queries/types/GetListing";
import FlexBox from "../../../elements/v2/Box/FlexBox";
import { ProfilePageProps_person } from "../../../pages/SearchPage/queries/types/ProfilePageProps";

interface Props {
  person: ProfilePageProps_person | GetListing_listing_author;
  className?: string;
}

export default function PersonSummaryBlock({ person, className }: Props) {
  const { followers, receivedTestimonials, collections, username } = person;
  const routes = useContext<any>(RoutesContext);
  const items = [
    { number: followers.totalCount, word: "Follower" },
    {
      link: routes.reviews_person_path({ username }),
      number: receivedTestimonials.totalCount,
      word: "Review",
    },
    {
      link: routes.collections_person_path({ username }),
      number: collections.totalCount,
      word: "Collection",
    },
  ];

  return (
    <FlexBox mt={2}>
      {items.map((item, index) => (
        <PersonSummaryItem key={index} {...item} />
      ))}
    </FlexBox>
  );
}
