import React from "react";
import Box, { BoxProps } from "../../../elements/v2/Box/Box";

export default function SectionDivide(props: BoxProps) {
  return (
    <Box mt={props?.mt || 3} mb={props?.mb || 5} height="16px" {...props}>
      <svg
        width="100%"
        height="2"
        viewBox="0 0 380 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 1H380" stroke="#CCCCCC" />
      </svg>
    </Box>
  );
}
