import React from "react";
import { createEntryPage } from "../FullPageLayout/FullPageLayout";
import Box from "../../elements/v2/Box/Box";
import { H1 } from "../../elements/v2/Typography/Heading";
import { P } from "../../elements/v2/Typography/Typography";
import Button from "../../elements/Button/Button";
import { NAV_SHOP_ALL_PATH } from "../../../utils/paths";

type Props = {
  search_query: string;
};

const Status410Page = ({ search_query }: Props) => {
  return (
    <Box display="flex" justifyContent="center" height="60vh">
      <Box
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        display="flex"
        flexDirection="column"
        width="50%"
      >
        <H1 style={{ textAlign: "center" }}>This listing has been removed</H1>

        <Box marginTop="10px">
          <P style={{ textAlign: "center" }}>
            There are a few possible reasons for this. For example, a user might have removed their
            account or a listing might have been removed.
          </P>
        </Box>

        <Box marginTop="10px">
          <Button
            href={`${NAV_SHOP_ALL_PATH}/?q=${search_query}`}
            text="Look for similar items"
            color="black"
          />
        </Box>
      </Box>
    </Box>
  );
};

type Args = {
  topbar: any; // This will remain a black box to typescript until we rewrite it
  status410Page: Props;
};

const mapEntryProps = ({ topbar, status410Page }: Args) => ({
  pageProps: {
    topbarProps: topbar,
  },
  componentProps: status410Page,
});

export default createEntryPage(mapEntryProps)(Status410Page);
