import r from "r-dom";
import ExternalOrderPreview from "../components/widgets/ExternalOrderPreview/ExternalOrderPreview";
import { wrapWithApollo } from "../graphql/client";
import { initialize as initializeI18n } from "../utils/i18n";
import ssrComponent from "../utils/ssr";
import { wrapWithThemeProvider } from "../utils/themeProvider";
import { flowRight } from "lodash";
import wrapWithStylesProvider from "../utils/wrapWithStylesProvider";
import { wrapWithRoutes } from "../utils/routes";

export default (props, context) => {
  initializeI18n(context.i18nLocale, context.i18nDefaultLocale, process.env.NODE_ENV, {
    region: "Unknown",
    language: "English",
  });

  const ExternalOrderPreviewApp = flowRight(
    wrapWithRoutes(context),
    wrapWithStylesProvider,
    wrapWithThemeProvider,
    wrapWithApollo
  )(() => r(ExternalOrderPreview, props));

  return ssrComponent(ExternalOrderPreviewApp);
};
