import React from "react";
import styled from "styled-components";
import Box from "../../../../elements/v2/Box/Box";
import Typography from "../../../../elements/v2/Typography/Typography";
import { CardContent, CardMedia } from "@material-ui/core";
import { RequestHistoricalOrders_requestHistoricalOrders } from "../../mutations/types/RequestHistoricalOrders";
import { formatMoney } from "../../../../../utils/numbers";
import Card from "../../../../elements/v2/Card/Card";
import { Svg } from "../../../../elements/Svg/Svg";
import noImageIcon from "../../../../../assets/icons/noImageIcon.svg";
import { CheckBox } from "../../../../elements/v2/Form/CheckBox";

export interface HistoricalOrdersItemCardProps {
  item: RequestHistoricalOrders_requestHistoricalOrders;
  isSelected: boolean;
  onSelect: () => void;
}

export const HistoricalOrdersItemCard = ({
  item,
  isSelected,
  onSelect,
}: HistoricalOrdersItemCardProps) => {
  const { title, brand, paidPrice, imageUrls, estimatedResaleValue, size, color } = item;
  const imageUrl = imageUrls[0];

  return (
    <Container onClick={onSelect} $selected={isSelected}>
      <CardImage>
        {imageUrl ? <CardMedia component="img" image={imageUrl} /> : <Svg content={noImageIcon} />}
      </CardImage>
      <CardContent>
        <Title>{brand}</Title>
        <SubTitle>{title}</SubTitle>
        <Box>
          {size && <ProductDetails>Size: {size}</ProductDetails>}
          {color && <ProductDetails>Color: {color}</ProductDetails>}
        </Box>
        <Box marginTop="16px">
          <PotentialValue>Potential value: {formatMoney(estimatedResaleValue)}</PotentialValue>
          <PriceDetails>Purchase price: {formatMoney(paidPrice)}</PriceDetails>
        </Box>
      </CardContent>
      <Box alignSelf="flex-start">
        <CheckBox checked={isSelected} data-testid="select-card-checkbox" />
      </Box>
    </Container>
  );
};

const Container = styled(Card)`
  background: white;
  width: 342px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  flex-shrink: 0;
  ${({ $selected }) =>
    $selected &&
    `
    background: #fafafa;
    box-shadow:
      0 1px 2px rgba(0, 0, 0, 0.1),
      #000 0 0 0 2px inset;
  `}
  &:hover {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), #000 0 0 0 2px inset;
  }
`;

const CardImage = styled(Box)`
  width: 100px;
  height: auto;
  margin: 16px;
  svg {
    opacity: 0.5;
  }
`;

const Title = styled(Typography)`
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 4px;
`;

const SubTitle = styled(Typography)`
  font-weight: 500;
  font-size: 13px;
  color: #000000;
  margin-bottom: 8px;
`;

const ProductDetails = styled(Typography)`
  font-weight: 500;
  font-size: 13px;
  color: #000000;
`;

const PotentialValue = styled(Typography)`
  font-weight: 500;
  font-size: 13px;
  color: #888888;
`;

const PriceDetails = styled(Typography)`
  font-size: 13px;
  color: #000000;
  font-weight: 700;
`;
