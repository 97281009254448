import { gql } from "@apollo/client";
import { CollectionCardFragment } from "../../../../graphql/fragments/collections";

export default gql`
  query GetCollection($id: Int) {
    collection(id: $id) {
      ...CollectionCardFragment
    }
  }
  ${CollectionCardFragment}
`;
