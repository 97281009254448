import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import BrandOneOfHeader from "./BrandOneOfHeader";

type Props = {
  featuredBrandLogoUrl?: string;
  featuredBrandHeaderUrl?: string;
  brand?: string;
};

/*
 * The brand should be passed to this page with in an object with properties such as:
 * brand_key, name, isDesigner, isFeatured, featuredBrandHeader, featuredBrandLogo
 *
 * It could even just be a brand id/key with a graphql request to grab the brand details.
 */
export default function BrandHeader({
  featuredBrandLogoUrl,
  featuredBrandHeaderUrl,
  brand,
}: Props) {
  if (brand.includes("one-of")) return <BrandOneOfHeader />;

  if (!featuredBrandLogoUrl || !featuredBrandHeaderUrl) return null;

  const isBrandOfficialPartner = brand.includes("romance-was-born");
  const classes = useStyles();
  return (
    <div className={classNames("t-featuredBrandHeader", classes.featuredBrandHeader)}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          width: "300px",
          flexShrink: 0,
        }}
      >
        <img className={classes.brandLogo} src={featuredBrandLogoUrl} />
        {isBrandOfficialPartner && (
          <p style={{ marginTop: "1rem", marginBottom: "0px" }}>An official AirRobe Partner</p>
        )}
      </div>
      <div
        className={classes.brandHeader}
        style={{ backgroundImage: `url(${featuredBrandHeaderUrl})` }}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  featuredBrandHeader: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexWrap: "wrap-reverse",
      justifyContent: "center",
    },
  },
  brandLogo: {
    display: "flex",
    justifyContent: "center",
    width: "200px",
    maxWidth: "300px",
    marginLeft: "2rem",
    marginRight: "2rem",
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: {
      marginTop: "2rem",
      marginBottom: "2rem",
      width: "200px",
    },
  },
  brandHeader: {
    display: "flex",
    justifyContent: "center",
    objectFit: "cover",
    width: "100%",
    height: "25vh",
    backgroundPosition: "initial",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
}));
