import classNames from "classnames";
import React from "react";
import Avatar from "../../elements/Avatar/Avatar";
import { GetPersonReviews_person_receivedTestimonials_results } from "../../pages/ProfilePage/queries/types/GetPersonReviews";
import css from "./TestimonialCard.module.css";

interface Props {
  testimonial: GetPersonReviews_person_receivedTestimonials_results;
}

// TODO: we're just piggy-backing off styles that are compiled in the Rails asset pipeline. If this
// gets a redesign, we should bring them into the /client scope.
export default function TestimonialCard({ testimonial }: Props) {
  const image = {
    url: testimonial.author.avatarImageUrl,
  };

  return (
    <div className={css.root}>
      <div className={css.avatar}>
        <Avatar givenName={testimonial.author.givenName} image={image} />
      </div>

      <div className={css.content}>
        <div>{testimonial.text}</div>
        <div>{testimonial.author.givenName}</div>
        <a className={css.listing} href={testimonial.listing.url}>
          {testimonial.listing.title}
        </a>
      </div>
      <div
        className={classNames(css.thumb, {
          [css.positive]: testimonial.positive,
          [css.negative]: !testimonial.positive,
        })}
      >
        <i className={testimonial.positive ? "icon-thumbs-up" : "icon-thumbs-down"} />
      </div>
    </div>
  );
}
