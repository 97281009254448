import React from "react";
import styled from "styled-components";
import { useFormik, Field, FormikProvider, setNestedObjectValues } from "formik";
import Typography, { A } from "../../../elements/v2/Typography/Typography";
import Box from "../../../elements/v2/Box/Box";
import { Input } from "../../../elements/v2/Form/Input";
import Button, { BUTTON_SIZES } from "../../../elements/v2/Button/Button";
import { isValidEmail } from "../../../../utils/emails";
import Checkbox from "../../../composites/FormControls/Checkbox/Checkbox";
import FlexBox from "../../../elements/v2/Box/FlexBox";
import { Select } from "../../../elements/v2/Form/Select/Select";
import axios from "axios";
import { getCsrfHeaders } from "../../../../utils/csrfHelper";
import { ifTrue } from "../../../../utils/styledHelpers";
import { MenuItem } from "@material-ui/core";

const cachedEmailAvailability = {};

export const checkEmailAvailability = async (email) => {
  if (!cachedEmailAvailability[email]) {
    cachedEmailAvailability[email] = axios({
      method: "get",
      url: `/people/check_email_availability_and_validity?person[email]=${email}`,
      headers: getCsrfHeaders(),
      withCredentials: true,
    });
  }

  const result = await cachedEmailAvailability[email];

  return Boolean(result.data);
};

const validate = async (values: any) => {
  const errors: any = {};

  if (!values.email) {
    errors.email = "Required";
  } else if (!isValidEmail(values.email)) {
    errors.email = "Invalid email address";
  } else {
    const available = await checkEmailAvailability(values.email);
    if (!available) {
      errors.email = "This email is already in use";
    }
  }

  if (!values.password) {
    errors.password = "Required";
  }

  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = "Passwords do not match";
  }

  if (!values.terms) {
    errors.terms = "Required";
  }

  if (!values.yearOfBirth) {
    errors.yearOfBirth = "Required";
  }

  return errors;
};

type Props = {
  onSignupPress: (values) => void;
  onGoToLogin: () => void;
};

export const SignupForm = ({ onSignupPress, onGoToLogin }: Props) => {
  const formik = useFormik({
    initialValues: {
      email: undefined,
      password: undefined,
      confirmPassword: undefined,
      givenName: undefined,
      familyName: undefined,
      yearOfBirth: "",
      terms: false,
      marketingOptIn: false,
    },
    validate,
    onSubmit: async (values, formikHelpers) => {
      const validationErrors = await formikHelpers.validateForm();
      if (Object.keys(validationErrors).length > 0) {
        formikHelpers.setTouched(setNestedObjectValues(validationErrors, true));
        return;
      }
      onSignupPress(values);
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Box px={4}>
          <FlexBox justifyContent="center" flexDirection="column" mb={4}>
            <PageTitle>Create an AirRobe account to complete your purchase</PageTitle>
            <PageSubText>
              AirRobe is the easiest way to buy, sell and rent pre-loved fashion. Better for your
              wallet and the planet.
            </PageSubText>
          </FlexBox>
          <Box mt={2}>
            <Input
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              errorMessage={formik.errors.email}
            />
          </Box>
          <Box mt={2}>
            <Input
              type="password"
              label="Password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              errorMessage={formik.errors.password}
            />
          </Box>

          <Box mt={2}>
            <Input
              type="password"
              label="Confirm password"
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
              errorMessage={formik.errors.confirmPassword}
            />
          </Box>
          <Box mt={2}>
            <Input
              type="text"
              label="First name"
              name="givenName"
              value={formik.values.givenName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.givenName && Boolean(formik.errors.givenName)}
              errorMessage={formik.errors.givenName}
            />
          </Box>
          <Box mt={2}>
            <Input
              type="text"
              label="Last name"
              name="familyName"
              value={formik.values.familyName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.familyName && Boolean(formik.errors.familyName)}
              errorMessage={formik.errors.familyName}
            />
          </Box>
          <Box mt={2}>
            <Select
              name="yearOfBirth"
              label="Year of Birth"
              value={formik.values.yearOfBirth}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.yearOfBirth && Boolean(formik.errors.yearOfBirth)}
              errorMessage={formik.errors.yearOfBirth}
            >
              {Array.from(
                { length: new Date().getFullYear() - 1940 },
                (_, i) => new Date().getFullYear() - i
              ).map((menuItem: number | string) => {
                return (
                  <MenuItem key={menuItem} value={menuItem}>
                    {menuItem}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <FlexBox alignItems="center" mt={5}>
            <Field name="terms" component={Checkbox} />
            <TermsText $error={formik.touched.terms && Boolean(formik.errors.terms)}>
              I accept the Terms of Use and Privacy Policy
            </TermsText>
          </FlexBox>

          <FlexBox alignItems="center" pt={1}>
            <Field validate name="marketingOptIn" component={Checkbox} />
            <MarketingText>
              I agree to receive marketing emails from AirRobe and understand that I can change my
              mind at any time
            </MarketingText>
          </FlexBox>

          <Box mt={5.5}>
            <SignupButton text="Create account" $width="100%" type="submit" />
          </Box>

          <FlexBox justifyContent="center" mt={4}>
            <TextLink onClick={onGoToLogin}>Already have an account? Log in</TextLink>
          </FlexBox>
        </Box>
      </form>
    </FormikProvider>
  );
};

const PageTitle = styled(Typography)`
  font-size: 13px;
  font-weight: 500;
  color: #222222;
  text-transform: uppercase;
  text-align: center;
`;

const PageSubText = styled(Typography)`
  font-size: 13px;
  font-weight: 500;
  color: #222222;
  text-align: center;
  margin-top: 16px;
`;

const TermsText = styled(Typography)<{ $error: boolean }>`
  font-size: 11px;
  font-weight: 500;
  color: #222222;
  ${ifTrue("$error")`
    color: red;
  `}
`;

const MarketingText = styled(Typography)`
  font-size: 11px;
  font-weight: 500;
  color: #222222;
`;

const SignupButton = styled(Button).attrs({
  size: BUTTON_SIZES.LARGE,
  type: "submit",
})`
  width: 100%;
`;

const TextLink = styled(A)`
  text-decoration: underline;
  font-size: 13px;
  cursor: pointer;
`;
