import { createTheme } from "@material-ui/core/styles";
import { Overrides } from "@material-ui/core/styles/overrides";
import { Shadows } from "@material-ui/core/styles/shadows";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

export enum Breakpoints {
  xs = 0,
  sm = 600,
  tablet = 768,
  md = 960,
  laptop = 1024,
  lg = 1280,
  xl = 1920,
}

const breakpointKeys = <Breakpoint[]>Object.keys(Breakpoints);

// We're defying Material UI's encouragement to stick to a primary and secondary color, and here
// declare our own palette that has provision for a "brand" color. The main reason we don't
// shoe-horn it into either one of primary and secondary colors is because Material UI is
// opinionated about how and when those colors should be used. We prefer to retain control, so we go
// with this approach for now.
const theme = createTheme({
  breakpoints: {
    keys: breakpointKeys,
    values: Breakpoints,
  },
  spacing: 8,
  palette: {
    primary: {
      main: "#000000",
    },
    brand: {
      main: "#FF9579",
    },
  },
  shadows: Array(25).fill("none") as Shadows,
  typography: {
    fontFamily: '"Montserrat", Helvetica, sans-serif',
  },
  overrides: {
    MuiChip: {
      label: {
        color: "black",
      },
    },
    MuiRadio: {
      root: {},
      colorSecondary: {
        "&$checked": {
          color: "#FF9579",
        },
      },
    },
    MuiCheckbox: {
      root: {},
      colorSecondary: {
        "&$checked": {
          color: "#FF9579",
        },
      },
    },
    MuiAccordion: {
      root: {
        "&$expanded": {
          margin: 0,
        },
        // Don't show lines between accordion items
        "&:before": {
          display: "none",
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        "&$expanded": {
          minHeight: 48,
        },
        borderBottomWidth: 0,
      },
      content: {
        fontWeight: "bold",
        "&$expanded": {
          margin: "12px 0",
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        display: "block",
        padding: "0 16px 16px",
      },
    },
    MuiDropzoneArea: {
      root: {
        minHeight: 0,
      },
      icon: {
        display: "none",
      },
    },
  } as Overrides,
});

export default theme;
