import { gql } from "@apollo/client";

export default gql`
  fragment CurrencyParts on Currency {
    code
  }

  query GetCurrencies {
    currencies {
      ...CurrencyParts
    }
  }
`;
