import React, { ReactChildren } from "react";
import SearchContext, { SearchProviderProps } from "../../../utils/search/SearchContext";
import Topbar, { TopbarProps } from "../../widgets/Topbar/Topbar";
import ssrComponent from "../../../utils/ssr";
import { flowRight } from "lodash";
import { wrapWithThemeProvider } from "../../../utils/themeProvider";
import { wrapWithApollo } from "../../../graphql/client";
import { withPersistSSR } from "../../../utils/PersistSSR";
import isSSR from "../../../utils/isSSR";
import { wrapWithRorpContext } from "../../../contexts/RorpContext";
import wrapWithStylesProvider from "../../../utils/wrapWithStylesProvider";
import { wrapWithRouter } from "../../../utils/Router";
import { wrapWithRoutes } from "../../../utils/routes";
import { initializeLocale } from "../../../utils/locale";
import Footer from "../../widgets/Footer/Footer";
import ProductFeedbackSurvey from "../../widgets/TypeformSurveys/ProductFeedbackSurvey";

interface Props {
  topbarProps: TopbarProps;
  searchProviderProps?: SearchProviderProps;
  children: ReactChildren;
}

// This component renders children inside a fragment with our topbar at the top. Routes need to be
// available via context, which means that you need to nest this component inside a
// RoutesContext.Provider that provides the routes required by the Topbar as a minimum. This stuff
// isn't great - a lot of this old code needs refactoring into typescript.
function FullPageLayout({ topbarProps, searchProviderProps = {}, children }: Props) {
  initializeLocale(topbarProps.i18n);

  // Hiding footer and topbar based on display prop
  return (
    <SearchContext {...searchProviderProps}>
      {!topbarProps.isAirrobeNativeView && <Topbar {...topbarProps} />}
      {children}
      {!topbarProps.isAirrobeNativeView && <Footer />}
    </SearchContext>
  );
}

export default FullPageLayout;

const wrappedFullPageLayout = (context, pageProps, componentProps, Component) =>
  flowRight(
    wrapWithRoutes(context),
    wrapWithRorpContext(context),
    wrapWithRouter(context),
    wrapWithStylesProvider,
    wrapWithThemeProvider,
    wrapWithApollo,
    withPersistSSR
  )(() => {
    return (
      <FullPageLayout {...pageProps}>
        <ProductFeedbackSurvey />
        <Component {...componentProps} />
      </FullPageLayout>
    );
  });

export type EntryProps = {
  pageProps: object;
  componentProps?: object;
};

export type MapClientEntryProps<Args> = (args: Args, context) => EntryProps;
export type MapServerEntryProps<Args> = (args: Args, context) => EntryProps | Promise<EntryProps>;

export const createEntryPage = (
  mapClientEntryProps: MapClientEntryProps<any>,
  mapServerEntryProps: MapServerEntryProps<any> = mapClientEntryProps
) => (Component) => {
  if (isSSR())
    return async (args, context) => {
      const { pageProps, componentProps } = await mapServerEntryProps(args, context);
      return await ssrComponent(
        wrappedFullPageLayout(context, pageProps, componentProps, Component)
      );
    };

  return (args, context) => {
    const { pageProps, componentProps } = mapClientEntryProps(args, context);
    return wrappedFullPageLayout(context, pageProps, componentProps, Component);
  };
};
