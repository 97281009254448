import { useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { RoutesContext } from "../../../../utils/routes";
import StatusRow from "../components/StatusRow";
import CONFIRM_TRANSACTION from "../mutations/ConfirmTransaction";
import SET_TRANSACTION_SHIPPED from "../mutations/SetTransactionShipped";
import { ConfirmTransaction } from "../mutations/types/ConfirmTransaction";
import { SetTransactionShipped } from "../mutations/types/SetTransactionShipped";
import { GetTransaction_me, GetTransaction_transaction } from "../queries/types/GetTransaction";
import css from "../TransactionStatus.module.css";
import { GreyTransactionActionButton, TransactionActionButton } from "../TransactionStatus";

export interface Props {
  me: GetTransaction_me;
  transaction: GetTransaction_transaction;
}

function Paid({ me, transaction }: Props) {
  const routes = useContext(RoutesContext) as any;
  const iAmSeller = transaction.seller.id === me.id;
  const iAmBuyer = transaction.buyer.id === me.id;
  const isShippingMethod = transaction.deliveryMethod === "shipping";
  const isPickupMethod = transaction.deliveryMethod === "pickup";
  const hasShipped = transaction.shipped;

  const [error, setError] = useState<string>(null);

  const [
    setTransactionShipped,
    { data: shippedData, loading: shippedLoading },
  ] = useMutation<SetTransactionShipped>(SET_TRANSACTION_SHIPPED);

  const [
    confirmTransaction,
    { data: confirmData, loading: confirmLoading },
  ] = useMutation<ConfirmTransaction>(CONFIRM_TRANSACTION, {
    onCompleted: (response) => setError(response.confirmTransaction.error),
    onError: (e) => setError(e.message),
  });

  useEffect(() => {
    if (error) {
      (window as any).FlashMessage.error(`Sorry, that didn't work: ${error}`);
    }
  }, [error]);

  return (
    <React.Fragment>
      {iAmBuyer && isShippingMethod && !hasShipped && (
        <StatusRow>
          <div>
            <p>
              <b>Current stage:</b>
            </p>
            <p>
              Please wait while{" "}
              <a href={transaction.seller.profileUrl}>{transaction.seller.displayName}</a> ships
              your item to its new home.
            </p>
            <p>We will email you once the item has been shipped.</p>
          </div>
        </StatusRow>
      )}

      {iAmBuyer && isShippingMethod && hasShipped && (
        <StatusRow>
          <div>
            <p>
              <b>Current stage:</b>
            </p>
            <p>The product has been shipped.</p>
            <p>
              When your item arrives, please check its condition to ensure it matches its listing.
              If you are happy with your purchase, mark the transaction as completed. If you are
              unhappy with the item, feel free to use the "There's a problem with my order" button
              below to reach out to our AirRobe customer service team.
            </p>
          </div>
        </StatusRow>
      )}

      {iAmSeller && isShippingMethod && !hasShipped && (
        <StatusRow>
          <div>
            <p>
              <b>Shipping Item:</b>
            </p>
            <p>
              Now it's time to post the item to its new home. After confirming, feel free to add a
              photo or tracking number to let{" "}
              <a href={transaction.buyer.profileUrl}>{transaction.buyer.displayName}</a> know that
              the product has been shipped.
            </p>
            <p>
              Need help with shipping? Try shipping with Sendle, our carbon-neutral, door to door
              shipping partner.
            </p>
            <p>
              <a href="https://try.sendle.com/en-au/partners/airrobe" className={css.sendleLink}>
                {" "}
                Use our partnership discount with Sendle
              </a>
            </p>
          </div>
        </StatusRow>
      )}

      {iAmSeller && isShippingMethod && hasShipped && (
        <StatusRow>
          <div>
            <p>
              <b>Product Shipped:</b>
            </p>
            <p>The product has been shipped.</p>
            <p>
              {" "}
              Waiting for <a href={transaction.buyer.profileUrl}>
                {transaction.buyer.displayName}
              </a>{" "}
              to mark the order received. Once the order is marked as received, the payment will be
              released to your bank account.
            </p>
          </div>
        </StatusRow>
      )}

      {iAmSeller && isPickupMethod && (
        <StatusRow>
          <div>
            <p>
              <b>Current stage:</b>
            </p>
            <p>
              Now it's time for{" "}
              <a href={transaction.buyer.profileUrl}>{transaction.buyer.displayName}</a> to pick up
              the item from you.
            </p>
            <p>You can contact them below to organize a time and location for the pickup.</p>
          </div>
        </StatusRow>
      )}

      {iAmBuyer && isPickupMethod && (
        <StatusRow>
          <div>
            <p>
              <b>Current stage:</b>
            </p>
            <p>
              Now it's time for you to pickup the order from{" "}
              <a href={transaction.seller.profileUrl}>{transaction.seller.displayName}</a>.
            </p>
            <p>You can contact them below to organize a time and location for the pickup.</p>
          </div>
        </StatusRow>
      )}
      <div className={css.actions}>
        {iAmSeller && isShippingMethod && !hasShipped && (
          <TransactionActionButton
            text="Mark shipped"
            loading={shippedLoading}
            onClick={() =>
              setTransactionShipped({
                variables: { input: { id: transaction.id } },
              })
            }
          />
        )}

        {iAmBuyer && (
          <React.Fragment>
            {((isShippingMethod && hasShipped) || isPickupMethod) && (
              <TransactionActionButton
                text="Order received, approve transfer"
                loading={confirmLoading}
                onClick={() => {
                  confirmTransaction({
                    variables: { input: { txId: transaction.id } },
                  });
                }}
              />
            )}
            <GreyTransactionActionButton
              text="There's a problem with my order"
              href={routes.cancel_person_message_path(transaction.buyer.username, {
                id: transaction.id,
              })}
            />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}

export default Paid;
