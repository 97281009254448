import { gql } from "@apollo/client";
import moneyFragment from "./money";

export const ListingCardFragment = gql`
  fragment ListingCardFragment on Listing {
    id
    brand
    isMine
    title
    description
    currency
    isInMyCollections
    quantity
    unitType
    sold
    url
    path
    editUrl
    profileSort
    conciergeSort
    author {
      id
      profileUrl
      avatarImageUrl
      givenName
      familyName
      username
    }
    price {
      ...MoneyParts
    }
    originalPrice {
      ...MoneyParts
    }
    isPriceReduced
    priceReducedPercentage
    priceReducedPercentageBucket
    rrp {
      ...MoneyParts
    }
    previewUrl
    closed
  }
  ${moneyFragment}
`;
