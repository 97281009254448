import { gql } from "@apollo/client";

const SkipFeedback = gql`
  mutation SkipFeedback($input: SkipFeedbackMutationInput!) {
    skipFeedback(input: $input) {
      transaction {
        id
        currentState
        shipped
        buyerTestimonials: testimonials(from: buyer) {
          author {
            id
          }
        }
        sellerTestimonials: testimonials(from: seller) {
          author {
            id
          }
        }
        buyerSkippedFeedback
        sellerSkippedFeedback
      }
      error
    }
  }
`;

export default SkipFeedback;
