import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Box from "../../../elements/v2/Box/Box";
import { Heading } from "../components/Heading";
import { useMutation } from "@apollo/client";
import REQUEST_HISTORICAL_ORDERS_MUTATION from "../mutations/RequestHistoricalOrders";
import { AffiliateType, HistoricalOrdersPageProps } from "../HistoricalOrdersPage";
import { SelectItemsStep } from "./SelectItemsStep";
import { WhiteBox } from "../components/WhiteBox";
import { Description } from "../components/Description";
import {
  RequestHistoricalOrders,
  RequestHistoricalOrdersVariables,
} from "../mutations/types/RequestHistoricalOrders";
import { getHistoricalOrdersNotFoundPath } from "../../../../utils/paths";
import { Errors } from "../components/Errors";
import { LoadingSpinner } from "../../../elements/v2/LoadingSpinner/LoadingSpinner";
import Events from "../../../../utils/analytics";

type FetchOrdersStepProps = {
  affiliate: AffiliateType;
  username: HistoricalOrdersPageProps["username"];
};

const FetchOrdersStep = ({ affiliate, username }: FetchOrdersStepProps) => {
  const history = useHistory();
  const { email } = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [fetchedItems, setFetchedItems] = useState<any>([]);
  const [fetching, setFetching] = useState<boolean>(false);

  const [requestHistoricalOrders] = useMutation<
    RequestHistoricalOrders,
    RequestHistoricalOrdersVariables
  >(REQUEST_HISTORICAL_ORDERS_MUTATION);

  if (!fetching) {
    requestHistoricalOrders({
      variables: {
        input: {
          affiliateAppId: affiliate.appId,
          emailAddress: email,
        },
      },
    })
      .then(({ data }) => {
        if (data.requestHistoricalOrders.length > 0) setFetchedItems(data.requestHistoricalOrders);
        else history.push(getHistoricalOrdersNotFoundPath(affiliate.appId));
      })
      .catch((e) => {
        setErrors([e.message]);
        // Once we understand how these errors are show/handled, we might want to handle graphql error reporting globally
        if (e.networkError)
          throw new Error("GraphQL network error: " + e.networkError.result?.error);
        else if (e.graphQLErrors.length)
          throw new Error("GraphQL errors:" + JSON.stringify(e.graphQLErrors));
        throw e;
      });

    setFetching(true);
    setErrors([]);
  }

  useEffect(() => {
    if (fetchedItems.length)
      Events.sendHoStep3Ready({
        items: fetchedItems,
        affiliate: affiliate,
        email: email,
        errors: errors,
      });
  }, [fetchedItems]);

  if (fetchedItems.length)
    return <SelectItemsStep {...{ affiliate, username, email, fetchedItems }} />;

  return (
    <Box>
      <WhiteBox>
        <Heading>Importing past orders in progress</Heading>
        <Box my={1} />
        <Description>
          Please wait while we look for eligible items to add to your circular wardrobe.
        </Description>
      </WhiteBox>
      <Box mt={4}>
        <LoadingSpinner />
      </Box>
      <Box mt={1.5}>
        <Description>Importing past orders in progress</Description>
      </Box>
      <Errors errors={errors} />
    </Box>
  );
};

export default FetchOrdersStep;
