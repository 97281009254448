import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { GetListing_listing, GetListing_listing_price } from "../../queries/types/GetListing";
import Box, { BoxProps } from "../../../../elements/v2/Box/Box";
import Button, { BUTTON_SIZES, OutlineButton } from "../../../../elements/v2/Button/Button";
import { DeliveryMethod, ListingShape, UnitType } from "../../../../../types/graphql-global-types";
import { MakeAnOffer } from "../MakeAnOffer/MakeAnOffer";
import SelectDeliveryMethod from "../SelectDeliveryMethod/SelectDeliveryMethod";
import SelectRentalPeriod, { validateRentalPeriod } from "../SelectRentalPeriod/SelectRentalPeriod";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { PdpMoreInfoText, PdpSpecialInfoText } from "../../../../elements/PdpTypography";
import moment from "moment";
import Events from "../../../../../utils/analytics";
import { useMobileMediaQuery } from "../../../../../utils/useMediaQueries";

const mapPriceToProps = (price: GetListing_listing_price) => ({
  cents: price.fractional,
  currency: {
    code: price.currency.code,
    symbol: price.currency.symbol,
  },
});

type Props = BoxProps & {
  isAdmin: boolean;
  listing?: GetListing_listing;
  googleMapsApiKey: string;
  listingPublishedByAirrobe: boolean;
  showBuyButton: boolean;
  showMakeAnOfferButton: boolean;
};

export default function StartTransactionFlow({
  isAdmin,
  listing,
  googleMapsApiKey,
  listingPublishedByAirrobe,
  showBuyButton,
  showMakeAnOfferButton,
  ...props
}: Props) {
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState<DeliveryMethod>(
    listing?.deliveryMethods[0] || DeliveryMethod.none
  );
  const [showValidation, setShowValidation] = useState<boolean>(false);
  const { isMobile } = useMobileMediaQuery();
  const formRef = useRef<HTMLFormElement>();

  const { id, listingShape, unitType, price, isMine, sold, closed, publishedDate } = listing;

  if (isMine || !listingShape || sold || closed || listingShape === ListingShape.recycle)
    return null;

  const isRental = listingShape === ListingShape.rent;
  const isPerMonth = unitType === UnitType.month;
  const isForSale = listingShape === ListingShape.resell;
  const buttonText = isForSale ? "Buy Now" : "Rent Now";
  const startTransactionPath =
    isRental && isPerMonth ? `/en/listings/${id}/initiate` : "/en/transactions/new";
  const contactUrl = `/listings/${listing.id}/contact`;

  const handleSubmit = (e) => {
    const params = Array.from(e.currentTarget.querySelectorAll(`input[name]`)).reduce(
      (o: object, node: HTMLInputElement) => ({ ...o, [node.name]: node.value }),
      {}
    );

    if (isRental && !validateRentalPeriod(unitType, params)) {
      e.preventDefault();
      setShowValidation(true);
      formRef.current?.scrollIntoView();
      return;
    }
    return params;
  };

  const clearOfferFormBeforeBuying = () => {
    document.getElementById("offer-form")?.remove();
  };

  function handleCheckAvailabilityClick(listing: GetListing_listing, contactUrl: string) {
    Events.sendCheckAvailabilityClicked(listing);
    const olderListingContactMessage = encodeURIComponent(
      `Hi ${listing.author.displayName},\n\nIs this listing still available for purchase?\n\nIf so, I'm interested!`
    );
    window.location.assign(`${contactUrl}?contact_message=${olderListingContactMessage}`);
  }

  return (
    <Box width="100%" {...props}>
      <form
        id="transaction-form"
        action={startTransactionPath}
        method="get"
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <input hidden={true} id="listing_id" name="listing_id" value={id} readOnly={true} />
        <SelectDeliveryMethod
          mt={3}
          listing={listing}
          selectedDeliveryMethod={selectedDeliveryMethod}
          onSelectDeliveryMethod={setSelectedDeliveryMethod}
          googleMapsApiKey={googleMapsApiKey}
        />
        <SelectRentalPeriod
          mt={4}
          listing={listing}
          selectedDeliveryMethod={selectedDeliveryMethod}
          showValidation={showValidation}
        />
        <MobileCompatibleBox mt={4}>
          {showBuyButton && (
            <BuyButton onClick={clearOfferFormBeforeBuying}>{buttonText}</BuyButton>
          )}
          <Box width={showMakeAnOfferButton ? "100%" : "auto"}>
            {showMakeAnOfferButton && (
              <Box mt={[0, 0, 2]} width="100%" ml={isMobile && showBuyButton ? "10px" : "0"}>
                <MakeAnOffer
                  listing={listing}
                  formData={{
                    onSubmit: handleSubmit,
                    element: formRef.current,
                    startTransactionPath,
                  }}
                  startingPrice={mapPriceToProps(price)}
                  isPrimaryCta={!showBuyButton}
                />
              </Box>
            )}
            {!isMobile && <ContactButton href={contactUrl} />}
          </Box>
        </MobileCompatibleBox>
        {isMobile && <ContactButton href={contactUrl} />}
      </form>
      {isAdmin && (
        <Box mt={2}>
          <CalendarTodayIcon />
          {publishedDate && (
            <PdpSpecialInfoText>Listed {moment(publishedDate).fromNow()}</PdpSpecialInfoText>
          )}
          <PdpMoreInfoText onClick={() => handleCheckAvailabilityClick(listing, contactUrl)}>
            Check availability
          </PdpMoreInfoText>
        </Box>
      )}
    </Box>
  );
}

const BuyButton = ({ ...props }) => (
  <Button type="submit" size={BUTTON_SIZES.LARGE} $fullWidth {...props} />
);

const ContactButton = ({ href }: { href: string }) => {
  return (
    <Box mt={2}>
      <OutlineButton href={href} size={BUTTON_SIZES.LARGE} $fullWidth>
        Message
      </OutlineButton>
    </Box>
  );
};

const MobileCompatibleBox = (props: BoxProps) => {
  const [show, setShow] = useState(true);
  const { isMobile } = useMobileMediaQuery();

  useEffect(() => {
    // hides call to action buttons when product recommendations section comes into top of the page
    const scrollHandler = () => {
      const productRecommendations = document.getElementById("product-recommendations");
      const distanceFromTopOfScreen = productRecommendations?.getBoundingClientRect()?.y;
      const topOfScreen = 0;
      const prodRecsIsPastTopOfScreen = distanceFromTopOfScreen < topOfScreen;
      if (prodRecsIsPastTopOfScreen) {
        setShow(false);
      } else {
        setShow(true);
      }
    };
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  });

  if (!isMobile) return <Box {...props} />;
  if (show && isMobile) return <StickyBox {...props} />;
  return null;
};

const StickyBox = styled(Box)`
  display: flex;
  box-shadow: 0px -4px 8px 0px #0000001a;
  width: 100%;
  position: fixed;
  bottom: 0;
  margin-left: -24px;
  padding: 12px 24px 16px 24px;
  background-color: white;
  z-index: 999;
`;
