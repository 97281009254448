import r from "r-dom";
import AccountVerificationPending from "../components/widgets/AccountVerificationPending/AccountVerificationPending";
import { withApollo } from "../graphql/client";
import { initialize as initializeI18n } from "../utils/i18n";

export default (props, marketplaceContext) => {
  initializeI18n(
    marketplaceContext.i18nLocale,
    marketplaceContext.i18nDefaultLocale,
    process.env.NODE_ENV,
    {
      language: "English",
    }
  );
  return function AccountVerificationPendingApp() {
    return withApollo(r(AccountVerificationPending));
  };
};
