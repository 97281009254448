import { Grid } from "@material-ui/core";
import React from "react";
import CustomFormikField from "../../../FormControls/CustomFormikField/CustomFormikField";
import FormSection from "../../../FormControls/FormSection/FormSection";
import ImageUploader, { S3Props } from "../../../FormControls/ImageUploader/ImageUploader";
import TextField from "../../../FormControls/TextField/TextField";
import { GetListingFormData_listing } from "../../queries/types/GetListingFormData";

const TitleImagesStep = (s3Props: S3Props, listing: GetListingFormData_listing) => (props) => {
  const {
    formField: { title, images },
  } = props;

  return (
    <Grid container spacing={2} direction="column" wrap="nowrap">
      <Grid item>
        <FormSection title="Title">
          <CustomFormikField fieldName={title.name} InnerComponent={TextField} fullWidth />
        </FormSection>
      </Grid>
      <Grid item>
        <FormSection
          title="Photos"
          classesOverrides={{
            innerFormSection: {
              flexWrap: "nowrap",
            },
          }}
        >
          <CustomFormikField
            fieldName={images.name}
            InnerComponent={ImageUploader}
            s3Props={s3Props}
            listing={listing}
          />
        </FormSection>
      </Grid>
    </Grid>
  );
};

const withProps = (s3props: S3Props, listing: GetListingFormData_listing) =>
  TitleImagesStep(s3props, listing);

export default withProps;
