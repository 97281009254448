import React from "react";
import Portal from "../../composites/Portal/Portal";
import LoadingIndicator, { Props } from "./LoadingIndicator";

export default function LoadingOverlay(props: Props) {
  return (
    <Portal parentElement={document.body}>
      <LoadingIndicator fullscreen {...props} />
    </Portal>
  );
}
