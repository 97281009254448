import { useQuery } from "@apollo/client";
import React from "react";
import { formatReviewCounts } from "../../../../../utils/testimonials";
import useQueryParam from "../../../../../utils/useQueryParam";
import ContentBlock from "../../../../composites/ContentBlock/ContentBlock";
import PageSelection from "../../../../composites/PageSelection/PageSelection";
import TestimonialCard from "../../../../composites/TestimonialCard/TestimonialCard";
import NoContent from "../../../../elements/NoContent/NoContent";
import GET_PERSON_REVIEWS from "../../queries/GetPersonReviews";
import { GetPersonReviews, GetPersonReviewsVariables } from "../../queries/types/GetPersonReviews";
import { useSearchPageContext } from "../../SearchPageContext";

const perPage = 20;

export default function ProfileReviewsPage() {
  const { profilePage } = useSearchPageContext();
  const person = profilePage.person;
  const page = Number(useQueryParam("page") || 1);
  const variables = { id: person.id, page, perPage };
  const { error, data, previousData, loading } = useQuery<
    GetPersonReviews,
    GetPersonReviewsVariables
  >(GET_PERSON_REVIEWS, { variables });
  const usableData = data || previousData;
  const pageCount = person.receivedTestimonials.pageCount || 0;
  const receivedTestimonials = usableData?.person.receivedTestimonials.results || [];

  const subtitle = formatReviewCounts({
    positiveCount: person.positiveTestimonials.totalCount,
    totalCount: person.receivedTestimonials.totalCount,
  });

  return (
    <ContentBlock title="Reviews" subtitle={subtitle} isLoading={loading}>
      {person.receivedTestimonials.totalCount === 0 ? (
        <NoContent text="No reviews to show" />
      ) : null}
      {receivedTestimonials.map((testimonial) => (
        <TestimonialCard key={testimonial.id} testimonial={testimonial} />
      ))}
      {pageCount > 1 ? (
        <PageSelection currentPage={page} totalPages={pageCount} location={""} pageParam="page" />
      ) : null}
    </ContentBlock>
  );
}
