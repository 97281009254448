import { isEmpty } from "lodash";
import { RECENTLY_VIEWED_LISTINGS_LOCAL_STORE_KEY } from "../../ListingPage";
import isSSR from "../../../../../utils/isSSR";
import { GetListing_listing } from "../../queries/types/GetListing";

const SIZE_FIELDS = {
  size_guide: "Tops & dresses",
  pants_size: "Bottoms",
  shoe_size: "Shoes",
  accessories_size: "Accessories",
};

const getDepartmentFilter = (listing) => {
  const customField = listing.customFields.find((cf) => cf.key === "apparel_category");
  if (!customField) return null;

  const value = customField.value?.selectedOptions.find(Boolean)?.title;
  if (!value) return null;

  return `department = "${value}"`;
};

const getBrandFilter = (listing: GetListing_listing) => {
  return `brand = "${listing.brand}"`;
};

const getAuthorFilter = (listing: GetListing_listing) => {
  return `author = "${listing.author.username}"`;
};

const getCategoryFilter = (listing: GetListing_listing) => {
  const value = listing.category.url;
  if (!value) return null;

  return `category_id = "${value}"`;
};

const getColourFilter = (listing) => {
  const customField = listing.customFields.find((cf) => cf.key === "colour");
  if (!customField) return null;

  const values = customField.value?.selectedOptions.map((so) => so.title);
  if (isEmpty(values)) return null;

  return `ARRAY_MATCH(${values.map((v) => `colour = "${v}"`).join(" OR ")})`;
};

const getSizeFilter = (listing) => {
  const customField = listing.customFields.find((cf) => Object.keys(SIZE_FIELDS).includes(cf.key));
  if (!customField) return null;

  const sizeFieldTitle = SIZE_FIELDS[customField.key];

  const values = customField.value?.selectedOptions.map((so) => `${sizeFieldTitle}|${so.title}`);
  if (isEmpty(values)) return null;

  return `ARRAY_MATCH(${values.map((v) => `size = "${v}"`).join(" OR ")})`;
};

const getOccasionFilter = (listing) => {
  const customField = listing.customFields.find((cf) => cf.key === "occasion");
  if (!customField) return null;

  const values = customField.value?.selectedOptions.map((so) => so.title);
  if (isEmpty(values)) return null;

  return `ARRAY_MATCH(${values.map((v) => `occasion = "${v}"`).join(" OR ")})`;
};

const getDiscountedFilter = () => {
  return `is_price_reduced = "true"`;
};

const getNotDiscountedFilter = () => {
  return `is_price_reduced != "true"`;
};

export function getMoreFromSameAuthorFilters(listing: GetListing_listing) {
  return [`id != ${listing.id}`, getAuthorFilter(listing)].join(" AND ");
}

export function getDealsFilters(listing: GetListing_listing) {
  return [
    `id != ${listing.id}`,
    getDepartmentFilter(listing),
    getBrandFilter(listing),
    getCategoryFilter(listing),
    getDiscountedFilter(),
  ]
    .filter(Boolean)
    .join(" AND ");
}

export function getSimilarFilters(listing: GetListing_listing) {
  return [
    `id != ${listing.id}`,
    getDepartmentFilter(listing),
    getBrandFilter(listing),
    getCategoryFilter(listing),
    getColourFilter(listing),
    getSizeFilter(listing),
    getNotDiscountedFilter(),
  ]
    .filter(Boolean)
    .join(" AND ");
}

export function getMoreBrandFilters(listing: GetListing_listing) {
  return [
    `id != ${listing.id}`,
    getDepartmentFilter(listing),
    getBrandFilter(listing),
    getCategoryFilter(listing),
    getNotDiscountedFilter(),
  ]
    .filter(Boolean)
    .join(" AND ");
}

export function getMoreSizeFilters(listing: GetListing_listing) {
  return [
    `id != ${listing.id}`,
    getDepartmentFilter(listing),
    getCategoryFilter(listing),
    getSizeFilter(listing),
  ]
    .filter(Boolean)
    .join(" AND ");
}

export function getRecentlyViewedListingFilters(listing: GetListing_listing): string | null {
  if (!isSSR()) {
    const recentlyViewListingIds =
      JSON.parse(localStorage.getItem(RECENTLY_VIEWED_LISTINGS_LOCAL_STORE_KEY)) || [];

    return recentlyViewListingIds
      .filter((listingId: number) => listingId !== listing.id)
      .map((listingId: number) => `id = ${listingId}`)
      .join(" OR ");
  } else {
    return null;
  }
}
