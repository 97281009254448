import React from "react";
import styled from "styled-components";
import JoinComponents from "../../../../composites/JoinComponents/JoinComponents";
import FlexBox from "../../../../elements/v2/Box/FlexBox";
import {
  GetListing_listing,
  GetListing_listing_customFields,
  GetListing_listing_customFields_value,
  GetListing_listing_customFields_value_CustomCheckboxFieldValue,
  GetListing_listing_customFields_value_CustomCheckboxFieldValue_selectedOptions,
  GetListing_listing_customFields_value_CustomNumericFieldValue,
  GetListing_listing_customFields_value_CustomTextFieldValue,
} from "../../queries/types/GetListing";
import ProductHeading from "../ProductHeading/ProductHeading";
import {
  CUSTOM_FIELD_CONDITION_OPTIONS,
  CUSTOM_FIELD_KEYS,
} from "../../../../../constants/CustomFieldConstants";
import { Span } from "../../../../elements/v2/Typography/Typography";

import InfoTooltip from "../../../../composites/FormControls/InfoTooltip/InfoTooltip";
import { isEmpty, isNil } from "lodash";

type Props = {
  listing: GetListing_listing;
};

const CATEGORY_FIELD_KEYS = [
  CUSTOM_FIELD_KEYS.APPAREL_CATEGORY,
  CUSTOM_FIELD_KEYS.INTIMATES_CATEGORY,
  CUSTOM_FIELD_KEYS.SHOES_CATEGORY,
];

const OTHER_FIELD_KEYS = [
  CUSTOM_FIELD_KEYS.SIZE_GUIDE,
  CUSTOM_FIELD_KEYS.PANTS_SIZE,
  CUSTOM_FIELD_KEYS.SHOE_SIZE,
  CUSTOM_FIELD_KEYS.ACCESSORIES_SIZE,
  CUSTOM_FIELD_KEYS.LABEL_SIZE,
  CUSTOM_FIELD_KEYS.OCCASION,
  CUSTOM_FIELD_KEYS.APPAREL_MATERIAL,
  CUSTOM_FIELD_KEYS.ACCESSORIES_MATERIAL,
  CUSTOM_FIELD_KEYS.MATERIAL_NOTES,
  CUSTOM_FIELD_KEYS.COLOUR,
  CUSTOM_FIELD_KEYS.PATTERN,
  CUSTOM_FIELD_KEYS.LENGTH,
  CUSTOM_FIELD_KEYS.DRESSES_STYLE,
  CUSTOM_FIELD_KEYS.JUMPSUITS_STYLE,
  CUSTOM_FIELD_KEYS.PANTS_STYLE,
  CUSTOM_FIELD_KEYS.TOPS_STYLE,
  CUSTOM_FIELD_KEYS.TRY_ON,
  CUSTOM_FIELD_KEYS.DRY_CLEANING,
  CUSTOM_FIELD_KEYS.ADAPTIVE,
];

export default function Details({ listing }: Props) {
  const {
    rrp,
    brand,
    condition,
    conditionNotes,
    category,
    customFields,
    dryCleaning,
    tryOnAvailability,
  } = listing;

  return (
    <Container>
      <ProductHeading>Details</ProductHeading>
      <StyledTable>
        <tbody>
          {category && <FieldRow title={"Product Category"}>{category.name}</FieldRow>}

          {CATEGORY_FIELD_KEYS.map((key) => (
            <CustomField key={key} customFields={customFields} customFieldKey={key} />
          ))}

          {rrp && <FieldRow title={"Retail Price"}>{rrp.formattedWithCurrencySign}</FieldRow>}

          {brand && <FieldRow title={"Brand"}>{brand}</FieldRow>}

          <CustomField customFields={customFields} customFieldKey={CUSTOM_FIELD_KEYS.COMES_WITH} />

          {condition && (
            <FieldRow title={"Condition"}>{CUSTOM_FIELD_CONDITION_OPTIONS[condition]}</FieldRow>
          )}
          {conditionNotes && <FieldRow title={"Condition Notes"}>{conditionNotes}</FieldRow>}

          {OTHER_FIELD_KEYS.map((key) => (
            <CustomField key={key} customFields={customFields} customFieldKey={key} />
          ))}

          {tryOnAvailability && (
            <FieldRow
              title={"Try-on availability"}
              info="Item can be arranged to be tried-on prior to transaction"
            >
              <Value>{tryOnAvailability}</Value>
            </FieldRow>
          )}

          {dryCleaning && (
            <FieldRow title={"Dry Cleaning"} info="Specified for rental items only">
              <Value>By {dryCleaning}</Value>
            </FieldRow>
          )}
        </tbody>
      </StyledTable>
    </Container>
  );
}

type FieldRowProps = {
  title: string;
  info?: string;
  children: React.ReactNode;
};

function FieldRow({ title, info, children }: FieldRowProps) {
  return (
    <tr>
      <td className="title">
        {title}: <Info info={info} />
      </td>
      <td className="description">{children}</td>
    </tr>
  );
}

function Info({ info }: { info?: string }) {
  if (!info) return null;

  return <InfoTooltip title={info} size="18px" />;
}

type CustomFieldRowProps = {
  field: GetListing_listing_customFields;
};

function CustomFieldValueIsText(
  value: GetListing_listing_customFields_value
): value is GetListing_listing_customFields_value_CustomTextFieldValue {
  return (
    (value as GetListing_listing_customFields_value_CustomTextFieldValue).textValue !== undefined
  );
}

function CustomFieldValueIsNumeric(
  value: GetListing_listing_customFields_value
): value is GetListing_listing_customFields_value_CustomNumericFieldValue {
  return (
    (value as GetListing_listing_customFields_value_CustomNumericFieldValue).numValue !== undefined
  );
}

function CustomFieldValueIsCheckbox(
  value: GetListing_listing_customFields_value
): value is GetListing_listing_customFields_value_CustomCheckboxFieldValue {
  return (
    (value as GetListing_listing_customFields_value_CustomCheckboxFieldValue).selectedOptions !==
    undefined
  );
}

function isCustomFieldValueEmpty(value) {
  if (!value) return true;

  if (CustomFieldValueIsNumeric(value)) return isNil(value.numValue);

  if (CustomFieldValueIsCheckbox(value)) return isEmpty(value.selectedOptions);

  if (CustomFieldValueIsText(value)) return !value.textValue;

  return false;
}

function CustomFieldRow({ field }: CustomFieldRowProps) {
  const { value } = field;

  if (isCustomFieldValueEmpty(value)) return null;

  return (
    <FieldRow title={field.name}>
      {CustomFieldValueIsText(value) && value.textValue}
      {CustomFieldValueIsNumeric(value) && value.numValue}
      {CustomFieldValueIsCheckbox(value) && (
        <JoinComponents separator={", "}>
          {value.selectedOptions.map(
            (
              option: GetListing_listing_customFields_value_CustomCheckboxFieldValue_selectedOptions
            ) => (
              <span key={option.key}>{option.title}</span>
            )
          )}
        </JoinComponents>
      )}
    </FieldRow>
  );
}

type CustomFieldProps = {
  customFields: GetListing_listing_customFields[];
  customFieldKey: string;
};

function CustomField({ customFields, customFieldKey }: CustomFieldProps) {
  const field = customFields.find((f) => f.key == customFieldKey);
  if (!field) return null;

  return <CustomFieldRow field={field} />;
}

const Container = styled(FlexBox)`
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0;
  gap: 6px;
`;

const StyledTable = styled.table`
  padding-top: 4px;
  border-spacing: 0 6px;
  width: 100%;
  tbody {
    tr {
      line-height: 1.5em;
      td.title {
        font-weight: 600;
        padding-right: 16px;
        white-space: nowrap;
      }
      td.description {
        font-weight: 400;
        width: 100%;
      }
    }
  }
`;

const Value = styled(Span)`
  text-transform: capitalize;
`;
