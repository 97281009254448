import { gql } from "@apollo/client";

export default gql`
  query GetBrandSynonyms {
    brandSynonyms {
      name
      canonical
    }
  }
`;
