import { gql } from "@apollo/client";
import { ListingCardFragment } from "../../../../graphql/fragments/listings";

export default gql`
  query GetPeopleListings(
    $authorIds: [String!]!
    $page: Int
    $perPage: Int
    $sortField: String
    $sortDirection: SortDirection
  ) {
    peopleListings(
      authorIds: $authorIds
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      results {
        ...ListingCardFragment
      }
      totalCount
      pageCount
    }
  }
  ${ListingCardFragment}
`;
