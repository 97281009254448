import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import ImagePreviewCard from "./ImagePreviewCard";
import SortableDndContext from "../../SortableDnd/SortableDndContext";
import SortableItem from "../../SortableDnd/SortableItem";
import React from "react";
import { File } from "./ImageUploader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#FFFFFF",
      display: "flex",
      overflow: "hidden",
      flexWrap: "wrap",
    },
    grid: {
      display: "inline-grid",
      width: "100%",
      gridTemplateColumns: "minmax(206px, max-content) repeat(auto-fill, 206px)",
      gridGap: 10,
    },
    item: {
      display: "inline-block",
      flex: "0 0 100%",
      position: "relative",
      textAlign: "center",
      color: "white",
      maxWidth: "fit-content",
      margin: 3,
      userSelect: "none",
    },
  })
);

interface Props {
  files: Array<File>; // export File interface from ImageUploader and import it here
  onDeleteListingImage: (listingImageId: string) => void;
  onRemoveFile: (uuid: string) => void;
  onReorder: (listingImageIds: Array<string | number>) => void;
}

function DraggableImagesPreviewPanel({
  files,
  onDeleteListingImage,
  onRemoveFile,
  onReorder,
}: Props) {
  const classes = useStyles();

  return (
    files.length > 0 && (
      <div className={classes.root}>
        <SortableDndContext onReorder={onReorder} itemIds={files.map(({ uuid }) => uuid)}>
          <Grid className={classes.grid}>
            {files.map((file) => {
              return (
                <SortableItem key={file.uuid} id={file.uuid}>
                  <Grid item className={classes.item}>
                    <ImagePreviewCard
                      file={file}
                      onDeleteListingImage={onDeleteListingImage}
                      onRemoveFile={onRemoveFile}
                    />
                  </Grid>
                </SortableItem>
              );
            })}
          </Grid>
        </SortableDndContext>
      </div>
    )
  );
}

export default DraggableImagesPreviewPanel;
