import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import GET_MY_EMAILS_WITH_CONSENTS from "../queries/GetMyEmailsWithConsents.graphql";
import Box from "../../../elements/v2/Box/Box";
import { emailMatches } from "../../../../utils/emails";
import FlexBox from "../../../elements/v2/Box/FlexBox";
import styled from "styled-components";
import NewEmailAction from "../components/NewEmailAction";
import SelectedEmailAction from "../components/SelectedEmailAction";
import { Heading } from "../components/Heading";
import ResendEmailConfirmationAction from "../components/ResendEmailConfirmationAction";
import {
  GetMyEmailsWithConsents,
  GetMyEmailsWithConsentsVariables,
} from "../queries/types/GetMyEmailsWithConsents";
import { AffiliateType } from "../HistoricalOrdersPage";
import { Description } from "../components/Description";
import { EmailOption } from "../components/EmailOption";
import { NewEmailOption } from "../components/NewEmailOption";
import { H2 } from "../../../elements/v2/Typography/Heading";
import Events from "../../../../utils/analytics";

export const NEW_EMAIL_OPTION = "NEW_EMAIL";

type SelectEmailStepProps = {
  affiliate: AffiliateType;
  notFound?: boolean;
};

const SelectEmailStep = ({ affiliate, notFound = false }: SelectEmailStepProps) => {
  const { email } = useParams();
  const [selectedEmail, setSelectedEmail] = useState<string>(email || "");

  const emailRef = useRef<HTMLInputElement>();

  const variables: GetMyEmailsWithConsentsVariables = { affiliateAppId: affiliate.appId };
  const { data } = useQuery<GetMyEmailsWithConsents>(gql(GET_MY_EMAILS_WITH_CONSENTS), {
    variables,
  });

  useEffect(() => {
    if (data) {
      notFound ? Events.sendHoNoOrders(data) : Events.sendHoStep2Started(data);
    }
  }, [data]);

  useEffect(() => {
    const primaryEmailAddress = data?.me?.primaryEmail?.address;
    if (!selectedEmail && primaryEmailAddress) setSelectedEmail(primaryEmailAddress);
  }, [selectedEmail, data]);

  const emails = data?.me?.emails;
  const myHistoricalOrdersConsent = data?.myHistoricalOrdersConsent;
  const hasConsentedEmail = Boolean(
    myHistoricalOrdersConsent?.find(({ emailAddress }) => emailMatches(emailAddress, selectedEmail))
  );
  const hasSelectedNewEmail = selectedEmail === NEW_EMAIL_OPTION;

  const isSelectedEmailConfirmed = useMemo(() => {
    if (!selectedEmail) return false;
    if (hasSelectedNewEmail) return false;
    if (!emails) return false;

    return Boolean(
      emails.find(({ address, isConfirmed }) => isConfirmed && emailMatches(address, selectedEmail))
    );
  }, [emails, selectedEmail, hasSelectedNewEmail]);

  const description = notFound
    ? `Looks like we couldn't find any eligible items to import for this email address.
       Be sure to try all of your email addresses you use to shop just in case!
    `
    : `Please select the email address you normally use to shop with our merchant partner.
       AirRobe will only search for orders where an email address has been verified.
    `;

  return (
    <Container>
      {notFound ? (
        <Heading2>Oh no...we’re sorry.</Heading2>
      ) : (
        <Heading>Select Email Address</Heading>
      )}
      <Box mt={2}>
        <Description>{description}</Description>
      </Box>

      {emails ? (
        <BodyContainer mt={4}>
          {emails.map((email) => (
            <EmailOption
              key={email.address}
              email={email}
              isSelected={emailMatches(email.address, selectedEmail)}
              onSelectEmail={(address) => setSelectedEmail(address)}
            />
          ))}

          <NewEmailOption
            emailRef={emailRef}
            isSelected={hasSelectedNewEmail}
            onSelectEmail={(address) => setSelectedEmail(address)}
          />

          <Actions>
            {hasSelectedNewEmail && (
              <NewEmailAction affiliateAppId={affiliate.appId} emailRef={emailRef} />
            )}
            {selectedEmail && !hasSelectedNewEmail && !isSelectedEmailConfirmed && (
              <ResendEmailConfirmationAction
                affiliateAppId={affiliate.appId}
                selectedEmail={selectedEmail}
              />
            )}
            {!hasSelectedNewEmail && isSelectedEmailConfirmed && (
              <SelectedEmailAction
                affiliate={affiliate}
                selectedEmail={selectedEmail}
                hasConsentedEmail={hasConsentedEmail}
              />
            )}
          </Actions>
        </BodyContainer>
      ) : (
        <Box h={50} />
      )}
    </Container>
  );
};

export default SelectEmailStep;

const Container = styled(FlexBox)`
  width: fit-content;
  flex-direction: column;
  align-items: center;
`;

const BodyContainer = styled(Box)`
  max-width: 380px;
  width: 100%;
`;

const Actions = styled(FlexBox)`
  flex-direction: column;
  align-items: center;
`;

const Heading2 = styled(H2)`
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  color: #000000;
`;
