import React from "react";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import { closestCenter, DndContext, useSensor, PointerSensor, useSensors } from "@dnd-kit/core";

interface Props {
  onReorder: (ids: Array<string | number>) => void;
  itemIds: Array<string>;
  children: React.ReactNode;
}

/**
 * There is an open issue regarding the error "Can't perform a React state update on an unmounted component"
 * https://github.com/clauderic/dnd-kit/issues/637
 * If this issue has been resolved and merged simply update the @dnd-kit/core package.
 * The error is intermittent and does not affect the behaviour of the app.
 * - Pius
 */
export default function SortableDndContext({ onReorder, itemIds, children }: Props) {
  const pointerSensor = useSensor(PointerSensor, { activationConstraint: { distance: 10 } });
  const sensors = useSensors(pointerSensor);
  function handleOnDragEnd(result) {
    const { active, over } = result;

    const oldIndex = itemIds.findIndex((id) => id === active.id);
    const newIndex = itemIds.findIndex((id) => id === over.id);
    const newItemIds = arrayMove(itemIds, oldIndex, newIndex);

    onReorder(newItemIds);

    return newItemIds;
  }

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleOnDragEnd} sensors={sensors}>
      <SortableContext items={itemIds}>{children}</SortableContext>
    </DndContext>
  );
}
