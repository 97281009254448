import React from "react";
import styled from "styled-components";
import { GetListing_listing } from "../../queries/types/GetListing";
import Box, { BoxProps } from "../../../../elements/v2/Box/Box";
import { formatMoney } from "../../../../../utils/numbers";
import Typography, { Span } from "../../../../elements/v2/Typography/Typography";

type Props = BoxProps & {
  listing?: GetListing_listing;
  showPriceReduced?: boolean;
};

export default function ListingPrice({ listing, showPriceReduced, ...props }: Props) {
  return (
    <Box {...props}>
      <Price>
        {showPriceReduced && <OriginalPrice>{formatMoney(listing.originalPrice)}</OriginalPrice>}
        <CurrentPrice $reduced={showPriceReduced}>{formatMoney(listing.price)}</CurrentPrice>
        {listing.unitType && <Per>{`/ ${listing.unitType}`}</Per>}
      </Price>
    </Box>
  );
}

const Price = styled(Typography)`
  display: inline-flex;
  gap: 5px;
  font-size: 17px;
  line-height: 25px;
  align-items: center;
`;

const OriginalPrice = styled(Span)`
  font-weight: 500;
  text-decoration: line-through;
`;

const CurrentPrice = styled(Span)<{ $reduced?: boolean }>`
  font-weight: 600;
  ${({ $reduced }) => $reduced && `color: red`}
`;

const Per = styled(Span)`
  font-weight: 500;
`;
