import { gql } from "@apollo/client";
import { CollectionCardFragment } from "../../../../graphql/fragments/collections";

export default gql`
  mutation UpdateCollection($input: UpdateCollectionMutationInput!) {
    updateCollection(input: $input) {
      error
      collection {
        ...CollectionCardFragment
      }
    }
  }
  ${CollectionCardFragment}
`;
