import { gql } from "@apollo/client";

export default gql`
  query GetListingsEngagement($personId: String!) {
    listingsEngagement(personId: $personId) {
      listingId
      viewCount
      favourites
      listingImage
      editProductPath
      editListingPricePath
    }
  }
`;
