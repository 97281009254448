export const CUSTOM_FIELD_CONDITION_OPTIONS = {
  gently_used: "Gently used, no obvious signs of wear",
  like_new: "Hardly worn, no signs of wear",
  new_with_tags: "Never worn, with Tags",
  new_without_tags: "Never worn, without Tags",
  well_loved: "Well loved, visible signs of wear",
};

export const CUSTOM_FIELD_KEYS = {
  ACCESSORIES_MATERIAL: "accessories_material",
  ACCESSORIES_SIZE: "accessories_size",
  ADAPTIVE: "adaptive",
  APPAREL_CATEGORY: "apparel_category",
  APPAREL_MATERIAL: "apparel_material",
  BRAND: "brand",
  COLOUR: "colour",
  COMES_WITH: "comes_with",
  CONDITION_NOTES: "condition_notes",
  DRESSES_STYLE: "dresses_style",
  DRY_CLEANING: "dry_cleaning",
  INTIMATES_CATEGORY: "intimates_category",
  JUMPSUITS_STYLE: "jumpsuits_style",
  LABEL_SIZE: "label_size",
  LENGTH: "length",
  MATERIAL_NOTES: "material_notes",
  OCCASION: "occasion",
  PANTS_SIZE: "pants_size",
  PANTS_STYLE: "pants_style",
  PATTERN: "pattern",
  REFERRAL_CODE: "referral_code",
  RRP: "rrp",
  SHOE_SIZE: "shoe_size",
  SHOES_CATEGORY: "shoes_category",
  SIZE_GUIDE: "size_guide",
  SWIMWEAR_STYLE: "swimwear_style",
  TOPS_STYLE: "tops_style",
  TRY_ON: "try_on",
};
