import React from "react";
import { ADD_AN_ITEM_PATH, getUserPath, USER_LISTINGS_PATH } from "../../../utils/paths";
import FlexBox from "../../elements/v2/Box/FlexBox";
import { NavLink } from "./Nav";
import { TopbarUserProps } from "./types";
import styled from "styled-components";
import { Span } from "../../elements/v2/Typography/Typography";
import { breakpoints } from "../../../utils/styledHelpers";

type ShortcutsProps = {
  user: TopbarUserProps;
};

export default function Shortcuts({ user }: ShortcutsProps) {
  const { loggedInUsername } = user;

  return (
    <Container>
      {loggedInUsername && (
        <NavLink
          href={getUserPath(loggedInUsername, USER_LISTINGS_PATH)}
          id="circular-wardrobe-button"
        >
          <Span t="web.topbar.listings" />
        </NavLink>
      )}
      <NavLink href={ADD_AN_ITEM_PATH} t="web.topbar.create_listing" id="add-new-listing-button" />
    </Container>
  );
}

const Container = styled(FlexBox)`
  gap: 32px;
  align-items: center;

  ${breakpoints.down("lg")} {
    #circular-wardrobe-button {
      display: none;
    }
  }

  ${breakpoints.down("tablet")} {
    display: none;
  }
`;
