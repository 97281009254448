export const WIN_WISH_LIST_CAMPAIGN = {
  NAME: "wish-signup-2",
  START_DATE: "2023-09-20",
  FINISH_DATE: "2024-03-31",
  RENDER_DELAY_SECONDS: 9,
  HIDE_ON_CLOSE_DAYS: 1,
};

export const SIGNUP_CAMPAIGN = {
  NAME: "signup",
  RENDER_DELAY_SECONDS: 9,
  HIDE_ON_CLOSE_DAYS: 1,
};

export const GMAIL_CONNECT_CAMPAIGN = {
  NAME: "gmail-connect",
  RENDER_DELAY_SECONDS: 9,
  HIDE_ON_CLOSE_DAYS: 1,
};
