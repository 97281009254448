import { MoneyParts } from "../graphql/fragments/types/MoneyParts";
import { Money } from "../components/pages/CheckoutPage/components/PriceBreakdown/PriceBreakdown";
import { CurrencyCode } from "../types/graphql-global-types";

export const mapPriceToMoneyParts = (price: Money): MoneyParts => ({
  __typename: "Money",
  fractional: price?.fractional || price?.cents || 0,
  currency: {
    __typename: "Currency",
    code: price?.currency?.code || price?.currency_iso || CurrencyCode.AUD,
    symbol: price?.currency?.symbol || "$",
    symbolFirst: price?.currency?.symbolFirst || true,
  },
});
