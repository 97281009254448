import ActionButton from "../ActionButton/ActionButton";
import React from "react";
import { FollowPerson, FollowPersonVariables } from "./mutations/types/FollowPerson";
import { ProfilePageProps_person } from "../../../../queries/types/ProfilePageProps";
import { gql, useMutation } from "@apollo/client";
import FOLLOW_PERSON_MUTATION from "./mutations/FollowPerson.graphql";
import followRedirectsWithReturnPath from "../../../../../../../utils/followRedirectsWithReturnPath";
import css from "./FollowButton.module.css";
import { GetListing_listing_author } from "../../../../../ListingPage/queries/types/GetListing";
import clsx from "clsx";

interface Props {
  person: ProfilePageProps_person | GetListing_listing_author;
  whichFollowButton: "Profile" | "Listing";
  className?: string;
}

function FollowButton({ person, whichFollowButton, className }: Props) {
  const [followPersonMutation, { data: mutationData }] = useMutation<
    FollowPerson,
    FollowPersonVariables
  >(gql(FOLLOW_PERSON_MUTATION));

  followRedirectsWithReturnPath(mutationData?.followPerson.redirectTo);
  // Get the current followed status from either the result of the mutation, or the props. Actually
  // this is the kind of thing that the apollo cache should handle for us (the mutation should
  // update the cache record automatically provided it has an id and a __typename in the response),
  // but it's a little unclear to me how to proceed with this until we get a better solution for
  // server-rendering, as the initial data for the person isn't fetched in the client side, and so
  // doesn't exist in the apollo cache.
  const { isFollowedByMe } = mutationData?.followPerson?.person || person;

  const handleFollowButtonClick = () => {
    const follow = !isFollowedByMe; // Toggle follow state on click
    followPersonMutation({
      variables: { input: { personId: person.id, follow } },
    });
  };

  return (
    <ActionButton
      withCircle={whichFollowButton === "Profile"}
      className={clsx(
        whichFollowButton === "Profile" ? css.actionButton : css.smallFollowButton,
        className
      )}
      isDark={whichFollowButton === "Profile"}
      onClick={handleFollowButtonClick}
      isActive={isFollowedByMe}
    >
      {isFollowedByMe ? "Following" : "Follow"}
    </ActionButton>
  );
}

export default FollowButton;
