import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Box from "../../../elements/v2/Box/Box";
import { LoadingSpinner } from "../../../elements/v2/LoadingSpinner/LoadingSpinner";
import { H5 } from "../../../elements/v2/Typography/Heading";
import { Pre } from "../../../elements/v2/Typography/Typography";
import styled from "styled-components";
import GET_MY_EMAILS from "../query/GetMyEmails.graphql";
import { gql, useQuery } from "@apollo/client";
import { emailMatches } from "../../../../utils/emails";
import { GetMyEmails } from "../query/types/GetMyEmails";
import FlexBox from "../../../elements/v2/Box/FlexBox";

type Props = {
  onConfirmed: () => void;
};

export const ConfirmEmail = ({ onConfirmed }: Props) => {
  const { emailAddress } = useParams();
  const [isEmailConfirmed, setEmailConfirmed] = useState<boolean>(false);

  const { stopPolling } = useQuery<GetMyEmails>(gql(GET_MY_EMAILS), {
    fetchPolicy: "network-only",
    pollInterval: 3000,
    onCompleted: ({ me }) => {
      me?.emails.forEach(({ address, isConfirmed }) => {
        if (emailMatches(address, emailAddress) && isConfirmed) setEmailConfirmed(true);
      });
    },
  });

  useEffect(() => {
    if (isEmailConfirmed) {
      stopPolling();
      onConfirmed();
    }
  }, [stopPolling, isEmailConfirmed]);

  return (
    <FlexBox alignItems="center" flexDirection="column" px={3}>
      <Heading>Check your email to confirm</Heading>
      <Box mt={2}>
        <Description>
          Please check your inbox for &apos;{emailAddress}&apos; and click the link to validate your
          email address with AirRobe.
        </Description>
      </Box>
      <Box mt={4}>
        <LoadingSpinner />
      </Box>
      <Box mt={1.5}>
        <Description>Waiting for email confirmation</Description>
      </Box>
    </FlexBox>
  );
};

const Heading = styled(H5)`
  font-weight: 500;
  font-size: 15px;
  color: #000000;
`;
const Description = styled(Pre)`
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #000000;
`;
