import { gql } from "@apollo/client";
import { ListingCardFragment } from "../../../../graphql/fragments/listings";

export default gql`
  query GetCollectionListings($collectionId: Int, $perPage: Int, $page: Int) {
    collection(id: $collectionId) {
      id
      displayTitle
      coverImageUrl
      url
      owner {
        isMe
      }
      listings(perPage: $perPage, page: $page) {
        pageCount
        results {
          ...ListingCardFragment
        }
      }
      isDefault
    }
  }
  ${ListingCardFragment}
`;
