import { gql } from "@apollo/client";

export default gql`
  mutation ToggleListingCollection($input: ToggleListingCollectionMutationInput!) {
    toggleListingCollection(input: $input) {
      redirectTo
      isInCollection
      collection {
        url
        displayTitle
      }
      listing {
        id
        isInMyCollections
        collections {
          id
        }
      }
    }
  }
`;
