import React, { useContext, useState } from "react";
import Avatar from "../../elements/Avatar/Avatar";
import NotificationBadge from "../../elements/NotificationBadge/NotificationBadge";
import styled from "styled-components";
import FlexBox from "../../elements/v2/Box/FlexBox";
import Box from "../../elements/v2/Box/Box";
import Menu, { arrowTopRightStyles } from "../../composites/v2/Menu/Menu";
import { breakpoints } from "../../../utils/styledHelpers";
import { TopbarUserProps } from "./types";
import RorpContext from "../../../contexts/RorpContext";
import MenuItem, { MenuItemDivider, MenuItemProps } from "../../composites/v2/Menu/MenuItem";
import {
  ADD_AN_ITEM_PATH,
  ADMIN_PATH,
  getUserPath,
  isPathActive,
  LOGOUT_PATH,
  USER_FAVOURITES_PATH,
  USER_INBOX_PATH,
  USER_LISTINGS_PATH,
  USER_PROFILE_PATH,
  USER_SETTINGS_PATH,
} from "../../../utils/paths";
import { t } from "../../../utils/i18n";

export default function AvatarMenu({ user }: { user: TopbarUserProps }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { pathname } = useContext(RorpContext);

  const open = (e) => {
    setAnchorEl(e.target.closest(".AvatarMenu"));
  };

  const close = () => {
    setAnchorEl(null);
  };

  const { isAdmin, loggedInUsername, avatar, unReadMessagesCount } = user;
  const { givenName, familyName } = avatar;
  const displayName = [givenName, familyName].join(" ").trim();

  return (
    <Container className={"AvatarMenu"} tabIndex={0}>
      <AvatarMenuToggle onClick={open}>
        <Avatar {...avatar} />
        {unReadMessagesCount > 0 && (
          <Box position="absolute" top={-5} left={22}>
            <NotificationBadge count={unReadMessagesCount} />
          </Box>
        )}
      </AvatarMenuToggle>
      <StyledMenu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={close}>
        <MenuItem disabled style={{ opacity: 1 }}>
          {displayName}
        </MenuItem>
        <MenuItemDivider />
        <MenuItemUser path={USER_LISTINGS_PATH} username={loggedInUsername}>
          {t("web.topbar.listings")}
        </MenuItemUser>
        <MenuItem href={ADD_AN_ITEM_PATH} active={isPathActive(pathname, ADD_AN_ITEM_PATH)}>
          {t("web.topbar.create_listing")}
        </MenuItem>
        <MenuItemUser path={USER_PROFILE_PATH} username={loggedInUsername} exact>
          {t("web.topbar.profile")}
        </MenuItemUser>
        <MenuItemUser path={USER_INBOX_PATH} username={loggedInUsername}>
          {t("web.topbar.inbox")}{" "}
          {unReadMessagesCount > 0 && (
            <Box mt={-1} ml={"5px"}>
              <NotificationBadge count={unReadMessagesCount} />
            </Box>
          )}
        </MenuItemUser>
        <MenuItemUser path={USER_FAVOURITES_PATH} username={loggedInUsername}>
          {t("web.topbar.favourite")}
        </MenuItemUser>
        <MenuItemUser path={USER_SETTINGS_PATH} username={loggedInUsername}>
          {t("web.topbar.settings")}
        </MenuItemUser>
        <MenuItemDivider />
        {isAdmin && (
          <MenuItem href={ADMIN_PATH} active={isPathActive(pathname, ADMIN_PATH)}>
            {t("web.topbar.admin_dashboard")}
          </MenuItem>
        )}
        <MenuItem href={LOGOUT_PATH}>{t("web.topbar.logout")}</MenuItem>{" "}
      </StyledMenu>
    </Container>
  );
}

const MenuItemUser = ({
  path,
  username,
  exact = false,
  ...props
}: {
  path: string;
  username: string;
  exact?: boolean;
} & MenuItemProps) => {
  const { pathname } = useContext(RorpContext);
  const href = getUserPath(username, path);
  const active = isPathActive(pathname, href, exact);

  return <MenuItem href={href} active={active} {...props} />;
};

const Container = styled(FlexBox)`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-self: flex-start;
  flex-shrink: 0;
  height: 100%;
  width: 34px;
  padding: 8px 0;
  cursor: pointer;

  ${breakpoints.up("sm")} {
    display: flex;
  }

  ${breakpoints.up("lg")} {
    width: 44px;
  }
`;

const AvatarMenuToggle = styled(Box)`
  position: relative;
  height: 100%;
  width: 100%;
`;

const StyledMenu = styled(Menu).attrs({
  className: "AvatarMenu-Menu",
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin: {
    horizontal: "right",
    vertical: -8,
  },
  arrowTop: true,
})`
  .MuiPaper-root {
    width: 277px;
    ${breakpoints.down("sm")} {
      display: none;
    }
  }

  .MuiList-root {
    padding: 12px 0;
  }

  ${breakpoints.up("sm")} {
    ${arrowTopRightStyles(21)}
  }
  ${breakpoints.down("lg")} {
    ${arrowTopRightStyles(15)}
  }
`;
