import { t } from "../../../utils/i18n";
import classNames from "classnames";
import { button, div } from "r-dom";
import checkmarkImage from "./images/checkmark.svg";
import loadingImage from "./images/loading.svg";
import css from "./ManageAvailability.module.css";

interface SaveButtonProps {
  saveInProgress: boolean;
  saveFinished: boolean;
  isVisible: boolean;
  onClick: () => void;
}
export default function SaveButton({
  saveInProgress,
  saveFinished,
  isVisible,
  onClick,
}: SaveButtonProps) {
  let html = null;
  let text = null;

  if (saveInProgress) {
    html = { __html: loadingImage };
  } else if (saveFinished) {
    html = { __html: checkmarkImage };
  } else {
    text = t("web.listings.save_and_close_availability_editing");
  }

  return div(
    {
      className: css.saveButtonContainer,
    },
    button(
      {
        className: classNames({
          [css.saveButton]: true,
          [css.saveButtonVisible]: isVisible,
        }),
        disabled: saveInProgress || saveFinished,
        onClick: onClick,
        dangerouslySetInnerHTML: html,
      },
      text
    )
  );
}
