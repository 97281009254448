import styled from "styled-components";
import { P } from "../../../elements/v2/Typography/Typography";
import FlexBox from "../../../elements/v2/Box/FlexBox";
import React from "react";
import Box from "../../../elements/v2/Box/Box";
import { GetMyEmailsWithConsents_me_emails } from "../queries/types/GetMyEmailsWithConsents";
import { RadioButton } from "../../../elements/v2/Form/RadioButton";

type Props = {
  email: GetMyEmailsWithConsents_me_emails;
  isSelected: boolean;
  onSelectEmail: (String) => void;
};

export const EmailOption = ({ email, isSelected, onSelectEmail }: Props) => {
  const { address, isConfirmed } = email;

  return (
    <OptionBox key={address} $checked={isSelected} onClick={() => onSelectEmail(address)}>
      <RadioContainer>
        <RadioButton checked={isSelected} value={address} />
        <RadioLabel>{address}</RadioLabel>
      </RadioContainer>
      {!isConfirmed && <WarningLabel>(Requires confirmation)</WarningLabel>}
    </OptionBox>
  );
};

const OptionBox = styled(Box)`
  width: 100%;
  background: white;
  margin-top: 4px;
  padding: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  ${({ $checked }) =>
    $checked &&
    `
    background: #fafafa;
    box-shadow:
      0 1px 2px rgba(0, 0, 0, 0.1),
      #000 0 0 0 2px inset;
  `}
`;

const RadioContainer = styled(FlexBox)`
  align-items: center;
`;

const RadioLabel = styled(P)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #222222;
`;

const WarningLabel = styled(RadioLabel)`
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  margin: -10px 0 5px 40px;
  color: #999999;
  display: flex;
  align-items: center;
`;
