import { Button, IconButton } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PencilIcon from "@material-ui/icons/Create";
import classNames from "classnames";
import React, { useContext } from "react";
import { RoutesContext } from "../../../utils/routes";
import { ListingShape } from "../ListingForm/ProductSuccess";
import {
  GetListingFormData_listing_author,
  GetListingFormData_listing_estimatedResaleValue,
  GetListingFormData_listing_rrp,
} from "../ListingForm/queries/types/GetListingFormData";

export interface Props {
  listing: ListingProps;
  includeActionButtons?: Boolean;
  includePencilButton?: Boolean;
  listingShapes?: Record<ListingShape, number>;
  className?: string;
  actionButtonHrefOverride?: string; // Optionally override the action button links
}

// These are the props for the listing that this card requires. We use the generated types from the
// GetListingFormData query for convenience, but any query that satisfies this interface will work.
interface ListingProps {
  id: number;
  title: string;
  brand: string;
  previewUrl?: string;
  rrp: GetListingFormData_listing_rrp;
  author: GetListingFormData_listing_author;
  estimatedResaleValue: GetListingFormData_listing_estimatedResaleValue;
}

export default function ListingCardHorizontal({
  listing,
  includeActionButtons = false,
  includePencilButton = false,
  listingShapes,
  actionButtonHrefOverride,
  className,
}: Props) {
  const classes = useStyles();
  const routes = useContext(RoutesContext) as any; // For now, this is a JS import and unkown to ts

  return (
    <Card className={classNames(classes.root, className)} square>
      <div className={classes.bar}></div>
      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <div style={{ display: "flex", flexGrow: 1 }}>
          {listing.previewUrl && (
            <CardMedia
              className={classes.cover}
              image={listing.previewUrl}
              title={listing.title}
              data-cy="listing-card-image"
            />
          )}
          <div className={classes.details}>
            <CardContent className={classes.content} classes={{ root: classes.contentRoot }}>
              <Typography variant="h6" className={classes.brand} data-cy="listing-card-brand">
                {listing.brand}
              </Typography>
              <Typography variant="subtitle1" color="textPrimary" data-cy="listing-card-title">
                {listing.title}
              </Typography>
              <br />
              {listing.rrp && (
                <div className={classes.detailsBottom}>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    className={classes.detailsBottomLeft}
                  >
                    Retail price:
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    className={classes.detailsBottomRight}
                    data-cy="listing-card-rrp"
                  >
                    {listing.rrp.formattedWithCurrencySign}
                  </Typography>
                </div>
              )}
              {listing.estimatedResaleValue && (
                <div className={classes.detailsBottom}>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    className={classes.detailsBottomLeft}
                  >
                    Est Resale:
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    className={classes.detailsBottomRight}
                    data-cy="listing-card-est-resale"
                  >
                    {listing.estimatedResaleValue.formattedWithCurrencySign}
                  </Typography>
                </div>
              )}
            </CardContent>
          </div>
        </div>
        {includeActionButtons && listingShapes && (
          <div
            style={{
              display: "flex",
              paddingBottom: 8,
              padding: 6,
              gap: 20,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              href={
                actionButtonHrefOverride ||
                routes.list_listing_path(listing.id, {
                  listing_shape: listingShapes.resell,
                })
              }
              className={classes.actionButton}
            >
              Re-sell
            </Button>
            <Button
              variant="contained"
              color="primary"
              href={
                actionButtonHrefOverride ||
                routes.list_listing_path(listing.id, {
                  listing_shape: listingShapes.rent,
                })
              }
              className={classes.actionButton}
            >
              Rent
            </Button>
            <Button
              variant="contained"
              color="primary"
              href={actionButtonHrefOverride || routes.donate_listing_path({ id: listing.id })}
              className={classes.actionButton}
            >
              Recycle
            </Button>
          </div>
        )}
      </div>
      {includePencilButton && (
        <IconButton
          aria-label="edit"
          href={routes.edit_product_path(listing.id)}
          color="primary"
          className={classes.pencil}
        >
          <PencilIcon />
        </IconButton>
      )}
    </Card>
  );
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        position: "relative",
        display: "flex",
        margin: 16,
        borderRadius: 0,
        [theme.breakpoints.down("sm")]: {
          margin: 12,
        },
      },
      details: {
        display: "flex",
        flexDirection: "column",
      },
      brand: {
        fontWeight: 600,
      },
      title: {
        minHeight: 56,
      },
      detailsBottom: {
        display: "flex",
      },
      detailsBottomLeft: {
        fontWeight: 600,
      },
      detailsBottomRight: {
        paddingLeft: 5,
      },
      content: {
        flex: "1 0 auto",
      },
      contentRoot: {
        [theme.breakpoints.down("sm")]: {
          padding: "8px 8px",
        },
      },
      cover: {
        width: 150,
        padding: 16,
        backgroundSize: "contain",
        backgroundOrigin: "content-box",

        [theme.breakpoints.down("sm")]: {
          padding: 8,
        },
      },
      bar: {
        width: 5,
        backgroundColor: "lightgrey",
      },
      pencil: {
        position: "absolute",
        top: 0,
        right: 0,
        padding: 0,
      },
      actionButton: {
        flexGrow: 1,
        marginLeft: 5,
        marginRight: 5,
        borderRadius: 0,
        "&:hover": {
          color: "white",
        },
      },
    }),
  {
    name: "ListingCardHorizontal",
  }
);
