import React from "react";
import styled from "styled-components";
import { useFormik, Field, FormikProvider, setNestedObjectValues } from "formik";
import Typography, { A } from "../../elements/v2/Typography/Typography";
import { Input } from "../../elements/v2/Form/Input";
import Button from "../../elements/v2/Button/Button";
import { isValidEmail } from "../../../utils/emails";
import { default as DefaultCheckbox } from "../../composites/FormControls/Checkbox/Checkbox";
import FlexBox from "../../elements/v2/Box/FlexBox";
import { ifTrue } from "../../../utils/styledHelpers";
import { LinkButton as DefaultLinkButton } from "../../elements/v2/Button/Button";
import { checkEmailAvailability } from "../../pages/CheckoutLoginPage/components/SignupForm";

const validate = async (values: any) => {
  const errors: any = {};

  if (!values.givenName) {
    errors.givenName = "Required";
  }

  if (!values.familyName) {
    errors.familyName = "Required";
  }

  if (!values.terms) {
    errors.terms = "Required";
  }

  if (!values.email) {
    errors.email = "Required";
  } else if (!isValidEmail(values.email)) {
    errors.email = "Invalid email address";
  } else {
    const available = await checkEmailAvailability(values.email);
    if (!available) {
      errors.email = "This email is already in use";
    }
  }

  if (!values.password) {
    errors.password = "Required";
  }

  return errors;
};

type Props = {
  onSignupPress: (values) => void;
  onLoginPress?: () => void;
  loginLink?: string;
};

export const SignupForm = ({ onSignupPress, onLoginPress, loginLink }: Props) => {
  const formik = useFormik({
    initialValues: {
      email: undefined,
      password: undefined,
      givenName: undefined,
      familyName: undefined,
      terms: false,
      marketingOptIn: false,
    },
    validate,
    onSubmit: async (values, formikHelpers) => {
      const validationErrors = await formikHelpers.validateForm();
      if (Object.keys(validationErrors).length > 0) {
        formikHelpers.setTouched(setNestedObjectValues(validationErrors, true));
        return;
      }
      onSignupPress(values);
    },
  });
  const termsError = formik.touched.terms && Boolean(formik.errors.terms);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <FormInput
          type="text"
          placeholder="First Name"
          name="givenName"
          value={formik.values.givenName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.givenName && Boolean(formik.errors.givenName)}
          errorMessage={formik.errors.givenName}
        />
        <FormInput
          type="text"
          placeholder="Last Name"
          name="familyName"
          value={formik.values.familyName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.familyName && Boolean(formik.errors.familyName)}
          errorMessage={formik.errors.familyName}
        />
        <FormInput
          id="email"
          type="email"
          name="email"
          placeholder="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          errorMessage={formik.errors.email}
        />
        <FormInput
          type="password"
          placeholder="Password"
          password={true}
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          errorMessage={formik.errors.password}
        />

        <FlexBox flexDirection="column" $gap={1}>
          <AgreementSection>
            <AgreementCheckbox $error={termsError} name="terms" component={Checkbox} />
            <TermsText $error={termsError}>
              I accept the
              <PlainLink href="https://airrobe.com/terms-conditions"> Terms of Use</PlainLink> and
              <PlainLink href="https://airrobe.com/privacy-policy"> Privacy Policy</PlainLink>.
            </TermsText>
          </AgreementSection>

          <AgreementSection>
            <Field name="marketingOptIn" component={Checkbox} />
            <MarketingText>
              I agree to receive marketing email from AirRobe and understand that I can opt out at
              any time.
            </MarketingText>
          </AgreementSection>

          {termsError && (
            <AgreementSection>
              <TermsText $error={termsError}>You must accept the terms to sign up</TermsText>
            </AgreementSection>
          )}
        </FlexBox>

        <SignupButton $fullWidth>Sign Up</SignupButton>

        <LinkButton
          $underline={true}
          $fullWidth
          href={loginLink || "/login"}
          onClick={onLoginPress}
        >
          Sign in
        </LinkButton>
      </Form>
    </FormikProvider>
  );
};

const Checkbox = styled(DefaultCheckbox)`
  padding: 0;
  &.Mui-checked {
    color: black;
  }
`;

const AgreementSection = styled(FlexBox).attrs({
  justifyContent: "left",
  alignItems: "start",
})`
  gap: 8px;
`;

const MarketingText = styled(Typography)`
  font-size: 11px;
  font-weight: 500;
  color: #111;
`;

const TermsText = styled(MarketingText)<{ $error: boolean }>`
  ${ifTrue("$error")`
    color: red;
  `}
`;

const AgreementCheckbox = styled(Field)<{ $error: boolean }>`
  ${ifTrue("$error")`
    .MuiSvgIcon-root {
      color: red;
    }
  `}
`;

const LinkButton = styled(DefaultLinkButton)`
  color: #333;
  font-size: 13px;
  padding-top: 4px;
  padding-bottom: 0;
  height: 32px;
  font-weight: 500;
  line-height: 24px;
`;

const SignupButton = styled(Button).attrs({
  type: "submit",
})`
  height: 48px;
  margin-top: 8px;
  font-weight: 600;
  line-height: 24px;
`;

const Form = styled.form`
  padding: 4px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: start;
`;

const FormInput = styled(Input).attrs({
  fullWidth: true,
  placeholderColor: "#666",
})``;

const PlainLink = styled(A)`
  font-style: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
`;
