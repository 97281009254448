import React from "react";
import styled from "styled-components";
import FlexBox from "../../../../elements/v2/Box/FlexBox";
import { P } from "../../../../elements/v2/Typography/Typography";
import { H4 } from "../../../../elements/v2/Typography/Heading";
import ssrComponent from "../../../../../utils/ssr";
import { flowRight } from "lodash";
import { wrapWithRorpContext } from "../../../../../contexts/RorpContext";
import wrapWithStylesProvider from "../../../../../utils/wrapWithStylesProvider";
import { wrapWithThemeProvider } from "../../../../../utils/themeProvider";
import ShieldIcon from "../../../../../assets/icons/shield.svg";
import { Svg } from "../../../../elements/Svg/Svg";
import { BoxProps } from "../../../../elements/v2/Box/Box";
import { GetListing_listing } from "../../queries/types/GetListing";
import { ListingShape } from "../../../../../types/graphql-global-types";

type Props = BoxProps & {
  listing?: GetListing_listing;
};

export default function BuyersPromise({ listing, ...props }: Props) {
  const { listingShape, isMine, sold, closed } = listing;

  if (isMine || !listingShape || sold || closed || listingShape === ListingShape.recycle)
    return null;

  return (
    <Container {...props}>
      <FlexBox alignItems="center" $gap={1}>
        <Svg content={ShieldIcon} />
        <Heading>Our buyer&apos;s promise</Heading>
      </FlexBox>
      <RowContainer>
        <Row>
          <Title>Payment Protection</Title>
          <Subtitle>
            Payments are held in escrow until you receive the item and approve the payment.
          </Subtitle>
        </Row>
        <Row>
          <Title>Authenticity and Condition Guarantee</Title>
          <Subtitle>
            If your item isn&apos;t what you were expecting, gets lost, or damaged, you&apos;ll be
            refunded the purchase and postage costs.
          </Subtitle>
        </Row>
        <Row>
          <Title>24/7 Customer Service</Title>
          <Subtitle>We&apos;re here to support you anytime you need us.</Subtitle>
        </Row>
      </RowContainer>
    </Container>
  );
}

export const BuyersPromiseApp = (props, context) => {
  return ssrComponent(
    flowRight(
      wrapWithRorpContext(context),
      wrapWithStylesProvider,
      wrapWithThemeProvider
    )(() => <BuyersPromise {...props} />)
  );
};

const Container = styled(FlexBox)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 10px 24px;
  gap: 6.5px;
  background: #f3f3f3;
`;

const Heading = styled(H4)`
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 1.04px;
  text-transform: uppercase;
  margin-top: -4px;
`;

const Row = styled.div``;

const Title = styled(P)`
  font-weight: 600;
  margin-bottom: 0.75em;
`;
const Subtitle = styled(P)`
  margin-bottom: 12.5px;
`;

const RowContainer = styled.div`
  padding-top: 8px;
`;
