import styled from "styled-components";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

export const CheckBox = styled(Checkbox).attrs({
  color: "primary",
})`
  padding: 10px;
  width: 40px;
  height: 40px;
  color: #222222;
`;
