import styled from "styled-components";
import React from "react";
import GridBox from "../../../elements/v2/Box/GridBox";
import LinkBox from "../../../elements/v2/Box/LinkBox";
import { H2, H5 } from "../../../elements/v2/Typography/Heading";
import { breakpoints } from "../../../../utils/styledHelpers";
import Box from "../../../elements/v2/Box/Box";

export default styled((props) => (
  <GridBox
    mt={4}
    breakpoints={{
      xs: "grid-template-columns: repeat(2, minmax(0, 1fr))",
      sm: "grid-template-columns: repeat(4, minmax(0, 1fr))",
    }}
    {...props}
  />
))`
  gap: 10px;
  justify-items: stretch;
  align-items: stretch;
`;

type TileStyleProps = {
  $large?: boolean;
};

export const GridItem = styled(LinkBox)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  background-size: cover;
  background-position: center;
  ${({ $large }: TileStyleProps) => $large && "grid-column: span 2;"}
`;

export const Tile = styled(GridItem)`
  flex-direction: column;
  align-items: center;
`;

export const TileImage = styled(Box)`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  padding-bottom: ${({ $large }: TileStyleProps) => ($large ? "67%" : "100%")};
`;

export const TileTitle = styled(H2)`
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-top: 16px;
  b {
    font-weight: 600;
  }
  ${breakpoints.down("md")} {
    font-size: 20px;
  }
  ${breakpoints.down("tablet")} {
    font-size: 16px;
  }
  ${breakpoints.down("sm")} {
    font-size: 20px;
    margin-bottom: 24px;
  }
`;

export const TileSubtitle = styled(H5)`
  margin-top: 8px;
  margin-bottom: 30px;
  text-align: center;
  ${breakpoints.down("sm")} {
    display: none;
  }
`;
