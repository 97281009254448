import React from "react";
import { Breadcrumbs as MUIBreadcrumbs } from "@material-ui/core";
import styled from "styled-components";
import { A } from "../../../elements/v2/Typography/Typography";
import Box from "../../../elements/v2/Box/Box";

export type Breadcrumb = {
  path: string;
  label: string;
};

export interface BreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
}

function Breadcrumbs({ breadcrumbs }: BreadcrumbsProps) {
  return (
    <Box>
      <MUIBreadcrumbs aria-label="breadcrumb">
        {breadcrumbs.map(({ path, label }, index) => (
          <Link href={path} key={path} $isLastBreadcrumb={index + 1 === breadcrumbs.length}>
            {label}
          </Link>
        ))}
      </MUIBreadcrumbs>
    </Box>
  );
}

export default Breadcrumbs;

const Link = styled(A)`
  font-weight: ${(props: { href: string; key: string; $isLastBreadcrumb: boolean }) =>
    props.$isLastBreadcrumb ? "700" : "500"};
  font-size: 11px;
  line-height: 14.63px;
  margin-top: 8px;
  color: #222222;
  text-transform: capitalize;
`;
