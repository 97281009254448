import React, { useContext } from "react";
import RorpContext from "../../../contexts/RorpContext";
import styled from "styled-components";
import FlexBox from "../../elements/v2/Box/FlexBox";
import { A } from "../../elements/v2/Typography/Typography";
import {
  createHref,
  isMenuItemActive,
  LinkData,
  MenuLinkType,
  onMenuItemClick,
  useCurrentParams,
} from "../../../utils/navLinks";
import Popover from "@material-ui/core/Popover";
import Box from "../../elements/v2/Box/Box";
import { H3 } from "../../elements/v2/Typography/Heading";

type MegaMenuProps = {
  anchorEl: HTMLElement | null;
  departmentKey: string | null;
  t: string;
  departmentPath?: string;
  linkData?: LinkData[];
  username?: string;
};

export default function MegaMenu({
  anchorEl,
  departmentKey,
  t,
  departmentPath,
  linkData,
  username,
}: MegaMenuProps) {
  const { pathname } = useContext(RorpContext);
  const currentParams = useCurrentParams();

  return (
    <MegaMenuPopover
      open={Boolean(anchorEl && departmentKey === t)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      disableRestoreFocus
      hideBackdrop
    >
      <FlexBox justifyContent="center" $gap={3}>
        {linkData.map(
          ({ t, type, params, children }) =>
            type !== MenuLinkType.all_department &&
            (children ? (
              <Box key={t}>
                <Box mb={2}>
                  <CategoryTitle t={t} />
                </Box>
                {children.map(({ t, type, path, params }) => (
                  <MenuItem
                    key={t}
                    href={path || createHref(departmentPath, params)}
                    t={t}
                    onClick={onMenuItemClick(username, type, t, params)}
                    $active={isMenuItemActive(
                      pathname,
                      departmentPath,
                      type,
                      currentParams,
                      params
                    )}
                  />
                ))}
              </Box>
            ) : (
              <MenuItem
                key={t}
                href={createHref(departmentPath, params)}
                t={t}
                onClick={onMenuItemClick(username, type, t, params)}
                $active={isMenuItemActive(pathname, departmentPath, type, currentParams, params)}
              />
            ))
        )}
      </FlexBox>
    </MegaMenuPopover>
  );
}

const MegaMenuPopover = styled(Popover)`
  &.MuiPopover-root {
    pointer-events: none;
    > .MuiPaper-root {
      pointer-events: auto;
      width: 100%;
      background-color: white;
      border-bottom: 1px solid #ddd;
      display: flex;
      justify-content: center;
      padding: 16px 0 56px;
      overflow: auto;
      max-height: calc(100vh - 160px);
    }
  }
`;

const MenuItem = styled(A)<{ $active: boolean }>`
  display: block;
  color: black;
  font-size: 12px;
  font-weight: 500;
  line-height: 2;

  ${({ $active }) =>
    $active &&
    `
    font-weight: 700;
  `}
`;

const CategoryTitle = styled(H3)`
  color: #999;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  margin-bottom: 15px;
`;
