import { useFilter } from "@sajari/react-hooks";
import React, { useCallback, useContext, useMemo } from "react";
import { GetSearchProps } from "../../queries/types/GetSearchProps";
import styled from "styled-components";
import { Category, getSelectedCategoryData, Subcategory } from "../../../../../utils/categories";
import { FilterNames } from "../../../../../utils/filters";
import { A } from "../../../../elements/v2/Typography/Typography";
import RorpContext from "../../../../../contexts/RorpContext";

interface Props {
  categories: GetSearchProps["categories"];
}

export default function CategoryFilter({ categories }: Props) {
  const { href, search } = useContext(RorpContext);
  const { options, selected, setSelected } = useFilter(FilterNames.category);
  const { selectedCategory, selectedParentCategory } = getSelectedCategoryData(
    categories,
    selected
  );
  const categoryOptions = useMemo<string[]>(
    () => options.filter(({ count }) => count > 0).map((o) => o.label),
    [options]
  );

  const isCategorySelected = useCallback(
    (category: Category): boolean => {
      if (!selectedCategory) return false;
      if (selectedCategory.id === category.id) return true;
      return selectedParentCategory?.id === category.id;
    },
    [selectedCategory, selectedParentCategory]
  );

  const availableCategories = useMemo<Category[]>(
    () => [
      ...categories.filter((cat) => isCategorySelected(cat) || categoryOptions.includes(cat.url)),
    ],
    [categories, categoryOptions]
  );

  const availableSubcategories = useMemo<Subcategory[]>(
    () =>
      selectedParentCategory
        ? [
            ...selectedParentCategory.subcategories.filter(
              (cat) => isCategorySelected(cat) || categoryOptions.includes(cat.url)
            ),
          ]
        : [],
    [selectedParentCategory, categoryOptions]
  );

  const generateCategoryLink = (categoryUrl: string) => {
    if (search) return `${href.replace(search, "")}category=${categoryUrl}`;
    else return `${href}?category=${categoryUrl}`;
  };

  function handleOnClick(e, category: Category) {
    e.preventDefault();

    setSelected([category.url]);

    return false;
  }

  const renderSubCategories = (isParentSelected: boolean) => {
    return (
      <Container>
        {isParentSelected &&
          availableSubcategories.map((subCategory) => (
            <li key={subCategory.url} style={{ marginLeft: "10px" }}>
              <CategoryButton
                $selected={subCategory.url === selected[0]}
                onClick={(e) => handleOnClick(e, subCategory)}
                href={generateCategoryLink(subCategory.url)}
              >
                {subCategory.name}
              </CategoryButton>
            </li>
          ))}
      </Container>
    );
  };

  return (
    <div className="t-searchFilterGroup">
      <Container>
        {availableCategories.map((category) => (
          <li key={category.url}>
            <CategoryButton
              $selected={isCategorySelected(category)}
              onClick={(e) => handleOnClick(e, category)}
              href={generateCategoryLink(category.url)}
            >
              {category.name}
            </CategoryButton>
            {renderSubCategories(isCategorySelected(category))}
          </li>
        ))}
      </Container>
    </div>
  );
}

const Container = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const CategoryButton = styled(A)`
  font-weight: ${(props: { $selected: boolean }) => (props.$selected ? "700" : "500")};
  font-size: 14px;
  line-height: 2;
  text-transform: capitalize;
  cursor: pointer;
`;
