import React from "react";
import styled, { AnyStyledComponent } from "styled-components";
import {
  palette,
  PaletteProps,
  spacing,
  SpacingProps,
  display,
  DisplayProps,
  flexbox,
  FlexboxProps,
  borders,
  BordersProps,
  sizing,
  SizingProps,
  positions,
  PositionsProps,
} from "@material-ui/system";
import { $breakpoints } from "../../../../utils/styledHelpers";
import { AirRobeTheme, useTheme } from "@material-ui/core";

type ReactDiv = React.HTMLAttributes<HTMLDivElement>;

type SystemProps = DisplayProps &
  SizingProps &
  PositionsProps &
  FlexboxProps &
  BordersProps &
  SpacingProps &
  PaletteProps;

export type BoxProps = ReactDiv &
  SystemProps & {
    breakpoints?: any;
    children?: React.ReactNode;
    w?: SizingProps["width"];
    h?: SizingProps["height"];
  };

const Box = ({ breakpoints, w, h, ...props }: BoxProps) => {
  const theme = useTheme<AirRobeTheme>();

  return (
    <StyledBox
      $breakpoints={breakpoints}
      width={w && theme.spacing(w)}
      height={h && theme.spacing(h)}
      {...props}
    />
  );
};

export default Box;

// @ts-ignore
const StyledBox: AnyStyledComponent = styled.div`
  ${display}
  ${sizing}
  ${positions}
  ${flexbox}
  ${borders}
  ${palette}
  ${spacing}
  ${$breakpoints}
`;
