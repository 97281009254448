import { useEffect } from "react";
import { paramsToQueryString } from "./url";

// Redirect to the path provided with the "return_to" param set to the current location. Will not
// yet handle the case where the redirect path already contains a query string.
export default function followRedirectsWithReturnPath(redirectPath) {
  useEffect(() => {
    if (redirectPath) {
      const query = paramsToQueryString({
        return_to: window.location.pathname,
      });
      window.location = redirectPath + query;
    }
  }, [redirectPath]);
}
