import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Box from "../../../elements/v2/Box/Box";

import { P } from "../../../elements/v2/Typography/Typography";
import { useMutation } from "@apollo/client";
import {
  SendEmailConfirmation,
  SendEmailConfirmationVariables,
} from "../mutations/types/SendEmailConfirmation";
import SEND_EMAIL_CONFIRMATION_MUTATION from "../mutations/SendEmailConfirmation";
import { getHistoricalOrdersConfirmEmailPath } from "../../../../utils/paths";
import { ActionButton } from "./ActionButton";
import Events from "../../../../utils/analytics";

type Props = {
  affiliateAppId: string;
  selectedEmail: string;
};

const ResendEmailConfirmationAction = ({ affiliateAppId, selectedEmail }: Props) => {
  const history = useHistory();
  const [errors, setErrors] = useState<string[]>([]);

  const [sendEmailConfirmation] = useMutation<
    SendEmailConfirmation,
    SendEmailConfirmationVariables
  >(SEND_EMAIL_CONFIRMATION_MUTATION);

  function handleConfirmEmail() {
    if (selectedEmail) {
      sendEmailConfirmation({
        variables: { input: { email: selectedEmail, resend: true } },
      })
        .then(() => {
          history.push(getHistoricalOrdersConfirmEmailPath(affiliateAppId, selectedEmail));
        })
        .catch(({ graphQLErrors }) => {
          setErrors(graphQLErrors.map((e) => e.message));
        })
        .finally(() => {
          Events.sendHoStep2ConfirmEmail({
            email: selectedEmail,
            affiliateAppId: affiliateAppId,
            errors: errors,
          });
        });
    }
  }

  return (
    <Box width="100%">
      <Box mt={4}>
        {errors.map((err) => (
          <P key={err} style={{ color: "red" }}>
            {err}
          </P>
        ))}
      </Box>
      <Box mt={1}>
        <ActionButton onClick={handleConfirmEmail}>Resend Email Confirmation</ActionButton>
      </Box>
    </Box>
  );
};

export default ResendEmailConfirmationAction;
