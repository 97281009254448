import classNames from "classnames";
import React, { useEffect, useRef, useState, CSSProperties } from "react";
import { canUseDOM } from "../../../utils/featureDetection";
import { t } from "../../../utils/i18n";
import noImageIcon from "./images/noImageIcon.svg";
import plusIcon from "./images/plusIcon.svg";
import css from "./SmartImage.module.css";

// This is legacy code and a little confusing, but a smart idea. We track the load state of images,
// and if loading fails, we render a fallback image.

interface Props {
  className?: string;
  imageUrl: string;
  addImageHref?: string;
  fallbackElement?: React.ReactNode;
  title?: string;
  alt?: string;
  style?: CSSProperties;
}

const IMAGE_LOADING_TIMEOUT = 10000;
const IMAGE_TIMEOUT_TYPE_ERROR = "Force image resolve as error";
const IMAGE_LOADING = "loading";
const IMAGE_LOADED = "loaded";
const IMAGE_FAILED = "failed";

// TODO: Is this really necessary..? #blog-post-driven-development
const delayedPromiseCurry = (setTimeoutRefs) => (timeMs, name) =>
  new Promise((resolve) => {
    const timeoutRef = { name, timeout: setTimeout(resolve, timeMs) };
    setTimeoutRefs((timeoutRefs) => [...timeoutRefs, timeoutRef]);
  });

const clearTimeouts = (timeouts, name = null) => {
  timeouts.forEach((to) => {
    if (name == null || to.name === name) {
      window.clearTimeout(to.timeout);
    }
  });
};

const triggerImgLoad = (image) => {
  if (image.complete && image.naturalHeight > 0) {
    const event = document.createEvent("UIEvent");
    event.initEvent("load", true, true);
    image.dispatchEvent(event);
  }
};

const triggerImgError = (image, forceError = false) => {
  const forCompleted = !forceError && image.complete;
  const hasNoHeight = image.naturalHeight === 0;

  if ((forCompleted && hasNoHeight) || (forceError && hasNoHeight)) {
    const event = document.createEvent("UIEvent");
    event.initEvent("error", true, true);
    image.dispatchEvent(event);
  }
};

const triggerInitialImgStatuses = (image) => {
  triggerImgLoad(image);
  triggerImgError(image);
};

export default function SmartImage({
  className,
  imageUrl,
  addImageHref,
  fallbackElement,
  title,
  alt,
  style,
}: Props) {
  const [imageStatus, setImageStatus] = useState(IMAGE_LOADING);
  const [timeouts, setTimeouts] = useState([]);
  const imageRef = useRef(null);

  const delay = useRef(delayedPromiseCurry(setTimeouts)).current;

  useEffect(() => {
    const ref = imageRef.current;
    if (canUseDOM && ref != null) {
      if (ref.complete) {
        triggerInitialImgStatuses(ref);
      } else {
        delay(IMAGE_LOADING_TIMEOUT, IMAGE_TIMEOUT_TYPE_ERROR).then(() => {
          triggerImgError(ref, true);
        });
      }
    }
  }, []);

  function cleanupTimeouts() {
    timeouts.forEach((to) => window.clearTimeout(to.timeout));
  }
  useEffect(() => cleanupTimeouts);

  function handleImageLoaded() {
    clearTimeouts(timeouts, IMAGE_TIMEOUT_TYPE_ERROR);
    setImageStatus(IMAGE_LOADED);
  }

  function handleImageErrored() {
    clearTimeouts(timeouts, IMAGE_TIMEOUT_TYPE_ERROR);
    setImageStatus(IMAGE_FAILED);
  }

  const image = (
    <img
      className={className}
      ref={imageRef}
      src={imageUrl}
      title={title}
      style={style}
      alt={alt}
      onLoad={handleImageLoaded}
      onError={handleImageErrored}
    />
  );

  const addImage = (
    <div className={css.noImageText}>
      <a className={css.noImageLink} href={addImageHref}>
        <span dangerouslySetInnerHTML={{ __html: plusIcon }} />
        {t("web.listing_card.add_picture")}
      </a>
    </div>
  );

  const noImage = <div className={css.noImageText}>{t("web.listing_card.no_picture")}</div>;

  const fallback = (
    <>
      {typeof fallbackElement === "undefined" ? (
        <div className={classNames(css.noImageContainer, className)} style={style}>
          <div className={css.noImageWrapper}>
            <div className={css.noImageIcon} dangerouslySetInnerHTML={{ __html: noImageIcon }} />
            {addImageHref ? addImage : noImage}
          </div>
        </div>
      ) : (
        fallbackElement
      )}
    </>
  );

  return imageUrl && imageStatus !== IMAGE_FAILED ? image : fallback;
}
