import classNames from "classnames";
import React, { MouseEvent, ReactNode, RefObject, useEffect, useRef, useState } from "react";
import css from "./Dropdown.module.css";

const HOVER_TIMEOUT = 300;

interface Props {
  handleComponent: ReactNode;
  children: ReactNode;
  className?: string;
  contentClassName?: string;
  closeOnClick?: boolean;
  openOnHover?: boolean;
  innerRef?: RefObject<HTMLDivElement>;
}

const defaultHandleComponent = <div>Dropdown</div>;

function Dropdown({
  handleComponent = defaultHandleComponent,
  children,
  className,
  contentClassName,
  openOnHover,
  closeOnClick,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  // Used to open the dropdown after a delay if openOnHover is true
  const [mouseOverTimeoutId, setMouseOverTimeoutId] = useState(null as null | number);

  // Used to close the dropdown after a delay
  const [mouseLeaveTimeoutId, setMouseLeaveTimeoutId] = useState(null as null | number);

  const contentRef = useRef(null);

  useEffect(
    // ...Remove any timeout functions on unmount
    () =>
      function cleanup() {
        window.clearTimeout(mouseLeaveTimeoutId);
      },
    []
  );

  function handleMouseOver(_) {
    if (openOnHover) {
      setMouseOverTimeoutId(window.setTimeout(open, HOVER_TIMEOUT));
    }

    window.clearTimeout(mouseLeaveTimeoutId);
  }

  function handleMouseLeave() {
    window.clearTimeout(mouseLeaveTimeoutId);
    window.clearTimeout(mouseOverTimeoutId);

    setMouseLeaveTimeoutId(window.setTimeout(close, HOVER_TIMEOUT));
  }

  function handleClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (isOpen) {
      close();
    } else {
      open();
    }
  }

  function handleContentClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (closeOnClick) {
      close();
    }
  }

  return (
    <div
      {...{
        // ref: innerRef,
        onMouseOver: handleMouseOver,
        onMouseLeave: handleMouseLeave,
        className: classNames(className, css.dropdown, {
          [css.openDropdown]: isOpen,
        }),
      }}
    >
      <div onClick={handleClick} className={classNames(css.handle, { [css.activeHandle]: isOpen })}>
        {handleComponent}
      </div>
      <div
        onClick={handleContentClick}
        ref={contentRef}
        className={classNames([css.dropdownContent, contentClassName])}
      >
        {children}
      </div>
    </div>
  );
}

export default Dropdown;
