import { FormikValues } from "formik";
import React, { useContext } from "react";
import { RoutesContext } from "../../../utils/routes";
import { GoogleMapsProps } from "../FormControls/AddressAutoComplete/AddressAutoComplete";
import { S3Props } from "../FormControls/ImageUploader/ImageUploader";
import MultistepListingForm from "./MultistepListingForm";
import UPSERT_LISTING from "./mutations/upsertListing";
import { GetListingFormData_listing } from "./queries/types/GetListingFormData";
import listingFormModel, { getInitialValues } from "./ResellForm/FormModel/listingFormModel";
import validationSchema from "./ResellForm/FormModel/validationSchema";
import ConditionStep from "./ResellForm/Steps/ConditionStep";
import DeliveryStep from "./ResellForm/Steps/DeliveryStep";
import { ConditionWarning } from "../../pages/ListingFormPage/ListingFormPage";
import { CurrencyCode } from "../../../types/graphql-global-types";

interface Props {
  listing: GetListingFormData_listing;
  s3Props: S3Props;
  googleMapsProps: GoogleMapsProps;
  listingShapeId: number;
  initialCurrency: CurrencyCode;
  minimumPrice: number;
  conditionWarning: ConditionWarning;
}

export default function ResellListingForm({
  listing,
  s3Props,
  googleMapsProps,
  listingShapeId,
  initialCurrency,
  minimumPrice,
  conditionWarning,
}: Props) {
  const routes: any = useContext(RoutesContext);
  const steps = [ConditionStep(s3Props, listing, conditionWarning), DeliveryStep(googleMapsProps)];

  const buildSubmitPayload = (values: FormikValues) => ({
    listing: {
      listingShapeId: listingShapeId,
      condition: values.condition,
      conditionNotes: values.conditionNotes,
      price: {
        formatted: values.price,
        currencyCode: values.currency,
      },
      ...(values.enableSmartPricing
        ? {
            floorPrice: {
              formatted: values.floorPrice,
              currencyCode: values.currency,
            },
          }
        : {}),
      ...(!values.includeShippingFee
        ? {
            shippingPrice: {
              formatted: values.includeDomesticShippingFee ? "0.00" : values.shippingFee,
              currencyCode: values.currency,
            },
            shippingPriceInternational: {
              formatted: values.includeInternationalShippingFee
                ? "0.00"
                : values.shippingFeeInternational,
              currencyCode: values.currency,
            },
          }
        : {}),
      deliveryMethods: values.deliveryMethods,
      location: values.location,
      imageIds: values.images.map((image) => image.listingImageId),
    },
  });

  const onMutationComplete = (setErrors) => (data) => {
    if (data.upsertListing.validationErrors.length > 0) {
      setErrors(
        data.upsertListing.validationErrors.map((e) => `${e.attribute} - ${e.error_message}`)
      );
    } else if (data.upsertListing.redirectTo) {
      window.location = data.upsertListing.redirectTo;
    } else {
      window.location = routes.list_success_listing_path(data.upsertListing.listing.id);
    }
  };

  return (
    <MultistepListingForm
      title={`Hi ${listing.author.displayName}, let's start re-selling.`}
      subtitle="Fill in a few details to finalise your listing."
      listing={listing}
      steps={steps}
      buildSubmitPayload={buildSubmitPayload}
      mutation={UPSERT_LISTING}
      onMutationComplete={onMutationComplete}
      listingFormModel={listingFormModel}
      initialValues={getInitialValues(listing, initialCurrency)}
      validationSchema={validationSchema(minimumPrice)}
      submitButtonText="List my item"
    />
  );
}
