import React from "react";
import styled from "styled-components";
import Box from "../../../elements/v2/Box/Box";
import { P } from "../../../elements/v2/Typography/Typography";
import FlexBox from "../../../elements/v2/Box/FlexBox";

export type BestOfferProps = {
  listingPrice: string;
  offeredPrice: string;
};

export const BestOffer = ({ listingPrice, offeredPrice }: BestOfferProps) => {
  return (
    <MainContainer p={1}>
      <FlexBox justifyContent="space-between">
        <P>Listed price:</P>
        <P>{listingPrice}</P>
      </FlexBox>

      <FlexBox justifyContent="space-between">
        <BoldLabel>Offered price:</BoldLabel>
        <BoldLabel>{offeredPrice}</BoldLabel>
      </FlexBox>
    </MainContainer>
  );
};

const MainContainer = styled(Box)`
  border: 1px solid grey;
`;

const BoldLabel = styled(P)`
  font-weight: bold;
`;
