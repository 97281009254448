import { InputLabelProps, TextField as MUITextField } from "@material-ui/core";
import { FieldInputProps } from "formik";
import React from "react";
import styled from "styled-components";
import Typography from "../../../elements/v2/Typography/Typography";
import Box from "../../../elements/v2/Box/Box";

export interface Props {
  field?: FieldInputProps<string>;
  error?: boolean;
  errorMessage?: string;
  [x: string]: any;
  inputLabelProps?: Partial<InputLabelProps>;
}

export default function TextField({
  field,
  error,
  errorMessage,
  inputLabelProps = {},
  ...muiProps
}: Props) {
  return (
    <>
      <MUITextField
        type="text"
        variant="outlined"
        InputLabelProps={inputLabelProps}
        inputProps={{
          "data-cy": field?.name,
        }}
        error={error}
        {...field}
        {...muiProps}
      />
      {error && errorMessage && (
        <Box display="flex" flexDirection="row" marginTop="4px">
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Box>
      )}
    </>
  );
}

export const ErrorMessage = styled(Typography)`
  font-size: 15px;
  font-weight: 400;
  font-family: Arial, sans-serif;
  font-style: normal;
  color: #f00;
  margin-left: 5px;
`;
