import { gql } from "@apollo/client";
import { CollectionCardFragment } from "../../../../graphql/fragments/collections";

export default gql`
  query GetPersonCollections($personId: String!, $page: Int!, $perPage: Int!) {
    person(id: $personId) {
      id
      collections(page: $page, perPage: $perPage) {
        pageCount
        results {
          ...CollectionCardFragment
        }
      }
    }
  }
  ${CollectionCardFragment}
`;
