import { Filter } from "@sajari/react-search-ui";
import React from "react";
import { SearchFilterClassNames } from "../../../../../utils/search/SearchContext";
import styled from "styled-components";

export default function BrandFilter() {
  return (
    <FilterWrapper>
      <Filter className="t-searchFilterGroup" type="list" name="brand" title="" sort="alpha" />
    </FilterWrapper>
  );
}

const FilterWrapper = styled.div`
  .${SearchFilterClassNames.list.checkboxGroup} label {
    font-size: 14px;
    line-height: 1.5;
  }
`;
