import classNames from "classnames";
import r, { div } from "r-dom";
import { Component } from "react";
import MenuItem, { Props as MenuItemProps } from "../../elements/MenuItem/MenuItem";
import css from "./Menu.module.css";

interface Props {
  content: Array<MenuItemProps>;
  className?: string;
}

// TODO: we can simplify this component by merging it with the Menu component, and also by making it
// clearer that the purpose of the resolveElement function is simply to provide a default
// ContentComponent prop to each item.
class MenuContent extends Component<Props> {
  constructor(props: Props, context) {
    super(props, context);
    this.resolveElement = this.resolveElement.bind(this);
  }

  resolveElement(data, index) {
    if (data.type === "menuitem") {
      return Object.assign(
        {},
        { ContentComponent: MenuItem },
        { props: Object.assign({}, data, { index }) }
      );
    }
    return null;
  }

  render() {
    return div(
      {
        className: classNames("MenuContent", css.menuContent, this.props.className),
      },
      [
        div({ className: css.menuContentArrowBelow }),
        div({ className: css.menuContentArrowTop }),
        div(
          {
            className: css.menuContentItems,
          },
          this.props.content.map((v, i) => {
            const elemData = this.resolveElement(v, i);
            return r(elemData.ContentComponent, elemData.props);
          })
        ),
      ]
    );
  }
}

export default MenuContent;
