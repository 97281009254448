import React from "react";
import LinkBox, { LinkBoxProps } from "../Box/LinkBox";
import styled from "styled-components";

// The props for this component are based on the props for a regular div
type BaseProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export type CardProps = LinkBoxProps;

export default function Card(props: CardProps) {
  return <StyledLinkBox {...props} />;
}

const StyledLinkBox = styled(LinkBox)`
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  &:hover {
    box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.1);
  }
`;
