import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import { BUTTON_SIZES, OutlineButton } from "../../../elements/v2/Button/Button";
import GET_HISTORICAL_ORDERS_ENABLED_AFFILIATES from "../queries/GetHistoricalOrdersEnabledAffiliates";
import { GetHistoricalOrdersEnabledAffiliates } from "../queries/types/GetHistoricalOrdersEnabledAffiliates";
import { LoadingSpinner } from "../../../elements/v2/LoadingSpinner/LoadingSpinner";
import FlexBox from "../../../elements/v2/Box/FlexBox";
import { getHistoricalOrdersAffiliatePath } from "../../../../utils/paths";
import Box from "../../../elements/v2/Box/Box";
import { Description } from "./Description";
import Events from "../../../../utils/analytics";

export default function AffiliateList() {
  const { error, data, loading } = useQuery<GetHistoricalOrdersEnabledAffiliates>(
    GET_HISTORICAL_ORDERS_ENABLED_AFFILIATES
  );

  const getUrlWithProtocol = (url: string) => {
    if (!url) return "";
    if (url.includes("http")) return url;
    return `https://${url}`;
  };

  useEffect(() => {
    if (data?.historicalOrdersEnabledAffiliates) Events.sendHoStep1Started(data);
  }, [data]);

  const handleAnalytics = (affiliate) => {
    Events.sendHoStep1Completed({
      affiliates: data.historicalOrdersEnabledAffiliates,
      selectedAffiliate: affiliate,
    });
  };

  if (error) return <></>;

  const affiliates = data?.historicalOrdersEnabledAffiliates || [];

  if (loading) return <LoadingSpinner />;

  if (!affiliates.length)
    return (
      <Box mt={4}>
        <Description>
          <b>This feature is coming soon!</b>
        </Description>
      </Box>
    );

  return (
    <List>
      {affiliates.map((affiliate, i) => (
        <AffiliateContainer key={i}>
          <a href={getUrlWithProtocol(affiliate?.siteUrl)} target="_blank" rel="noreferrer">
            <Logo src={affiliate?.logoUrl}></Logo>
          </a>
          <ConnectButton
            size={BUTTON_SIZES.SMALL}
            href={getHistoricalOrdersAffiliatePath(affiliate.appId)}
            t="web.wardrobe.previous_purchases.connect"
            data-testid="connect-button"
            onClick={() => handleAnalytics(affiliate)}
          />
        </AffiliateContainer>
      ))}
    </List>
  );
}

const Logo = styled.img`
  height: 32px;
  width: 200px;
  object-fit: contain;
  object-position: left;
`;

const List = styled(FlexBox)`
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  max-width: 776px;
`;

const AffiliateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  height: 68px;
  max-width: 384px;
  padding: 16px;
  border-radius: 5px;
  background: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  > a {
    display: flex;
    align-items: center;
  }
`;

const ConnectButton = styled(OutlineButton)`
  flex-shrink: 0;
`;
