import styled from "styled-components";
import { Switch as MuiSwitch } from "@material-ui/core";

const Switch = styled(MuiSwitch)`
  width: 28px;
  height: 16px;
  padding: 0;
  display: flex;
  &:active {
    & .MuiSwitch-thumb {
      width: 15px;
    }
    & .MuiSwitch-switchBase.Mui-checked {
      transform: translateX(9px);
    }
  }
  & .MuiSwitch-switchBase {
    padding: 2px;
    &.Mui-checked {
      transform: translateX(12px);
      color: #fff;
      & + .MuiSwitch-track {
        opacity: 1;
        background-color: black;
      }
    }
  }
  & .MuiSwitch-thumb {
    box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
    width: 12px;
    height: 12px;
    border-radius: 6px;
    transition: width 200ms;
  }
  & .MuiSwitch-track {
    border-radius: 8px;
    opacity: 1;
    background-color: #b2b2b2;
    box-sizing: border-box;
    transition: background-color 200ms;
  }
`;

export default Switch;
