import classNames from "classnames";
import React from "react";
import css from "./Country.module.css";
import { CountryParts } from "../../CountryPickerDropdown/queries/types/CountryParts";

interface Props {
  country: CountryParts;
  handleClick?: () => void;
  selected?: boolean;
}

export default function Country({ country, handleClick, selected }: Props) {
  return (
    <div className={classNames(css.root)} onClick={handleClick}>
      <div className={css.flag}>{country.emojiFlag}</div>
      <div className={css.name}>
        {country.name}
        {selected ? <span className={css.selected}> (selected)</span> : null}
      </div>
      <div className={css.currency}>{countryLabel(country)}</div>
    </div>
  );
}

// This is what we display as the clickable handle for the dropdown
export function countryLabel(country: CountryParts | null) {
  if (!country) {
    return "-";
  }
  return `${country.code} ${country.currency.symbol}`;
}
