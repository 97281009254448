import { camelCase, Dictionary } from "lodash";
import * as Yup from "yup";
import {
  GetCategories_categories,
  GetCategories_categories_customFields,
} from "../queries/types/GetCategories";
import listingFormModel from "./listingFormModel";

const {
  formField: { title, images, brand, rrp, currency },
} = listingFormModel;

function getCustomFieldValidation(customField: GetCategories_categories_customFields) {
  switch (customField.type) {
    case "NumericField":
      return customField.required
        ? Yup.number().required(`${customField.name} is required`)
        : Yup.number().notRequired();
    case "TextField": {
      return customField.required
        ? Yup.string().required(`${customField.name} is required`)
        : Yup.string().notRequired();
    }
    case "CheckboxField": {
      return customField.required
        ? Yup.array().min(1, `${customField.name} is required`)
        : Yup.array().notRequired();
    }
  }
}
function categoryHasChildren(
  categoryId: string,
  categoriesMap: Dictionary<GetCategories_categories>
) {
  return Object.values(categoriesMap).find((c) => c.parent?.id?.toString() === categoryId);
}

const customFieldsShape = (
  values,
  categoriesMap: Dictionary<GetCategories_categories>,
  required: Boolean
) =>
  Yup.object().shape({
    ...(values.category
      ? Object.fromEntries(
          categoriesMap[values.category].customFields
            .filter((customField) => customField.required == required)
            .map((customField) => [
              camelCase(customField.key),
              getCustomFieldValidation(customField),
            ])
        )
      : {}),
  });

const form = (categoriesMap: Dictionary<GetCategories_categories>) => {
  return [
    (values) =>
      Yup.object().shape({
        [title.name]: Yup.string()
          .required(title.requiredErrorMsg)
          .test(
            "len",
            "Title must be at least two characters long",
            (val) => val && val.length >= 2
          ),
        [images.name]: Yup.array()
          .min(1, images.requiredErrorMsg)
          .test(
            "still-uploading",
            images.imageUploadingMsg,
            (val) =>
              // valid if no image is still uploading and does not have a listing image id
              val && !val.some((image) => !image.doneUploading && !image.listingImageId)
          ),
      }),
    (values) =>
      Yup.object().shape({
        [brand.name]: Yup.string().required(brand.requiredErrorMsg),
        [currency.name]: Yup.string()
          .notRequired()
          .when(rrp.name, {
            is: (val) => val && val !== "",
            then: Yup.string().required(currency.requiredErrorMsg),
          }),
        [rrp.name]: Yup.number()
          .transform((value, originalValue) =>
            // This transform is necessary as Material ui doesn't like
            // us setting null as value for input, and yup doesn't like
            // empty string as value for a number.
            String(originalValue).trim() === "" ? null : value
          )
          .nullable()
          .integer("RRP must be a whole number")
          .notRequired()
          .typeError(rrp.invalidErrorMsg)
          .test("valid", rrp.invalidErrorMsg, (val) => {
            if (val) {
              return val > 0;
            }
            return true;
          }),
      }),
    (values) =>
      Yup.object().shape({
        rootCategory: Yup.string().nullable().required("Category is required"),
        subCategory: Yup.string().when("rootCategory", {
          is: (val) => categoryHasChildren(val, categoriesMap),
          then: Yup.string().required("Subcategory is required"),
        }),
      }),
    (values) => customFieldsShape(values, categoriesMap, true),
    (values) => customFieldsShape(values, categoriesMap, false),
  ];
};

export default form;
