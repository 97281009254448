import React from "react";
import styled from "styled-components";
import Box from "../../../elements/v2/Box/Box";
import { breakpoints } from "../../../../utils/styledHelpers";
import Typography from "../../../elements/v2/Typography/Typography";

type SectionProps = {
  title: string;
  children: React.ReactNode;
};

export default function Section({ title, children }: SectionProps) {
  return (
    <Container>
      <Header>
        <Title text={title} />
      </Header>
      {children}
    </Container>
  );
}

const Container = styled(Box).attrs({
  className: "MobileMenu-Section",
})`
  margin-top: 16px;

  ${breakpoints.up("sm")} {
    margin-top: 28px;
  }
`;

const Header = styled(Box).attrs({
  className: "MobileMenu-Section-Header",
})`
  position: relative;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 10px 16px;
`;

const Title = styled(Typography).attrs({
  className: "MobileMenu-Section-Title",
})`
  font-size: 12px;
  color: #999999;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1.25em;
`;
