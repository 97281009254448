import { gql } from "@apollo/client";

const SetProfileListingOrder = gql`
  mutation SetProfileListingOrder($input: SetProfileListingOrderMutationInput!) {
    setProfileListingOrder(input: $input) {
      success
      error
    }
  }
`;

export default SetProfileListingOrder;
