import classNames from "classnames";
import React, { CSSProperties } from "react";
import { CollectionCardFragment } from "../../../graphql/fragments/types/CollectionCardFragment";
import Card from "../../elements/Card/Card";
import CollectionMenu from "../CollectionMenu/CollectionMenu";
import css from "./CollectionCard.module.css";
import { MenuLabelTypeEnum } from "../../composites/Menu/Menu";

export interface Props {
  collection: CollectionCardFragment;
  className?: string;
  style?: CSSProperties;
  afterUpdate?: () => void;
  afterDelete?: () => void;
}

export default function CollectionCard({
  collection,
  className,
  afterUpdate,
  afterDelete,
  style,
}: Props) {
  // If the collection is mine, show the menu in the top right corner
  const action = collection.owner.isMe ? (
    <CollectionMenu
      className={css.menu}
      labelClassName={css.menuLabel}
      collection={collection}
      label="..."
      labelType={MenuLabelTypeEnum.PLAIN}
      afterDelete={afterDelete}
      afterUpdate={afterUpdate}
    />
  ) : null;

  const backgroundImageUrl = collection.coverImageUrl || collection.coverImageFallbackUrl;

  return (
    <Card
      style={{ ...style }}
      className={classNames(css.root, className, "t-collectionCard")}
      backgroundImageUrl={backgroundImageUrl}
      href={collection.profileCollectionUrl}
    >
      <div className={css.overlay} />
      <div className={css.title}>{collection.displayTitle}</div>
      {action}
    </Card>
  );
}
