import styled from "styled-components";
import FlexBox from "../../../../elements/v2/Box/FlexBox";
import { Dialog, DialogContent } from "@material-ui/core";
import closeIcon from "./assets/closeIcon.svg";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import React, { ReactNode, useState } from "react";
import { GetListing_listing } from "../../queries/types/GetListing";
import { getCustomFieldSize } from "../Size/Size";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ({
  children,
  handleModalClose,
  listing,
  isMobile,
  startingPriceFormatted,
}: {
  children: ReactNode;
  handleModalClose: Function;
  listing: GetListing_listing;
  isMobile: boolean;
  startingPriceFormatted?: any;
}) => {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    handleModalClose();
    setOpen(false);
  };
  if (isMobile)
    return (
      <OfferModalForm open={open} onClose={handleClose} fullScreen TransitionComponent={Transition}>
        <OfferForm id="offer-form" data-testid="offer-form">
          <Close onClick={handleClose} data-testid="close" />
          <DialogContent>
            <Listing>
              <img src={listing.listingImages[0].thumbUrl} alt="Listing Image" height={106} />
              <ListingDetails>
                <ListingDetailBrand>{listing?.brand}</ListingDetailBrand>
                <ListingDetailTitle>{listing?.title}</ListingDetailTitle>
                <ListingDetailRow>Size: {getCustomFieldSize(listing)}</ListingDetailRow>
                <ListingDetailRow>{startingPriceFormatted}</ListingDetailRow>
              </ListingDetails>
            </Listing>
            {children}
          </DialogContent>
        </OfferForm>
      </OfferModalForm>
    );
  else
    return (
      <OfferForm id="offer-form" data-testid="offer-form" $border={true}>
        {children}
      </OfferForm>
    );
};

const OfferForm = styled(FlexBox)<{ $border?: boolean }>`
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0px 24px 20px 24px;
  border: ${({ $border }) => ($border ? "1px solid black" : "none")};
`;

const OfferModalForm = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 0px;
    align-items: center;
    justify-content: space-between;
  }
  .MuiDialogContent-root {
    padding: 40px 0px;
    width: 100%;
  }
  font-size: 16px;
`;

const Listing = styled.div`
  display: flex;
  margin: 20px 0px;
  border-width: 1px 0px 1px 0px;
  border-style: solid;
  border-color: #dddddd;
  padding: 40px 10px;
  gap: 16px;
`;

const ListingDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  gap: 4px;
`;

const ListingDetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  color: #222222;
`;

const ListingDetailTitle = styled(ListingDetailRow)`
  color: #666666;
`;

const ListingDetailBrand = styled(ListingDetailRow)`
  text-transform: uppercase;
`;

const Close = styled.span.attrs({
  dangerouslySetInnerHTML: {
    __html: closeIcon,
  },
})`
  border: none;
  position: absolute;
  right: 0px;
  margin: 20px;
  z-index: 10;
`;
