import React, { useContext } from "react";
import Section from "./Section";
import { t } from "../../../../utils/i18n";
import RorpContext from "../../../../contexts/RorpContext";
import { TopbarUserProps } from "../types";
import {
  ADMIN_PATH,
  isPathActive,
  LOGIN_PATH,
  LOGOUT_PATH,
  SIGNUP_PATH,
} from "../../../../utils/paths";
import MobileMenuItem from "./MobileMenuItem";

type MenuMobileOtherProps = {
  user: TopbarUserProps;
};

export default function SectionOther({ user }: MenuMobileOtherProps) {
  const { pathname } = useContext(RorpContext);
  const { isAdmin, loggedInUsername } = user;

  return (
    <Section title={""}>
      {isAdmin && (
        <MobileMenuItem href={ADMIN_PATH} active={isPathActive(pathname, ADMIN_PATH)}>
          {t("web.topbar.admin_dashboard")}
        </MobileMenuItem>
      )}
      {loggedInUsername ? (
        <MobileMenuItem href={LOGOUT_PATH}>{t("web.topbar.logout")}</MobileMenuItem>
      ) : (
        <>
          <MobileMenuItem href={LOGIN_PATH}>{t("web.topbar.login")}</MobileMenuItem>
          <MobileMenuItem href={SIGNUP_PATH}>{t("web.topbar.signup")}</MobileMenuItem>
        </>
      )}
    </Section>
  );
}
