import React from "react";
import OneOfHero from "../../../../assets/images/featured_brands/one-of-hero.lqip.png";
import OneOfLogo from "../../../../assets/images/featured_brands/one-of-logo.lqip.png";
import { getLqipBackgroundImageUrl } from "../../../../utils/images";
import styled from "styled-components";
import FlexBox from "../../../elements/v2/Box/FlexBox";
import Box from "../../../elements/v2/Box/Box";
import { H2, H5 } from "../../../elements/v2/Typography/Heading";
import { breakpoints } from "../../../../utils/styledHelpers";

/*
 * The One/Of brand is a one-off (no pun intended) marketing campaign with some last minute custom designs.
 * Hence, this is rendered separately. This is not an ideal way to handle the Brand Header rendering,
 * but its a stop-gap to get this feature out fast.
 */
export default function BrandOneOfHeader() {
  return (
    <Center>
      <Logo my={{ xs: 2, sm: 6 }} style={{ backgroundImage: `url(${OneOfLogo.src})` }} />
      <Hero style={{ backgroundImage: getLqipBackgroundImageUrl(OneOfHero) }} />
      <Copy>
        <Title>
          For the first time, ONE/OF archival garments are exclusively available to rent or buy on
          AirRobe. In celebration of the brand’s debut at New York Fashion Week, explore the opulent
          range conceived and created by leading sustainable designer, Patricia Voto.
        </Title>
        <Subtitle>
          ONE/OF by Patricia Voto is a New York-based luxury fashion house born from vestiges of
          heritage brands. ONE/OF creates limited ready-to-wear collections with reclaimed elements
          designed for evolving modern life.
        </Subtitle>
      </Copy>
      <Divider mb={4} />
    </Center>
  );
}

const Center = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const Logo = styled(Box)`
  width: 150px;
  height: 65px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  ${breakpoints.down("sm")} {
    width: 95px;
    height: 40px;
  }
`;

const Hero = styled(Box)`
  width: 100%;
  background-position: center;
  background-repeat: repeat no-repeat;
  background-size: auto 100%;
  height: 40vw;
  max-height: 460px;
  ${breakpoints.down("tablet")} {
    max-height: 230px;
  }
`;

const Copy = styled(Center)`
  padding: 0 32px;
  max-width: 864px;
  width: 100%;
  text-align: center;
`;

const Title = styled(H2)`
  font-family: Lora, serif;
  margin-top: 56px;
  line-height: 32px;
  ${breakpoints.down("sm")} {
    font-size: 17px;
    line-height: 24px;
    margin-top: 24px;
  }
`;

const Subtitle = styled(H5)`
  margin-top: 32px;
  margin-bottom: 32px;
  text-transform: initial;
  line-height: 24px;
  ${breakpoints.down("sm")} {
    font-size: 13px;
    line-height: 19px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

const Divider = styled(Box)`
  border-top: 1px solid #949aa1;
  width: 50px;
  height: 1px;
`;
