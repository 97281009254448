import { FilterBuilder } from "@sajari/react-hooks";
import departments, { DepartmentsUnion } from "./departments";
import { ProfilePageProps } from "../components/pages/SearchPage/queries/types/ProfilePageProps";

export enum PageType {
  product = "product",
  brand_category = "brand_category",
}

export interface LongTailPage {
  key: string;
  title: string;
  pageType: PageType;
  productLongTailPageItemIds?: string[];
  isProductLongTailPage?: boolean;
}

export interface DiscountEligibilityPage {
  isDiscountEligibilityPage: boolean;
  title: string;
  discountPercent: number;
}

export interface ConciergePage {
  isConciergePage: boolean;
  airrobeConciergePerson: ProfilePageProps;
}

export type FilterProps = {
  department: DepartmentsUnion;
  isDesignerBrand: boolean;
  brand?: string;
  collectionId?: number;
  profilePage?: ProfilePageProps;
  isProfilePage?: boolean;
  latitude?: string;
  longitude?: string;
  longTailPage?: LongTailPage;
  discountEligibilityPage?: DiscountEligibilityPage;
  conciergePage?: ConciergePage;
};

export enum FilterNames {
  colour = "colour",
  category = "category",
  brand = "brand",
  size = "size",
  price = "price",
  itemType = "item_type",
  condition = "condition",
  comesWith = "comes_with",
  country = "country",
  deals = "price_reduced_percentage_bucket",
  length = "length",
  adaptive = "adaptive",
  material = "material",
  style = "style",
  occasion = "occasion",
  pattern = "pattern",
}

const countryFilter = new FilterBuilder({
  name: "country",
  field: "country",
});

const brandFilter = new FilterBuilder({
  name: "brand",
  field: "brand",
});

const categoryFilter = new FilterBuilder({
  name: "category",
  field: "categories",
  array: true,
});

const conditionFilter = new FilterBuilder({
  name: "condition",
  field: "condition",
  array: false,
});

const adaptiveFilter = new FilterBuilder({
  name: "adaptive",
  field: "adaptive",
  array: false,
});

const colorFilter = new FilterBuilder({
  name: "colour",
  field: "colour",
  array: true,
});

const priceFilter = new FilterBuilder({
  name: "price",
  field: "price_aud",
  options: {
    "$0-$50": "price_aud <= 50",
    "$50-$100": "price_aud > 50 AND price_aud <= 100",
    "$100-$200": "price_aud > 100 AND price_aud <= 200",
    "$200-$500": "price_aud > 200 AND price_aud <= 500",
    "$500+": "price_aud > 500",
  },
});

const priceReducedPercentageBucketFilter = new FilterBuilder({
  name: "price_reduced_percentage_bucket",
  field: "price_reduced_percentage_bucket",
});

const priceReducedPercentageFilter = new FilterBuilder({
  name: "price_reduced_percentage",
  field: "price_reduced_percentage",
});

const reducedPriceFilter = new FilterBuilder({
  name: "reduced_price",
  field: "is_price_reduced",
});

const sizeFilter = new FilterBuilder({
  name: "size",
  field: "size",
  array: true,
});

const comesWithFilter = new FilterBuilder({
  name: "comes_with",
  field: "comes_with",
  array: true,
});

const itemTypeFilter = new FilterBuilder({
  name: "item_type",
  field: "item_type",
});

const patternFilter = new FilterBuilder({
  name: "pattern",
  field: "pattern",
  array: true,
});

const occasionFilter = new FilterBuilder({
  name: "occasion",
  field: "occasion",
  array: true,
});

const lengthFilter = new FilterBuilder({
  name: "length",
  field: "length",
  array: true,
});

const materialFilter = new FilterBuilder({
  name: "material",
  field: "material",
  array: true,
});

const styleFilter = new FilterBuilder({
  name: "style",
  field: "style",
  array: true,
});

export const applyFilters = ({ searchProps }: { searchProps: FilterProps }) => {
  const filters = [];

  if (searchProps?.collectionId) {
    filters.push(`collection_ids ~ [${searchProps.collectionId}]`);
  }

  if (searchProps?.isProfilePage) {
    filters.push(`author="${searchProps.profilePage.person.username}"`);
  }

  if (searchProps?.isDesignerBrand) {
    filters.push("is_designer_brand=true");
  }

  if (searchProps?.brand) {
    filters.push(`brand_key="${searchProps.brand}"`);
  }

  if (searchProps?.department) {
    const searchFilterValue = departments[searchProps.department]?.searchFilterValue;
    if (searchFilterValue) filters.push(`department="${searchFilterValue}"`);
  }

  if (searchProps?.longTailPage?.key) {
    filters.push(`long_tail_page_key ~ ["${searchProps.longTailPage.key}"]`);
  }

  if (searchProps?.discountEligibilityPage?.isDiscountEligibilityPage) {
    filters.push(
      `seller_commission_percent >= ${searchProps?.discountEligibilityPage?.discountPercent}`
    );
  }

  if (searchProps?.conciergePage?.isConciergePage) {
    filters.push("is_concierge=true");
  }

  return filters.filter(Boolean).join(" AND ");
};

export default [
  categoryFilter,
  conditionFilter,
  adaptiveFilter,
  colorFilter,
  priceFilter,
  comesWithFilter,
  brandFilter,
  sizeFilter,
  itemTypeFilter,
  reducedPriceFilter,
  priceReducedPercentageBucketFilter,
  priceReducedPercentageFilter,
  countryFilter,
  patternFilter,
  occasionFilter,
  lengthFilter,
  materialFilter,
  styleFilter,
];
