import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import React, { Fragment } from "react";
import CustomFormikField from "../../../FormControls/CustomFormikField/CustomFormikField";
import FormSection from "../../../FormControls/FormSection/FormSection";
import RadioGroup, { RadioGroupOptions } from "../../../FormControls/RadioGroup/RadioGroup";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const dryCleaningOptions: RadioGroupOptions = [
  { value: "lender", label: "Lender" },
  { value: "borrower", label: "Borrower" },
];

const tryOnAvailabilityOptions: RadioGroupOptions = [
  { value: "available", label: "Available" },
  { value: "unavailable", label: "Unavailable" },
];

export default function Price({ values, formField: { dryCleaning, tryOnAvailability } }) {
  const classes = useStyles();

  return (
    <Fragment>
      <Grid item>
        <FormSection
          title="Dry Cleaning"
          classesOverrides={{
            innerFormSection: {
              paddingTop: 0,
            },
          }}
        >
          <CustomFormikField
            fieldName={dryCleaning.name}
            InnerComponent={RadioGroup as React.ComponentType}
            options={dryCleaningOptions}
            row
          />
        </FormSection>
      </Grid>
      <Grid item>
        <FormSection
          title="Try-on availability"
          classesOverrides={{
            innerFormSection: {
              paddingTop: 0,
            },
          }}
        >
          <CustomFormikField
            fieldName={tryOnAvailability.name}
            InnerComponent={RadioGroup as React.ComponentType}
            options={tryOnAvailabilityOptions}
            row
          />
        </FormSection>
      </Grid>
    </Fragment>
  );
}
