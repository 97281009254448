import Immutable from "immutable";
import r, { a, div } from "r-dom";
import { Component } from "react";
import { canUseDOM, canUsePushState } from "../../../utils/featureDetection";
import { t } from "../../../utils/i18n";
import FlashNotification from "../../composites/FlashNotification/FlashNotification";
import ManageAvailabilityCalendar, {
  Props as ManageAvailabilityCalendarProps,
} from "../../composites/ManageAvailabilityCalendar/ManageAvailabilityCalendar";
import ManageAvailabilityHeader, {
  Props as ManageAvailabilityHeaderProps,
} from "../../composites/ManageAvailabilityHeader/ManageAvailabilityHeader";
import SideWinder from "../../composites/SideWinder/SideWinder";
import css from "./ManageAvailability.module.css";
import SaveButton from "./SaveButton";

const CALENDAR_RENDERING_TIMEOUT = 100;

interface Props {
  actions: {
    removeFlashNotification: () => void;
    closeEditView: () => void;
  };
  availability_link: Element;
  flashNotifications: Immutable.List<any>; // TODO: convert flash notification code to typescript
  hasChanges: boolean;
  saveInProgress?: boolean;
  saveFinished?: boolean;
  onOpen: () => void;
  onSave: () => void;
  onCloseCallback: () => void;
  isOpen?: boolean;
  header: ManageAvailabilityHeaderProps;
  calendar: ManageAvailabilityCalendarProps;
  sideWinderWrapper: any; // TODO: convert to typescript
}

/**
   Return `true` if component should load initial data.
*/
const shouldLoad = (isOpen, prevIsOpen) => isOpen && !prevIsOpen;

/**
   Load initial data if needed. This should happen only once when
   component `isOpen` becomes `true`
*/
const loadInitialDataIfNeeded = (props, prevProps = null) => {
  const isOpen = props.isOpen;
  const prevIsOpen = prevProps && prevProps.isOpen;

  if (shouldLoad(isOpen, prevIsOpen)) {
    props.calendar.onMonthChanged(props.calendar.initialMonth);
  }
};

const setPushState = (state, title, path) => {
  if (canUseDOM && canUsePushState) {
    window.history.pushState(state, title, path);
  } else if (canUseDOM) {
    window.location.hash = path;
  }
};

class ManageAvailability extends Component<Props> {
  calendarTimeout: number = null;

  state: {
    renderCalendar: boolean;
    viewportHeight?: number;
  } = {
    renderCalendar: false,
    viewportHeight: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      renderCalendar: false,
      viewportHeight: null,
    };

    this.clickHandler = this.clickHandler.bind(this);
    this.resizeHandler = this.resizeHandler.bind(this);
  }

  componentDidMount() {
    // react-dates calendar height is often calculated incorrectly in
    // Safari when the SideWinder is shown. Rendering it
    // asynchronously allows the calendar to calculate the height
    // properly.
    // See: https://github.com/airbnb/react-dates/issues/46
    this.calendarTimeout = window.setTimeout(() => {
      this.setState({ renderCalendar: true }); // eslint-disable-line react/no-set-state
    }, CALENDAR_RENDERING_TIMEOUT);

    if (this.props.availability_link) {
      this.props.availability_link.addEventListener("click", this.clickHandler);
    }

    loadInitialDataIfNeeded(this.props);

    this.setState({ viewportHeight: window.innerHeight }); // eslint-disable-line react/no-set-state
    window.addEventListener("resize", this.resizeHandler);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    // manage location hash
    const containsHash = window.location.hash.indexOf("#manage-availability") >= 0;
    const href = window.location.href;
    const paramsIndex = href.indexOf("?");
    const searchPart = paramsIndex >= 0 ? href.substring(paramsIndex) : "";

    if (nextProps.isOpen && !containsHash) {
      const openPath = `${window.location.pathname}#manage-availability${searchPart}`;
      setPushState(null, "Availability calendar is open", openPath);
    } else if (!nextProps.isOpen && containsHash) {
      setPushState(
        null,
        "Availability calendar is closed",
        `${window.location.pathname}${searchPart}`
      );
    }
  }

  componentDidUpdate(prevProps) {
    loadInitialDataIfNeeded(this.props, prevProps);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeHandler);
    window.clearTimeout(this.calendarTimeout);
    if (this.props.availability_link) {
      this.props.availability_link.removeEventListener("click", this.clickHandler);
    }
  }

  clickHandler(e) {
    e.preventDefault();
    this.props.onOpen();
  }

  resizeHandler() {
    this.setState({ viewportHeight: window.innerHeight }); // eslint-disable-line react/no-set-state
  }

  render() {
    const showCalendar = this.props.isOpen && this.state.renderCalendar;
    const defaultLink = a(
      {
        href: "#",
        onClick: this.clickHandler,
      },
      t("web.listings.edit_listing_availability")
    );
    const maybeRenderDefaultLink = this.props.availability_link ? null : defaultLink;

    const winder = {
      wrapper: this.props.sideWinderWrapper,
      maxWidth: 342,
      minWidth: 320,
      height: this.state.viewportHeight,
      isOpen: this.props.isOpen,
      onClose: () => {
        if (this.props.saveInProgress) {
          return;
        }
        const explanation = t(
          "web.listings.confirm_discarding_unsaved_availability_changes_explanation"
        );
        const question = t("web.listings.confirm_discarding_unsaved_availability_changes_question");
        const text = `${explanation}\n\n${question}`;

        // eslint-disable-next-line no-alert
        if (!this.props.hasChanges || window.confirm(text)) {
          this.props.actions.closeEditView();
          if (typeof this.props.onCloseCallback === "function") {
            this.props.onCloseCallback();
          }
        }
      },
    };

    return div([
      maybeRenderDefaultLink,
      r(SideWinder, winder, [
        div({ className: css.content }, [
          r(ManageAvailabilityHeader, this.props.header),
          showCalendar
            ? r(ManageAvailabilityCalendar, {
                ...this.props.calendar,
                extraClasses: css.calendar,
              })
            : null,
          r(SaveButton, {
            isVisible: this.props.hasChanges,
            saveInProgress: this.props.saveInProgress,
            saveFinished: this.props.saveFinished,
            onClick: this.props.onSave,
          }),
        ]),
      ]),
      r(FlashNotification, {
        actions: this.props.actions,
        messages: this.props.flashNotifications,
      }),
    ]);
  }
}

export default ManageAvailability;
