import r from "r-dom";
import PlantBox, { Props } from "./PlantBox";
import ssrComponent from "../../../utils/ssr";

export default (props: Props, _) => {
  function PlantBoxApp() {
    return r(PlantBox, props);
  }

  return ssrComponent(PlantBoxApp);
};
