import { gql } from "@apollo/client";

const UPSERT_PRODUCT = gql`
  mutation UpsertProduct($input: UpsertProductMutationInput!) {
    upsertProduct(input: $input) {
      validationErrors {
        attribute
        errorMessage
      }
      product {
        id
        author {
          username
        }
      }
    }
  }
`;

export default UPSERT_PRODUCT;
