import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  ChartData,
  ChartDataset,
  ChartOptions,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { useEffect, useRef } from "react";

// ChartJS 3.0 is tree-shakeable and imports need to be registered in order to be included.
Chart.register(
  LinearScale,
  LineController,
  CategoryScale,
  LineElement,
  BarController,
  BarElement,
  PointElement,
  Tooltip,
  Legend,
  Title
);

// Roll our own hook to setup a chartJS chart. Takes data and some options as arguments, and returns
// a ref that should be assigned to an empty HTML Canvas element. Automagically generates tooltip
// configuration if the dataset specifies a "tooltip suffix" option.
interface ChartJSHookOptions {
  data: ChartData;
  options: ChartOptions;
}
export function useChartJSChart({ data, options }: ChartJSHookOptions) {
  const chartRef = useRef<HTMLCanvasElement>();

  useEffect(() => {
    if (chartRef.current && data) {
      var ctx = chartRef.current.getContext("2d");

      new Chart(ctx, {
        type: "bar",
        data: {
          ...data,
          datasets: data.datasets.map(setupTooltips),
        },
        options,
      });
    }
  }, [data, chartRef.current]);

  return chartRef;
}

// Given a dataset that includes a tooltip suffix, provide some options that Chart.js understands to
// setup the tooltip.
export function setupTooltips(dataset: ChartDataset & { tooltipSuffix?: string }) {
  if (!dataset.tooltipSuffix) return dataset; // ... If no such key is present, bail out early

  return {
    ...dataset,
    tooltip: {
      callbacks: {
        label: ({ raw }) => `${raw}${dataset.tooltipSuffix}`,
      },
    },
  };
}
