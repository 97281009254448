import React from "react";
import styled from "styled-components";
import { P } from "../../../elements/v2/Typography/Typography";
import Box from "../../../elements/v2/Box/Box";

type Props = {
  errors: string[];
};

export function Errors({ errors }: Props) {
  if (!errors.length) return null;

  return (
    <Box mt={2}>
      {errors.map((message) => (
        <Error key={message}>{message}</Error>
      ))}
    </Box>
  );
}

const Error = styled(P)`
  color: indianred;
`;
