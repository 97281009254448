import { createStyles, Grid, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { Dictionary } from "lodash";
import React, { useEffect } from "react";
import CustomFormikField from "../../../FormControls/CustomFormikField/CustomFormikField";
import Dropdown from "../../../FormControls/Dropdown/Dropdown";
import FormSection from "../../../FormControls/FormSection/FormSection";
import { GetCategories_categories } from "../queries/types/GetCategories";

const useStyles = makeStyles(() =>
  createStyles({
    hideSubcategories: {
      display: "none",
    },
  })
);

const CategoryStep = (categoriesMap: Dictionary<GetCategories_categories>) => ({
  values,
  setFieldValue,
  handleChange,
}) => {
  const classes = useStyles();
  const allCategories = Object.values(categoriesMap);
  const rootCategories = allCategories.filter((c) => !c.parent?.id);
  const subCategories = values.rootCategory
    ? allCategories.filter((c) => c.parent?.id === values.rootCategory)
    : [];

  useEffect(() => {
    // Here we deal with the formik state changes necessary after
    // the user selects root/sub categories. We do this in useEffect
    // instead of in onChange handlers for simplicity.
    // The main goal here is to set the category value in Formik. This
    // will be either the selected subcategory or the selected root category
    // in case the root category has no children (shoes being a current example).
    if (subCategories.length === 0) {
      // No subcategories means a root category was chosen that has
      // no children. The root category is the selected category in this
      // case.
      setFieldValue("category", values.rootCategory);
    } else {
      // Otherwise, the selected category is the subcategory.
      setFieldValue("category", values.subCategory);
    }
  }, [values.rootCategory, values.subCategory]);

  return (
    <Grid container spacing={2} direction="column" wrap="nowrap">
      <Grid item>
        <FormSection title="Category">
          <CustomFormikField
            fieldName="rootCategory"
            placeholder="Select category"
            fullWidth
            InnerComponent={Dropdown}
            options={rootCategories.map((c) => ({
              value: c.id,
              label: c.name,
            }))}
            onChange={(e) => {
              handleChange(e);
              return setFieldValue("subCategory", "");
            }}
          />
        </FormSection>
      </Grid>
      <Grid
        item
        className={classNames({
          [classes.hideSubcategories]: subCategories.length === 0,
        })}
      >
        <FormSection title="Subcategory">
          <CustomFormikField
            fieldName="subCategory"
            placeholder="Select subcategory"
            fullWidth
            InnerComponent={Dropdown}
            options={subCategories.map((c) => ({
              value: c.id,
              label: c.name,
            }))}
          />
        </FormSection>
      </Grid>
    </Grid>
  );
};

const withProps = (categoriesMap: Dictionary<GetCategories_categories>) =>
  CategoryStep(categoriesMap);

export default withProps;
