import { gql } from "@apollo/client";

export default gql`
  query GetTransaction($id: Int!) {
    me {
      id
    }
    transaction(id: $id) {
      id
      currentState
      shipped
      deliveryMethod
      customOfferProvided
      seller {
        id
        displayName
        profileUrl
        username
      }
      buyer {
        id
        displayName
        profileUrl
        username
      }
      listing {
        title
        url
      }
      buyerTestimonials: testimonials(from: buyer) {
        author {
          id
        }
      }
      sellerTestimonials: testimonials(from: seller) {
        author {
          id
        }
      }
      buyerSkippedFeedback
      sellerSkippedFeedback
    }
  }
`;
