import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Box from "../../../elements/v2/Box/Box";

import { Checkbox } from "@material-ui/core";
import FlexBox from "../../../elements/v2/Box/FlexBox";
import { P } from "../../../elements/v2/Typography/Typography";
import styled from "styled-components";
import { getHistoricalOrdersFetchOrdersPath } from "../../../../utils/paths";
import { AffiliateType } from "../HistoricalOrdersPage";
import { ActionButton } from "./ActionButton";
import Events from "../../../../utils/analytics";

type Props = {
  affiliate: AffiliateType;
  selectedEmail: string;
  hasConsentedEmail: boolean;
};

const SelectedEmailAction = ({ affiliate, selectedEmail, hasConsentedEmail }: Props) => {
  const history = useHistory();
  const [hasGivenConsent, setGivenConsent] = useState<boolean>(hasConsentedEmail);

  function handleConsentChange(e) {
    setGivenConsent(e.currentTarget.checked);
  }

  function handleFindMyOrders() {
    history.push(getHistoricalOrdersFetchOrdersPath(affiliate.appId, selectedEmail));
    Events.sendHoStep2Completed({
      selectedEmail: selectedEmail,
      hasGivenConsent: hasGivenConsent,
      affiliate: affiliate,
    });
  }

  return (
    <Box width="100%">
      <FlexBox mt={4}>
        <Checkbox checked={hasGivenConsent} onChange={handleConsentChange} />
        <ConsentLabel>
          I give permission for AirRobe to access my past purchases from {affiliate?.displayName}
        </ConsentLabel>
      </FlexBox>
      <Box mt={4}>
        <ActionButton disabled={!hasGivenConsent} onClick={handleFindMyOrders}>
          Find My Past Orders
        </ActionButton>
      </Box>
    </Box>
  );
};

export default SelectedEmailAction;

const ConsentLabel = styled(P)`
  display: flex;
  align-items: center;
  text-align: left;
`;
