import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  Card,
  Fade,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import CustomFormikField from "../../../FormControls/CustomFormikField/CustomFormikField";
import FormSection from "../../../FormControls/FormSection/FormSection";
import ImageUploader from "../../../FormControls/ImageUploader/ImageUploader";
import InputField from "../../../FormControls/InputField/InputField";
import { ConditionWarning } from "../../../../pages/ListingFormPage/ListingFormPage";
import { S3Props } from "../../../FormControls/ImageUploader/ImageUploader";
import { GetListingFormData_listing } from "../../queries/types/GetListingFormData";
import { FormikValues } from "formik";
import RadioGroup, { RadioGroupOptions } from "../../../FormControls/RadioGroup/RadioGroup";
import { RentFormFields, ResellFormFields } from "../../RentForm/FormModel/listingFormModel";
import { CUSTOM_FIELD_CONDITION_OPTIONS } from "../../../../../constants/CustomFieldConstants";

interface ConditionSectionProps {
  formField: RentFormFields | ResellFormFields;
  values: FormikValues;
  conditionWarning: ConditionWarning;
  listing: GetListingFormData_listing;
  s3Props: S3Props;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hideConditionNotes: {
      display: "none",
    },
  })
);

const shouldHideImageUploader = (values) =>
  values.condition !== "gently_used" && values.condition !== "well_loved";

const conditionOptions: RadioGroupOptions = [
  { value: "new_with_tags", label: CUSTOM_FIELD_CONDITION_OPTIONS.new_with_tags },
  { value: "new_without_tags", label: CUSTOM_FIELD_CONDITION_OPTIONS.new_without_tags },
  { value: "like_new", label: CUSTOM_FIELD_CONDITION_OPTIONS.like_new },
  { value: "gently_used", label: CUSTOM_FIELD_CONDITION_OPTIONS.gently_used },
  { value: "well_loved", label: CUSTOM_FIELD_CONDITION_OPTIONS.well_loved },
];

export default function ConditionSection(props: ConditionSectionProps) {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = React.useState(false);
  const {
    formField: { condition, conditionNotes, images },
    values: { condition: productConditionInput, conditionNotes: conditionNotesInput },
  } = props;
  const { values, s3Props, listing, conditionWarning } = props;
  React.useEffect(() => {
    const conditionWarningModalOpen =
      conditionWarning?.message &&
      (productConditionInput === "gently_used" || productConditionInput === "well_loved");
    /**
     * @Note - ConditionSection is updated every time the user types into the
     * Condition Notes InputField This prevents the modal from showing when that happens.
     */
    if (conditionNotesInput.length === 0) {
      setModalOpen(conditionWarningModalOpen);
    }
  }, [productConditionInput, conditionNotesInput, conditionWarning?.message]);

  return (
    <React.Fragment>
      <Grid item>
        <FormSection
          title="Condition"
          classesOverrides={{
            innerFormSection: {
              paddingTop: 0,
            },
          }}
        >
          <CustomFormikField
            fieldName={condition.name}
            InnerComponent={RadioGroup as React.ComponentType}
            options={conditionOptions}
          />
        </FormSection>
      </Grid>
      <Grid
        item
        className={classNames({
          [classes.hideConditionNotes]: shouldHideImageUploader(values),
        })}
      >
        <FormSection
          title="Condition notes"
          description="Document any flaws for a smooth selling process. And, upload some new photos, too."
          classesOverrides={{
            innerFormSection: {
              flexWrap: "nowrap",
            },
          }}
        >
          <InputField
            name={conditionNotes.name}
            label={conditionNotes.label}
            placeholder="This top has a small snag on the back..."
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            rows={4}
            inputProps={{
              "data-cy": conditionNotes.name,
            }}
          />
          <CustomFormikField
            fieldName={images.name}
            InnerComponent={ImageUploader}
            s3Props={s3Props}
            listing={listing}
          />
        </FormSection>
      </Grid>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        className={classes.modal}
        fullWidth
        maxWidth="lg"
      >
        <Fade in={modalOpen}>
          <Card className={classes.modalInner}>
            <DialogContent>
              <Typography variant="h6" className={classes.modalTitle}>
                {conditionWarning?.anchorText}
              </Typography>
            </DialogContent>
            <DialogContent>
              <p>{conditionWarning?.message}</p>
              {conditionWarning?.url ? (
                <p>
                  <a href={conditionWarning?.url} target="_blank" rel="noopener noreferrer">
                    {conditionWarning?.anchorText}
                  </a>
                </p>
              ) : null}
            </DialogContent>
          </Card>
        </Fade>
      </Dialog>
    </React.Fragment>
  );
}
