// Our CardPanel takes an array of children, a loading prop, and props that are used for pagination,
// and renders a collection of items with a "column" style. That is, if an array of "Card-like"
// items is passed-in, and the columnsByBreakpoint prop specifies 4 columns for the current
// device-width, each card will be rendered with their width set to 25%, minus some allowance for
// horizontal padding. This means that each item passed in (as children) needs to accept and apply a
// style prop for this to work (refer to the stories.tsx file for an example).
//
// TODO: this should definitely be done with CSS grid!
//
import classNames from "classnames";
import { findKey } from "lodash";
import React, { Children, ReactElement, ReactNode, useCallback, useRef } from "react";
import { MediaQueryObject, useMedia } from "react-media";
import LoadingIndicator from "../../elements/LoadingIndicator/LoadingIndicator";
import NoContent from "../../elements/NoContent/NoContent";
import PageSelection from "../PageSelection/PageSelection";
import css from "./CardPanel.module.css";

export interface Props {
  children: ReactNode | Array<ReactNode>;
  className?: string;
  currentPage: number;
  totalPages: number;
  location: string;
  pageParam: string;
  loading: boolean;
  columnsByBreakpoint: MediaQueryObject;
  hasFeaturedTopRow?: boolean;
  noContentText?: string;
}

// Items need to accept apply a "style" property.
function CardPanel({
  children,
  className,
  currentPage,
  totalPages,
  location,
  pageParam,
  loading,
  columnsByBreakpoint,
  hasFeaturedTopRow,
  noContentText,
}: Props) {
  const containerRef = useRef<null | HTMLDivElement>(null);

  const numberOfColumns = Number(findKey(useMedia({ queries: columnsByBreakpoint })) || 1);

  function cardStyle(columnCount) {
    return {
      margin: "10px",
      width: `calc(100% / ${columnCount} - 20px)`,
    };
  }

  // If we're "featuring" the top row, display top-row items as double-width. The number of
  // top-row items therefore will be equal to the regular column count (numberOfColumns) divided
  // by 2. We can only consider "featuring" items if the regular column count is >= 2.
  const featuredWidthFactor = 2;
  const showFeaturedTopRow = hasFeaturedTopRow && numberOfColumns >= featuredWidthFactor;
  const featuredColumnCount = numberOfColumns / featuredWidthFactor;

  const childrenWithColumnStyle = Children.map(children, (child, index) => {
    const itemIsFeatured = showFeaturedTopRow && index < featuredColumnCount;
    const columnCount = itemIsFeatured ? featuredColumnCount : numberOfColumns;

    return React.cloneElement(child as ReactElement, { style: cardStyle(columnCount) });
  });

  const content =
    loading || childrenWithColumnStyle.length > 0 ? (
      childrenWithColumnStyle
    ) : (
      <NoContent text={noContentText} />
    );

  const handlePageClick = useCallback(() => {
    const raf = requestAnimationFrame(() => {
      if (containerRef.current) {
        containerRef.current.scrollIntoView({ behavior: "smooth" });
      }
    });

    return () => cancelAnimationFrame(raf);
  }, []);

  return (
    <div className={classNames(css.container, className)} ref={containerRef}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <div className={css.panel}>{content}</div>
          <PageSelection
            className={css.responsivePadding}
            currentPage={currentPage}
            totalPages={totalPages}
            location={location}
            pageParam={pageParam}
            handleClick={handlePageClick}
          />
        </>
      )}
    </div>
  );
}

export default CardPanel;
