import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { FieldInputProps } from "formik";
import React from "react";

export type CheckboxGroupOptions = { value: string; label: string }[];

export interface Props {
  field: FieldInputProps<string>;
  options: CheckboxGroupOptions;
}

const CheckboxGroup = ({ field, options }: Props) => (
  <FormGroup>
    {options.map((option, i) => (
      <FormControlLabel
        key={i}
        control={
          <Checkbox
            name={field.name}
            value={option.value}
            onChange={field.onChange}
            checked={field.value.includes(option.value)}
            inputProps={{
              "data-cy": `checkbox-${field.name}-${option.value}`,
            }}
          />
        }
        label={option.label}
      />
    ))}
  </FormGroup>
);
export default CheckboxGroup;
