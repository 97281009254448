import { DocumentNode, useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  createStyles,
  Grid,
  LinearProgress,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import classNames from "classnames";
import { Form, Formik, FormikProps, FormikValues } from "formik";
import { range } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import {
  parseSearchQueryParams,
  removeSearchQueryParam,
  upsertSearchQueryParam,
} from "../../../utils/url";
import ListingCardHorizontal from "../ListingCardHorizontal/ListingCardHorizontal";
import { GetListingFormData_listing } from "./queries/types/GetListingFormData";
import { StepProps } from "./ResellForm/Steps/ConditionStep";

interface Props {
  title: string;
  subtitle?: string;
  centerTitle?: boolean;
  listing: GetListingFormData_listing;
  steps: React.ReactNode[];
  buildSubmitPayload: (values: FormikValues) => { [x: string]: any };
  mutation: DocumentNode;
  // TODO type onMutationComplete correctly
  onMutationComplete: (setErrors: any) => (data: any) => void;
  listingFormModel: { [x: string]: any };
  initialValues: FormikValues;
  validationSchema: any;
  submitButtonText: string;
  canEditProduct?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 0,
      width: "100%",
      [theme.breakpoints.up("lg")]: {
        maxWidth: 500,
      },
    },
    helloCard: {
      height: "100%",
      [theme.breakpoints.down("md")]: {
        backgroundColor: "#F7F7F7",
      },
    },
    helloCardItem: {
      backgroundColor: "white",
      width: "100%",
    },
    helloCardItemOff: {
      width: "100%",
      [theme.breakpoints.down("md")]: {
        backgroundColor: "#F7F7F7",
      },
    },
    title: {
      fontWeight: 600,
      paddingLeft: 16,
      paddingTop: 16,
    },
    subtitle: {
      paddingLeft: 16,
    },
    progress: {
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    barColor: {
      backgroundColor: "#FF9579",
    },
    barBackgroundColor: {
      backgroundColor: "#E0E0E0",
    },
    step: {
      [theme.breakpoints.down("lg")]: {
        width: "100%",
      },
      [theme.breakpoints.up("lg")]: {
        width: 700,
      },
      [theme.breakpoints.up("xl")]: {
        width: 1000,
      },
    },
    buttons: {
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.up("laptop")]: {
        width: 500,
      },
      [theme.breakpoints.up("lg")]: {
        width: 700,
      },
      [theme.breakpoints.up("xl")]: {
        width: 1000,
      },
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
    },
    formArea: {
      width: "100%",
      [theme.breakpoints.up("md")]: {
        paddingTop: "16px !important",
      },
    },
  })
);

export default function MultistepListingForm({
  title,
  subtitle,
  centerTitle = false,
  listing,
  steps,
  buildSubmitPayload,
  mutation,
  onMutationComplete,
  listingFormModel,
  initialValues,
  validationSchema,
  submitButtonText,
  canEditProduct = true,
}: Props) {
  const classes = useStyles();
  const formRef = useRef<FormikProps<StepProps["values"]>>();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const { formId, formField } = listingFormModel;

  const [errors, setErrors] = useState<string[]>(null);
  const setErrorsCb = (e: React.SetStateAction<string[]>) => {
    setErrors(e);
    formRef.current?.setSubmitting(false);
  };

  function updateStep(newStep: React.SetStateAction<number>, location: string) {
    // Some users have given feedback that when they go to the next step in the form they aren't automatically scrolled to the top of the new pag.
    window.scrollTo(0, 0);
    setActiveStep(newStep);
    // Do not touch history location unless we are making a change,
    // to avoid an infinite loop with the callback in history.listen
    if (currentLocationStep(location) != newStep) {
      if (newStep === 0) {
        history.push({
          search: removeSearchQueryParam(location.search, "step"),
        });
      } else {
        history.push({
          search: upsertSearchQueryParam(location.search, "step", newStep),
        });
      }
    }
  }

  function currentLocationStep(location: string) {
    const stepParam = parseSearchQueryParams(location.search).step;
    return stepParam ? parseInt(stepParam) : 0;
  }

  function adjustInitialStep(form, location) {
    var initialStep = currentLocationStep(location);
    // Initial step must be between 0 and the maximum number of steps
    initialStep = Math.max(0, initialStep);
    initialStep = Math.min(steps.length - 1, initialStep);
    // We cannot let the user skip over currently invalid form steps.
    // So we try to find the first step before the requested one that
    // is invalid...
    const firstInvalidStep = range(initialStep).find((i) => {
      return !validationSchema[i](form.values).isValidSync(form.values);
    });
    // ... and if none are found, it's ok to take the user to the requested one
    initialStep = firstInvalidStep ?? initialStep;
    updateStep(initialStep, location);
  }

  useEffect(() => {
    if (errors) {
      errors.forEach((error) => {
        (window as any).FlashMessage.error(`Sorry, that didn't work: ${error}`);
      });
    }
  }, [errors]);

  useEffect(() => {
    // Set the initial step when the component loads for the
    // first time, dealing with the case when the page is reloaded or
    // the form is accessed for the first time...
    if (formRef.current) {
      adjustInitialStep(formRef.current, history.location);
    }

    // ... and do the same after the user navigates via the
    // browser's back and forward buttons. The component doesn't re-render
    // when that happens, so we cannot rly just on the code above for this
    // scenario.
    return history.listen((location) => {
      adjustInitialStep(formRef.current, location);
    });
  }, [formRef]);

  useEffect(() => {}, [history.location.search]);

  const onCompleted = onMutationComplete(setErrorsCb);

  const [submitForm, _] = useMutation(mutation, {
    onCompleted: (data) => onCompleted(data),
  });

  const isLastStep = activeStep === steps.length - 1;

  function handleSubmit(
    values: FormikValues,
    actions: { setTouched: (arg0: {}) => void; setSubmitting: (arg0: boolean) => void }
  ) {
    if (isLastStep) {
      submitForm({
        variables: {
          input: { id: listing?.id, ...buildSubmitPayload(values) },
        },
      });
    } else {
      updateStep(activeStep + 1, history.location);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  const renderStepContent = (
    step: number,
    values: StepProps["values"],
    errors: StepProps["errors"],
    setFieldValue: StepProps["setFieldValue"],
    handleChange: StepProps["handleChange"],
    handleBlur: StepProps["handleBlur"],
    validationSchema: StepProps["validationSchema"]
  ) => {
    const Step = steps[step];
    return (
      <Box className={classes.step}>
        <Step
          formField={formField}
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          validationSchema={validationSchema}
        />
      </Box>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12} lg={4} className={classes.root}>
        <Card square className={classes.helloCard}>
          <Grid container alignItems="flex-start" spacing={1}>
            <Grid item xl={12} className={classes.helloCardItem}>
              <Typography
                variant="h6"
                className={classes.title}
                align={centerTitle ? "center" : "inherit"}
                data-cy="form-title"
              >
                {centerTitle ? title.toUpperCase() : title}
              </Typography>
              {subtitle && (
                <Typography variant="subtitle1" color="textPrimary" className={classes.subtitle}>
                  {subtitle}
                </Typography>
              )}
            </Grid>
            <Grid item xl={12} className={classNames(classes.progress, classes.helloCardItem)}>
              <LinearProgress
                variant="determinate"
                value={((activeStep + 1) / steps.length) * 100}
                classes={{
                  barColorPrimary: classes.barColor,
                  colorPrimary: classes.barBackgroundColor,
                }}
              />
            </Grid>
            <Grid item xl={12} className={classes.helloCardItemOff}>
              {listing && (
                <ListingCardHorizontal listing={listing} includePencilButton={canEditProduct} />
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item md={12} lg={8} className={classes.formArea}>
        <Formik
          initialValues={initialValues}
          validationSchema={() =>
            Yup.lazy((values) => {
              return validationSchema[activeStep](values);
            })
          }
          onSubmit={handleSubmit}
        >
          {(form: FormikProps<StepProps["values"]>) => {
            formRef.current = form;
            const { isSubmitting, values, errors, setFieldValue, handleChange, handleBlur } = form;

            return (
              <Form id={formId}>
                {renderStepContent(
                  activeStep,
                  values,
                  errors,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  validationSchema[activeStep]
                )}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button
                      onClick={() => {
                        return updateStep(activeStep - 1, history.location);
                      }}
                      className={classes.button}
                      data-cy="back-button"
                    >
                      Back
                    </Button>
                  )}
                  <div className={classes.wrapper}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      data-cy="submit-button"
                    >
                      {isLastStep ? submitButtonText : "Next"}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                        data-cy="loading"
                      />
                    )}
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
