import { AirRobeTheme, useTheme } from "@material-ui/core";
import { ClickTracking, SearchProvider, Variables } from "@sajari/react-search-ui";
import { SSRProvider } from "@sajari/react-components";
import { pickBy } from "lodash";
import React from "react";
import filters, { applyFilters } from "../filters";
import pipelineConfigClient from "./pipelineConfigClient";
import { getQueryParams, initFiltersFromURLState, initVariablesFromURLState } from "./queryParams";
import { ProviderPipelineConfig } from "@sajari/react-hooks/dist/ContextProvider/types";
import URLStateSync from "./URLStateSync";
import { Pipeline } from "@sajari/react-hooks";
import { SearchUIContextProviderValues } from "@sajari/react-search-ui/dist/ContextProvider/types";
import { SearchPageProps } from "../../components/pages/SearchPage/SearchPage";

export const eventTracking = new ClickTracking("id");
const clientPipeline = new Pipeline(pipelineConfigClient, "app", eventTracking);

export type SearchProviderProps = {
  initialResponse?: string;
  searchOnLoad?: boolean;
  search?: ProviderPipelineConfig;
  syncUrlState?: boolean;
};

type Props = SearchProviderProps & {
  children: React.ReactNode;
};

const SearchContext = ({ children, syncUrlState, ...props }: Props) => {
  const searchTheme = useSearchTheme();

  return (
    <SSRProvider>
      <SearchProvider
        customClassNames={searchClassNames}
        theme={searchTheme}
        search={{ pipeline: clientPipeline, filters }}
        {...props}
      >
        {syncUrlState && <URLStateSync delay={100} />}
        {children}
      </SearchProvider>
    </SSRProvider>
  );
};

export default SearchContext;

export const getSearchProviderPipelineConfig = (
  searchProps: SearchPageProps,
  searchString: string,
  pipeline = clientPipeline
): ProviderPipelineConfig => {
  const params = getQueryParams(searchString);
  const filter = applyFilters({ searchProps });

  const variables = new Variables(
    pickBy({
      q: params["q"],
      filter,
      resultsPerPage: 40,
      inputCenterLat: searchProps.latitude,
      inputCenterLng: searchProps.longitude,
      page: params["page"],
    })
  );

  initFiltersFromURLState({
    filters,
    params,
  });

  initVariablesFromURLState({
    variables,
    params,
    mappingKeys: [
      { paramKey: "show", variableKey: "resultsPerPage", defaultValue: "15" },
      { paramKey: "sort", variableKey: "sort" },
      { paramKey: "q", variableKey: "q" },
      { paramKey: "page", variableKey: "page" },
    ],
  });

  if (searchProps.isProfilePage) {
    variables.set({ sort: "profile_sort" });
  }

  if (searchProps?.conciergePage?.isConciergePage) {
    variables.set({ sort: "concierge_sort" });
  }

  return {
    pipeline,
    variables,
    filters,
  };
};

export type SearchClassNames = SearchUIContextProviderValues["customClassNames"];

export const SearchSortingClassNames: SearchClassNames["sorting"] = {
  select: "SearchSorting-select",
};

export const SearchInputClassNames: SearchClassNames["input"] = {
  inputContainer: "SearchInput-iconContainer",
  input: "SearchInput-input",
};

export const SearchFilterClassNames: SearchClassNames["filter"] = {
  list: {
    checkboxGroup: "SearchFilter-checkboxGroup",
  },
};

const searchClassNames: SearchClassNames = {
  sorting: SearchSortingClassNames,
  input: SearchInputClassNames,
  filter: SearchFilterClassNames,
};

const useSearchTheme = () => {
  const theme = useTheme<AirRobeTheme>();
  return {
    color: {
      primary: {
        base: theme.palette.brand.main,
        active: theme.palette.brand.main,
      },
    },
  };
};
