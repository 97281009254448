import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Box from "../../elements/v2/Box/Box";
import { Route, Switch } from "react-router-dom";
import { DELIVERY_OPTIONS, ProductDisplay } from "./components/ProductDisplay";
import { LoginForm } from "./components/LoginForm";
import styled from "styled-components";
import { breakpoints } from "../../../utils/styledHelpers";
import { createEntryPage } from "../FullPageLayout/FullPageLayout";
import { CHECKOUT_PATH } from "../../../utils/paths";
import SnackbarAlert from "../../composites/v2/SnackbarAlert/SnackbarAlert";
import { SignupForm } from "./components/SignupForm";
import { ConfirmEmail } from "./components/ConfirmEmail";
import { getCsrfHeaders } from "../../../utils/csrfHelper";
import GoogleSignUpButton from "../../composites/v2/GoogleSSO/GoogleSignUpButton";

interface Props {
  listing: {
    id: string;
    imageUrl: string;
    colour?: string;
    title: string;
    sizeGuide?: string;
    rrp: string;
    price: string;
    shippingPrice: string;
    total: string;
  };
  delivery?: DELIVERY_OPTIONS;
  startOn?: string;
  endOn?: string;
  returnTo: string;
  offeredPrice?: string;
}

const BASE_PATH = `*${CHECKOUT_PATH}/:listing_id/login`;
export const SIGNUP_PATH = `${BASE_PATH}/signup`;
export const CONFIRM_EMAIL_PATH = `${BASE_PATH}/confirm_email/:emailAddress`;

export const CheckoutLogin = ({
  listing,
  delivery,
  startOn,
  endOn,
  returnTo,
  offeredPrice,
}: Props) => {
  const history = useHistory();
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const handleOnAlertDismiss = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setIsAlertOpen(false);
  };

  const handleOnLoginPress = async (values) => {
    const formData = new FormData();
    formData.append("person[login]", values.email);
    formData.append("person[password]", values.password);

    try {
      await axios({
        method: "post",
        url: "/sessions",
        data: formData,
        headers: getCsrfHeaders(),
        withCredentials: true,
      });

      continueToTransaction();
    } catch (error) {
      console.log("ERROR", error.message);
      setIsAlertOpen(true);
    }
  };

  const handleOnSignupPress = async ({
    email,
    password,
    confirmPassword,
    givenName,
    familyName,
    yearOfBirth,
    terms,
    marketingOptIn,
  }) => {
    const formData = new FormData();
    formData.append("person[email]", email);
    formData.append("person[given_name]", givenName);
    formData.append("person[family_name]", familyName);
    formData.append("person[year_of_birth]", yearOfBirth);
    formData.append("person[password]", password);
    formData.append("person[password2]", confirmPassword);
    formData.append("person[terms]", terms ? "on" : "off");
    formData.append("person[admin_emails_consent]", marketingOptIn ? "on" : "off");

    try {
      await axios({
        method: "post",
        url: "/people",
        data: formData,
        headers: getCsrfHeaders(),
        withCredentials: true,
      });

      handleGoToConfirmEmail(email);
    } catch (error) {
      console.error(error.message);
      setIsAlertOpen(true);
    }
  };

  const handleGoToLogin = () => {
    history.push(`${CHECKOUT_PATH}/${listing.id}/login`);
  };

  const handleGoToSignup = () => {
    history.push(`${CHECKOUT_PATH}/${listing.id}/login/signup`);
  };

  const handleGoToConfirmEmail = (email) => {
    history.push(`${CHECKOUT_PATH}/${listing.id}/login/confirm_email/${email}`);
  };

  const handleOnCompleteSignup = () => {
    continueToTransaction();
  };

  const continueToTransaction = () => {
    // The controller doesn't seem to redirect properly the first time it hits the return to route
    // It appears to redirect to the search page. When trying the route again, it works that time.
    // Need to fix this asap. - Pius J
    window.location.href = returnTo;
  };

  return (
    <Route path={BASE_PATH}>
      <SnackbarAlert
        open={isAlertOpen}
        severity="error"
        onClose={handleOnAlertDismiss}
        content={"An error has occurred trying to login"}
      />
      <CheckoutContainer>
        <ProductContainer>
          <ProductDisplay
            imageUrl={listing.imageUrl}
            listingTitle={listing.title}
            listingColour={listing.colour}
            listingSize={listing.sizeGuide}
            retailPrice={listing.rrp}
            salePrice={listing.price}
            subTotal={listing.price}
            shippingCost={listing.shippingPrice}
            total={listing.total}
            delivery={delivery}
            startOn={startOn}
            endOn={endOn}
            offeredPrice={offeredPrice}
          />
        </ProductContainer>
        <LoginContainer>
          <GoogleSignUpButton px={4} mb={3} />
          <Switch>
            <Route exact path={BASE_PATH}>
              <LoginForm onLoginPress={handleOnLoginPress} onGoToSignup={handleGoToSignup} />
            </Route>
            <Route exact path={SIGNUP_PATH}>
              <SignupForm onSignupPress={handleOnSignupPress} onGoToLogin={handleGoToLogin} />
            </Route>
            <Route exact path={CONFIRM_EMAIL_PATH}>
              <ConfirmEmail onConfirmed={handleOnCompleteSignup} />
            </Route>
          </Switch>
        </LoginContainer>
      </CheckoutContainer>
    </Route>
  );
};

type Args = {
  checkoutLoginPage: Props;
  topbar: any;
};

const mapEntryProps = ({ topbar, checkoutLoginPage }: Args) => ({
  pageProps: { topbarProps: topbar },
  componentProps: checkoutLoginPage,
});

export default createEntryPage(mapEntryProps)(CheckoutLogin);

const CheckoutContainer = styled(Box)`
  flex-direction: row;
  display: flex;
  background-color: #f3f3f3;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 80px;

  ${breakpoints.down("sm")} {
    padding-top: 5px;
    flex-direction: column;
    height: auto;
  }
`;

const ProductContainer = styled(Box)`
  background-color: #ffffff;
  margin-right: 4px;
  padding-top: 24px;
  padding-bottom: 40px;
  width: 428px;

  ${breakpoints.down("sm")} {
    width: auto;
    margin-right: 0;
    padding-bottom: 32px;
    margin-bottom: 4px;
  }
`;

const LoginContainer = styled(Box)`
  background-color: #ffffff;
  margin-right: 4px;
  padding-top: 24px;
  padding-bottom: 40px;
  width: 428px;

  ${breakpoints.down("sm")} {
    width: auto;
    padding-bottom: 32px;
    margin-right: 0;
    margin-left: 0;
    margin-top: 4px;
  }
`;
