import { useMutation } from "@apollo/client";
import { Box, Button, Card, createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { RoutesContext } from "../../../utils/routes";
import ListingCard2 from "../ListingCardHorizontal/ListingCardHorizontal";
import DONATE_LISTING from "./mutations/donateListing";
import OffsetInfo from "./OffsetInfo/OffsetInfo";
import { GetListingFormData_listing } from "./queries/types/GetListingFormData";

interface Props {
  listing: GetListingFormData_listing;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "#E0E0E0",
    },
    helloCard: {
      padding: 16,
    },
    bold: {
      fontWeight: 600,
    },
    offsetList: {
      display: "flex",
      flexDirection: "column",
    },
    offsetItem: {
      display: "flex",
    },
    offsetText: {
      paddingLeft: 8,
    },
    button: {
      width: "80%",
    },
  })
);

export default function DonateListingForm({ listing }: Props) {
  const classes = useStyles();
  const history = useHistory();
  const routes = useContext(RoutesContext);
  const [donateListing, { data }] = useMutation(DONATE_LISTING, {
    onCompleted: (data) => {
      if (data.donateListing.validationErrors.length > 0) {
        setErrors(
          data.donateListing.validationErrors.map((e) => `${e.attribute} - ${e.error_message}`)
        );
      } else {
        history.push(routes.donate_success_listing_path(data.donateListing.listing.id));
      }
    },
  });

  const [errors, setErrors] = useState<string[]>(null);
  useEffect(() => {
    if (errors) {
      errors.forEach((error) => {
        (window as any).FlashMessage.error(`Sorry, that didn't work: ${error}`);
      });
    }
  }, [errors]);

  return (
    <Card square className={classes.root}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Card square className={classes.helloCard} style={{ width: "100%" }}>
          <Typography variant="h6" className={classes.bold} align="center">
            RECYCLE AN ITEM
          </Typography>
        </Card>
        <Box style={{ maxWidth: 1000 }}>
          <Grid container spacing={4} direction="column">
            <Grid item>
              <ListingCard2 listing={listing} />
            </Grid>
            <Grid item>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <Typography
                    variant="h6"
                    gutterBottom
                    color="textPrimary"
                    className={classes.bold}
                    align="center"
                  >
                    YOU ARE ABOUT TO RECYCLE THIS ITEM
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="subtitle1" align="center">
                    Thank you for choosing to recycle and doing your part in reducing the amount of
                    textile landfill.
                    <br />
                    When you choose to recycle with AirRobe, we will ensure your items are either
                    reused, upcycled or recycled.
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="subtitle1" align="center">
                    Depending on how many items you choose to recycle, there may be a small cost
                    associated to send and process your goods.
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="subtitle1" align="center">
                    By recycling this item, you will have offset:
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <OffsetInfo />
            </Grid>
            <Grid item>
              <Grid container spacing={2} direction="column" alignItems="center">
                <Grid item className={classes.button}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() =>
                      donateListing({
                        variables: {
                          input: { id: listing.id },
                        },
                      })
                    }
                    data-cy="donate-button"
                  >
                    Yes, recycle
                  </Button>
                </Grid>
                <Grid item className={classes.button}>
                  <Button variant="outlined" fullWidth onClick={() => history.goBack()}>
                    No, go back
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  );
}
