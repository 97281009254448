import styled from "styled-components";
import { P } from "../../../elements/v2/Typography/Typography";

export default styled(P)`
  color: #222;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 1.04px;
  margin-bottom: 24px;
`;
