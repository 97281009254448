import styled from "styled-components";
import { Radio } from "@material-ui/core";

export const RadioButton = styled(Radio).attrs({
  color: "primary",
  disableRipple: true,
})`
  padding: 10px;
  width: 40px;
  height: 40px;
`;
