import { gql } from "@apollo/client";

const UPSERT_LISTING = gql`
  mutation UpsertListing($input: UpsertListingMutationInput!) {
    upsertListing(input: $input) {
      validationErrors {
        attribute
        errorMessage
      }
      listing {
        id
      }
      redirectTo
    }
  }
`;

export default UPSERT_LISTING;
