import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import FlexBox from "../../../elements/v2/Box/FlexBox";
import { ErrorMessage } from "../../FormControls/TextField/TextField";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import MenuItem from "../Menu/MenuItem";
import ClearIcon from "@material-ui/icons/Clear";
import Box from "../../../elements/v2/Box/Box";
import { uniqBy } from "lodash";
import fuzzySort from "../../../../utils/search/fuzzySort";

export type PlaceResult = google.maps.places.PlaceResult;

const NUMBER_OF_PREDICTIONS = 5;

interface Props {
  apiKey: string;
  id?: string;
  name?: string;
  label: string;
  value: string;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
  onPlaceSelection: (placeResult: PlaceResult) => void;
  error?: boolean;
  errorMessage?: string;
}

export default React.memo(
  ({
    label,
    apiKey,
    id,
    value,
    error,
    errorMessage,
    onChange,
    onBlur,
    onPlaceSelection,
    ...inputProps
  }: Props) => {
    const {
      placesService,
      placePredictions,
      getPlacePredictions,
      isPlacePredictionsLoading,
    } = usePlacesService({
      apiKey: apiKey,
      debounce: 300,
      language: "en",
    });
    const [isFloating, setIsFloating] = useState(false);
    const [focused, setFocused] = useState(false);
    const [predictions, setPredictions] = useState<PlaceResult[]>([]);
    const [showPredictionsMenu, setShowPredictionsMenu] = useState(false);

    const inputRef = useRef(null);

    const isAvailable = (func?: any) => {
      return typeof func !== "undefined";
    };

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        isAvailable(onChange) && onChange(event);
        getPlacePredictions({ input: event.target.value });
      },
      [onChange, getPlacePredictions]
    );

    const handleBlur = useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        isAvailable(onBlur) && onBlur(event);
        event.target.value === "" && setIsFloating(false);
        setFocused(false);
        setTimeout(() => setShowPredictionsMenu(false), 200);
      },
      [onBlur]
    );

    const handleFocus = useCallback(() => {
      setIsFloating(true);
      setFocused(true);
    }, []);

    const handleLabelClick = useCallback((e) => {
      e.preventDefault();
      inputRef.current?.focus();
    }, []);

    const handleItemClick = useCallback(
      (prediction: PlaceResult) => {
        onPlaceSelection(prediction);
      },
      [onPlaceSelection]
    );

    useEffect(() => {
      value !== "" && setIsFloating(true);
    }, [value]);

    useEffect(() => {
      if (placePredictions.length > 0) {
        for (let i = 0; i < NUMBER_OF_PREDICTIONS && placePredictions[i] !== undefined; i++)
          placesService?.getDetails(
            {
              placeId: placePredictions[i].place_id,
            },
            (placeDetails: PlaceResult) => {
              const sortedPredictions = fuzzySort([...predictions, placeDetails], value, [
                "formatted_address",
              ]);

              setPredictions(
                uniqBy(sortedPredictions, "formatted_address").slice(0, NUMBER_OF_PREDICTIONS)
              );

              if (!showPredictionsMenu) setShowPredictionsMenu(true);
            }
          );
      }
    }, [JSON.stringify(placePredictions)]);

    return (
      <Box>
        <Container $focused={focused}>
          <Label $isFloating={isFloating} htmlFor={id} onClick={handleLabelClick}>
            {label || ""}
          </Label>
          <Input
            {...inputProps}
            ref={inputRef}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={""}
            value={value}
            onChange={handleChange}
          />
        </Container>
        {!isPlacePredictionsLoading && value !== "" && showPredictionsMenu && (
          <PredictionsMenu>
            <FlexBox alignItems="center" p={1}>
              <EngineText>Suggestions powered by </EngineText>
              <GoogleText>Google</GoogleText>
              <CloseMenu onClick={() => setShowPredictionsMenu(false)} data-testid="close" />
            </FlexBox>
            {predictions.map((prediction, i) =>
              prediction ? (
                <PredicationItem
                  key={i}
                  onClick={() => handleItemClick(prediction)}
                  formattedAddress={prediction.formatted_address}
                  value={value.split(",")[0]}
                />
              ) : null
            )}
          </PredictionsMenu>
        )}
        {error && errorMessage && (
          <FlexBox flexDirection="row" mt={0.5}>
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </FlexBox>
        )}
      </Box>
    );
  }
);

const Container = styled(FlexBox)<{ $focused: boolean }>`
  width: 100%;
  flex-direction: column;
  height: 57px;
  border-radius: 2px;
  border: 1px solid #ccc;
  padding-top: 24.5px;
  padding-bottom: 10.5px;
  padding-left: 9px;
  outline: 0;
  box-shadow: none;
  cursor: text;
  ${({ $focused }) =>
    $focused &&
    `
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(210, 96%, 45%, 25%);
    border-color: hsla(210, 96%, 45%, 50%);
  `}}
`;
const Label = styled.label<{ $isFloating: boolean }>`
  rgba(0, 0, 0, 0.54);
  position: absolute;
  color: #767676;
  font-family: Arial, sans-serif;
  font-size: 16px;
  transform: translate(3px, -4px) scale(1);
  ${({ $isFloating }) =>
    $isFloating &&
    `
    transform: translate(3px, -15px) scale(0.62);
  `}
  transition: all ease-in-out 200ms;
  transform-origin: top left;
  cursor: text;
`;
const Input = styled.input`
  width: 100%;
  border: none;
  font-family: Arial, sans-serif;
  font-size: 16px;
  rgba(0, 0, 0, 0.87);
  :focus {
    outline: none;
  }
`;

const Span = styled.span`
  font-family: inherit;
  font-size: 15px;
  font-weight: 400;
  color: #666;
  letter-spacing: 0.4px;
`;

const BoldSpan = styled(Span)`
  font-weight: 700;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
`;

const PredictionsMenu = styled(FlexBox)`
  flex-direction: column;
  background-color: white;
  position: absolute;
  display: flex;
  max-width: 350px;
  width: 88vw;
  min-width: 280px;
  box-shadow: 0 3px 5px #ccc;
  z-index: 2;
`;

const CloseMenu = styled(ClearIcon)`
  position: relative;
  margin-left: auto;
  margin-right: 0;
  height: 16px;
  cursor: pointer;
  color: #666;
`;

interface PredictionItemProps {
  formattedAddress: string;
  onClick: any;
  value: string;
}

const PredicationItem = ({ formattedAddress, onClick, value }: PredictionItemProps) => {
  return (
    <Item onClick={onClick}>
      {/*First matching address section will be bold*/}
      {formattedAddress
        .split(",")
        .map((part, i) =>
          part.toLowerCase().includes(value.toLowerCase()) && i === 0 ? (
            <BoldSpan key={i}>{part}&nbsp;</BoldSpan>
          ) : (
            <Span key={i}>{part}&nbsp;</Span>
          )
        )}
    </Item>
  );
};

const Item = styled(MenuItem)`
  font-family: Arial, sans-serif;
  background-color: white;
  min-height: 30px;
  max-height: 70px;
  width: 100%;
  text-transform: none;
  flex-wrap: wrap;
  line-height: 18px;
  padding-left: 10px;
  padding-right: 10px;
`;

const EngineText = styled.span`
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-left: 3px;
  color: #888;
`;

const GoogleText = styled(EngineText)`
  font-family: Product Sans, sans-serif;
  color: #666;
`;
