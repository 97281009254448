import * as Yup from "yup";
import listingFormModel from "./listingFormModel";
const {
  formField: {
    condition,
    conditionNotes,
    currency,
    price,
    includeShippingFee,
    includeDomesticShippingFee,
    includeInternationalShippingFee,
    shippingFee,
    shippingFeeInternational,
    rentalPeriod,
    dryCleaning,
    tryOnAvailability,
    images,
    deliveryMethods,
    location,
  },
} = listingFormModel;

const form = (minimumPrice) => [
  (values) =>
    Yup.object().shape({
      [condition.name]: Yup.string().nullable().required(`${condition.requiredErrorMsg}`),
      [conditionNotes.name]: Yup.string().when(condition.name, {
        is: (val) => val === "gently_used" || val == "well_loved",
        then: Yup.string().required(`${conditionNotes.requiredErrorMsg}`),
      }),
      [currency.name]: Yup.string().required(`${currency.requiredErrorMsg}`),
      [images.name]: Yup.array().when(condition.name, {
        is: (val) => val === "gently_used" || val == "well_loved",
        then: Yup.array()
          .min(1, images.requiredErrorMsg)
          .test(
            "still-uploading",
            images.imageUploadingMsg,
            (val) =>
              // valid if no image is still uploading and does not have a listing image id
              !val.some((image) => !image.doneUploading && !image.listingImageId)
          ),
      }),
      [price.name]: Yup.number()
        .required(`${price.requiredErrorMsg}`)
        .typeError("Price is invalid")
        .test("valid", `${price.minAmountErrorMsg(minimumPrice)}`, (val) => {
          if (val) {
            return val >= minimumPrice;
          }
          return false;
        }),
      [rentalPeriod.name]: Yup.string().required(rentalPeriod.requiredErrorMsg),
      [includeShippingFee.name]: Yup.boolean().required(),
      [includeDomesticShippingFee.name]: Yup.boolean().required(),
      [includeInternationalShippingFee.name]: Yup.boolean().required(),
      [shippingFee.name]: Yup.number()
        .transform((value, originalValue) =>
          // This transform is necessary as Material ui doesn't like
          // us setting null as value for input, and yup doesn't like
          // empty string as value for a number.
          String(originalValue).trim() === "" ? null : value
        )
        .nullable()
        .notRequired()
        .when(includeDomesticShippingFee.name, {
          is: (val) => {
            return !val;
          },
          then: Yup.number()
            .required(shippingFee.requiredErrorMsg)
            .typeError(shippingFee.invalidErrorMsg)
            .test("valid", shippingFee.invalidErrorMsg, (val) => {
              if (val) {
                return val > 0;
              }
              return false;
            }),
        }),
      [shippingFeeInternational.name]: Yup.number()
        .transform((value, originalValue) =>
          // This transform is necessary as Material ui doesn't like
          // us setting null as value for input, and yup doesn't like
          // empty string as value for a number.
          String(originalValue).trim() === "" ? null : value
        )
        .nullable()
        .notRequired()
        .when(includeInternationalShippingFee.name, {
          is: (val) => {
            return !val;
          },
          then: Yup.number()
            .required(shippingFeeInternational.requiredErrorMsg)
            .typeError(shippingFeeInternational.invalidErrorMsg)
            .test("valid", shippingFeeInternational.invalidErrorMsg, (val) => {
              if (val) {
                return val > 0;
              }
              return false;
            }),
        }),
      [dryCleaning.name]: Yup.string()
        .required(dryCleaning.requiredErrorMsg)
        .typeError(dryCleaning.requiredErrorMsg),
      [tryOnAvailability.name]: Yup.string()
        .required(tryOnAvailability.requiredErrorMsg)
        .typeError(tryOnAvailability.requiredErrorMsg),
    }),
  (values) =>
    Yup.object().shape({
      [deliveryMethods.name]: Yup.array().min(1, deliveryMethods.requiredErrorMsg),
      [location.name]: Yup.object().test((val) => !!val && !!val.address && val.address !== ""),
    }),
];

export default form;
