import React from "react";
import StatusRow from "../components/StatusRow";
import { GetTransaction_me, GetTransaction_transaction } from "../queries/types/GetTransaction";
import { P } from "../../../elements/v2/Typography/Typography";
import { TransactionState } from "../../../../types/graphql-global-types";

export interface Props {
  me: GetTransaction_me;
  transaction: GetTransaction_transaction;
}

function Rejected({ me, transaction }: Props) {
  const iAmSeller = transaction.seller.id === me.id;
  const iAmBuyer = transaction.buyer.id === me.id;

  return (
    <>
      <StatusRow iconName="ss-delete">Rejected</StatusRow>
      {iAmSeller && transaction.currentState === TransactionState.rejected && (
        <P style={{ hyphens: "none" }}>
          Please advise the buyer below why you are unable to complete the sale.
        </P>
      )}
    </>
  );
}

export default Rejected;
