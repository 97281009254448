import { gql } from "@apollo/client";

const DELETE_LISTING_IMAGE = gql`
  mutation DeleteListingImage($input: DeleteListingImageMutationInput!) {
    deleteListingImage(input: $input) {
      id
      error
    }
  }
`;

export default DELETE_LISTING_IMAGE;
