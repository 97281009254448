import React from "react";
import { BUTTON_SIZES, OutlineButton, TextButton } from "../../../elements/v2/Button/Button";
import styled from "styled-components";
import HeroImageDesktop1 from "../../../../assets/images/discovery/hero_banners/hero_desktop_1.lqip.png";
import HeroImageDesktop2 from "../../../../assets/images/discovery/hero_banners/hero_desktop_2.lqip.png";
import HeroImageDesktop3 from "../../../../assets/images/discovery/hero_banners/hero_desktop_3.lqip.jpg";
import Box from "../../../elements/v2/Box/Box";
import FlexBox from "../../../elements/v2/Box/FlexBox";
import { breakpoints } from "../../../../utils/styledHelpers";
import { Container, Mid, Subtitle, Title } from "../components/Section";
import moment from "moment";

const heroImages = [HeroImageDesktop1, HeroImageDesktop3, HeroImageDesktop2];

function getBackgroundImage() {
  // rotate every 2 weeks
  const weekIndex = Math.round(moment().week() / 2) % heroImages.length;
  const image = heroImages[weekIndex];

  return `
    linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
    url(${image.src}), url(${image.preSrc})
  `;
}

const anchorLinks: { href: string; t: string }[] = [
  { href: "#shop_by_brand", t: "web.discovery.sections.shop_by_brand.anchor_name" },
  { href: "#shop_by_new", t: "web.discovery.sections.shop_new_arrivals.anchor_name" },
  { href: "#shop_by_category", t: "web.discovery.sections.shop_by_category.anchor_name" },
];

export default function BannerSection() {
  return (
    <HeroSection>
      <Mid>
        <Box mb={{ xs: 2, md: 4 }}>
          <HeroTitle tHtml="web.discovery.title_html" $inverse />
        </Box>
        <HeroSubtitle t="web.discovery.subtitle" $inverse />
        <FlexBox mt={4} $gap={2}>
          <OutlineButton
            $inverse
            size={BUTTON_SIZES.LARGE}
            href="/s"
            t="web.discovery.actions.shop_all"
          />
        </FlexBox>
        <AnchorsBox mb={10}>
          {anchorLinks.map(({ href, t }) => (
            <TextButton key={href} $inverse href={href} t={t} />
          ))}
        </AnchorsBox>
      </Mid>
    </HeroSection>
  );
}

const HeroSection = styled(Container)`
  background-image: ${getBackgroundImage()};
  background-size: auto 100%;
  background-position: calc(100vw - 50%);
  height: 730px;
  min-height: 355px;
  ${breakpoints.down("tablet")} {
    max-height: 495px;
  }
`;

const HeroTitle = styled(Title)`
  font-size: 60px;
  line-height: 1.25;
  font-weight: 400;
  letter-spacing: -0.02em;
  white-space: pre-wrap;
  text-transform: none;
  i {
    display: inline-block;
    transform: skew(-6deg, 0);
  }
  ${breakpoints.down("tablet")} {
    font-size: 44px;
  }
  ${breakpoints.down(350)} {
    font-size: 36px;
  }
`;

const HeroSubtitle = styled(Subtitle)`
  font-size: 24px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.1em;
  text-transform: none;
  ${breakpoints.up("sm")} {
    white-space: pre-wrap;
  }
  ${breakpoints.down("tablet")} {
    font-size: 20px;
  }
`;

const AnchorsBox = styled(FlexBox)`
  position: absolute;
  bottom: 0;
  gap: 32px;
  ${breakpoints.down("tablet")} {
    display: none;
  }
`;
