// On page load, look at the flash notifications element and use the data to render flash messages
onDocReady(function () {
  const flash = document.body.dataset.flash;

  if (!flash) return;

  const flashMessages = JSON.parse(flash);

  Object.keys(flashMessages).forEach(function (flashKey) {
    var flashFunction =
      window.FlashMessage[flashKey] || window.FlashMessage.info;
    var flashMessage = flashMessages[flashKey];
    if (flashMessage && flashMessage.length > 0) {
      flashFunction(flashMessage);
    }
  });
});

function onDocReady(callback) {
  if (["complete", "interactive"].indexOf(document.readyState) > -1) {
    callback();
  } else {
    document.addEventListener("DOMContentLoaded", callback);
  }
}
