import styled from "styled-components";
import React, { ReactNode } from "react";
import { TextButton } from "../../../elements/v2/Button/Button";
import Box, { BoxProps } from "../../../elements/v2/Box/Box";
import { H1, H4 } from "../../../elements/v2/Typography/Heading";
import FlexBox from "../../../elements/v2/Box/FlexBox";

export type LinkProps = {
  tid: string;
  href: string;
};

export type MidProps = BoxProps;

export type SectionProps = {
  id: string;
  title: string;
  subtitle: string;
  midProps?: MidProps;
  links?: LinkProps[];
  children?: ReactNode;
};

export default ({
  id,
  title,
  subtitle,
  midProps,
  links = [],
  children,
  ...props
}: SectionProps) => (
  <Container id={id} {...props}>
    <Mid {...midProps}>
      <Title text={title} />
      <FlexBox
        my={1}
        breakpoints={{
          xs: "flex-direction: column; gap: 24px;",
          sm: "flex-direction: row; gap: 40px;",
        }}
      >
        <Box style={{ flexGrow: 1 }}>
          <Subtitle text={subtitle} />
        </Box>
        <Box />
        {links.map((link) => (
          <TextButton key={link.tid} href={link.href} t={`${link.tid}`} />
        ))}
      </FlexBox>
      {children}
    </Mid>
  </Container>
);

export const Container = styled(Box)`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Mid = styled(Box).attrs({
  mx: { xs: 3, sm: "5%" },
  mt: { xs: 8, sm: 10 },
  mb: { xs: 3, sm: 4 },
})`
  width: 100%;
  max-width: 1152px;
`;

export const Title = styled(H1)`
  font-size: 30px;
  line-height: 38px;
`;

export const Subtitle = styled(H4)`
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.05em;
`;
