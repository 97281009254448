import React from "react";
import styled from "styled-components";
import { GetListing_listing } from "../../queries/types/GetListing";
import Box, { BoxProps } from "../../../../elements/v2/Box/Box";
import Typography from "../../../../elements/v2/Typography/Typography";
import ProductHeading from "../ProductHeading/ProductHeading";
import { formatMoney } from "../../../../../utils/numbers";
import { DeliveryMethod } from "../../../../../types/graphql-global-types";
import FlexBox from "../../../../elements/v2/Box/FlexBox";
import { RadioButton } from "../../../../elements/v2/Form/RadioButton";
import { Svg } from "../../../../elements/Svg/Svg";
import LocationIcon from "../../../../../assets/icons/location.svg";
import ListingLocationMap from "../ListingLocationMap/ListingLocationMap";

type Props = BoxProps & {
  listing?: GetListing_listing;
  selectedDeliveryMethod: DeliveryMethod;
  onSelectDeliveryMethod: (DeliveryMethod) => void;
  googleMapsApiKey: string;
};

export default function SelectDeliveryMethod({
  listing,
  selectedDeliveryMethod,
  onSelectDeliveryMethod,
  googleMapsApiKey,
  ...props
}: Props) {
  const { deliveryMethods, applicableShippingCharge, sold } = listing;

  const { price: shippingFee, international } = applicableShippingCharge || {};

  if (sold) return null;

  const selectable = deliveryMethods.length > 1;
  const hasShipping = deliveryMethods.includes(DeliveryMethod.shipping);
  const hasPickup = deliveryMethods.includes(DeliveryMethod.pickup);
  const showLocationMap = selectedDeliveryMethod === DeliveryMethod.pickup;
  const isNone = selectedDeliveryMethod === DeliveryMethod.none;

  if (!hasShipping && !hasPickup) return null;

  const address = listing.location?.localityAddress;

  const handleClickDeliveryOption = (deliveryMethod: DeliveryMethod) => {
    if (selectable) {
      onSelectDeliveryMethod(deliveryMethod);
    }
  };

  return (
    <Box {...props}>
      <ProductHeading>Delivery Method</ProductHeading>
      {!isNone && (
        <input
          hidden={true}
          id="delivery"
          name="delivery"
          value={selectedDeliveryMethod}
          readOnly={true}
        />
      )}
      {hasShipping && (
        <DeliveryOption
          mt={1}
          $selectable={selectable}
          onClick={() => handleClickDeliveryOption(DeliveryMethod.shipping)}
        >
          <FlexBox alignItems="center" width="100%">
            {selectable && (
              <RadioButton
                checked={selectedDeliveryMethod === DeliveryMethod.shipping}
                value={DeliveryMethod.shipping}
              />
            )}
            <Content>
              {international ? "International " : ""}Shipping:&nbsp;
              {shippingFee?.fractional > 0 ? `Add ${formatMoney(shippingFee)}` : "Free"}
            </Content>
          </FlexBox>
        </DeliveryOption>
      )}
      {hasPickup && (
        <DeliveryOption
          mt={1}
          $selectable={selectable}
          onClick={() => handleClickDeliveryOption(DeliveryMethod.pickup)}
        >
          <FlexBox justifyContent="space-between" alignItems="center" width="100%">
            <FlexBox>
              {selectable && (
                <RadioButton
                  checked={selectedDeliveryMethod === DeliveryMethod.pickup}
                  value={DeliveryMethod.pickup}
                />
              )}
              <Content>Pickup{address && `: ${address}`}</Content>
            </FlexBox>
            <IconContainer>
              <Svg content={LocationIcon} />
            </IconContainer>
          </FlexBox>

          <Map listing={listing} $hide={!showLocationMap} googleMapsApiKey={googleMapsApiKey} />
        </DeliveryOption>
      )}
    </Box>
  );
}

const Content = styled(Typography)`
  max-width: calc(100% - 40px);
  display: inline-flex;
  gap: 5px;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #222222;
`;

const DeliveryOption = styled(Box)<{ $selectable?: boolean; onClick: () => void }>`
  cursor: pointer;
  padding: 8px 0;
  ${({ $selectable }) =>
    $selectable &&
    `
    border: 1px solid #E6E8EC;
    border-radius: 3px;
  `}
`;

const IconContainer = styled(Box)`
  padding: 5px;
  line-height: 0;
`;

const Map = styled(ListingLocationMap)<{ $hide?: boolean }>`
  height: 300px;
  padding: 8px;
  ${({ $hide }) =>
    $hide &&
    `
    display: none;
  `}
`;
