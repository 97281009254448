import { without } from "lodash";
import React, { useEffect, useState } from "react";
import { GetCountryPickerDropdownProps_countries } from "../CountryPickerDropdown/queries/types/GetCountryPickerDropdownProps";
import Country from "./components/Country";
import CountrySearchBar from "./components/CountrySearchBar";
import css from "./CountryPicker.module.css";
import { filterCountries } from "./utils/search";

type CountryType = GetCountryPickerDropdownProps_countries;

export interface Props {
  countries: Array<CountryType>;
  selectedCountry: CountryType;
  handleCountryClick: (country: CountryType) => void;
}

export default function CountryPicker({ countries, selectedCountry, handleCountryClick }: Props) {
  // Keep track of the search query here, uppercased, and debounced. We also keep the query in state
  // in our search component, but that is not debounced - we do this to make sure everything is nice
  // and responsive.
  const [searchQuery, setSearchQuery] = useState("");
  const [countriesToDisplay, setCountriesToDisplay] = useState([]);

  const handleSearch = (query: string) => setSearchQuery(query.toUpperCase());

  // Display a list of countries that match the query, minus the selected country (as it's already
  // shown at the top)
  useEffect(() => {
    const filteredCountries = filterCountries(countries, searchQuery);
    setCountriesToDisplay(without(filteredCountries, selectedCountry));
  }, [selectedCountry, searchQuery]);

  return (
    <div className={css.root}>
      <div className={css.header}>Choose your country / currency</div>

      <div className={css.searchBarWrapper}>
        <CountrySearchBar handleSubmit={handleSearch} placeholder="E.g. Australia, France, US" />
      </div>

      <div className={css.countries}>
        {selectedCountry ? (
          <div className={css.selectedCountry}>
            <Country selected country={selectedCountry} />
          </div>
        ) : null}
        {countriesToDisplay.map((country) => (
          <Country
            key={country.code}
            country={country}
            handleClick={() => handleCountryClick(country)}
          />
        ))}
      </div>
    </div>
  );
}
