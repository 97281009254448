import React, { useEffect, useState } from "react";
import AvatarMenu from "./AvatarMenu";
import LoginLinks from "./LoginLinks";
import Nav from "./Nav";
import SearchBar from "../../composites/SearchBar/SearchBar";
import Shortcuts from "./Shortcuts";
import Logo, { LogoProps } from "../../elements/Logo/Logo";
import CountryPickerDropdown, {
  CountryPickerDropdownProps,
} from "../../widgets/CountryPickerDropdown/CountryPickerDropdown";
import { initializeLocale, InitializeLocaleProps } from "../../../utils/locale";
import styled from "styled-components";
import Box from "../../elements/v2/Box/Box";
import { breakpoints } from "../../../utils/styledHelpers";
import FlexBox from "../../elements/v2/Box/FlexBox";
import { SITE_FOR_BRANDS_URL } from "../../../utils/paths";
import { A } from "../../elements/v2/Typography/Typography";
import { TopbarUserProps } from "./types";
import MobileMenu from "./MobileMenu/MobileMenu";
import ssrComponent from "../../../utils/ssr";
import { flowRight } from "lodash";
import { wrapWithRorpContext } from "../../../contexts/RorpContext";
import wrapWithStylesProvider from "../../../utils/wrapWithStylesProvider";
import { wrapWithThemeProvider } from "../../../utils/themeProvider";
import { wrapWithApollo } from "../../../graphql/client";
import SearchContext from "../../../utils/search/SearchContext";
import ImportGmailOrders, { ImportGmailOrdersProps } from "../ImportGmailOrders/ImportGmailOrders";
import GmailConnectModal from "../Modals/GmailConnectModal";
import { useMobileMediaQuery } from "../../../utils/useMediaQueries";
import SignupCampaign from "../Modals/SignupCampaign";

export type TopbarProps = {
  logo: LogoProps;
  user?: TopbarUserProps;
  countryPickerDropdown?: CountryPickerDropdownProps;
  isAirrobeNativeView?: boolean;
  i18n?: InitializeLocaleProps;
  importGmailOrdersProps: ImportGmailOrdersProps & {
    showGmailImportBanner: boolean;
    showGmailImportModal: boolean;
  };
};

export default function Topbar({
  user = {},
  logo,
  countryPickerDropdown,
  importGmailOrdersProps,
}: TopbarProps) {
  const { loggedInUsername } = user;
  const { isMobile } = useMobileMediaQuery({ breakpoint: 480 });
  const [showSignUp, setShowSignUp] = useState(false);
  const [href, setHref] = useState("");

  const openSignupModal = () => setShowSignUp(true);
  const closeSignupModal = () => setShowSignUp(false);

  useEffect(() => {
    setHref(window.location.href);
  }, []);

  return (
    <>
      {!loggedInUsername && !href.includes("login") && (
        <SignupCampaign clickOpened={showSignUp} onClose={closeSignupModal} />
      )}
      <TopbarContainer>
        {!isMobile && (
          <HeaderLevelOne>
            <HeaderColumn>
              <SiteTab $active t="web.topbar.site.shop" />
              <SiteTab href={SITE_FOR_BRANDS_URL} t="web.topbar.site.marketing" />
            </HeaderColumn>
            <HeaderColumn>
              <CountryPickerDropdown {...countryPickerDropdown} />
              {loggedInUsername ? (
                <AvatarMenu user={user} />
              ) : (
                <LoginLinks compact={false} openSignupModal={openSignupModal} />
              )}
            </HeaderColumn>
          </HeaderLevelOne>
        )}
        <HeaderLevelTwo>
          <HeaderColumn>
            <MobileMenu user={user} />
            <StyledLogo {...logo} />
          </HeaderColumn>
          {!isMobile && (
            <HeaderColumn>
              <Nav username={loggedInUsername} />
            </HeaderColumn>
          )}
          <SearchHeaderColumn>
            <Shortcuts user={user} />
            <SearchBar />
          </SearchHeaderColumn>
          {isMobile && (
            <HeaderColumn>
              {loggedInUsername ? (
                <AvatarMenu user={user} />
              ) : (
                <LoginLinks openSignupModal={openSignupModal} />
              )}
              <CountryPickerDropdown {...countryPickerDropdown} />
            </HeaderColumn>
          )}
        </HeaderLevelTwo>
      </TopbarContainer>
      {importGmailOrdersProps.showGmailImportBanner && (
        <ImportGmailOrders {...importGmailOrdersProps} />
      )}
      {importGmailOrdersProps.showGmailImportModal && (
        <GmailConnectModal {...importGmailOrdersProps} />
      )}
    </>
  );
}

export const TopbarApp = (props, context) => {
  initializeLocale(props.i18n);

  return ssrComponent(
    flowRight(
      wrapWithRorpContext(context),
      wrapWithStylesProvider,
      wrapWithThemeProvider,
      wrapWithApollo
    )(() => (
      <SearchContext>
        <Topbar {...props} />
      </SearchContext>
    ))
  );
};

const TopbarContainer = styled(Box).attrs({ className: "Topbar" })`
  position: relative;
  z-index: 1;
  background: white;
  box-shadow: 0 0 2px rgb(0 0 0 / 25%);
  font-size: 14px;
`;

const HeaderColumn = styled(FlexBox)`
  justify-content: space-between;
  align-items: center;
  height: 100%;
  gap: 16px;
`;

const HeaderLevelOne = styled(FlexBox)`
  justify-content: space-between;
  padding: 0 16px;
  border-bottom: 1px solid #e3e3e3;
  height: 50px;
  z-index: 1;

  ${breakpoints.up("sm")} {
    height: 50px;
    padding: 0 24px;
    > :first-child {
      gap: 32px;
    }
    > :last-child {
      gap: 16px;
    }
  }

  ${breakpoints.up("lg")} {
    height: 60px;
  }
`;

const HeaderLevelTwo = styled(FlexBox)`
  justify-content: space-between;
  height: 72px;
  padding: 0 16px 0 0;
  z-index: 0;
  gap: 16px;

  > :first-child {
    gap: 8px;
    margin-right: 8px;
  }

  ${breakpoints.up("sm")} {
    height: 60px;
    padding: 0 24px;
    gap: 32px;
    > :first-child {
      gap: 16px;
      margin-right: 0;
    }
  }

  ${breakpoints.up("lg")} {
    height: 80px;
  }
`;

const SearchHeaderColumn = styled(HeaderColumn)`
  flex-grow: 1;

  ${breakpoints.up("sm")} {
    max-width: 400px;
  }

  ${breakpoints.up("lg")} {
    max-width: 570px;
  }
`;

const SiteTab = styled(A)`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #222222;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  ${({ $active }) =>
    $active &&
    `
    font-weight: 700;
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -1px;
      width: 100%;
      border-bottom: 3px solid black;
    }
  `}
  ${breakpoints.down("sm")} {
    font-size: 9px;
  }
`;

const StyledLogo = styled(Logo)`
  margin-bottom: 2px;

  ${breakpoints.up("sm")} {
    margin-bottom: 4px;
  }

  ${breakpoints.up("tablet")} {
    margin-bottom: 6px;
  }
`;
