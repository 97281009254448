import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
} from "@material-ui/core";
import { translate } from "../../../../utils/i18n";
import React, { ReactNode } from "react";
import { $breakpoints, ifTrue } from "../../../../utils/styledHelpers";
import styled from "styled-components";

export type TextContentProps = {
  t?: string;
  tHtml?: string;
  text?: string;
  children?: ReactNode;
};
export type withTextContentProps<C> = TextContentProps & C;
export type TypographyProps = withTextContentProps<MuiTypographyProps>;

const TextContent = ({ t, tHtml, text, children }: TextContentProps) => {
  if (t) return translate(t);
  if (tHtml) return <span dangerouslySetInnerHTML={{ __html: translate(tHtml) }} />;
  if (text) return text;
  return children;
};

export const withTextContent = (Component) => ({
  t,
  text,
  tHtml,
  children,
  ...props
}: withTextContentProps<typeof Component>) => (
  <Component {...props}>{TextContent({ t, tHtml, text, children })}</Component>
);

const TypographyBase = withTextContent(styled(MuiTypography)`
  ${$breakpoints}
`);

export default TypographyBase;

export const P = styled(TypographyBase)`
  font-size: 14px;
  line-height: 18px;
`;

export const Span = styled(TypographyBase).attrs({ component: "span" })`
  font-size: inherit;
  font-weight: inherit;
`;

export const Small = styled(TypographyBase).attrs({ component: "small" })`
  font-size: inherit;
  font-weight: 500;
`;

export const Super = styled(Span)`
  font-size: 0.7em;
  vertical-align: super;
`;

export const BoldSpan = styled(Span)`
  font-weight: 700;
`;

export const MedSpan = styled(Span)`
  font-weight: 500;
`;

export const A = styled(TypographyBase).attrs({ component: "a" })`
  color: inherit;
  text-decoration: inherit;
  ${ifTrue("$underline")`
    text-decoration: underline;
  `}
`;

export const Pre = styled(P)`
  white-space: pre-wrap;
`;
