import { camelCase, Dictionary, uniqBy } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { CurrencyCode } from "../../../../../types/graphql-global-types";
import { GetListingFormData_listing } from "../../queries/types/GetListingFormData";
import {
  GetCategories_categories,
  GetCategories_categories_customFields,
} from "../queries/types/GetCategories";

const form = {
  formId: "listingForm",
  formField: {
    title: {
      name: "title",
      requiredErrorMsg: "Title is required",
    },
    description: {
      name: "description",
      requiredErrorMsg: "Title is required",
    },
    images: {
      name: "images",
      label: "Images",
      requiredErrorMsg: "At least one image is required",
      imageUploadingMsg: "Images are still uploading, please wait",
    },
    brand: {
      name: "brand",
      requiredErrorMsg: "Brand is required",
    },
    currency: {
      name: "currency",
      label: "Currency",
      requiredErrorMsg: "Currency is required",
    },
    rrp: {
      name: "rrp",
      requiredErrorMsg: "Retail price is required",
      invalidErrorMsg: "Retail price is invalid",
    },
  },
};

export default form;

function getCustomFieldValue(
  customField: GetCategories_categories_customFields,
  listing?: GetListingFormData_listing
) {
  const listingCustomFieldValue = listing?.customFields?.find((f) => f.key == customField.key)
    ?.value;
  switch (customField.type) {
    case "NumericField": {
      return listingCustomFieldValue?.numValue || "";
    }
    case "TextField": {
      return listingCustomFieldValue?.textValue || "";
    }
    case "CheckboxField": {
      return listingCustomFieldValue?.selectedOptions?.map((option) => option.key) || [];
    }
  }
}

export function getInitialValues(
  categoriesMap: Dictionary<GetCategories_categories>,
  initialCurrency: CurrencyCode,
  listing?: GetListingFormData_listing
) {
  return {
    title: listing?.title ?? "",
    description: listing?.description ?? "",
    images:
      listing?.listingImages?.map((image) => ({
        uuid: uuidv4(),
        listingImageId: image.id,
        processedURL: image.squareUrl,
        doneUploading: true,
        doneProcessing: image.ready,
      })) ?? [],
    brand: listing?.brand,
    currency: listing?.rrp ? listing.rrp.currency.code : initialCurrency,
    rrp: listing?.rrp ? listing.rrp.formatted : "",
    rootCategory: listing?.category?.parent?.id ?? listing?.category?.id ?? "",
    subCategory: listing?.category?.parent ? listing?.category?.id : "",
    // Even custom fields not belonging to the listing's current
    // category need an initial value, to avoid fields having
    // undefined values when the user selects a category from the
    // dropdown. As such, we create initial values for all possible
    // custom fields from the category map.
    ...Object.fromEntries(
      uniqBy(
        Object.values(categoriesMap).flatMap((c) => c.customFields),
        "key"
      ).map((customField) => [
        camelCase(customField.key),
        getCustomFieldValue(customField, listing),
      ])
    ),
  };
}
