import classNames from "classnames";
import React from "react";
import LoadingIndicator from "../../elements/LoadingIndicator/LoadingIndicator";
import css from "./ContentBlock.module.css";

interface Props {
  title?: string;
  subtitle?: string;
  children: React.ReactNode;
  action?: React.ReactNode;
  className?: string;
  isLoading?: boolean;
}

export default function ContentBlock({
  title,
  children,
  className,
  action,
  isLoading,
  subtitle,
}: Props) {
  const content = isLoading ? <LoadingIndicator /> : children;

  return (
    <div className={classNames([css.root, className])}>
      <div className={css.titleBar}>
        {title ? <h4 className={css.title}>{title}</h4> : null}
        {action ? action : null}
        {subtitle ? <h5 className={css.subtitle}>{subtitle}</h5> : null}
      </div>

      <div className={css.content}>{content}</div>
    </div>
  );
}
