import { useFilter } from "@sajari/react-hooks";
import React from "react";
import { Divider } from "@material-ui/core";
import FlexBox from "../../../../elements/v2/Box/FlexBox";
import { Title } from "../FilterSection";
import styled from "styled-components";
import Switch from "../../../../elements/v2/Switch/Switch";
import Events from "../../../../../utils/analytics";

const TRUE_LABEL = "true";

export default function SwitchFilter({ title, filterName }: { title: string; filterName: string }) {
  const { options, selected, setSelected } = useFilter(filterName);

  // Only show filter if there are reduced price items (option.count returns the # of items in the
  // result set that match this option).
  if (!options.find((option) => option.label === TRUE_LABEL && option.count)) return null;

  const isSelected = selected.includes(TRUE_LABEL);

  const handleChange = (event: { target: { checked: any } }) => {
    setSelected(event.target.checked ? [TRUE_LABEL] : []);
    Events.sendToggleReducedPrice({
      toggledOn: event.target.checked,
    });
  };

  return (
    <>
      <Container>
        <Title>{title}</Title>
        <Switch onChange={handleChange} checked={isSelected} />
      </Container>
      <Divider variant="middle" component="p" />
    </>
  );
}

const Container = styled(FlexBox)`
  justify-content: space-between;
  padding: 12px 16px;
`;
