import classNames from "classnames";
import React, { MouseEvent, useEffect } from "react";
import Portal from "../../composites/Portal/Portal";
import css from "./Modal.module.css";

export interface Props {
  handleClose: () => void;
  children: React.ReactNode;
}

export default function Modal({ handleClose, children }) {
  // We wrap modal content in a click blocker to prevent click events propagating up the tree to the
  // parent of the modal. The modal is rendered "outside" its parent (using our Portal component),
  // however click events are still bound to the parent, which is unlikely to be the behaviour that
  // we want.
  const blockEvent = (event: MouseEvent) => {
    event.stopPropagation();
  };

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        handleClose();
      }
    };
    window.addEventListener("keydown", close);
    const cleanup = () => window.removeEventListener("keydown", close);
    return cleanup;
  }, []);

  return (
    <Portal parentElement={document.body}>
      <div className={css.backdrop} onClick={handleClose}>
        <div className={classNames(css.modal, "t-modal")} onClick={blockEvent}>
          {children}
        </div>
      </div>
    </Portal>
  );
}
