/* eslint-env commonjs */
// This file should be sunset
import _ from "lodash";
import r from "r-dom";
import { createContext } from "react";

let Routes = {};

try {
  Routes = require("../routes/routes.js");
} catch (e) {
  console.warn("Can not load route bundle routes.js"); // eslint-disable-line no-console
}

/**
 * Examples:
 *
 * addDefaultArgs([1, 2, 3], {}) => [1, 2, 3, {}]
 * addDefaultArgs([1, 2, 3, {format: "json"}], {locale: "en"}) => [1, 2, 3, {format: "json", locale: "en"}]
 * addDefaultArgs([1, 2, 3, {locale: "fr"}], {locale: "en"}) => [1, 2, 3, {locale: "fr"}]
 */
function addDefaultArgs(args, defaultArgs) {
  const argsArray = _.toArray(args);
  const last = _.last(argsArray);

  if (last && _.isObject(last)) {
    return _.initial(argsArray).concat([_.assign({}, defaultArgs, last)]);
  } else {
    return argsArray.concat([defaultArgs]);
  }
}

function didYouMean(routeName) {
  if (_.endsWith(routeName, "_path") && Routes[routeName]) {
    return `Did you mean '${routeName.replace(/_path$/, "")}'?`;
  } else {
    return null;
  }
}

function routeNameToPathHelperName(routeName) {
  return `${routeName}_path`;
}

function wrapWithDefaultArgs(pathFns, defaultArgs) {
  return pathFns.reduce((routeObject, { pathHelperName, pathHelper }) => {
    const withDefaultArgsFn = function withDefaultArgs(...args) {
      return pathHelper(...addDefaultArgs(args, defaultArgs));
    };

    // Copy the toString function.
    // It contains the path spec, which might be useful
    // For example:
    // single_conversation_path.toString => (/:locale)/:person_id/messages/:conversation_type/:id(.:format)
    withDefaultArgsFn.toString = pathHelper.toString;

    routeObject[pathHelperName] = withDefaultArgsFn; // eslint-disable-line no-param-reassign
    return routeObject;
  }, {});
}

//
// Public API
//

/**
 * Creates a subset of all routes.
 *
 * You can pass also `defaultArgs` object, for example for "locale"
 */
export function subset(routesSubset, defaultArgs = {}) {
  const pathHelpers = routesSubset.map((routeName) => {
    const pathHelperName = routeNameToPathHelperName(routeName);
    const pathHelper = Routes[pathHelperName];

    if (pathHelper) {
      return { pathHelperName, pathHelper };
    } else {
      throw new Error(
        _.compact([`Couldn't find named route: '${routeName}'.`, didYouMean(routeName)]).join(" ")
      );
    }
  });

  return wrapWithDefaultArgs(pathHelpers, defaultArgs);
}

/**
 * Returns all routes.
 *
 * ** Use this ONLY in styleguide or in tests. **
 */
export function all(defaultArgs) {
  const pathHelperNames = _.keys(Routes).filter((key) => _.endsWith(key, "_path"));

  const pathFns = pathHelperNames.map((pathHelperName) => {
    const pathHelper = Routes[pathHelperName];
    return { pathHelperName, pathHelper };
  });

  return wrapWithDefaultArgs(pathFns, defaultArgs);
}

// We expose a subset of routes to this app via context. I don't think this complexity is really
// necessary, but the thing is the 'subset' function merges in the marketplace locale into many of
// the routes, as a default argument. If that weren't the case, we would be able to simply import
// all routes in the places where we need them, instead of this 'subset' approach. In any case this
// will benefit from some re-thinking, and correct usage of React context APIs.
export const RoutesContext = createContext({});

export const withRoutes = (routes) => (children) =>
  r(RoutesContext.Provider, { value: routes }, children);

export const railsRoutesSubset = [
  "cancel_person_message",
  "collection_person",
  "contact_person",
  "donate_listing",
  "donate_success_listing",
  "edit_product",
  "list_listing",
  "list_success_listing",
  "new_person_message_feedback",
  "new_product",
  "person_listings",
  "person",
  "product_success",
  "reviews_person",
  "collections_person",
  "search",
];

export const wrapWithRoutes = (context) => {
  const routes = subset(railsRoutesSubset, {
    locale: context.i18nLocale,
  });
  return (Component) => () => withRoutes(routes)(r(Component));
};
