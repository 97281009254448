import Fuse from "fuse.js";

export default (array: any[], searchValue: string | any, keys: string[]) => {
  const fuseOptions = {
    includeScore: true,
    keys: keys,
  };

  const fuse = new Fuse(array, fuseOptions);
  return fuse.search(searchValue).map((result) => result.item);
};
