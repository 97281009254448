import { gql } from "@apollo/client";

export default gql`
  query GetListingImages($ids: [Int!]!) {
    listingImages(ids: $ids) {
      id
      ready
      squareUrl
    }
  }
`;
