import { Breadcrumb } from "../components/composites/v2/Breadcrumbs/Breadcrumbs";

//Adds breadcrumbs structure to head for SEO
export const addBreadcrumbsStructure = (breadcrumbs: Breadcrumb[], product?: Breadcrumb) => {
  document.getElementById("breadcrumbs-structure")?.remove(); // remove previous structure data if found

  const baseUrl = "https://shop.airrobe.com";
  const breadCrumbsStructureData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [],
  };

  breadcrumbs.forEach((breadcrumb, i) => {
    breadCrumbsStructureData.itemListElement.push({
      "@type": "ListItem",
      position: i + 1,
      name: breadcrumb.label,
      item: baseUrl + breadcrumb.path,
    });
  });
  if (product) {
    breadCrumbsStructureData.itemListElement.push({
      "@type": "ListItem",
      position: breadcrumbs.length + 1,
      name: product.label,
      item: baseUrl + product.path,
    });
  }

  const script = document.createElement("script");
  script.type = "application/ld+json";
  script.id = "breadcrumbs-structure";
  script.text = JSON.stringify(breadCrumbsStructureData);

  document.head.appendChild(script);
};
