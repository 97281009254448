import { div, span } from "r-dom";
import { t } from "../../../utils/i18n";
import css from "./ManageAvailabilityHeader.module.css";

export interface Props {
  backgroundColor: string;
  imageUrl?: string;
  title: string;
}
const ManageAvailabilityHeader = (props: Props) =>
  div(
    {
      className: css.root,
    },
    [
      props.imageUrl
        ? div({
            className: css.imageLayer,
            style: { backgroundImage: `url(${props.imageUrl})` },
          })
        : null,
      div({
        className: css.colorLayer,
        style: { backgroundColor: props.backgroundColor },
      }),
      div({ className: css.listingHeader }, [
        span({ className: css.availabilityHeader }, t("web.listings.edit_availability_header")),
        props.title,
      ]),
    ]
  );

export default ManageAvailabilityHeader;
