import React, { useContext } from "react";
import RorpContext from "../../../../../contexts/RorpContext";
import styled from "styled-components";
import { isPathActive } from "../../../../../utils/paths";
import Box from "../../../../elements/v2/Box/Box";
import { A } from "../../../../elements/v2/Typography/Typography";
import departments from "../../../../../utils/departments";

const navLinks: { label: string; navPath: string }[] = ["s", "womens", "mens", "kids"].map(
  (k) => departments[k]
);

export const DepartmentFilter = () => {
  const { pathname } = useContext(RorpContext);
  return (
    <Box flexDirection="column" display="flex">
      {navLinks.map(({ navPath, label }) => (
        <Link
          href={navPath}
          key={navPath}
          className={isPathActive(pathname, navPath) ? "active" : " "}
        >
          {label}
        </Link>
      ))}
    </Box>
  );
};

const Link = styled(A)`
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  margin-top: 8px;
  &.active {
    font-weight: 700;
  }
`;
