import { debounce } from "lodash";
import React, { useState } from "react";
import classNames from "classnames";
import icon from "../images/search-icon.svg";
import css from "./CountrySearchBar.module.css";

// TODO: I would ideally like this to be unified with our main search bar (from the Topbar), but
// that component also does a lot of extra stuff, including combining keyword and location search
// inputs depending on the mode, and collapsing into a very different component on mobile screens.
// For now I think the most efficient strategy is to duplicate the look-and-feel, but when we update
// our header, I would like to unify these components.
export default function CountrySearchBar({ handleSubmit, placeholder }) {
  const [searchText, setSearchText] = useState("");
  const debouncedSubmit = debounce(handleSubmit, 300);
  const handleChange = (event) => {
    setSearchText(event.target.value);
    debouncedSubmit(event.target.value);
  };

  return (
    <form
      className={classNames({ [css.form]: true })}
      style={{ backgroundColor: "transparent" }}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(searchText);
      }}
    >
      <span className={css.focusContainer} />
      <input
        className={css.keywordInput}
        type={"search"}
        name={"q"}
        placeholder={placeholder}
        value={searchText}
        onChange={handleChange}
      />
      <button
        className={css.searchButton}
        type={"submit"}
        name="search-button"
        dangerouslySetInnerHTML={{ __html: icon }}
        style={{ backgroundColor: "transparent" }}
      />
    </form>
  );
}
