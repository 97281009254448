import React, { useState } from "react";
import styled from "styled-components";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import { useQuery } from "@apollo/client";
import {
  GetListingsEngagement,
  GetListingsEngagementVariables,
} from "./queries/types/GetListingsEngagement";
import GET_LISTINGS_ENGAGEMENT from "./queries/GetListingsEngagement";
import Box from "../../../../../../elements/v2/Box/Box";
import Typography from "../../../../../../elements/v2/Typography/Typography";
import Menu from "../../../../../../composites/v2/Menu/Menu";
import MenuItem, { MenuItemDivider } from "../../../../../../composites/v2/Menu/MenuItem";
import InfoTooltip from "../../../../../../composites/FormControls/InfoTooltip/InfoTooltip";
import { LoadingSpinnerCentered } from "../../../../../../elements/v2/LoadingSpinner/LoadingSpinner";
import { useSearchPageContext } from "../../../../SearchPageContext";

export default function ListingsEngagementBlock() {
  const { profilePage } = useSearchPageContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentListing, setCurrentListing] = useState(undefined);
  const open = (listing) => {
    setAnchorEl(document.getElementById(`options-${listing.listingId}`));
    setCurrentListing(listing);
  };
  const close = () => {
    setAnchorEl(null);
    setCurrentListing(undefined);
  };
  const { loading, data, error } = useQuery<GetListingsEngagement, GetListingsEngagementVariables>(
    GET_LISTINGS_ENGAGEMENT,
    {
      variables: { personId: profilePage.person.id },
    }
  );

  const listingsEngagement = data?.listingsEngagement || [];

  if (listingsEngagement.length == 0) return null;

  return (
    <Container>
      <FullWidthGrey />
      <TitleBar>
        <Title>Activity</Title>
      </TitleBar>
      {loading && <LoadingSpinnerCentered />}
      <Block>
        {listingsEngagement.map((listing) => (
          <ActivityCard key={listing.listingId}>
            <Details>
              <img width="45px" height="56px" src={listing.listingImage} />
              {
                // disabled for until 1 month after launch
                // <RowFlex>
                //   <InfoTooltip
                //     title={"Number of times your listing has been viewed."}
                //     icon={<BarChartOutlinedIcon />}
                //   />
                //   <span>{listing.viewCount}</span>
                //   <span>Views</span>
                // </RowFlex>
              }
              <RowFlex>
                <InfoTooltip
                  title={"Number of people who have added your listing to their favourites."}
                  icon={<FavoriteBorderOutlinedIcon />}
                />
                <span>{listing.favourites}</span>
                <span>Favourites</span>
              </RowFlex>
            </Details>
            <Options id={`options-${listing.listingId}`}>
              <MoreHorizOutlinedIcon onClick={() => open(listing)} />
              {currentListing && (
                <CustomMenu open={true} anchorEl={anchorEl} onClose={close}>
                  <MenuTitle>Action</MenuTitle>
                  <Divider />
                  <CustomMenuItem routeAction={currentListing.editProductPath}>
                    Tag with extra details
                  </CustomMenuItem>
                  <Divider />
                  <CustomMenuItem routeAction={currentListing.editListingPricePath}>
                    Lower price
                  </CustomMenuItem>
                </CustomMenu>
              )}
            </Options>
          </ActivityCard>
        ))}
      </Block>
    </Container>
  );
}

const Container = styled(Box)`
  position: relative;
  margin-top: 24px;
  padding-top: 16px;
`;

const FullWidthGrey = styled.div<any>`
  position: absolute;
  top: 0;
  right: -40px;
  left: -40px;
  height: 100%;
  background-color: #f6f6f6;
  z-index: -1;
`;

const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  @media only screen and (max-width: 769px) {
    width: 100%;
  }
  gap: 10px;
  font-weight: 500;
  font-size: 13px;
  flex-grow: 1;
  line-height: 40px;
  padding-bottom: 40px;
  padding-top: 20px;
`;

const Empty = styled(Typography)`
  color: grey;
`;

const TitleBar = styled(RowFlex)`
  display: flex;
  flex-wrap: wrap;
`;

const Title = styled(RowFlex)`
  font-weight: 600;
  font-size: 18px;
  flex-grow: 1;
  line-height: 40px;
`;

const ActivityCard = styled(RowFlex)`
  display: flex;
  background-color: white;
  height: 72px;
  padding: 8px 16px 8px 8px;
  border-radius: 3px;
  box-shadow: 0px 2px 5px 0px #0000001a;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
`;

const Details = styled(RowFlex)`
  gap: 15px;
  svg {
    position: relative;
    top: 8px;
    margin: 0px 4px;
  }
  span {
    padding: 0px 2px;
  }
  align-items: center;
`;
const Options = styled(RowFlex)``;

const CustomMenu = styled(Menu)`
  ul {
    padding: 0px 0px;
  }
`;

const MenuTitle = styled(Typography)`
  font-weight: 600;
  font-size: 13px;
  padding: 8px 8px;
`;

const CustomMenuItem = styled(MenuItem)`
  font-weight: 500;
  font-size: 13px;
  text-transform: none;
  padding: 10px 8px;
  min-height: 20px;
`;

const Divider = styled(MenuItemDivider)`
  width: 100%;
  margin: 0px 0px;
`;
