// We currently use media queries to selectively render content based on the screen resolution in a
// couple places. While this approach seems like it should be ok, we've determined that we should
// migrate to a more conventional mobile-responsive setup. The reason is because our SSR process
// will not have a concept of "screen resolution", so will always need to render either the browser
// version or the desktop version (etc, as the case may be). This fundamentally causes a problem
// with React's DOM tree reconciliation, which makes no attempt to "patch" changes in the DOM.
// According to React (https://github.com/facebook/react/issues/10591), if your application returns
// different HTML on the server versus client, it's a bug - simple!
//
// The maintainers of the react-responsive library also contended with this problem
// (https://github.com/yocontra/react-responsive/issues/162), and this workaround is suggested.
// Given that it leads to a re-render with a client-side repaint after the state change, we'd
// consider it pretty sub-optimal. Our goal is to therefore move away from the react-responsive
// library.
import { useLayoutEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

export const useMobileMediaQuery = (props?: { breakpoint?: number }) => {
  const [isClient, setIsClient] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: props?.breakpoint || 768 });

  if (typeof window !== "undefined")
    useLayoutEffect(() => {
      setIsClient(true);
    }, []);

  return {
    isMobile: isClient ? isMobile : false,
    isClient,
  };
};
