import React, { useState } from "react";
import Box from "../../elements/v2/Box/Box";
import wrapWithStylesProvider from "../../../utils/wrapWithStylesProvider";
import { wrapWithThemeProvider } from "../../../utils/themeProvider";
import ssrComponent from "../../../utils/ssr";
import { flowRight } from "lodash";
import { wrapWithRorpContext } from "../../../contexts/RorpContext";
import { wrapWithApollo } from "../../../graphql/client";
import styled from "styled-components";
import { Svg } from "../../elements/Svg/Svg";
import ChevronSvg from "./assets/chevron.svg";

interface WardrobeStatsProps {
  resaleValue: string;
  moneyEarned: string;
  co2Offset: string;
}

export default function WardrobeStats({ resaleValue, moneyEarned, co2Offset }: WardrobeStatsProps) {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => {
    const collapsible = document.getElementById("collapsible");
    if (collapsed) {
      // using timeout to give enough time for transition effects
      setTimeout(() => collapsible.setAttribute("display", "flex"), 300);
      setCollapsed(false);
    } else {
      setTimeout(() => collapsible.setAttribute("display", "none"), 300);
      setCollapsed(true);
    }
  };

  return (
    <Container py={2} px={1}>
      <Title>My Circular Wardrobe</Title>
      <ResaleSection>
        <Subtitle>
          Est Resale Value
          <Chevron content={ChevronSvg} onClick={toggleCollapse} $collapsed={collapsed} />
        </Subtitle>
        <Value>{resaleValue}</Value>
      </ResaleSection>
      <CollapsibleSection id={"collapsible"} $collapsed={collapsed}>
        <SubSection>
          <Subtitle>Money Earned</Subtitle>
          <Value>{moneyEarned}</Value>
        </SubSection>
        <SubSection>
          <Subtitle>CO2 Offset</Subtitle>
          <Value>{co2Offset}</Value>
        </SubSection>
      </CollapsibleSection>
    </Container>
  );
}

export const WardrobeStatsApp = (props, context) =>
  ssrComponent(
    flowRight(
      wrapWithRorpContext(context),
      wrapWithStylesProvider,
      wrapWithThemeProvider,
      wrapWithApollo
    )(() => <WardrobeStats {...props} />)
  );

const Title = styled.span`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.12px;
  text-transform: uppercase;
`;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CollapsibleSection = styled(Box)<{ $collapsed: boolean }>`
  justify-content: space-between;
  transition: 300ms all ease-in;
  display: flex;
  z-index: 0;
  opacity: 100;
  margin-top: 0;
  ${({ $collapsed }) =>
    $collapsed &&
    `
    opacity: 0;
    margin-top: -70px;
  `}
`;

const SubSection = styled(Box)`
  display: flex;
  gap: 4px;
  justify-content: left;
  flex-direction: column;
`;

const ResaleSection = styled(SubSection)`
  width: 100%;
  z-index: 1;
`;

const Value = styled.span`
  color: #222;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  width: fit-content;
`;

const Subtitle = styled.span`
  color: #111;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  display: flex;
  align-items: center;
  min-height: 24px;
  gap: 4px;
`;

const Chevron = styled(Svg)<{ $collapsed: boolean }>`
  transform: rotate3d(0);
  ${({ $collapsed }) =>
    $collapsed &&
    `
    transform: rotate3d(1, 0, 0, 180deg);
  `}
  transition: 300ms all ease-in;
`;
