import React from "react";

type Props = {
  content: string;
  onClick?: (e?: any) => any;
  style?: any;
};

export const Svg = ({ content, ...props }: Props) => (
  <span dangerouslySetInnerHTML={{ __html: content }} {...props} />
);
