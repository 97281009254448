import React from "react";
import hamburgerIcon from "../images/hamburgerIcon.svg";
import { t } from "../../../../utils/i18n";
import NotificationBadge from "../../../elements/NotificationBadge/NotificationBadge";
import styled from "styled-components";
import Box from "../../../elements/v2/Box/Box";
import { breakpoints } from "../../../../utils/styledHelpers";

type MobileMenuToggleProps = {
  onClick?: () => void;
  notificationCount?: number;
};

export default function MobileMenuToggle({ notificationCount, ...props }: MobileMenuToggleProps) {
  return (
    <Container {...props}>
      <HamburgerIcon
        title={t("web.topbar.menu")}
        dangerouslySetInnerHTML={{ __html: hamburgerIcon }}
      />
      {notificationCount > 0 && (
        <BadgeWrapper>
          <NotificationBadge count={notificationCount} />
        </BadgeWrapper>
      )}
    </Container>
  );
}

export const Container = styled(Box)`
  display: inline-block;
  padding: 16px 2px 16px 16px;
  cursor: pointer;
  position: relative;
`;

export const HamburgerIcon = styled.span``;

const BadgeWrapper = styled(Box)`
  position: absolute;
  top: 12px;
  left: 29px;
  min-width: 18px;
  height: 18px;
  border-radius: 9px;

  ${breakpoints.up("sm")} {
    display: none;
  }
`;
