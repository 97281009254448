/* eslint-disable react/no-set-state */

import moment, { Moment } from "moment";
import r, { div } from "r-dom";
import { DayPicker, isInclusivelyBeforeDay, isSameDay } from "react-dates";

// This shouldn't be necessary, but our sass loader doesn't seem to be working properly with direct
// imports from node_modules. This is fine for now to unblock us, later versions of the gem use CSS-
// in-js solutions, and if we run up against the issue with other libraries, we can either deal with
// the webpack configuration then, or use a similar approach as we do here:
require("!style-loader!css-loader!sass-loader!react-dates/css/styles.scss");

import transformModifiers, { pastDaysInMonth } from "../../../utils/transformModifiers";

import css from "./ManageAvailabilityCalendar.module.css";

const isPast = (day) => {
  const today = moment();
  return !isSameDay(day, today) && isInclusivelyBeforeDay(day, today);
};

const isReserved = (reservedDays, day) => !!reservedDays.find((d) => isSameDay(d, day));

const isBlocked = (blockedDays, day) => !!blockedDays.find((d) => isSameDay(d, day));

export interface Props {
  initialMonth: Moment;
  blockedDays: [moment.Moment];
  reservedDays: [moment.Moment];
  onDayAllowed: (day: moment.Moment) => void;
  onDayBlocked: (day: moment.Moment) => void;
  onMonthChanged: (month: moment.Moment) => void;
  extraClasses: string;
}

export default function ManageAvailabilityCalendar(props: Props) {
  const handleDayClick = (day) => {
    if (isReserved(props.reservedDays, day) || isPast(day)) {
      // Cannot allow or block a reserved or a past day
      return;
    } else if (isBlocked(props.blockedDays, day)) {
      props.onDayAllowed(day);
    } else {
      props.onDayBlocked(day);
    }
  };

  const pickerProps = {
    numberOfMonths: 1,
    enableOutsideDays: true,
    hideKeyboardShortcutsPanel: true,
    initialVisibleMonth: () => props.initialMonth,
    onDayClick: handleDayClick,
    onPrevMonthClick: () => {
      props.onMonthChanged(moment(props.initialMonth).subtract(1, "months"));
    },
    onNextMonthClick: () => {
      props.onMonthChanged(moment(props.initialMonth).add(1, "months"));
    },
    modifiers: transformModifiers({
      past: pastDaysInMonth(props.initialMonth),
      today: [moment()],
      blocked: props.blockedDays,
      reserved: props.reservedDays,
    }),
  };

  return div({ className: `${css.root} ${props.extraClasses || ""}` }, [r(DayPicker, pickerProps)]);
}
