import React from "react";
import FlexBox from "../v2/Box/FlexBox";
import styled from "styled-components";
import { Span } from "../v2/Typography/Typography";

type NotificationBadgeProps = {
  count: number;
};

export default function NotificationBadge({ count, ...props }: NotificationBadgeProps) {
  return (
    <Container {...props}>
      <Count>{count}</Count>
    </Container>
  );
}

const Container = styled(FlexBox).attrs({
  className: "NotificationBadge",
})`
  align-items: center;
  min-width: 18px;
  height: 18px;
  border-radius: 9px;
  padding: 0 3px;
  background-color: #ff9579;
`;

const Count = styled(Span).attrs({
  className: "NotificationBadge-Count",
})`
  text-align: center;
  width: 100%;
  color: white;
  font-size: 11px;
  line-height: 1;
  margin-top: 1px;
  font-weight: 700;
`;
