import ReactOnRails from "react-on-rails";
import isSSR from "./isSSR";
import { AuthenticityHeaders } from "react-on-rails/node_package/lib/types";
import React from "react";

// This file allows you to submit forms to rails controllers or
// ajax requests to call POST/PUT/DELETE requests to the rails controllers
// By default the rails application automatically adds CSRF protection to
// its forms, so making a direct request from the React app to rails will
// cause a CSRF token missing error.

// This function gets the csrf token required
export const getCsrfToken = (): string | null =>
  isSSR() ? null : ReactOnRails.authenticityToken();

// This gives you the headers required for your ajax request.
// Usage:
/**
 * return axios(`/en/sessions/request_new_password"`, {
 *   method: "post",
 *   data: data,
 *   withCredentials: true,
 *   headers: getCsrfHeaders(),
 * });
 */
export const getCsrfHeaders = (otherHeaders = {}): AuthenticityHeaders | null =>
  isSSR() ? null : ReactOnRails.authenticityHeaders(otherHeaders);

// This gives you the form field required in your form to be submitted.
// Usage:
/**
 * <form action="/en/sessions/request_new_password" method="post">
 *   <AuthenticityToken />
 *   <input type="text" name="email" id="email" />
 *   <button name="button" type="submit">Request new password</button>
 * </form>
 */
export const AuthenticityToken = () => {
  const token = getCsrfToken();
  if (!token) return null;

  return <input type="hidden" name="authenticity_token" value={getCsrfToken()} />;
};
