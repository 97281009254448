import { createStyles, FormControlLabel, makeStyles, Radio } from "@material-ui/core";
import MUIRadioGroup from "@material-ui/core/RadioGroup";
import classNames from "classnames";
import { FieldInputProps } from "formik";
import React from "react";
// import Radio from "./Radio";

export type RadioGroupOptions = { value: string; label: string }[];

export interface Props {
  field: FieldInputProps<string>;
  options: RadioGroupOptions;
  row?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    row: {
      // MUI provides the "row" option on RadioGroup but it still displays
      // as a column because of the FormGroup css that RadioGroup inherits for some
      // reason and which uses flex-wrap. We turn that off here.
      flexWrap: "nowrap",
    },
  })
);

const RadioGroup = ({ field, options, row = false }: Props) => {
  const classes = useStyles();

  return (
    <MUIRadioGroup
      row={row}
      {...field}
      name={field.name}
      className={classNames({
        [classes.row]: row,
      })}
      aria-label="condition"
    >
      {options.map((option, i) => (
        <FormControlLabel
          value={option.value}
          key={i}
          control={
            <Radio
              inputProps={{
                "data-cy": `radio-${field.name}-${option.value}`,
              }}
            />
          }
          label={option.label}
        />
      ))}
    </MUIRadioGroup>
  );
};
export default RadioGroup;
