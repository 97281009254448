import React from "react";
import styled from "styled-components";
import Box from "../../../../elements/v2/Box/Box";
import { GetListing_listing } from "../../queries/types/GetListing";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { P } from "../../../../elements/v2/Typography/Typography";
import FlexBox from "../../../../elements/v2/Box/FlexBox";
import ListingCollectionsDropdown from "../../../../widgets/ListingCollectionsDropdown/ListingCollectionsDropdown";
import { breakpoints } from "../../../../../utils/styledHelpers";

type Props = {
  listing?: GetListing_listing;
};

export default function ProductImageCarousel({ listing }: Props) {
  const { listingImages } = listing;

  return (
    <Container>
      <FavouriteIcon listing={listing} />
      <Carousel
        autoPlay={false}
        infiniteLoop
        showIndicators={false}
        showStatus={false}
        showArrows={listingImages.length > 1}
        showThumbs={listingImages.length > 1}
        thumbWidth={80}
      >
        {listingImages.map((listingImage) => (
          <div className="image-container" key={listingImage.id}>
            {listingImage.ready ? (
              <img src={listingImage.largeUrl} alt={listing.title} />
            ) : (
              <ImageProcessing>
                <ProcessingMessage>(Processing uploaded image...)</ProcessingMessage>
              </ImageProcessing>
            )}
          </div>
        ))}
      </Carousel>
    </Container>
  );
}

const Container = styled(Box)`
  position: relative;
  --control-arrow-color: #d0d0c7;
  .carousel-root {
    outline: none;
    * {
      box-sizing: border-box;
    }
  }
  .carousel {
    position: relative;
    margin: 0;
    overflow: hidden;
    width: 100%;
    z-index: 1;
    .control-arrow {
      transition: all 0.25s ease-in;
      position: absolute;
      display: flex;
      align-items: center;
      z-index: 2;
      top: 0;
      background: none;
      border: 0;
      font-size: 32px;
      cursor: pointer;
      color: #d0d0c7;
      bottom: 0;
      margin-top: 0;
      padding: 5px;
      &.control-disabled {
        display: none;
      }
      &:before {
        width: 0;
        margin: 0 5px;
        display: block;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        content: "";
      }
      &.control-prev {
        left: 0;
        &:before {
          border-right: 8px solid var(--control-arrow-color);
        }
      }
      &.control-next {
        right: 0;
        &:before {
          border-left: 8px solid var(--control-arrow-color);
        }
      }
    }
    &.carousel-slider {
      .control-arrow {
        &.control-prev {
          width: 25%;
          justify-content: flex-start;
        }
        &.control-next {
          width: 75%;
          justify-content: flex-end;
        }
      }
    }

    .slider-wrapper {
      overflow: hidden;
      margin: auto;
      width: 100%;
      transition: height 0.15s ease-in;
      .slider {
        margin: 0;
        padding: 0;
        position: relative;
        list-style: none;
        width: 100%;
        &.animated {
          transition: all 0.35s ease-in-out;
        }
        .slide {
          min-width: 100%;
          margin: 0;
          position: relative;
          text-align: center;

          .image-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 600px;
            ${breakpoints.down("tablet")} {
              height: 400px;
            }
            ${breakpoints.down("sm")} {
              height: 300px;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              border: 0;
            }
          }
        }
      }
      &.axis-horizontal .slider {
        display: flex;
        .slide {
          flex-direction: column;
          flex-flow: column;
        }
      }
      &.axis-vertical {
        display: flex;
        .slider {
          flex-direction: column;
        }
      }
    }
    img {
      width: 100%;
      display: inline-block;
    }
    .thumbs-wrapper {
      margin: 20px;
      overflow: hidden;
      .thumbs {
        padding: 0;
        transition: all 0.15s ease-in;
        transform: translate3d(0, 0, 0);
        position: relative;
        list-style: none;
        white-space: nowrap;
        .thumb {
          transition: border 0.15s ease-in;
          display: inline-block;
          margin-right: 6px;
          white-space: nowrap;
          overflow: hidden;
          border: 1px solid #fff;
          cursor: pointer;
          vertical-align: middle;
          img {
            vertical-align: top;
            opacity: 0.4;
          }
          &:focus {
            border: 3px solid #ccc;
            outline: none;
          }
          &.selected,
          &:hover {
            border: 1px solid #dfdad4;
            img {
              opacity: 1;
            }
          }
        }
      }
      .control-arrow {
        &:before {
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
        &.control-prev:before {
          border-right: 4px solid black;
        }
        &.control-next:before {
          border-left: 4px solid black;
        }
      }
    }
  }
`;

const ImageProcessing = styled(FlexBox)`
  height: 400px;
  width: 100%;
  border: 0;
  background-color: #3c3c3c;
  justify-content: center;
  align-items: center;
`;

const ProcessingMessage = styled(P)`
  font-size: 26px;
  font-weight: 600;
  color: #c3c3c3;
  text-align: center;
`;

const FavouriteIcon = styled(ListingCollectionsDropdown)`
  && {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 23px;
    z-index: 2;
  }
`;
