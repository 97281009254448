import { MenuLinkType } from "./navLinks";

export interface NavMenuItemsClickedProps {
  username: string;
  type: MenuLinkType;
  department: string;
  category?: string;
}

export enum EmailCaptureConnectStartedFrom {
  BANNER = "banner",
  BANNER_HOW_IT_WORKS = "banner_how_it_works",
  MODAL = "modal",
  MODAL_HOW_IT_WORKS = "modal_how_it_works",
  RESULT = "wardrobe_result",
}

export default class Events {
  static HO_STEP_1_STARTED = "HO Step 1 Started";
  static HO_STEP_1_COMPLETED = "HO Step 1 Completed";
  static HO_STEP_2_STARTED = "HO Step 2 Started";
  static HO_STEP_2_CONFIRM_EMAIL = "HO Step 2 Confirm Email";
  static HO_STEP_2_EMAIL_CONFIRMED = "HO Step 2 Email Confirmed";
  static HO_STEP_2_COMPLETED = "HO Step 2 Completed";
  static HO_STEP_3_READY = "HO Step 3 Ready";
  static HO_STEP_3_ADD_TO_AIRROBE = "HO Step 3 Add to AirRobe";
  static HO_STEP_4_IMPORT_SUCCESSFUL = "HO Step 4 Import Successful";
  static HO_NO_ORDERS = "HO No Orders Available";
  static HO_DONE = "HO Done";
  static HO_IMPORT_MANUALLY = "HO Import Manually"; // toDo: if users click button to ‘Import manually’
  static TOGGLE_REDUCED_PRICE_FILTER = "Reduced Price Filter Toggled";
  static MMAO_OFFER_INVALID = "MMaO Invalid Offer Due to Price";
  static MMAO_OFFER_INITIATED = "MMaO Button Clicked To Initiate Offer";
  static CAMPAIGN_MODAL_VIEWED = "Campaign Modal Viewed";
  static CAMPAIGN_MODAL_CLICKED = "Campaign Modal Clicked";
  static CAMPAIGN_MODAL_CLOSED = "Campaign Modal Closed";
  static CHECK_AVAILABILITY_CLICKED = "Check Availability Clicked";
  static PRODUCT_CARD_CLICKED = "Product Card Clicked";
  static NAV_MENU_ITEM_CLICKED = "Navigation Menu Item Clicked";
  static EMAIL_CAPTURE_CONNECT_STARTED = "Email Capture Started";
  static DISCOUNT_CODE_ATTEMPT = "Discount Code Attempt";

  static sendHoStep1Started = (props: any) => {
    window.ST.analytics.logEvent(Events.HO_STEP_1_STARTED, "pageview", "marketplace", props);
  };
  static sendHoStep1Completed = (props: any) => {
    window.ST.analytics.logEvent(Events.HO_STEP_1_COMPLETED, "clickaction", "marketplace", props);
  };
  static sendHoStep2Started = (props: any) => {
    window.ST.analytics.logEvent(Events.HO_STEP_2_STARTED, "pageview", "marketplace", props);
  };
  static sendHoStep2Completed = (props: any) => {
    window.ST.analytics.logEvent(Events.HO_STEP_2_COMPLETED, "clickaction", "marketplace", props);
  };
  static sendHoStep2ConfirmEmail = (props: any) => {
    window.ST.analytics.logEvent(
      Events.HO_STEP_2_CONFIRM_EMAIL,
      "clickaction",
      "marketplace",
      props
    );
  };
  static sendHoStep2EmailConfirmed = (props: any) => {
    window.ST.analytics.logEvent(
      Events.HO_STEP_2_EMAIL_CONFIRMED,
      "pageview",
      "marketplace",
      props
    );
  };
  static sendHoStep3Ready = (props: any) => {
    window.ST.analytics.logEvent(Events.HO_STEP_3_READY, "pageview", "marketplace", props);
  };
  static sendHoStep3AddToAirRobe = (props: any) => {
    window.ST.analytics.logEvent(
      Events.HO_STEP_3_ADD_TO_AIRROBE,
      "clickaction",
      "marketplace",
      props
    );
  };
  static sendHoDone = (props: any) => {
    window.ST.analytics.logEvent(Events.HO_DONE, "clickaction", "marketplace", props);
  };
  static sendHoImportSuccessful = (props: any) => {
    window.ST.analytics.logEvent(
      Events.HO_STEP_4_IMPORT_SUCCESSFUL,
      "success",
      "marketplace",
      props
    );
  };
  static sendHoNoOrders = (props: any) => {
    window.ST.analytics.logEvent(Events.HO_NO_ORDERS, "failed", "marketplace", props);
  };
  static sendHoImportManually = (props: any) => {
    window.ST.analytics.logEvent(Events.HO_IMPORT_MANUALLY, "clickaction", "marketplace", props);
  };

  static sendToggleReducedPrice = (props: any) => {
    window.ST.analytics.logEvent(
      Events.TOGGLE_REDUCED_PRICE_FILTER,
      "clickaction",
      "marketplace",
      props
    );
  };

  static sendMmaoOfferInvalid = (props: any) => {
    window.ST.analytics.logEvent(Events.MMAO_OFFER_INVALID, "clickaction", "marketplace", props);
  };
  static sendMmaoOfferInitiated = (props: any) => {
    window.ST.analytics.logEvent(Events.MMAO_OFFER_INITIATED, "clickaction", "marketplace", props);
  };

  static sendCampaignModalViewed = (props?: any) => {
    window.ST.analytics.logEvent(Events.CAMPAIGN_MODAL_VIEWED, "pageview", "marketplace", props);
  };
  static sendCampaignModalClicked = (props?: any) => {
    window.ST.analytics.logEvent(
      Events.CAMPAIGN_MODAL_CLICKED,
      "clickaction",
      "marketplace",
      props
    );
  };
  static sendCampaignModalClosed = (props?: any) => {
    window.ST.analytics.logEvent(Events.CAMPAIGN_MODAL_CLOSED, "clickaction", "marketplace", props);
  };

  static sendCheckAvailabilityClicked = (props?: any) => {
    window.ST.analytics.logEvent(
      Events.CHECK_AVAILABILITY_CLICKED,
      "clickaction",
      "marketplace",
      props
    );
  };

  static sendProductCardClicked = (listingData, listingCardLocation, props = {}) => {
    window.ST.analytics.logEvent(Events.PRODUCT_CARD_CLICKED, "clickaction", "marketplace", {
      listingData,
      listingCardLocation,
      url: window.location.href,
      ...props,
    });
  };

  static sendNavMenuItemClicked = (props: NavMenuItemsClickedProps) => {
    window.ST.analytics.logEvent(Events.NAV_MENU_ITEM_CLICKED, "clickaction", "marketplace", props);
  };

  static sendEmailCaptureConnectStarted = (from: EmailCaptureConnectStartedFrom, props) => {
    window.ST.analytics.logEvent(
      Events.EMAIL_CAPTURE_CONNECT_STARTED,
      "clickaction",
      "marketplace",
      {
        from,
        ...props,
      }
    );
  };

  static sendDiscountCodeAttempt = (props) => {
    window.ST.analytics.logEvent(Events.DISCOUNT_CODE_ATTEMPT, "clickaction", "marketplace", props);
  };
}
