import React from "react";
import isSSR from "./isSSR";
import { get, isEmpty } from "lodash";

const ssrData = {};

export function withPersistSSR(Component) {
  return function C() {
    if (!isSSR() || isEmpty(ssrData)) return <Component />;

    return (
      <>
        <Component />
        <script
          dangerouslySetInnerHTML={{ __html: `window.ssrData = ${JSON.stringify(ssrData)}` }}
        />
      </>
    );
  };
}

function sanitizeKey(key: string): string {
  return key.replace("=", "").toLowerCase();
}

export function putSsrData(key: string, data: string) {
  if (!isSSR()) return;

  ssrData[sanitizeKey(key)] = data;
}

export function getSsrData(key: string): string | undefined {
  if (isSSR()) return undefined;

  return get(window, `ssrData.${sanitizeKey(key)}`, undefined);
}
