import React, { RefObject, useState } from "react";
import { useHistory } from "react-router-dom";
import { P } from "../../../elements/v2/Typography/Typography";
import { useMutation } from "@apollo/client";
import Box from "../../../elements/v2/Box/Box";
import {
  SendEmailConfirmation,
  SendEmailConfirmationVariables,
} from "../mutations/types/SendEmailConfirmation";
import SEND_EMAIL_CONFIRMATION_MUTATION from "../mutations/SendEmailConfirmation";
import { isValidEmail } from "../../../../utils/emails";
import { getHistoricalOrdersConfirmEmailPath } from "../../../../utils/paths";
import { ActionButton } from "./ActionButton";
import Events from "../../../../utils/analytics";

type Props = {
  affiliateAppId: string;
  emailRef: RefObject<HTMLInputElement>;
};

const NewEmailAction = ({ affiliateAppId, emailRef }: Props) => {
  const history = useHistory();
  const [errors, setErrors] = useState<string[]>([]);

  const [sendEmailConfirmation] = useMutation<
    SendEmailConfirmation,
    SendEmailConfirmationVariables
  >(SEND_EMAIL_CONFIRMATION_MUTATION);

  function handleConfirmEmail() {
    const newEmail = emailRef?.current?.value;
    if (isValidEmail(newEmail)) {
      setErrors([]);

      sendEmailConfirmation({
        variables: { input: { email: newEmail } },
      })
        .then(() => {
          history.push(getHistoricalOrdersConfirmEmailPath(affiliateAppId, newEmail));
        })
        .catch(({ graphQLErrors }) => {
          setErrors(graphQLErrors.map((e) => e.message));
        })
        .finally(() => {
          Events.sendHoStep2ConfirmEmail({
            email: newEmail,
            affiliateAppId: affiliateAppId,
            errors: errors,
          });
        });
    } else setErrors(["Email address is invalid"]);
  }

  return (
    <Box width="100%">
      <Box mt={4}>
        {errors.map((err) => (
          <P key={err} style={{ color: "red" }}>
            {err}
          </P>
        ))}
      </Box>
      <Box mt={1}>
        <ActionButton onClick={handleConfirmEmail}>Confirm Email Address</ActionButton>
      </Box>
    </Box>
  );
};

export default NewEmailAction;
