import { Summary } from "@sajari/react-search-ui";
import React from "react";
import "cross-fetch/polyfill";
import FlexBox from "../../../elements/v2/Box/FlexBox";
import styled from "styled-components";
import { breakpoints } from "../../../../utils/styledHelpers";
import { useSearchPageContext } from "../SearchPageContext";
import CollectionMenu from "../../../widgets/CollectionMenu/CollectionMenu";
import { MenuLabelTypeEnum } from "../../../composites/Menu/Menu";
import SearchHeaderTitle from "./SearchHeaderTitle";
import SearchSort from "./SearchSort";

export default function SearchHeader() {
  const { collection, refetchCollection, isProductLongTailPage } = useSearchPageContext();

  return (
    <Container>
      <FlexBox $gap={3}>
        <SearchHeaderTitle />
        {collection?.owner.isMe && (
          <CollectionMenu
            label="Edit Collection"
            labelType={MenuLabelTypeEnum.DROPDOWN}
            collection={collection}
            afterUpdate={refetchCollection}
            afterDelete={() => (window.location.href = collection?.owner.profileUrl)}
          />
        )}
      </FlexBox>
      {!isProductLongTailPage && (
        <SortWrapper>
          <FlexBox mr={1} alignItems="center">
            <SearchSummary size="sm" />
          </FlexBox>
          <SearchSort />
        </SortWrapper>
      )}
      {!isProductLongTailPage && <MobileOnlySearchSummary />}
    </Container>
  );
}

const Container = styled(FlexBox)`
  justify-content: space-between;
  align-items: center;
  margin: 16px;
`;

const SortWrapper = styled(FlexBox)`
  justify-content: flex-end;

  ${breakpoints.down("tablet")} {
    display: none;
  }

  ${breakpoints.down("sm")} {
    width: 100%;
    justify-content: space-between;
  }
`;

const SearchSummary = styled(Summary)`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const MobileOnlySearchSummary = styled(Summary)`
  display: flex;
  align-items: center;
  white-space: nowrap;

  ${breakpoints.up("tablet")} {
    display: none;
  }
`;
