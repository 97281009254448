import React from "react";
import Box from "../../../elements/v2/Box/Box";
import { Heading } from "../components/Heading";
import AffiliateList from "../components/AffiliateList";
import { Description } from "../components/Description";
import { StepWrapper } from "../components/StepWrapper";

export const SelectAffiliateStep = () => {
  return (
    <StepWrapper>
      <Heading>Import Past Orders</Heading>
      <Box my={2}>
        <Description>
          <b>Select Brand or Merchant to Get Started</b>
          <br />
          <br />
          Add older purchases to your circular wardrobe with just a few clicks.
          <br />
          <br />
          <i>
            Please note that while importing past orders, certain orders may not appear due to
            eligibility criteria.
            <br />
            We are continually working to enhance this feature for a better experience.
          </i>
        </Description>
      </Box>
      <AffiliateList />
    </StepWrapper>
  );
};
