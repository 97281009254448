import React from "react";
import FlexBox, { FlexBoxProps } from "../v2/Box/FlexBox";
import styled from "styled-components";

export enum FontAwesomeIconName {
  BRIEFCASE = "briefcase",
  LOCK = "lock",
  UNLOCK = "unlock",
  TRASH = "trash",
  RECYCLE = "repeat",
  REPLY = "reply",
  SELL = "money",
  RENT = "money",
  COMMISSION = "money",
  EDIT = "edit",
  PENDING = "time",
  ALERT = "warning-sign",
  CROSS = "remove",
  STAR = "star",
  BOOK = "book",
  INFO = "info-sign",
}

type Props = FlexBoxProps & {
  iconName: FontAwesomeIconName;
};

export default function FontAwesomeIcon({ iconName, ...props }: Props) {
  return (
    <Icon {...props}>
      <i className={`icon-${iconName}`} />
    </Icon>
  );
}

const Icon = styled(FlexBox)`
  display: inline-flex;
  justify-content: center;
  width: 21px;
`;
