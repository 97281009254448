import { gql } from "@apollo/client";
import { CollectionCardFragment } from "../../../graphql/fragments/collections";

export default gql`
  query GetMyCollections {
    me {
      id
      collections {
        results {
          listingIds
          ...CollectionCardFragment
        }
      }
    }
  }
  ${CollectionCardFragment}
`;
