import React, { useContext } from "react";
import RorpContext from "../../../../contexts/RorpContext";
import Section from "./Section";
import { t } from "../../../../utils/i18n";
import { A } from "../../../elements/v2/Typography/Typography";
import {
  createHref,
  isMenuItemActive,
  navLinks,
  useCurrentParams,
} from "../../../../utils/navLinks";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styled from "styled-components";
import { MenuItemDivider } from "../../../composites/v2/Menu/MenuItem";
import Box from "../../../elements/v2/Box/Box";

export default function SectionMobileMegaMenu() {
  const { pathname } = useContext(RorpContext);

  return (
    <Section title={t("web.topbar.menu")}>
      {navLinks.map(({ t, path, type, children }) => (
        <Box key={t}>
          {children ? (
            <Accordion defaultExpanded={isMenuItemActive(pathname, path, type)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ padding: "0 16px" }}>
                <ParentMenuItem
                  $active={isMenuItemActive(pathname, path, type)}
                  className="department"
                  t={t}
                />
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0 }}>
                <MenuItemDivider />
                <Categories categoryLinks={children} departmentPath={path} />
              </AccordionDetails>
            </Accordion>
          ) : (
            <LeafMenuItem
              href={createHref(path)}
              $active={isMenuItemActive(pathname, path, type)}
              className="department"
              t={t}
            />
          )}
          <MenuItemDivider />
        </Box>
      ))}
    </Section>
  );
}

function Categories({ categoryLinks, departmentPath }) {
  const { pathname } = useContext(RorpContext);
  const currentParams = useCurrentParams();

  return categoryLinks.map(({ t, type, params, children }) =>
    children ? (
      <Accordion
        key={t}
        defaultExpanded={isMenuItemActive(
          pathname,
          departmentPath,
          type,
          currentParams,
          params,
          children
        )}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ padding: "0 16px 0 32px" }}>
          <ParentMenuItem
            $active={isMenuItemActive(
              pathname,
              departmentPath,
              type,
              currentParams,
              params,
              children
            )}
            className="category"
            t={t}
          />
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          {children.map(({ t, path, params, type }) => (
            <LeafMenuItem
              key={t}
              href={path || createHref(departmentPath, params)}
              $active={isMenuItemActive(pathname, departmentPath, type, currentParams, params)}
              className="subcategory"
              t={t}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    ) : (
      <LeafMenuItem
        key={t}
        href={createHref(departmentPath, params)}
        $active={isMenuItemActive(pathname, departmentPath, type, currentParams, params)}
        className="category"
        t={t}
      />
    )
  );
}

const MenuItem = styled(A)<{ $active: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #222222;
  font-weight: 500;
  font-size: 15px;
  line-height: 1;

  &.department {
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  &.category {
    font-size: 12px;
  }

  &.subcategory {
    font-size: 12px;
  }

  ${({ $active }) =>
    $active &&
    `
    font-weight: 700;
    color: black;
  `}
`;

const ParentMenuItem = styled(MenuItem)`
  &:focus,
  &:hover {
    color: black;
    font-weight: 700;
  }
`;

const LeafMenuItem = styled(MenuItem)`
  width: 100%;
  min-height: 48px;
  padding: 0 16px;

  &.category {
    padding-left: 32px;
  }

  &.subcategory {
    padding-left: 48px;
    min-height: 30px;
  }

  &:focus,
  &:hover {
    color: black;
    background-color: rgba(169, 172, 176, 0.07);
  }
`;
