import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import fetch from "cross-fetch";
import r from "r-dom";
import isSSR from "../utils/isSSR";
import { isEqual } from "lodash";
import merge from "deepmerge";

export const APOLLO_STATE_VAR_NAME = "__APOLLO_STATE__";

export const client = new ApolloClient({
  ssrMode: isSSR(),
  link: createUploadLink({
    credentials: "same-origin",
    fetch,
    uri: process.env.client_graphql_url,
  }),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: isSSR() ? "cache-first" : "cache-and-network",
    },
  },
});

if (!isSSR() && window[APOLLO_STATE_VAR_NAME]) {
  // We handle multiple APOLLO_STATE's here because some partial components may have GraphQL calls
  // Once we move to Full page components only, we can simplify this to restore only one state.
  // This article has a nice pattern for handling a lot of situations when we get there
  // https://developers.wpengine.com/blog/apollo-client-cache-rehydration-in-next-js
  window[APOLLO_STATE_VAR_NAME].forEach((state) => {
    // Get existing cache, loaded during client side data fetching
    const existingCache = client.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(state, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    });

    client.cache.restore(data);
  });
}

export const withApollo = (children: any) => r(ApolloProvider, { client }, children);

export const wrapWithApollo = (Component) => () => r(ApolloProvider, { client }, r(Component));
