import { createStyles, Grid, makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import classNames from "classnames";
import { Field } from "formik";
import React from "react";
import Checkbox from "../../../FormControls/Checkbox/Checkbox";
import FormSectionHeading from "../../../FormControls/FormSection/Heading";
import InfoTooltip from "../../../FormControls/InfoTooltip/InfoTooltip";
import Money from "../../../FormControls/Money/Money";
import { StepProps } from "../Steps/ConditionStep";
import ShippingSection from "../../Shared/ShippingSection";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formSection: {
      padding: 16,
      background: "#FFFFFF",
      borderTop: "1px solid #E0E0E0",
      borderBottom: "1px solid #E0E0E0",
    },
    innerFormSection: {
      padding: 16,
    },
    title: {
      fontWeight: 600,
    },
    hideShippingFee: {
      display: "none",
    },
    innerShippingSection: {
      gap: 4,
      padding: 8,
    },
    hideFloorPrice: {
      display: "none",
    },
  })
);

export function calculateFloorPrice(basePrice: number) {
  // Plus sign drops any "extra" zeroes at the end
  let floorPrice = +(basePrice * 0.65).toFixed(2);
  return floorPrice >= 19.99 ? floorPrice : 19.99;
}

export default function PriceSection({
  values,
  errors,
  setFieldValue,
  validationSchema,
  handleBlur,
  formField,
  handleChange,
}: StepProps) {
  const classes = useStyles();
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.up("laptop"));
  const { currency, price, enableSmartPricing, floorPrice } = formField;

  function handleOnBlurPrice(e: React.FocusEvent<HTMLInputElement>) {
    handleBlur(e);
    // If there's no validation error with price, calculate and set floor price
    if (!errors.price) {
      try {
        validationSchema(values).validateSyncAt("price", {
          price: e.target.value,
        });
        setFieldValue("floorPrice", calculateFloorPrice(parseFloat(values.price)).toString());
      } catch (e) {
        // If validation of the base price failed, erase the floor price.
        setFieldValue("floorPrice", "");
      }
    }
  }

  function handleOnBlurFloorPrice(e: React.FocusEvent<HTMLInputElement>) {
    handleBlur(e);
    try {
      validationSchema(values).validateSyncAt("price", {
        price: e.target.value,
      });
    } catch (e) {
      setFieldValue("floorPrice", "19.99");
    }
  }

  return (
    <Grid item>
      <Grid className={classes.formSection}>
        <Grid
          container
          spacing={0}
          direction="row"
          justify={mediumScreen ? "flex-start" : "space-between"}
          alignItems={mediumScreen ? "center" : undefined}
        >
          <Grid item>
            <FormSectionHeading title="Selling Price" />
          </Grid>
          <Grid item style={mediumScreen ? { paddingLeft: 16 } : {}}>
            <InfoTooltip title="This is the amount you will receive minus AirRobe fees and the shipping fee (if you include it in the overall sale price)." />
          </Grid>
        </Grid>
        <Grid container spacing={2} direction="column" className={classes.innerFormSection}>
          <Money currencyField={currency} amountField={price} handleOnBlur={handleOnBlurPrice} />
        </Grid>
        <Grid container direction="column" className={classes.innerFormSection}>
          <ShippingSection
            formField={formField}
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            handleBlur={handleBlur}
            validationSchema={validationSchema}
          />
          <Grid
            container
            spacing={0}
            direction="row"
            justifyContent={mediumScreen ? "flex-start" : "space-between"}
            alignItems={mediumScreen ? "center" : undefined}
          >
            <Grid item>
              <Field
                name={enableSmartPricing.name}
                label={enableSmartPricing.label}
                component={Checkbox}
              />
            </Grid>
            <Grid item>
              <InfoTooltip
                title="This will allow your sale price to automatically reduce overtime until it sells,
              or hits your “floor price” (the lowest you’re willing to go). Items have up to a 70% higher chance of selling with Smart Pricing."
              />
            </Grid>
          </Grid>
          <Grid
            className={classNames({
              [classes.hideFloorPrice]: !values.enableSmartPricing,
            })}
          >
            <Grid className={classes.innerFormSection}>
              <FormSectionHeading
                title="Floor Price"
                description="The lowest price you are willing to go is:"
              />
              <Money
                currencyField={currency}
                amountField={floorPrice}
                handleOnBlur={handleOnBlurFloorPrice}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
