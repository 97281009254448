import React, { FC } from "react";
import styled from "styled-components";
import Box, { BoxProps } from "./Box";
import { $colGap, $gap, $rowGap } from "../../../../utils/styledHelpers";

export type FlexBoxProps = BoxProps & {
  $gap?: number;
  $rowGap?: number;
  $colGap?: number;
};

const FlexBox: FC<FlexBoxProps> = (props) => <Box {...props} />;

export default styled(FlexBox)`
  display: flex;
  ${$gap}
  ${$rowGap}
  ${$colGap}
`;
