import React from "react";
import Slider, { Settings } from "react-slick";
import ListingCardHorizontal, {
  Props as ListingCardProps,
} from "../../composites/ListingCardHorizontal/ListingCardHorizontal";
import { GetExternalOrderPreviewProps } from "./queries/types/GetExternalOrderPreviewProps";

export type Props = GetExternalOrderPreviewProps & {
  listingShapes: ListingCardProps["listingShapes"];
};

export default function ExternalOrderPreview({
  listingShapes,
  externalOrderPreviewListings,
}: Props) {
  const cardClassName = "external-order-listing-card";

  // TODO: using jquery is a bit of an embarrassment here (we shouldn't be adding jQuery deps to our
  // react code, we should be working to remove jQuery completely), but we have this existing
  // equalizeHeights jquery plugin here and I don't have time to re-write it with vanilla js now.
  // Also, the knowledge of the "slick-track" class isn't great at all, but the slick package
  // doesn't give us any refs to hook into, so for now we just use jQuery + classes and wait for a
  // brighter day to modernise it :)

  // Equalise the heights of content on init
  const onSliderInit = () => ($(".slick-track") as any).equalizeHeights(`.${cardClassName}`);

  const settings: Settings = {
    infinite: true,
    speed: 300,
    slidesToShow: Math.min(externalOrderPreviewListings.length, 2),
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    onInit: onSliderInit,
  };

  return (
    <Slider {...settings}>
      {externalOrderPreviewListings.map((listing) => (
        <ListingCardHorizontal
          key={listing.id}
          listing={listing}
          listingShapes={listingShapes}
          includeActionButtons
          className={cardClassName}
          actionButtonHrefOverride={"#person_password1"}
        />
      ))}
    </Slider>
  );
}
