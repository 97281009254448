import React, { useEffect, useState } from "react";
import { createEntryPage } from "../FullPageLayout/FullPageLayout";
import Box from "../../elements/v2/Box/Box";
import { useMutation, useQuery } from "@apollo/client";
import GET_PEOPLE_LISTINGS from "./queries/GetPeopleListings";
import SET_PROFILE_LISTING_ORDER from "./mutations/SetProfileListingOrder";
import MOVE_PROFILE_LISTING_ORDER from "./mutations/MoveProfileListingOrder";
import useQueryParam from "../../../utils/useQueryParam";
import { LoadingSpinnerFullPage } from "../../elements/v2/LoadingSpinner/LoadingSpinner";
import FlexBox from "../../elements/v2/Box/FlexBox";
import styled from "styled-components";
import PageSelection from "../../composites/PageSelection/PageSelection";
import ListingSortCard from "./components/ListingSortCard";
import SortableItem from "../../composites/SortableDnd/SortableItem";
import SortableDndContext from "../../composites/SortableDnd/SortableDndContext";
import { Grid } from "@material-ui/core";
import { keyBy } from "lodash";
import { SortDirection } from "../../../types/graphql-global-types";
import MoveDialog from "./components/MoveDialog";
import SnackbarAlert from "../../composites/v2/SnackbarAlert/SnackbarAlert";
import { H2 } from "../../elements/v2/Typography/Heading";
import { breakpoints } from "../../../utils/styledHelpers";
import { BUTTON_SIZES, OutlineButton } from "../../elements/v2/Button/Button";
import { getUserBasePath } from "../../../utils/paths";
import { P } from "../../elements/v2/Typography/Typography";
import {
  GetPeopleListingsVariables,
  GetPeopleListings,
  GetPeopleListings_peopleListings_results,
} from "./queries/types/GetPeopleListings";

const PER_PAGE = 40;
const PAGE_PARAM = "p";

export enum SortField {
  PROFILE_SORT = "profile_sort",
  CONCIERGE_SORT = "concierge_sort",
}

type Props = {
  authorIds: string[];
  username: string;
};
function SortListingsPage({ authorIds, username }: Props) {
  const [
    selectedListing,
    setSelectedListing,
  ] = useState<GetPeopleListings_peopleListings_results | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaveError, setIsSaveError] = useState(false);
  const page = Number(useQueryParam(PAGE_PARAM) || 1);
  const sortField = username === "concierge" ? SortField.CONCIERGE_SORT : SortField.PROFILE_SORT;
  const { data, previousData, loading, error, refetch, networkStatus } = useQuery<
    GetPeopleListings,
    GetPeopleListingsVariables
  >(GET_PEOPLE_LISTINGS, {
    variables: {
      authorIds,
      page,
      perPage: PER_PAGE,
      sortField,
      sortDirection: SortDirection.asc,
    },
    fetchPolicy: "network-only",
    ssr: false,
    notifyOnNetworkStatusChange: true,
  });

  const [setProfileListingOrder] = useMutation(SET_PROFILE_LISTING_ORDER);
  const [moveProfileListingOrder] = useMutation(MOVE_PROFILE_LISTING_ORDER);

  const listings = data?.peopleListings?.results || [];

  const [items, setItems] = useState<GetPeopleListings_peopleListings_results[]>(listings);

  useEffect(() => {
    setItems(listings);
  }, [listings?.map((listing) => listing.id).join(",")]);

  const itemIds = items.map((item) => String(item.id));

  function onReorder(ids: Array<string | number>) {
    const startIndex = (page - 1) * PER_PAGE;
    const itemHash = keyBy(items, (item) => item.id);
    const newItems = ids.map((id, index) => ({
      ...itemHash[id],
      profileSort: startIndex + index,
    }));
    setItems(newItems);
    setProfileListingOrder({
      variables: {
        input: {
          authorIds,
          page,
          perPage: PER_PAGE,
          listingIds: newItems.map((item) => item.id),
          sortField,
        },
      },
    });
  }

  function onClickMove(listing: GetPeopleListings_peopleListings_results) {
    setSelectedListing(listing);
  }

  function onMoveListing(position: number) {
    setIsSaving(true);
    moveProfileListingOrder({
      variables: {
        input: {
          authorIds,
          listingId: selectedListing?.id,
          position,
          sortField,
        },
      },
    })
      .then(() => {
        refetch();
      })
      .catch(() => {
        setIsSaveError(true);
      })
      .finally(() => {
        setIsSaving(false);
      });
    setSelectedListing(null);
  }

  const waiting = isSaving || loading;

  return (
    <Box p={[2, 5]}>
      <SnackbarAlert
        open={isSaveError}
        severity="error"
        onClose={() => setIsSaveError(false)}
        content={"An error occurred while moving the listing. Please try again."}
      />
      <FlexBox mb={3} $gap={3} justifyContent="space-between" flexWrap="wrap">
        <H2>Sort Listings on Profile</H2>
        <OutlineButton size={BUTTON_SIZES.SMALL} href={getUserBasePath(username)}>
          Back to Profile
        </OutlineButton>
      </FlexBox>
      <Box mb={3}>
        <P>
          Re-order the listings on your profile page to bring your best listings to the front.
          <br />
          Drag and drop to sort within page or use the MOVE button to send it to a position on any
          page.
        </P>
      </Box>
      <Box width="100%">
        {waiting && <LoadingSpinnerFullPage />}
        {error && <Error>There was a problem loading this data.</Error>}
      </Box>
      {listings ? (
        <Box>
          <Box width="100%">
            <PageSelection
              currentPage={page}
              totalPages={data?.peopleListings?.pageCount}
              location=""
              pageParam={PAGE_PARAM}
            />
          </Box>
          <Items>
            <SortableDndContext onReorder={onReorder} itemIds={itemIds}>
              {items.map((listing) => (
                <SortableItem key={listing.id} id={String(listing.id)}>
                  <Item>
                    <ListingSortCard listing={listing} onClickMove={onClickMove} />
                  </Item>
                </SortableItem>
              ))}
            </SortableDndContext>
          </Items>
        </Box>
      ) : (
        <Box height="50vh"></Box>
      )}
      <MoveDialog
        onCancel={() => setSelectedListing(null)}
        onConfirm={onMoveListing}
        selectedListing={selectedListing}
        sortField={sortField}
      />
    </Box>
  );
}

const Items = styled(Grid)`
  display: inline-grid;
  width: 100%;
  grid-gap: 8px;
  grid-template-columns: repeat(4, minmax(40px, 200px));
  ${breakpoints.down("tablet")} {
    grid-template-columns: repeat(2, minmax(40px, 200px));
  }
`;

const Item = styled(Grid).attrs({ item: true })`
  display: inline-block;
  position: relative;
  width: 100%;
  user-select: none;
`;

const Error = styled(FlexBox)`
  justify-content: center;
  align-items: center;
  color: darkred;
  padding: 24px;
  height: 50vh;
`;

type Args = {
  props: Props;
  topbar: any;
};

const mapEntryProps = ({ topbar, props }: Args) => {
  return {
    pageProps: {
      topbarProps: topbar,
    },
    componentProps: props,
  };
};

export default createEntryPage(mapEntryProps)(SortListingsPage);
