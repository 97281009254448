import {
  GetSearchProps,
  GetSearchProps_categories,
  GetSearchProps_categories_subcategories,
} from "../components/pages/SearchPage/queries/types/GetSearchProps";
import { useMemo } from "react";
import { Dictionary, keyBy } from "lodash";

export type ParentCategory = GetSearchProps_categories;
export type Subcategory = GetSearchProps_categories_subcategories;
export type Category = ParentCategory | Subcategory;

export function isParentCategory(category: Category): category is ParentCategory {
  return !("parentId" in category);
}

export function isChildCategory(category: Category): category is Subcategory {
  return "parentId" in category;
}

export function getSelfArrayOrSubcategories(category: Category): Category[] {
  return isParentCategory(category) ? category.subcategories : [category];
}

export interface SelectedCategoryData {
  selectedCategory: Category;
  selectedParentCategory: ParentCategory;
  selectedChildCategory: Category | null;
}

export function getSelectedCategoryData(
  categories: GetSearchProps["categories"],
  selected: string[]
): SelectedCategoryData {
  const parentCategoriesById = useMemo<Dictionary<ParentCategory>>(() => keyBy(categories, "id"), [
    categories,
  ]);

  const allCategoriesByUrl = useMemo<Dictionary<Category>>(() => {
    const categoryArray = [...categories, ...categories.flatMap(getSelfArrayOrSubcategories)];
    return keyBy(categoryArray, "url");
  }, [categories]);

  const selectedCategory = useMemo<Category>(
    () => selected && selected[0] && allCategoriesByUrl[selected[0]],
    [selected, allCategoriesByUrl]
  );

  const selectedChildCategory = useMemo<Subcategory>(
    () => (selectedCategory && isChildCategory(selectedCategory) ? selectedCategory : null),
    [selectedCategory]
  );

  const selectedParentCategory = useMemo<ParentCategory>(() => {
    if (!selectedCategory) return null;
    if (isParentCategory(selectedCategory)) return selectedCategory;
    if (selectedChildCategory) return parentCategoriesById[selectedChildCategory.parentId];
  }, [selectedCategory, selectedChildCategory, parentCategoriesById]);

  return {
    selectedCategory,
    selectedParentCategory,
    selectedChildCategory,
  };
}
