import styled from "styled-components";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import React from "react";
import Chip, { ChipProps } from "./v2/Chip/Chip";

interface PercentOffChip extends ChipProps {
  percentOff?: string;
  className?: string;
  showPriceReduced?: boolean;
}

export default function PercentOffChip({
  percentOff,
  className,
  showPriceReduced,
}: PercentOffChip) {
  if (!showPriceReduced) return null;
  const label = `${percentOff}% off`;
  return <ChipStyled className={className} avatar={<LocalOfferIconCustom />} label={label} />;
}

export const ChipStyled = styled(Chip)`
  background-color: transparent;
  border: 1px solid black;
  border-radius: 2px;
  font-size: 11px;
  font-weight: 700;
`;

const LocalOfferIconCustom = styled(LocalOfferIcon)`
  &.MuiSvgIcon-root {
    color: #000000;
    width: 1rem;
    height: 1rem;
  }
`;
