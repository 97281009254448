import React, { useContext } from "react";
import Section from "./Section";
import { t } from "../../../../utils/i18n";
import RorpContext from "../../../../contexts/RorpContext";
import { TopbarUserProps } from "../types";
import {
  ADD_AN_ITEM_PATH,
  isPathActive,
  USER_FAVOURITES_PATH,
  USER_INBOX_PATH,
  USER_LISTINGS_PATH,
  USER_PROFILE_PATH,
} from "../../../../utils/paths";
import NotificationBadge from "../../../elements/NotificationBadge/NotificationBadge";
import styled from "styled-components";
import MobileMenuItem, { MobileMenuItemUser } from "./MobileMenuItem";

type MenuMobileUserProps = {
  user: TopbarUserProps;
  notificationCount?: number;
};

export default function SectionUser({ user, notificationCount }: MenuMobileUserProps) {
  const { pathname } = useContext(RorpContext);
  const { isAdmin, loggedInUsername } = user;

  if (!loggedInUsername) return null;

  return (
    <Section title={t("web.topbar.user")}>
      <MobileMenuItemUser path={USER_LISTINGS_PATH} username={loggedInUsername}>
        {t("web.topbar.listings")}
      </MobileMenuItemUser>
      <MobileMenuItem href={ADD_AN_ITEM_PATH} active={isPathActive(pathname, ADD_AN_ITEM_PATH)}>
        {t("web.topbar.create_listing")}
      </MobileMenuItem>
      <MobileMenuItemUser path={USER_PROFILE_PATH} username={loggedInUsername} exact>
        {t("web.topbar.profile")}
      </MobileMenuItemUser>
      <MobileMenuItemUser path={USER_INBOX_PATH} username={loggedInUsername} exact>
        {t("web.topbar.inbox")}{" "}
        {notificationCount > 0 && <MenuItemNotificationBadge count={notificationCount} />}
      </MobileMenuItemUser>
      <MobileMenuItemUser path={USER_FAVOURITES_PATH} username={loggedInUsername}>
        {t("web.topbar.favourite")}
      </MobileMenuItemUser>
    </Section>
  );
}

const MenuItemNotificationBadge = styled(NotificationBadge)`
  border-radius: 12px;
  box-shadow: none;
  height: 24px;
  margin-top: -8px;
  margin-left: 8px;
  min-width: 24px;
  .NotificationBadge-Count {
    font-size: 14px;
    padding-top: 0;
  }
`;
