import React, { ReactNode, useEffect, useState } from "react";
import { Accordion, AccordionSummary, Dialog, DialogContent } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import styled from "styled-components";
import Typography from "../../elements/v2/Typography/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button, { BUTTON_SIZES } from "../../elements/v2/Button/Button";
import FlexBox from "../../elements/v2/Box/FlexBox";
import Events from "../../../utils/analytics";
import moment from "moment";

type BaseCampaignModalProps = {
  name: string;
  startDate?: string;
  finishDate?: string;
  renderDelaySeconds?: number;
  openingConditionFn?: () => boolean;
  hideForDaysOnClose?: number;
  onClose?: Function;
  maxWidth?: string;
  clickOpened?: boolean;
};

//either children or modalContent is required
type CampaignModalProps = BaseCampaignModalProps & { children: ReactNode };

export default function CampaignModal(props: CampaignModalProps) {
  const {
    name,
    startDate,
    finishDate,
    hideForDaysOnClose = 1,
    renderDelaySeconds = 0,
    openingConditionFn,
    onClose,
    children,
    maxWidth,
    clickOpened = false,
    ...otherProps
  } = props;
  const [open, setOpen] = useState(false);

  const hideForGivenDays = () => {
    const futureDate = moment().add(hideForDaysOnClose, "day").format("YYYY-MM-DD");
    localStorage.setItem(`ar-${name}-campaign:next-show`, futureDate);

    return futureDate;
  };

  const handleOpen = () => {
    if (openingConditionFn && !openingConditionFn()) return;
    const currentDate = moment().format("YYYY-MM-DD");
    const nextShowDate = localStorage.getItem(`ar-${name}-campaign:next-show`);

    // renderDelaySeconds wait before showing modal
    if (renderDelaySeconds && renderDelaySeconds > 0) {
      const showTime = sessionStorage.getItem(`ar-${name}-campaign:time`);
      if (showTime) {
        if (moment().valueOf() < parseInt(showTime)) return;
      } else {
        sessionStorage.setItem(
          `ar-${name}-campaign:time`,
          moment().add(renderDelaySeconds, "seconds").valueOf().toString()
        );
        setTimeout(handleOpen, renderDelaySeconds * 1000);
        return;
      }
    }

    // don't show survey outside campaign start and finish dates or until next show date
    if (currentDate < nextShowDate || currentDate < startDate || currentDate > finishDate) return;

    setOpen(true);

    Events.sendCampaignModalViewed({ campaignName: name });
  };

  const handleClose = () => {
    const nextShowDate = hideForGivenDays();
    onClose && onClose();
    Events.sendCampaignModalClosed({ campaignName: name, nextShowDate });
    setOpen(false);
  };

  useEffect(handleOpen, []);

  return (
    <Modal open={open || clickOpened} onClose={handleClose} $maxWidth={maxWidth} {...otherProps}>
      <DialogContent>
        <CloseSection>
          <ClearIcon onClick={handleClose} data-testid="close" />
        </CloseSection>
        {children}
      </DialogContent>
    </Modal>
  );
}

export const Heading = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 31px;
  font-family: Lora, "Montserrat", Helvetica, Arial, sans-serif;
  line-height: 30px;
`;

export const B = styled.span`
  font-weight: 700;
`;

export const Description = styled(Typography)`
  text-align: center;
`;

export const AccordionTitle = ({ children }: { children: ReactNode }) => (
  <AccordionSummary expandIcon={<ExpandMoreIcon />}>{children}</AccordionSummary>
);

export const CloseSection = styled.div`
  text-align: right;
  margin: 8px 8px 3px 0;
  z-index: 10;
`;

export const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const Img = styled.img`
  width: 100%;
  margin: 10px 0 0 0;
  object-fit: cover;
  max-height: 272px;
  object-position: 50% 20%;
`;
export const ImgCredits = styled.div`
  position: relative;
  bottom: 40px;
  right: 10px;
  font-size: 12px;
`;

export const Modal = styled(Dialog)<{ $maxWidth?: string }>`
  .MuiPaper-root {
    border-radius: 0;
  }
  .MuiDialogContent-root {
    padding: 0;
    ${({ $maxWidth }) =>
      $maxWidth &&
      `
      max-width: ${$maxWidth};
    `}
  }
  font-size: 16px;
`;

export const CampaignAccordion = styled(Accordion)`
  padding: 0;
  .MuiButtonBase-root {
    padding: 0;
    justify-content: right;
    max-height: 40px;
  }
  .MuiAccordionSummary-content {
    max-width: max-content;
    margin: 0 5px 0 0;
    font-size: 11px;
    font-weight: 500;
  }
  .Mui-expanded {
    max-height: 40px;
  }
  .MuiAccordionSummary-root {
    min-height: 30px;
  }
  .MuiAccordionDetails-root {
    padding: 0;
    ol {
      padding-left: 20px;
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      li {
        margin-bottom: 15px;
      }
    }
  }
`;

export const CallToAction = styled(FlexBox)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const CustomButton = styled(Button).attrs({
  size: BUTTON_SIZES.MEDIUM,
})`
  width: 100%;
  @media only screen and (max-width: 769px) {
    font-size: 11px;
  }
`;

type ActionButtonProps = {
  campaignName: string;
  onClick?: Function;
  link: string;
  children: ReactNode;
};

export const ActionButton = ({ campaignName, onClick, link, children }: ActionButtonProps) => {
  const handleClick = (e) => {
    Events.sendCampaignModalClicked({ campaignName, link });
    return onClick(e);
  };
  return (
    <CustomButton onClick={handleClick} href={link}>
      {children}
    </CustomButton>
  );
};
