import React from "react";
import { GetListing_listing, GetListing_listing_price } from "../../queries/types/GetListing";
import Box, { BoxProps } from "../../../../elements/v2/Box/Box";
import { ListingShape } from "../../../../../types/graphql-global-types";
import { AdminProps, ListingRoutes } from "../../ListingPage";
import ListingAction from "./ListingAction/ListingAction";
import { formatMoney } from "../../../../../utils/numbers";
import ApprovalForm from "./ApprovalForm/ApprovalForm";
import { FontAwesomeIconName } from "../../../../elements/FontAwesomeIcon/FontAwesomeIcon";
import styled from "styled-components";
import ListingStatus from "./ListingStatus/ListingStatus";
import { Link } from "@material-ui/core";

type Props = BoxProps & {
  isAdmin: boolean;
  routes: ListingRoutes;
  adminProps: AdminProps;
  listing?: GetListing_listing;
};

const getCalculatedCommission = (
  commissionPercent: number,
  listingPrice: GetListing_listing_price
) => {
  if (!listingPrice || !listingPrice?.fractional) return null;
  const commissionAmount = {
    ...listingPrice,
    fractional: Math.round((Number(listingPrice.fractional) * commissionPercent) / 100),
  };
  return formatMoney(commissionAmount);
};

export default function ListingActions({ isAdmin, routes, adminProps, listing, ...props }: Props) {
  const { listingShape, isMine, sold, closed, affiliateSource, brand, price, url } = listing;
  const isAuthorised = isMine || isAdmin;
  const oldListingPageUrl = url.replace(/\/p\//, "/po/");

  const {
    isApproved,
    isApprovalPending,
    isApprovalRejected,
    sellerCommissionPercent,
    brandApprovalNeeded,
  } = adminProps;

  if (sold)
    return (
      <Box {...props}>
        <ListingStatus mb={2} iconName={FontAwesomeIconName.LOCK}>
          Listing sold
        </ListingStatus>
        <Actions>
          <ListingAction mb={2} visible={isAdmin} iconName={FontAwesomeIconName.BRIEFCASE}>
            Affiliate Source: {affiliateSource}
          </ListingAction>
          {closed ? (
            <ListingAction
              visible={isAuthorised}
              href={routes.delete}
              iconName={FontAwesomeIconName.TRASH}
              confirm={{
                message: "Deleting a listing cannot be undone. \nAre you sure you want to proceed?",
              }}
            >
              Delete Listing
            </ListingAction>
          ) : (
            <ListingAction
              visible={isAuthorised}
              href={routes.close}
              iconName={FontAwesomeIconName.LOCK}
            >
              Make listing private
            </ListingAction>
          )}
          <ListingAction
            visible={isAdmin}
            href={routes.undoSold}
            iconName={FontAwesomeIconName.UNLOCK}
          >
            Remove sold marker from listing
          </ListingAction>
          <ListingAction
            visible={isAdmin}
            href={oldListingPageUrl}
            iconName={FontAwesomeIconName.BOOK}
          >
            Go to old listing page
          </ListingAction>
        </Actions>
      </Box>
    );
  else if (listingShape === ListingShape.recycle)
    return (
      <Box {...props}>
        <ListingStatus mb={2} iconName={FontAwesomeIconName.RECYCLE}>
          Listing marked for donation
        </ListingStatus>
        <Actions>
          <ListingAction mb={2} visible={isAdmin} iconName={FontAwesomeIconName.BRIEFCASE}>
            Affiliate Source: {affiliateSource}
          </ListingAction>
          <RowDiv>
            <ListingAction
              visible={isAuthorised}
              href={routes.undoDonate}
              iconName={FontAwesomeIconName.REPLY}
            >
              Undo Donate
            </ListingAction>
            <span dangerouslySetInnerHTML={{ __html: "&nbsp;".repeat(6) }} />
            <Link
              underline={"always"}
              variant={"h6"}
              target="_blank"
              href="https://airrobe.zendesk.com/hc/en-us/articles/4417340389017-Recycling-on-AirRobe"
            >
              How to Donate
            </Link>
          </RowDiv>
          <ListingAction
            visible={isAdmin}
            href={oldListingPageUrl}
            iconName={FontAwesomeIconName.BOOK}
          >
            Go to old listing page
          </ListingAction>
        </Actions>
      </Box>
    );
  else if (closed)
    return (
      <Box {...props}>
        <ListingStatus mb={2} visible={isAuthorised} iconName={FontAwesomeIconName.LOCK}>
          Listing is private
        </ListingStatus>
        <Actions>
          <ListingAction mb={2} visible={isAdmin} iconName={FontAwesomeIconName.BRIEFCASE}>
            Affiliate Source: {affiliateSource}
          </ListingAction>
          <ListingAction
            visible={isAuthorised}
            href={routes.editProduct}
            iconName={FontAwesomeIconName.EDIT}
          >
            Edit Item
          </ListingAction>
          <ListingAction
            visible={isAuthorised}
            href={routes.editSale}
            iconName={FontAwesomeIconName.SELL}
          >
            Re-sell this listing
          </ListingAction>
          <ListingAction
            visible={isAuthorised}
            href={routes.editRental}
            iconName={FontAwesomeIconName.RENT}
          >
            Rent this listing
          </ListingAction>
          <ListingAction
            visible={isAdmin}
            href={routes.editOld}
            iconName={FontAwesomeIconName.EDIT}
          >
            Open in old listing form
          </ListingAction>
          <ListingAction
            visible={isAuthorised}
            href={routes.donate}
            iconName={FontAwesomeIconName.RECYCLE}
          >
            Donate Listing
          </ListingAction>
          <ListingAction
            visible={isAuthorised}
            href={routes.delete}
            iconName={FontAwesomeIconName.TRASH}
            confirm={{
              message: "Deleting a listing cannot be undone. \nAre you sure you want to proceed?",
            }}
          >
            Delete Listing
          </ListingAction>
          <ListingAction
            visible={isAdmin}
            href={oldListingPageUrl}
            iconName={FontAwesomeIconName.BOOK}
          >
            Go to old listing page
          </ListingAction>
        </Actions>
      </Box>
    );
  else
    return (
      <Box {...props}>
        <ListingStatus mb={2} visible={isApprovalPending} iconName={FontAwesomeIconName.PENDING}>
          Listing is pending
        </ListingStatus>
        <ListingStatus mb={2} visible={isApprovalRejected} iconName={FontAwesomeIconName.CROSS}>
          Listing is rejected
        </ListingStatus>
        <Actions>
          <ListingAction
            mb={1}
            visible={isAdmin && isApprovalPending && brandApprovalNeeded}
            bolder
            iconName={FontAwesomeIconName.ALERT}
          >
            Approving this listing will approve its brand: {brand}
          </ListingAction>
          {isAdmin && (
            <ApprovalForm
              mb={1}
              author={listing.author}
              route={routes.approve}
              sellerCommissionPercent={sellerCommissionPercent}
              isApprovalPending={isApprovalPending}
            />
          )}
          <ListingAction
            mb={2}
            visible={isAdmin && isApprovalPending}
            href={routes.reject}
            iconName={FontAwesomeIconName.CROSS}
          >
            Reject Listing
          </ListingAction>
          <ListingAction visible={isAdmin && isApproved} iconName={FontAwesomeIconName.COMMISSION}>
            Commission: {sellerCommissionPercent}% (-{" "}
            {getCalculatedCommission(sellerCommissionPercent, price)})
          </ListingAction>
          <ListingAction mb={2} visible={isAdmin} iconName={FontAwesomeIconName.BRIEFCASE}>
            Affiliate Source: {affiliateSource}
          </ListingAction>
          <ListingAction
            visible={isAuthorised}
            href={routes.editProduct}
            iconName={FontAwesomeIconName.EDIT}
          >
            Edit Item
          </ListingAction>
          <ListingAction
            visible={isAuthorised}
            href={routes.editSale}
            iconName={FontAwesomeIconName.SELL}
          >
            Edit Sale
          </ListingAction>
          <ListingAction
            visible={isAuthorised}
            href={routes.editRental}
            iconName={FontAwesomeIconName.RENT}
          >
            Edit Rental
          </ListingAction>
          <ListingAction
            visible={isAdmin}
            href={routes.editOld}
            iconName={FontAwesomeIconName.EDIT}
          >
            Open in old listing form
          </ListingAction>
          <ListingAction
            visible={isAuthorised}
            href={routes.close}
            iconName={FontAwesomeIconName.LOCK}
          >
            Make listing private
          </ListingAction>
          <ListingAction visible={isAdmin} href={routes.star} iconName={FontAwesomeIconName.STAR}>
            Move to top of homepage
          </ListingAction>
          <ListingAction
            visible={isAdmin}
            href={oldListingPageUrl}
            iconName={FontAwesomeIconName.BOOK}
          >
            Go to old listing page
          </ListingAction>
        </Actions>
      </Box>
    );
}

const Actions = styled(Box)`
  padding: 20px;
  margin-bottom: 24px;
  background: #f6f6f6;

  &:empty {
    display: none;
  }
`;

const RowDiv = styled.div`
  display: flex;
  align-items: center;
  .MuiLink-root {
    font-weight: 700;
    position: relative;
    bottom: 1px;
  }
`;
