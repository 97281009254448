import { Accordion, AccordionDetails, AccordionSummary, Divider } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { ReactNode, useMemo } from "react";
import styled from "styled-components";
import Typography from "../../../elements/v2/Typography/Typography";
import { FilterItem, useFilter } from "@sajari/react-hooks";
import { FilterNames } from "../../../../utils/filters";
import { map } from "lodash";
import { colours } from "./filters/ColourFilter";

interface Props {
  label: string;
  filterName?: FilterNames;
  minOptionsWithCount?: number;
  children: ReactNode;
}

export const hasValidColourOptions = ({
  optionsWithCount,
  minOptionsWithCount,
}: {
  optionsWithCount: FilterItem[];
  minOptionsWithCount: number;
}) => {
  const validOptions = optionsWithCount.filter(({ label }) => map(colours, "id").includes(label));

  if (validOptions.length < minOptionsWithCount) {
    return false;
  }

  return true;
};

export const hasAvailableOptions = (
  filterName: FilterNames,
  filterOptions: FilterItem[],
  minOptionsWithCount: number
) => {
  const optionsWithCount = filterOptions.filter(({ count }) => count > 0);

  if (filterName === "colour") {
    return hasValidColourOptions({ optionsWithCount, minOptionsWithCount });
  }

  // fall back after color
  if (optionsWithCount.length < minOptionsWithCount) {
    return false;
  }

  return true;
};

export default function FilterSection({
  label,
  filterName,
  minOptionsWithCount = 2,
  children,
}: Props) {
  const filter = filterName && useFilter(filterName);
  const defaultExpanded = useMemo(() => !!filter?.selected[0], [filter?.selected]);
  const shouldNotRenderFilterGroup =
    filter &&
    !filter.selected.length &&
    !hasAvailableOptions(filterName, filter.options, minOptionsWithCount);

  if (shouldNotRenderFilterGroup) {
    return null;
  }

  return (
    <>
      <Accordion defaultExpanded={defaultExpanded} className="t-searchFilterSection">
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className="t-searchFilterSectionSummary">
          <Title>{label}</Title>
        </AccordionSummary>
        <AccordionDetails className="t-searchFilterSectionDetails">{children}</AccordionDetails>
      </Accordion>
      <Divider variant="middle" component="p" />
    </>
  );
}

export const Title = styled(Typography)`
  font-size: 12px;
  font-weight: 600;
  color: #222222;
  text-transform: uppercase;
`;
