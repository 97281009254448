import pluralize from "pluralize";

interface Args {
  positiveCount: number;
  totalCount: number;
}

export function formatReviewCounts({ positiveCount, totalCount }: Args) {
  if (totalCount === 0) {
    return;
  }

  return [
    pluralize("review", totalCount, true),
    `${Math.round((positiveCount / totalCount) * 100)}% positive`,
  ].join(", ");
}
