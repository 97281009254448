import React, { ReactElement, ReactNode } from "react";

interface Props {
  children: ReactElement[];
  separator: ReactNode;
}

export default function JoinComponents({ children, separator }: Props) {
  return children.reduce(
    (acc, next) =>
      acc === null ? (
        next
      ) : (
        <>
          {acc}
          {separator}
          {next}
        </>
      ),
    null
  );
}
