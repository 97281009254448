import React, { ReactNode } from "react";
import {
  BrowserRouter,
  BrowserRouterProps,
  StaticRouter,
  StaticRouterProps,
} from "react-router-dom";
import isSSR from "./isSSR";

type Props = (StaticRouterProps | BrowserRouterProps) & {
  children?: ReactNode;
};

// Use the StaticRouter or BrowserRouter, depending on whether we're on the server or client side
export default function Router({ context, ...props }: Props) {
  if (isSSR()) {
    const { location } = context;
    return <StaticRouter location={location} {...props} />;
  }

  return <BrowserRouter {...props} />;
}

export const wrapWithRouter = (context) => (Component) => () => (
  <Router context={context}>
    <Component />
  </Router>
);
