import React from "react";
import { makeStyles } from "@material-ui/core";
import Box from "../../../elements/v2/Box/Box";
import styled from "styled-components";
import Typography from "../../../elements/v2/Typography/Typography";
import { Divider } from "@material-ui/core";
import { breakpoints } from "../../../../utils/styledHelpers";
import FlexBox from "../../../elements/v2/Box/FlexBox";
import { formatRentalDate } from "../../../../utils/date";
import { BestOffer } from "./BestOffer";

export enum DELIVERY_OPTIONS {
  SHIPPING = "shipping",
  PICKUP = "pickup",
}

export type ProductDisplayProps = {
  imageUrl?: string;
  listingTitle?: string;
  listingColour?: string;
  listingSize?: string;
  retailPrice?: string | null;
  salePrice?: string;
  subTotal?: string;
  shippingCost?: string;
  total?: string;
  delivery?: DELIVERY_OPTIONS;
  startOn?: string;
  endOn?: string;
  offeredPrice?: string;
};

export const ProductDisplay = ({
  imageUrl,
  listingSize,
  listingTitle,
  retailPrice,
  listingColour,
  salePrice,
  subTotal,
  shippingCost,
  total,
  delivery,
  startOn,
  endOn,
  offeredPrice,
}: ProductDisplayProps) => {
  const classes = useStyles();
  return (
    <Box px={4}>
      <PageTitle>Order summary</PageTitle>

      <Container>
        <ImageContainer>
          <img
            className={classes.listingImage}
            src={`${imageUrl}?fit=crop&auto=format`}
            alt={listingTitle}
          />
        </ImageContainer>

        <Box>
          <ProductTitle>{listingTitle}</ProductTitle>
          {listingSize && <ProductSize>Size: {listingSize}</ProductSize>}
          {listingColour && <ProductColour>Colour: {listingColour}</ProductColour>}

          <FlexBox mt={2} mb={2}>
            {retailPrice && <RetailPriceText>{retailPrice}</RetailPriceText>}
            <SalePriceText>{salePrice}</SalePriceText>
          </FlexBox>

          {offeredPrice && <BestOffer listingPrice={salePrice} offeredPrice={offeredPrice} />}
        </Box>

        <PriceSection>
          {delivery === DELIVERY_OPTIONS.SHIPPING && (
            <Box mt={5}>
              <FlexBox justifyContent="space-between">
                <Text>Subtotal:</Text>
                <Text>{offeredPrice ?? subTotal}</Text>
              </FlexBox>
              <FlexBox justifyContent="space-between">
                <Text>Shipping:</Text>
                <Text>{shippingCost}</Text>
              </FlexBox>
            </Box>
          )}
          {startOn && endOn && (
            <Box mt={5}>
              <FlexBox justifyContent="space-between">
                <Text>Receive-by date:</Text>
                <Text>{formatRentalDate(startOn)}</Text>
              </FlexBox>
              <FlexBox justifyContent="space-between">
                <Text>Return-by date:</Text>
                <Text>{formatRentalDate(endOn)}</Text>
              </FlexBox>
            </Box>
          )}

          <Divider style={{ marginTop: "24px", marginBottom: "24px" }} />
          <FlexBox justifyContent="space-between">
            <Text>Total:</Text>
            <Text>{total}*</Text>
          </FlexBox>
        </PriceSection>
      </Container>
      <Star>*service fee may apply</Star>
    </Box>
  );
};

const PageTitle = styled(Typography)`
  font-size: 13px;
  font-weight: 500;
  color: #222222;
  text-transform: uppercase;
  margin-bottom: 24px;
`;

const PriceSection = styled(Box)``;

const Container = styled(Box)`
  ${breakpoints.down("lg")} {
    display: flex;
    flex-direction: column;
  }
`;

const ImageContainer = styled(Box)`
  ${breakpoints.down("lg")} {
    margin-bottom: 0px;
    margin-right: 16px;
  }
`;

const ProductTitle = styled(Typography)`
  font-size: 17px;
  font-weight: 700;
  color: #222222;
  text-transform: uppercase;
  ${breakpoints.down("lg")} {
    font-size: 13px;
  }
`;

const ProductColour = styled(Typography)`
  font-size: 15px;
  font-weight: 500;
  color: #666666;
  margin-top: 4px;
  ${breakpoints.down("lg")} {
    font-size: 13px;
  }
`;

const ProductSize = styled(Typography)`
  font-size: 15px;
  font-weight: 500;
  color: #222222;
  margin-top: 16px;
  ${breakpoints.down("lg")} {
    font-size: 13px;
  }
`;

const SalePriceText = styled(Typography)`
  font-size: 15px;
  font-weight: 700;
  color: #222222;
  ${breakpoints.down("lg")} {
    font-size: 13px;
  }
`;

const RetailPriceText = styled(Typography)`
  font-size: 15px;
  font-weight: 500;
  color: #666666;
  text-transform: uppercase;
  margin-right: 8px;
  text-decoration: line-through;
  ${breakpoints.down("lg")} {
    font-size: 13px;
  }
`;

const Text = styled(Typography)`
  font-size: 15px;
  font-weight: 500;
  color: #222222;
  ${breakpoints.down("lg")} {
    font-size: 13px;
  }
`;

const Star = styled.p`
  width: 100%;
  text-align: right;
  font-size: 8px;
`;

const useStyles = makeStyles((theme) => ({
  listingImage: {
    width: "100%",
    height: "50%",
    objectFit: "cover",
    [theme.breakpoints.down("lg")]: {
      width: "80px",
      height: "100px",
    },
  },
}));
