import { useMutation } from "@apollo/client";
import useInterval from "@use-it/interval";
import React, { useEffect } from "react";
import Spinner from "../../elements/LoadingIndicator/Spinner";
import css from "./AccountVerificationPending.module.css";
import REFETCH_MY_STRIPE_VERIFICATION_STATUS from "./mutations/RefetchMyStripeVerificationStatus";
import { RefetchMyStripeVerificationStatus } from "./mutations/types/RefetchMyStripeVerificationStatus";

export const POLL_EVERY_MS = 2000;

// This component renders a loading indicator and polls the server until stripe has made a
// determination on whether more information is required. Usually this takes less than a minute.
// Because the refetch may update data in the database (we store whether or not the account requires
// verification), we structure the request as a mutation and not a query.
export default function AccountVerificationPending() {
  const [refetchStatus, { data, error }] = useMutation<RefetchMyStripeVerificationStatus>(
    REFETCH_MY_STRIPE_VERIFICATION_STATUS,
    { variables: { input: {} } }
  );

  useInterval(refetchStatus, POLL_EVERY_MS);

  useEffect(() => {
    if (!data) return;

    const { stripeAccount } = data.refetchMyStripeVerificationStatus;

    // If the account is no longer pending verification, reload the page
    if (!stripeAccount.isPendingVerification) window.location.reload();
  }, [data]);

  useEffect(() => {
    if (error) throw error;
  }, [error]);

  return (
    <div>
      <div className={css.wrapper}>
        <Spinner className={css.spinner} />
        <h3 className="stripe-account-pending-verification">
          Waiting for confirmation from our payment provider.
        </h3>
      </div>
      <p>
        More information may be required to verify your identity. This usually only takes a moment.
      </p>
    </div>
  );
}
