import { DocumentNode, OperationVariables, QueryResult, useQuery } from "@apollo/client";
import React, { createContext, ReactNode } from "react";

// Given a query, create a context that provides the result of that query to children. This should
// not be over-used, as in general, pages should simply fetch the data that they need in their own
// query, but for some cases, where we want data to be available deep in the component hierarchy,
// this may be the way to go.
interface ProviderProps {
  children: ReactNode;
}

export default function withQueryProvider<Q, O = {}>(
  query: DocumentNode,
  options: OperationVariables = {}
) {
  const Context = createContext<Partial<QueryResult<Q, O>>>({});

  function Provider({ children }: ProviderProps) {
    const { data, loading, error, refetch } = useQuery<Q, O>(query, options);

    return (
      <Context.Provider value={{ data, loading, error, refetch }}>{children}</Context.Provider>
    );
  }

  return { Context, Provider };
}
