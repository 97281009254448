import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Card,
  createStyles,
  Dialog,
  Fade,
  Link,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import useInterval from "@use-it/interval";
import axios from "axios";
import { FieldInputProps, useField } from "formik";
import { keyBy, omit, omitBy } from "lodash";
import initial from "lodash/initial";
import last from "lodash/last";
import { DropzoneArea } from "material-ui-dropzone";
import { v4 as uuidv4 } from "uuid";
import usePrevious from "../../../../hooks/usePrevious";
import { GetListingFormData_listing } from "../../ListingForm/queries/types/GetListingFormData";
import DraggableImagesPreviewPanel from "./DraggableImagesPreviewPanel";
import { ReorderListingImages } from "./mutations/types/ReorderListingImages";
import CREATE_LISTING_IMAGE from "./mutations/createListingImage";
import DELETE_LISTING_IMAGE from "./mutations/deleteListingImage";
import REORDER_LISTING_IMAGES from "./mutations/reorderListingImages";
import GET_LISTING_IMAGES from "./queries/getListingImages";
import { GetListingImages_listingImages } from "./queries/types/GetListingImages";
import Box from "../../../elements/v2/Box/Box";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    preview: {
      paddingTop: 8,
    },
    tips: {
      display: "flex",
      flexDirection: "column",
    },
    moreTips: {
      alignSelf: "center",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalTitle: {
      fontWeight: 600,
    },
    modalInner: {
      padding: 16,
      outline: 0,
      [theme.breakpoints.down("sm")]: {
        padding: 8,
      },
    },
  })
);

export interface File {
  uuid: string;
  listingImageId: number;
  filename: string;
  path: string;
  asDataURL: string | ArrayBuffer;
  asBinary: any;
  progress: number;
  type: string;
  doneUploading: boolean;
  doneProcessing: boolean;
  processedURL: string;
  error: boolean;
}

export interface S3Props {
  key: string;
  AWSAccessKeyId: string;
  policy: string;
  signature: string;
  acl: string;
  success_action_status: string;
  url: string;
}

interface Props {
  field: FieldInputProps<string>;
  s3Props: S3Props;
  listing: GetListingFormData_listing;
  showTips: Boolean;
}

const stringToURLSafe = (s: string) => s.replace(/[^a-z0-9\-]/gi, "_").toLowerCase();

const filenameToURLSafe = (filename: string) => {
  const parts = filename.split(".");
  const extension = last(parts).toLowerCase();
  const body = initial(parts).join(".");

  return [stringToURLSafe(body), extension].join(".");
};

const fileExtension = (filename: string) => last(filename.split(".")).toLowerCase();

function contentTypeByFilename(filename) {
  const map = {
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    png: "image/png",
    gif: "image/gif",
    heic: "image/heic",
  };

  return map[fileExtension(filename)];
}

// Set or update a file from the state by id
const setFileWith = (
  setFilesById: React.Dispatch<React.SetStateAction<{ [id: string]: File }>>
) => (file: Partial<File>) =>
  setFilesById((files) => {
    const fileWas = files[file.uuid];
    return { ...files, [file.uuid]: { ...fileWas, ...file } };
  });

const removeFileByListingImageIdWith = (filesById, setFilesById) => (listingImageId: number) => {
  setFilesById((files) => omitBy(files, (file) => file.listingImageId === listingImageId));
};

const removeFileByUUIDWith = (setFilesById) => (uuid: string) =>
  setFilesById((files) => omit(files, uuid));

const uploadFileToS3With = (setFile, s3Props) => (file, fileIndex) => {
  const { key, AWSAccessKeyId, policy, signature, acl, success_action_status, url } = s3Props;

  const path = key.replace("${index}", fileIndex).replace("${filename}", file.filename);
  setFile({ ...file, path: url + path });

  var bodyFormData = new FormData();
  bodyFormData.append("key", path);
  bodyFormData.append("AWSAccessKeyId", AWSAccessKeyId);
  bodyFormData.append("policy", policy);
  bodyFormData.append("signature", signature);
  bodyFormData.append("acl", acl);
  bodyFormData.append("success_action_status", success_action_status);
  bodyFormData.append("Content-Type", file.type);
  bodyFormData.append("file", file.asBinary);

  return axios({
    method: "post",
    url: url,
    data: bodyFormData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: ({ loaded, total }) =>
      setFile({
        ...file,
        progress: Math.round((loaded / total) * 100),
      }),
  });
};

const addFilesWith = (setFile, uploadFileToS3) => (acceptedFiles) => {
  acceptedFiles.forEach((newFile, i) => {
    const uuid = uuidv4();

    let reader = new FileReader();

    reader.onloadend = () => {
      const fileName = filenameToURLSafe(newFile.name);
      const fileType = contentTypeByFilename(fileName);
      const file = {
        uuid,
        filename: fileName,
        type: fileType,
        asDataURL: reader.result,
        asBinary: newFile,
        progress: 0,
        doneUploading: false,
        doneProcessing: false,
      };
      setFile(file);
      uploadFileToS3(file, i)
        .then(() => setFile({ uuid: file.uuid, doneUploading: true }))
        .catch((error) => {
          console.log(error);
          setFile({ uuid: file.uuid, error: true });
        });
    };

    reader.readAsDataURL(newFile);
  });
};

const createListingImagesWith = (createListingImage, listingId) => (
  prevFilesById: { [id: string]: File },
  filesById: { [id: string]: File }
) => {
  if (prevFilesById !== undefined) {
    // Check if for any file...
    for (const uuid of Object.keys(prevFilesById)) {
      // it has just finished uploading to S3...
      if (!prevFilesById[uuid].doneUploading && filesById[uuid]?.doneUploading) {
        // and create a listing image for it
        createListingImage({
          variables: {
            input: {
              listingId: listingId,
              image: {
                uuid: uuid,
                path: filesById[uuid].path,
                filename: filesById[uuid].filename,
              },
            },
          },
        });
      }
    }
  }
};

const updateImagesStatusWith = (setFile, filesById: { [id: string]: File }) => (
  listingImages: [GetListingImages_listingImages]
) => {
  listingImages
    .filter((image) => image.ready)
    .forEach((image) => {
      const fileToUpdate: File = Object.values(filesById).find(
        (file) => file.listingImageId === image.id
      );
      setFile({
        uuid: fileToUpdate.uuid,
        doneProcessing: true,
        processedURL: image.squareUrl,
      });
    });
};

const pollListingImagesStatusWith = (filesById, getListingImages) => () => {
  const filesWaitingProcessing = Object.values(filesById).filter(
    (file: File) => file.doneUploading && !file.doneProcessing && file.listingImageId
  );

  if (filesWaitingProcessing.length > 0) {
    getListingImages({
      variables: {
        ids: filesWaitingProcessing.map((file: File) => file.listingImageId),
      },
    });
  }
};

function findFilePosition(files: File[], file: File) {
  return files.findIndex((f) => f.uuid === file.uuid);
}

const ImageUploader = ({ field, s3Props, listing, showTips = true }: Props) => {
  const classes = useStyles();
  const [_props, { value = [] }, { setValue }] = useField(field);
  const initialState = keyBy(value, "uuid");
  const [filesById, setFilesById] = useState<{ [id: string]: File }>(initialState);
  const [modalOpen, setModalOpen] = React.useState(false);
  const prevFilesById = usePrevious(filesById);
  const setFile = setFileWith(setFilesById);
  const removeFileByListingImageId = removeFileByListingImageIdWith(filesById, setFilesById);
  const uploadFileToS3 = uploadFileToS3With(setFile, s3Props);
  const addFiles = addFilesWith(setFile, uploadFileToS3);
  const updateImageStatus = updateImagesStatusWith(setFile, filesById);
  const [getListingImages, _lazyData] = useLazyQuery(GET_LISTING_IMAGES, {
    fetchPolicy: "no-cache",
    onCompleted: ({ listingImages }) => updateImageStatus(listingImages),
  });
  const [createListingImage, _data] = useMutation(CREATE_LISTING_IMAGE, {
    onCompleted: (data) => {
      if (!data.createListingImage.success) {
        setFile({
          uuid: data.createListingImage.uuid,
          error: true,
        });
      } else {
        setFile({
          uuid: data.createListingImage.uuid,
          listingImageId: data.createListingImage.image.id,
        });
      }
    },
  });
  const createListingImages = createListingImagesWith(createListingImage, listing?.id);
  const [deleteListingImage, __data] = useMutation(DELETE_LISTING_IMAGE, {
    onCompleted: (data) => removeFileByListingImageId(data.deleteListingImage.id),
  });
  const pollListingImagesStatus = pollListingImagesStatusWith(filesById, getListingImages);
  const removeFileByUUID = removeFileByUUIDWith(setFilesById);

  useInterval(pollListingImagesStatus, 2000);

  const [reorderListingImages] = useMutation<ReorderListingImages>(REORDER_LISTING_IMAGES);

  function createOnReorderListingImages(listing) {
    const listingId = listing?.id;
    // When we re-order, first update the value in state and then send to server
    return (listingImageUUIds: Array<number>) => {
      const files = listingImageUUIds.map((id) => filesById[id]);
      const listingImageIds = files.map((thumb) => thumb.listingImageId);
      setValue(files);
      reorderListingImages({
        variables: { input: { listingId, listingImageIds } },
      });
    };
  }

  // When a file is added, send to S3 and then our server
  useEffect(() => {
    // get current array of files
    const oldFiles: File[] = value;
    // get new set of files, not in any particular order
    const newFiles = Object.values(filesById);

    // Set the value of the Formik field to the new files, while preserving the order of the existing files
    setValue(
      newFiles
        // get the files that were in the old files
        .filter((file) => findFilePosition(oldFiles, file) > -1)
        // sort them by their position in the old files
        .sort((a, b) => findFilePosition(oldFiles, a) - findFilePosition(oldFiles, b))
        // add any new files that were not in the old files
        .concat(newFiles.filter((file) => findFilePosition(oldFiles, file) < 0))
    );

    // Create listing images for any files that may have finished uploading to S3
    createListingImages(prevFilesById, filesById);
  }, [prevFilesById, filesById]);

  function handleOnDeleteListingImage(listingImageId) {
    deleteListingImage({
      variables: {
        input: { id: listingImageId },
      },
    });
  }

  return (
    <Box className={classes.root}>
      <Box>
        <DropzoneArea
          acceptedFiles={["image/*"]}
          filesLimit={10}
          maxFileSize={25000000}
          dropzoneText="Upload pictures"
          showPreviews={false}
          showPreviewsInDropzone={false}
          onDrop={addFiles}
          showAlerts={["error"]}
          Icon={null}
          inputProps={{
            "data-cy": "image-uploader",
          }}
        />
      </Box>
      <Box className={classes.preview}>
        <DraggableImagesPreviewPanel
          files={value}
          onDeleteListingImage={handleOnDeleteListingImage}
          onRemoveFile={removeFileByUUID}
          onReorder={createOnReorderListingImages(listing)}
        />
      </Box>
      {showTips && (
        <Box className={classes.tips}>
          <ul>
            <li>
              For best results, include at least three images: front, back, and a detail shot.
            </li>
            <li>Include a photo of tags and/or the authentication cert where applicable</li>
          </ul>
          <Box className={classes.moreTips}>
            <Link
              underline="always"
              onClick={() => {
                setModalOpen(true);
                return false;
              }}
              style={{
                cursor: "pointer",
              }}
            >
              Read more on photo tips
            </Link>
            <Dialog
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              className={classes.modal}
              fullWidth
              maxWidth="lg"
            >
              <Fade in={modalOpen}>
                <Card className={classes.modalInner}>
                  <Typography variant="h6" className={classes.modalTitle}>
                    Tips for listing
                  </Typography>
                  <ol>
                    <li>
                      <b>Make it fresh.</b> Use your mum&apos;s advice here and leave it as
                      you&apos;d like to find it. Give it a warm wash, hang it to dry, and
                      steam/press it, to refresh it back to life.
                    </li>
                    <li>
                      <b>Capture it.</b> Your phone and great lighting are all you need. Use a plain
                      background and take photos and at various distances to capture the detail,
                      too. You know what else helps? Mirror selfies!
                    </li>
                    <li>
                      <b>Flaws? Document them.</b> Be as honest as possible so you get glorious
                      reviews and the transaction is straight forward.
                    </li>
                    <li>
                      <b>Self-promo.</b> No shame! Post a throwback of your item to socials and
                      shout about how you’re part of the circular fashion movement.
                    </li>
                    <li>
                      <b>Prove it.</b> It helps to add an authenticity card or certificate to act as
                      a security guarantee for your buyer and makes your item more appealing too.
                    </li>
                    <li>
                      <b>Set a fair price.</b> Is it from a sought-after brand or does it have
                      long-term value? Is this style currently trending? Take some time to research
                      prices for similar items. Remember: If it doesn’t sell right away, you can
                      always lower the price! And, we have an experienced community who can help
                      you, too.
                    </li>
                  </ol>
                </Card>
              </Fade>
            </Dialog>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ImageUploader;
