import { initialize as initializeI18n } from "./i18n";
import moment from "moment";

export type InitializeLocaleProps = {
  locale: string;
  defaultLocale: string;
};

export const initializeLocale = (i18n: InitializeLocaleProps) => {
  const locale = i18n.locale;
  const defaultLocale = i18n.defaultLocale;
  initializeI18n(locale, defaultLocale, process.env.NODE_ENV);
  moment.locale(locale);
};
