import { gql } from "@apollo/client";
import TestimonialCardFragment from "../../../../graphql/fragments/testimonials";

export default gql`
  query GetPersonReviews($id: String!, $page: Int, $perPage: Int) {
    person(id: $id) {
      id
      receivedTestimonials(page: $page, perPage: $perPage) {
        page
        pageCount
        totalCount
        results {
          ...TestimonialCardFragment
        }
      }
      positiveTestimonials: receivedTestimonials(onlyPositive: true) {
        totalCount
      }
    }
  }
  ${TestimonialCardFragment}
`;
