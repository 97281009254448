import React from "react";
import MuiChip from "@material-ui/core/Chip";
import styled from "styled-components";
import { ChipProps as MuiChipProps } from "@material-ui/core/Chip/Chip";
import { withTextContent } from "../Typography/Typography";

export interface ChipProps extends MuiChipProps {
  $selected?: boolean;
}

const Chip = styled(
  withTextContent(({ ...props }: ChipProps) => <MuiChip variant="outlined" {...props} />)
).attrs(({ $selected }) => $selected && { className: "t-selected" })`
  border-radius: 4px;
  padding: 7px 8px 7px 8px;

  ${({ $selected, theme }) =>
    $selected
      ? `
        background-color: #222222;
        &:focus:not(:hover) {
          background-color: #222222;
        }
        .MuiChip-label {
          color: #FFFFFF;
        }
      `
      : `
        background-color: #FFFFFF;
        &:focus:not(:hover) {
          background-color: #FFFFFF;
        }
      `}
`;

export default Chip;
