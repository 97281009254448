import r from "r-dom";
import { RefObject } from "react";
import { Props as MenuItemProps } from "../../elements/MenuItem/MenuItem";
import Dropdown from "../Dropdown/Dropdown";
import MenuContent from "./MenuContent";
import MenuLabel from "./MenuLabel";
import MenuLabelDropdown from "./MenuLabelDropdown";
import MenuLabelPlain from "./MenuLabelPlain";

export enum MenuLabelTypeEnum {
  DROPDOWN = "dropdown",
  MENU = "menu",
  PLAIN = "plain",
}

const MENULABEL_MAP = {
  [MenuLabelTypeEnum["MENU"]]: MenuLabel,
  [MenuLabelTypeEnum["DROPDOWN"]]: MenuLabelDropdown,
  [MenuLabelTypeEnum["PLAIN"]]: MenuLabelPlain,
};

interface Props {
  innerRef?: RefObject<HTMLDivElement>;
  name?: string;
  identifier?: string;
  className?: string;
  labelClassName?: string;
  menuLabelType?: MenuLabelTypeEnum;
  content?: Array<MenuItemProps>;
  openOnHover?: boolean;
}

// TODO: now that this component is rendered using a Dropdown component, it can be merged with
// MenuContent to make things simpler.
function Menu({
  name,
  identifier,
  className,
  labelClassName,
  menuLabelType,
  content,
  openOnHover,
  innerRef,
}: Props) {
  const requestedLabel = MENULABEL_MAP[menuLabelType];
  const LabelComponent = requestedLabel != null ? requestedLabel : null;

  const handleComponent = r(LabelComponent, {
    name: name,
    extraClasses: labelClassName,
  });

  return r(
    Dropdown,
    {
      innerRef,
      className,
      openOnHover,
      handleComponent,
      closeOnClick: true,
    },
    [
      r(MenuContent, {
        key: `${identifier}_menucontent`,
        content: content,
      }),
    ]
  );
}

export default Menu;
