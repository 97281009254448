import { Grid } from "@material-ui/core";
import { Dictionary } from "lodash";
import React from "react";
import { GetCategories_categories } from "../queries/types/GetCategories";
import CustomProductFieldSection from "../Sections/CustomProductFieldSection";

const MandatoryCustomProductFieldsStep = (categoriesMap: Dictionary<GetCategories_categories>) => ({
  values,
}) => {
  if (!values.category) return null;

  const mandatoryCustomFields = categoriesMap[values.category].customFields.filter(
    (c) => c.required
  );

  return (
    <Grid container spacing={2} direction="column" wrap="nowrap">
      {mandatoryCustomFields.map((customField, i) => (
        <Grid item key={i}>
          <CustomProductFieldSection customField={customField} />
        </Grid>
      ))}
    </Grid>
  );
};

const withProps = (categoriesMap: Dictionary<GetCategories_categories>) =>
  MandatoryCustomProductFieldsStep(categoriesMap);

export default withProps;
