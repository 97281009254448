import { gql } from "@apollo/client";

const DONATE_LISTING = gql`
  mutation DonateListing($input: DonateListingMutationInput!) {
    donateListing(input: $input) {
      validationErrors {
        attribute
        errorMessage
      }
      listing {
        id
      }
    }
  }
`;

export default DONATE_LISTING;
