import React from "react";
import { H4, HeadingProps } from "../../../../elements/v2/Typography/Heading";
import styled from "styled-components";

export default function ProductHeading(props: HeadingProps) {
  return (
    <Container>
      <H4 {...props} />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 0px 0px 8px;
  border-bottom: 1px solid #d2d2d2;
`;
