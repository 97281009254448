import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Box from "../../../elements/v2/Box/Box";

export type StripeProps = {
  inUse: boolean;
  publishableKey: string;
  amount: number;
  currency: string;
};

const appearance = {
  theme: "stripe" as const,
  labels: "floating" as const,
  variables: {
    borderRadius: "2px",
  },
  rules: {
    ".Input": {
      paddingBottom: "10px",
      boxShadow: "none",
      border: "1px solid #ccc",
    },
    ".AccordionItem": {
      boxShadow: "none",
      border: "1px solid #ccc",
    },
    ".Label--resting": {
      color: "#666",
      fontWeight: "400",
    },
    ".Label--floating": {
      color: "#666",
      fontWeight: "400",
      fontSize: "10px",
    },
  },
};

export default function ({
  inUse,
  publishableKey,
  amount,
  currency,
  children,
}: StripeProps & { children: React.ReactNode }) {
  if (!inUse) return;

  const stripePromise = loadStripe(publishableKey);
  const options = {
    amount,
    currency,
    appearance,
    mode: "payment" as const,
    paymentMethodCreation: "manual" as const,
  };

  return (
    <Box mt={3}>
      <Elements stripe={stripePromise} options={options}>
        {children}
      </Elements>
    </Box>
  );
}
