import { renderToStringWithData } from "@apollo/client/react/ssr";
import { ServerStyleSheets as MuiServerStylesheet } from "@material-ui/core/styles";
import { ServerStyleSheet as StyledServerStylesheet } from "styled-components";
import React from "react";
import { APOLLO_STATE_VAR_NAME, client } from "../graphql/client";
import isSSR from "./isSSR";
import { isEmpty } from "lodash";
import { renderToString } from "react-dom/server";

// Our withSSR HOC function takes a component and performs a couple of tasks that support proper
// SSR. Firstly, we collect css from the components, so that it can be added to the page in SSR
// responses, so the page renders with correct styles. Secondly, we wait for any graphql requests to
// complete, to ensure that the component tree is up-to-date with data before being returned in the
// response. Finally, we extract the state of the apollo client (which is used to make API requests)
// and serialize it in the page. When the app hydrates on the client side, the apollo client will
// load this stored state. When used in conjunction with an appropriate cache policy, this will
// prevent duplicate queries being fired on the client side after they have been run on the server
// side.
//
// TODO: while we use both MUI's deprecated styles solution (https://mui.com/system/styles/basics/),
// and styled-components directly, we collect css for both and render them separately on the page.
// Our goal is to phase out the former, and be left with only styled components.

async function renderWithApolloData(app) {
  // Don't resolve graphql requests in SSR while testing as its currently flaky with Apollo
  if (process.env.NODE_ENV === "test") return renderToString(app);
  return await renderToStringWithData(app);
}

async function withSSR(Component: React.FC) {
  if (!isSSR()) return Component;

  // Otherwise, we need to extract both html and CSS on the server
  const muiSheets = new MuiServerStylesheet();
  const styledSheet = new StyledServerStylesheet();

  const App = styledSheet.collectStyles(muiSheets.collect(<Component />));

  return await renderWithApolloData(App).then((componentHtml) => {
    const componentJss = muiSheets.toString();
    const componentCss = styledSheet.getStyleTags();
    const componentHead = getApolloStateScript();

    styledSheet.seal();

    return {
      componentHtml,
      componentHead,
      componentJss,
      componentCss,
    };
  });
}

const getApolloStateScript = () => {
  const initialState = client.extract();

  if (isEmpty(initialState)) return "";

  const encodedState = JSON.stringify(initialState).replace(/</g, "\\u003c");

  return `
      <script>
        if (typeof window.${APOLLO_STATE_VAR_NAME} === "undefined") window.${APOLLO_STATE_VAR_NAME} = [];
        window.${APOLLO_STATE_VAR_NAME}.push(${encodedState});
      </script>
    `;
};

const ssrComponent = isSSR() ? withSSR : (Component) => Component;

export default ssrComponent;
