import React from "react";
import styled from "styled-components";
import Box, { BoxProps } from "./Box";
import { grid, GridProps as MuiGridProps } from "@material-ui/system";

type SystemProps = MuiGridProps;

export type GridProps = SystemProps & BoxProps;

const GridBox = ({ ...props }: GridProps) => <Box {...props} />;

export default styled(GridBox)`
  display: grid;
  ${grid}
`;
