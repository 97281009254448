import { useQuery } from "@apollo/client";
import { FormikValues } from "formik";
import { camelCase, Dictionary, keyBy, snakeCase } from "lodash";
import React, { useContext } from "react";
import { RoutesContext } from "../../../utils/routes";
import { S3Props } from "../FormControls/ImageUploader/ImageUploader";
import MultistepListingForm from "./MultistepListingForm";
import listingFormModel, { getInitialValues } from "./ProductForm/FormModel/listingFormModel";
import validationSchema from "./ProductForm/FormModel/validationSchema";
import UPSERT_PRODUCT from "./ProductForm/mutations/upsertProduct";
import GET_CATEGORIES from "./ProductForm/queries/GetCategories";
import {
  GetCategories,
  GetCategories_categories,
  GetCategories_categories_customFields,
} from "./ProductForm/queries/types/GetCategories";
import BrandRRPStep from "./ProductForm/Steps/BrandRRPStep";
import CategoryStep from "./ProductForm/Steps/CategoryStep";
import MandatoryCustomProductFieldsStep from "./ProductForm/Steps/MandatoryCustomProductFieldsStep";
import OptionalCustomProductFieldsStep from "./ProductForm/Steps/OptionalCustomProductFieldsStep";
import TitleImagesStep from "./ProductForm/Steps/TitleImagesStep";
import { GetListingFormData_listing } from "./queries/types/GetListingFormData";
import { CurrencyCode } from "../../../types/graphql-global-types";

interface Props {
  listing?: GetListingFormData_listing;
  s3Props: S3Props;
  initialCurrency: CurrencyCode;
}

function getCustomFieldValue(
  customField: GetCategories_categories_customFields,
  values: FormikValues
) {
  const value = values[camelCase(customField.key)];
  switch (customField.type) {
    case "NumericField":
      return { numValue: value ? Number(value) : null };
    case "TextField":
      return { textValue: value };
    case "CheckboxField": {
      return { selectedOptions: value };
    }
  }
}

export default function ProductForm({ listing, s3Props, initialCurrency }: Props) {
  const { error, data, loading } = useQuery<GetCategories>(GET_CATEGORIES);
  const routes = useContext(RoutesContext);

  // TODO: treat loading and error cases properly
  if (loading) return "...";
  if (!data) return null;
  if (error) return "Error!";

  const onMutationComplete = (setErrors) => (data) => {
    if (data.upsertProduct.validationErrors.length > 0) {
      setErrors(
        data.upsertProduct.validationErrors.map((e) => `${e.attribute} - ${e.error_message}`)
      );
    } else {
      window.location = routes.product_success_path(data.upsertProduct.product.id);
    }
  };

  const categoriesMap: Dictionary<GetCategories_categories> = keyBy(data.categories, "id");

  function buildSubmitPayload(values: FormikValues) {
    return {
      product: {
        title: values.title,
        imageIds: values.images.map((image) => image.listingImageId),
        // Brand was either typed freely by user or selected from
        // one of the options we presented
        brand: values.brand?.canonical ?? values.brand,
        rrp: values.rrp
          ? {
              formatted: values.rrp,
              currencyCode: values.currency,
            }
          : null,
        categoryId: values.category,
        description: values.description,
        customFields: values.category
          ? categoriesMap[values.category].customFields.map((customField) => ({
              key: snakeCase(customField.key),
              value: getCustomFieldValue(customField, values),
            }))
          : {},
      },
    };
  }

  const steps = [
    TitleImagesStep(s3Props, listing),
    BrandRRPStep,
    CategoryStep(categoriesMap),
    MandatoryCustomProductFieldsStep(categoriesMap),
    OptionalCustomProductFieldsStep(categoriesMap),
  ];

  return (
    <MultistepListingForm
      title={"Add to your circular wardrobe"}
      subtitle="Items can be repurposed now, or later"
      listing={listing}
      steps={steps}
      buildSubmitPayload={buildSubmitPayload}
      mutation={UPSERT_PRODUCT}
      onMutationComplete={onMutationComplete}
      listingFormModel={listingFormModel}
      initialValues={getInitialValues(categoriesMap, initialCurrency, listing)}
      validationSchema={validationSchema(categoriesMap)}
      submitButtonText="Save item"
      canEditProduct={false}
    />
  );
}
