import { Snackbar, TextField } from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";

// This component integrates Material UI's Autocomplete with Formik and was
// heavily inspired by https://github.com/stackworx/formik-material-ui/blob/master/packages/formik-material-ui-lab/src/Autocomplete.tsx
// Also see: https://material-ui.com/components/autocomplete

const TextAutocomplete = ({
  field,
  form,
  filterOptions,
  options,
  getOptionLabel,
  getOptionSelected,
  getValueFromOption,
  placeholder,
  warning,
}) => {
  // We need the states below in order to:
  // - only show options after the user has typed in a minimum number of
  // characters
  // - not show options after the first load of the form, before the user
  // has interacted with the field
  const [open, setOpen] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [firstTouch, setFirstTouch] = useState(true);

  const hasValue = (obj, key, value) => {
    return obj.hasOwnProperty(key) && obj[key] === value;
  };
  const handleWarning = () => {
    setOpenWarning(true);
  };
  const handleCloseWarning = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenWarning(false);
  };
  return (
    <>
      <Autocomplete
        debug
        onChange={(_, option) => {
          if (option) {
            setOpen(false);
            return form.setFieldValue(field.name, getValueFromOption(option));
          }
        }}
        onInputChange={(_, value) => {
          form.setFieldValue(field.name, value);
          if (value.length > 1 && !firstTouch) {
            setOpen(true);
          } else {
            setOpen(false);
          }
          setFirstTouch(false);
        }}
        onBlur={(event) => {
          if (!openWarning && warning && event.target.value) {
            setOpenWarning(
              !options.some(function (obj) {
                return hasValue(obj, "canonical", event.target.value);
              })
            );
          }
          setOpen(false);
          return field.onBlur(event ?? field.name);
        }}
        open={open}
        onClose={() => setOpen(false)}
        defaultValue={field.value}
        filterOptions={filterOptions}
        getOptionLabel={(option) => {
          // It's possible that the field's current value doesn't match any of
          // the options available (specially when using freeSolo mode). In that
          // case, the value's label is the value itself.
          return getOptionLabel(option) ?? option;
        }}
        getOptionSelected={getOptionSelected}
        options={options}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              ...params.inputProps,
              "data-cy": field.name,
            }}
          />
        )}
      />
      <Snackbar open={openWarning} autoHideDuration={10000} onClose={handleCloseWarning}>
        <Alert severity="warning">{warning}</Alert>
      </Snackbar>
    </>
  );
};

export default TextAutocomplete;
