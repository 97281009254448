import classNames from "classnames";
import { div } from "r-dom";
import arrowLeft from "./images/arrowLeft.svg";
import arrowRight from "./images/arrowRight.svg";
import css from "./RoundButton.module.css";
import { Link } from "react-router-dom";
import React from "react";

export enum ArrowButton_Direction {
  LEFT = "left",
  RIGHT = "right",
}

interface ArrowButtonProps {
  className?: string;
  link?: string;
  handleClick?: () => any;
  direction: ArrowButton_Direction;
  isDisabled: boolean;
}

export const ArrowButton = ({
  className,
  link,
  handleClick,
  direction,
  isDisabled,
}: ArrowButtonProps) =>
  rMaybeLink(
    { link, handleClick, enable: !isDisabled },
    div({
      className: classNames(className, css.arrowButton, css.roundButton, {
        [css.disabled]: isDisabled,
      }),
      dangerouslySetInnerHTML: {
        __html: direction === "left" ? arrowLeft : arrowRight,
      },
    })
  );

const rMaybeLink = ({ link, handleClick, enable }, children) =>
  enable ? (
    <Link to={link} onClick={handleClick}>
      {children}
    </Link>
  ) : (
    children
  );
