import { useQuery } from "@apollo/client";
import React from "react";
import ProgressWizard from "../../composites/ProgressWizard/ProgressWizard";
import GET_TRANSACTION from "./queries/GetTransaction";
import {
  GetTransaction,
  GetTransaction_me,
  GetTransaction_transaction,
} from "./queries/types/GetTransaction";
import Confirmed from "./utils/Confirmed";
import Paid from "./utils/Paid";
import Preauthorized from "./utils/Preauthorized";
import Rejected from "./utils/Rejected";
import getTransactionSteps from "./utils/steps";
import styled from "styled-components";
import Button, { BUTTON_SIZES } from "../../elements/v2/Button/Button";

export interface Props {
  id: number;
}

function Status(me: GetTransaction_me, transaction: GetTransaction_transaction) {
  switch (transaction.currentState) {
    case "preauthorized":
      return <Preauthorized me={me} transaction={transaction} />;
    case "paid":
      return <Paid me={me} transaction={transaction} />;
    case "confirmed":
      return <Confirmed me={me} transaction={transaction} />;
    case "paid_out":
      return <Confirmed me={me} transaction={transaction} />;
    case "rejected":
      return <Rejected me={me} transaction={transaction} />;
  }
}

function TransactionStatus({ id }: Props) {
  const { data } = useQuery<GetTransaction>(GET_TRANSACTION, {
    variables: { id },
  });

  if (!data) return null;

  const { transaction, me } = data;

  const { steps, lastCompletedStepIndex } = getTransactionSteps(me, transaction);

  return (
    <div>
      {transaction.currentState !== "rejected" && (
        <ProgressWizard steps={steps} lastCompletedStepIndex={lastCompletedStepIndex} />
      )}
      {Status(me, transaction)}
    </div>
  );
}

export default TransactionStatus;

export const TransactionActionButton = styled(Button).attrs({
  size: BUTTON_SIZES.MEDIUM,
})`
  margin-bottom: 10px;
  padding: 22px 16px;
`;

export const GreyTransactionActionButton = styled(TransactionActionButton)`
  background-color: #e8e8e8;
  .MuiButton-label {
    color: black;
  }
`;
