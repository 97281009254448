import { useQuery } from "@apollo/client";
import { Box, createStyles, makeStyles } from "@material-ui/core";
import { useField } from "formik";
import React from "react";
import CustomFormikField from "../CustomFormikField/CustomFormikField";
import Dropdown from "../Dropdown/Dropdown";
import TextField from "../TextField/TextField";
import GET_CURRENCIES from "./queries/GetCurrencies";
import { GetCurrencies } from "./queries/types/GetCurrencies";
import { ResellFormFields } from "../../ListingForm/RentForm/FormModel/listingFormModel";

export interface Props {
  currencyField: ResellFormFields["currency"];
  amountField: ResellFormFields["price"] | ResellFormFields["floorPrice"];
  handleOnBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      fontWeight: 600,
    },
    root: {
      display: "flex",
    },
    input: {
      paddingLeft: 5,
    },
  })
);

export type CurrencyOptions = { value: string; label: string }[];

const defaultCurrencyOptions: CurrencyOptions = [
  { value: "AUD", label: "AUD" },
  { value: "USD", label: "USD" },
];

const Money = ({ amountField, currencyField, handleOnBlur }: Props) => {
  const { error, data, loading } = useQuery<GetCurrencies>(GET_CURRENCIES, {
    variables: {},
  });

  //TODO: we cannot really do this because it causes react-hooks execution order to change
  // see: https://reactjs.org/docs/hooks-rules.html
  // if (loading) {
  //   return <div>Loading...</div>;
  // }
  // if (error) {
  //   return <div>{JSON.stringify(error)}</div>;
  // }

  let currencyOptions = defaultCurrencyOptions;
  if (data) {
    const { currencies } = data;
    currencyOptions = currencies
      .map((currency) => ({
        value: currency.code,
        label: currency.code,
      }))
      .sort((a, b) => {
        if (a.value < b.value) {
          return -1;
        }
        if (a.value > b.value) {
          return 1;
        }
        return 0;
      });
  }
  const classes = useStyles();
  const [fieldCurrency, metaCurrency] = useField(currencyField);
  const [fieldAmount, metaAmount] = useField(amountField);

  return (
    <Box className={classes.root}>
      <Box>
        <CustomFormikField
          fieldName={fieldCurrency.name}
          InnerComponent={Dropdown}
          options={currencyOptions}
          placeholder="Select currency"
        />
      </Box>
      <Box className={classes.input}>
        <CustomFormikField
          fieldName={fieldAmount.name}
          InnerComponent={TextField}
          onBlur={handleOnBlur}
        />
      </Box>
    </Box>
  );
};
export default Money;
