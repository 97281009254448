import styled from "styled-components";
import React from "react";
import { AffiliateType } from "../HistoricalOrdersPage";

type Props = {
  affiliate: AffiliateType;
};

export const AffiliateLogo = ({ affiliate }: Props) => (
  <Logo src={affiliate.logoUrl} alt={affiliate.displayName} />
);

const Logo = styled.img`
  text-align: center;
  height: 40px;
`;
