import { gql } from "@apollo/client";

const MoveProfileListingOrder = gql`
  mutation MoveProfileListingOrder($input: MoveProfileListingOrderMutationInput!) {
    moveProfileListingOrder(input: $input) {
      success
      error
    }
  }
`;

export default MoveProfileListingOrder;
