import React, { useContext } from "react";
import RorpContext from "../../../contexts/RorpContext";
import styled from "styled-components";
import FlexBox from "../../elements/v2/Box/FlexBox";
import { A } from "../../elements/v2/Typography/Typography";
import { $active, breakpoints, ifTrue } from "../../../utils/styledHelpers";
import { isMenuItemActive, navLinks, onMenuItemClick } from "../../../utils/navLinks";
import MegaMenu from "./MegaMenu";

function Nav({ username }: { username?: string }) {
  const { pathname } = useContext(RorpContext);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [departmentKey, setDepartmentKey] = React.useState<string | null>(null);

  const makeOpenHandler = (key) => (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setDepartmentKey(key);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDepartmentKey(null);
  };

  return (
    <NavContainer>
      {navLinks.map(({ path, t, type, children }) => (
        <Department
          key={t}
          onMouseEnter={children && makeOpenHandler(t)}
          onMouseLeave={handleClose}
        >
          <NavLink
            href={path}
            t={t}
            $active={isMenuItemActive(pathname, path, type)}
            $open={departmentKey === t}
            onClick={onMenuItemClick(username, type, t)}
          />
          {children && (
            <MegaMenu
              anchorEl={anchorEl}
              departmentKey={departmentKey}
              t={t}
              departmentPath={path}
              linkData={children}
              username={username}
            />
          )}
        </Department>
      ))}
    </NavContainer>
  );
}

export default Nav;

const NavContainer = styled(FlexBox)`
  align-items: center;
  gap: 32px;
  margin: 0;
  height: 100%;

  ${breakpoints.down("md")} {
    display: none;
  }
`;

const Department = styled(FlexBox)`
  align-items: center;
  height: 100%;
`;

export const NavLink = styled(A)`
  display: inline-block;
  color: #222222;
  letter-spacing: 0.1em;
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  white-space: nowrap;
  text-transform: uppercase;
  padding: 16px 0;

  ${$active`
    font-weight: bold;
  `}

  ${ifTrue("$open")`
    border-bottom: 2px solid black;
  `}

  &:focus,
  &:hover {
    color: black;
    outline: none;
  }
`;
