import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@material-ui/core";
import { Field } from "formik";
import React from "react";
import Checkbox from "../../FormControls/Checkbox/Checkbox";
import Money from "../../FormControls/Money/Money";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FlexBox from "../../../elements/v2/Box/FlexBox";
import { StepProps } from "../ResellForm/Steps/ConditionStep";
import FormSectionHeading from "../../FormControls/FormSection/Heading";
import styled from "styled-components";
import { breakpoints } from "../../../../utils/styledHelpers";

export default function ShippingSection({
  values,
  setFieldValue,
  formField: {
    currency,
    includeDomesticShippingFee,
    includeInternationalShippingFee,
    shippingFee,
    shippingFeeInternational,
  },
}: StepProps) {
  const domesticCheckedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(e.target.name, e.target.checked);
    setFieldValue("includeShippingFee", e.target.checked && values.includeInternationalShippingFee);
  };

  const internationalCheckedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(e.target.name, e.target.checked);
    setFieldValue("includeShippingFee", e.target.checked && values.includeDomesticShippingFee);
  };

  return (
    <InnerFormSection container spacing={2} direction="column">
      <InnerFormSection item>
        <FormSectionHeading title="Set your shipping fee" />
      </InnerFormSection>
      <InnerFormSection item>
        <CheckboxContainer container direction="column">
          <Field
            name={includeDomesticShippingFee.name}
            label={<Heading>{includeDomesticShippingFee.label}</Heading>}
            component={Checkbox}
            onChange={domesticCheckedChange}
          />
          <CheckboxSubheading>You’ll pay for shipping domestically</CheckboxSubheading>
        </CheckboxContainer>
        <InnerShippingSection
          container
          direction="column"
          $hidden={values.includeDomesticShippingFee}
        >
          <Heading>Domestic shipping fee</Heading>
          <Subheading>
            Suggested rate: $8.95. This will be added on top of your sell price.
          </Subheading>
          <Grid item>
            <Money currencyField={currency} amountField={shippingFee} />
          </Grid>
          <ShippingGuideAccordion>
            <ShippingGuideAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Heading>Domestic shipping guide</Heading>
            </ShippingGuideAccordionSummary>
            <AccordionDetails>
              <FlexBox flexDirection="column" $gap={2}>
                <Heading>
                  Based on&nbsp;
                  <A href="https://auspost.com.au/business/shipping/guidelines/size-weight-guidelines">
                    AusPost Shipping Guide
                  </A>
                </Heading>
                <FlexBox flexDirection="column">
                  <ShippingGuideText $bold>Small (under 500g)</ShippingGuideText>
                  <ShippingGuideText>Avg: $8.95</ShippingGuideText>
                  <ShippingGuideText>
                    T-shirts, scarves, belts, mini skirts, small jewellery items, etc.
                  </ShippingGuideText>
                </FlexBox>
                <FlexBox flexDirection="column">
                  <ShippingGuideText $bold>Medium (under 1kg)</ShippingGuideText>
                  <ShippingGuideText>Avg: $15.00</ShippingGuideText>
                  <ShippingGuideText>
                    Jeans, dresses, ballet flats, sweaters, handbags, etc.
                  </ShippingGuideText>
                </FlexBox>
                <FlexBox flexDirection="column">
                  <ShippingGuideText $bold>Large (under 2kg)</ShippingGuideText>
                  <ShippingGuideText>Avg: $22.00</ShippingGuideText>
                  <ShippingGuideText>
                    Boots, sneakers, light coats, heavy knitwear, bundles, etc.
                  </ShippingGuideText>
                </FlexBox>
              </FlexBox>
            </AccordionDetails>
          </ShippingGuideAccordion>
        </InnerShippingSection>
        <CheckboxContainer $mobileGap={true} container direction="column">
          <Field
            name={includeInternationalShippingFee.name}
            label={<Heading>{includeInternationalShippingFee.label}</Heading>}
            component={Checkbox}
            onChange={internationalCheckedChange}
          />
          <CheckboxSubheading>
            The shipping fee for overseas buyers will be the same as the domestic rate
          </CheckboxSubheading>
        </CheckboxContainer>
        <InnerShippingSection
          container
          direction="column"
          $hidden={values.includeInternationalShippingFee}
        >
          <Heading>International shipping fee</Heading>
          <Subheading>
            Suggested rate: $24.95. This will be added on top of your sell price.
          </Subheading>
          <Grid item>
            <Money currencyField={currency} amountField={shippingFeeInternational} />
          </Grid>
          <ShippingGuideAccordion>
            <ShippingGuideAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Heading>International shipping guide</Heading>
            </ShippingGuideAccordionSummary>
            <AccordionDetails>
              <FlexBox flexDirection="column" $gap={2}>
                <Heading>
                  Based on&nbsp;
                  <A href="https://auspost.com.au/business/shipping/guidelines/size-weight-guidelines">
                    AusPost Shipping Guide
                  </A>
                </Heading>

                <FlexBox flexDirection="column">
                  <ShippingGuideText $bold>Small Goods (up to 500g)</ShippingGuideText>
                  <ShippingGuideText>Avg:: $24.95</ShippingGuideText>
                  <ShippingGuideText>T-shirts, scarves, small jewelry items</ShippingGuideText>
                </FlexBox>

                <FlexBox flexDirection="column">
                  <ShippingGuideText $bold>Medium Goods (up to 1kg)</ShippingGuideText>
                  <ShippingGuideText>Avg: $39.75</ShippingGuideText>
                  <ShippingGuideText>
                    Jeans, dresses, shoes (e.g., ballet flats, sandals)
                  </ShippingGuideText>
                </FlexBox>

                <FlexBox flexDirection="column">
                  <ShippingGuideText $bold>Large Goods (up to 2kg)</ShippingGuideText>
                  <ShippingGuideText>Avg: $56.15</ShippingGuideText>
                  <ShippingGuideText>Coats, sweaters, heavier clothing items</ShippingGuideText>
                </FlexBox>

                <FlexBox flexDirection="column">
                  <ShippingGuideText $bold>Extra Large Goods (2kg to 20kg)</ShippingGuideText>
                  <ShippingGuideText>Starting from: $89.75</ShippingGuideText>
                  <ShippingGuideText>
                    Bulk clothing shipments, larger garments (e.g., jackets, bulk t-shirts)
                  </ShippingGuideText>
                </FlexBox>

                <FlexBox flexDirection="column">
                  <ShippingGuideText $bold>Notes</ShippingGuideText>
                  <ShippingGuideText>
                    Ensure items are securely packaged and comply with destination country
                    regulations.
                  </ShippingGuideText>
                  <ShippingGuideText>
                    For items valued over $100, consider purchasing Extra Cover for loss or damage
                    protection.
                  </ShippingGuideText>
                  <ShippingGuideText>
                    Prices may vary based on destination zones, so check Australia Post&apos;s
                    International Post Guide for specific rates to different countries.
                  </ShippingGuideText>
                </FlexBox>
              </FlexBox>
            </AccordionDetails>
          </ShippingGuideAccordion>
        </InnerShippingSection>
      </InnerFormSection>
    </InnerFormSection>
  );
}

const Subheading = styled.span`
  color: #777;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: left;
  padding-bottom: 8px;
`;

const CheckboxSubheading = styled(Subheading)`
  margin-left: 39px;
  margin-top: -8px;
  ${breakpoints.down(345)} {
    margin-top: 0;
  }
`;

const Heading = styled.span`
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  text-align: left;
  color: #000;
  height: auto;
`;

const ShippingGuideAccordion = styled(Accordion)`
  .MuiAccordionSummary-root {
    justify-content: left;
    margin-top: 0;
  }
  &.MuiPaper-root {
    margin-top: 16px;
    padding-top: 8px;
    background-color: #f7f7f7;
  }
  .MuiAccordionSummary-expandIcon {
    transform: rotate3d(1, 0, 0, 0deg);
    padding: 0 12px 12px 12px;
    &.Mui-expanded {
      transform: rotate3d(1, 0, 0, 180deg);
      padding: 12px 12px 0 12px;
    }
  }
`;

const ShippingGuideAccordionSummary = styled(AccordionSummary)`
  .MuiAccordionSummary-content {
    font-family: Arial, sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 28px;
    text-decoration: underline;
    flex-grow: 0;
    margin-top: 0;
  }
`;
const ShippingGuideText = styled.span<{ $bold?: boolean }>`
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 500;
  ${({ $bold }) => $bold && "font-weight: 700;"}
  line-height: 19px;
  text-align: left;
  color: #000;
`;

const A = styled.a`
  text-decoration: none;
  color: #000;
`;

const InnerFormSection = styled(Grid)`
  padding: 16px;
`;

const InnerShippingSection = styled(Grid)<{ $hidden?: boolean }>`
  gap: 4px;
  padding: 8px;
  ${({ $hidden }) => $hidden && "display: none;"}
`;

const CheckboxContainer = styled(Grid)<{ $mobileGap?: boolean }>`
  ${({ $mobileGap }) =>
    $mobileGap && "@media screen and (max-width: 447px) and (min-width: 345px) { gap: 8px; }"}
`;
