import { useQuery } from "@apollo/client";
import { ChartOptions } from "chart.js";
import React from "react";
import { useChartJSChart } from "../../../utils/chartJs";
import GET_ACTIVATION_REPORT from "./queries/GetActiveUsersReport";
import { GetActiveUsersReport } from "./queries/types/GetActiveUsersReport";

export interface Props {
  options: ChartOptions;
}

// TODO: we could further consolidate these components so they take a graphql query as a prop, but I
// think this is ok for now, as having explicit components with a dedicated, typed query file helps
// typescript maintain the rule of type-law in these strange and uncertain times.
export default function AdminActiveUsersReport({ options }: Props) {
  const { data } = useQuery<GetActiveUsersReport>(GET_ACTIVATION_REPORT);
  const chartRef = useChartJSChart({ options, data: data?.activeUsersReport });

  return <canvas ref={chartRef} width="500" height="500"></canvas>;
}
