import React from "react";
import "cross-fetch/polyfill";
import styled from "styled-components";
import { useSearchPageContext } from "../SearchPageContext";
import { FilterNames } from "../../../../utils/filters";
import { departmentPathname } from "./SearchBreadcrumbs/SearchBreadcrumbs";
import { useFilter } from "@sajari/react-hooks";
import { getSelectedCategoryData } from "../../../../utils/categories";
import { H1 } from "../../../elements/v2/Typography/Heading";

export default function SearchHeaderTitle() {
  const { selected } = useFilter(FilterNames.category);
  const {
    categories,
    collection,
    longTailPage,
    discountEligibilityPage,
    conciergePage,
  } = useSearchPageContext();
  const { selectedParentCategory, selectedChildCategory } = getSelectedCategoryData(
    categories,
    selected
  );
  const department = departmentPathname();

  const categoryTitle = selectedChildCategory?.name || selectedParentCategory?.name || "";

  let title = "";

  if (department) {
    title = department.label;

    if (categoryTitle) title += ` ${categoryTitle}`;
  } else if (collection) {
    title = collection.displayTitle;

    if (categoryTitle) title += ` ${categoryTitle}`;
  } else if (longTailPage?.title) {
    title = longTailPage.title;
  } else if (discountEligibilityPage?.isDiscountEligibilityPage) {
    title = discountEligibilityPage.title;
  }

  return <Title>{title}</Title>;
}

const Title = styled(H1)`
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
  color: #000000;
  min-width: 165px;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  padding: 4px 0;
`;
