import { GetTransaction_me, GetTransaction_transaction } from "../queries/types/GetTransaction";

export default function getTransactionSteps(
  me: GetTransaction_me,
  transaction: GetTransaction_transaction
) {
  const allSteps = [
    {
      label: "Request accepted",
      isComplete: transaction.currentState === "paid",
      isVisible: true,
    },
    {
      label: "Product shipped",
      isComplete: transaction.shipped,
      isVisible: transaction.deliveryMethod === "shipping",
    },
    {
      label: "Product received",
      isComplete: ["confirmed", "paid_out"].includes(transaction.currentState),
      isVisible: true,
    },
  ];

  const visibleSteps = allSteps.filter((step) => step.isVisible);
  const steps = visibleSteps.map((step) => step.label);

  const lastCompletedStepIndex = visibleSteps.map((step) => step.isComplete).lastIndexOf(true);

  return { steps, lastCompletedStepIndex };
}
