import React, { useContext, useEffect, useMemo } from "react";
import Breadcrumbs from "../../../../composites/v2/Breadcrumbs/Breadcrumbs";
import departments from "../../../../../utils/departments";
import { useFilter } from "@sajari/react-hooks";
import { FilterNames } from "../../../../../utils/filters";
import { useSearchPageContext } from "../../SearchPageContext";
import { getSelectedCategoryData } from "../../../../../utils/categories";
import RorpContext from "../../../../../contexts/RorpContext";
import { addBreadcrumbsStructure } from "../../../../../utils/breadcrumbsHelper";
import styled from "styled-components";
import FlexBox from "../../../../elements/v2/Box/FlexBox";
import { breakpoints } from "../../../../../utils/styledHelpers";
import { GetCollection_collection } from "../../queries/types/GetCollection";
import { ProfilePageProps_person } from "../../queries/types/ProfilePageProps";

export const findDepartmentViaPath = (path: string) => {
  const removeBackslash = path?.substring(1);
  return departments[removeBackslash];
};

export const departmentPathname = () => {
  const { pathname } = useContext(RorpContext);

  if (pathname === "/designer") {
    return { label: "Designer", navPath: pathname };
  }

  const departmentPathname: { label: string; navPath: string } | undefined = useMemo(
    () => findDepartmentViaPath(pathname),
    []
  );

  return departmentPathname;
};

type SearchBreadcrumbsProps = {
  collection?: GetCollection_collection;
  person?: ProfilePageProps_person;
};

function SearchBreadcrumbs({ collection, person }: SearchBreadcrumbsProps) {
  const breadcrumbs = [{ path: "/", label: "home" }];
  const { selected } = useFilter(FilterNames.category);
  const { categories } = useSearchPageContext();
  const { selectedParentCategory, selectedChildCategory } = getSelectedCategoryData(
    categories,
    selected
  );
  const department = departmentPathname();

  if (department) {
    breadcrumbs.push({
      path: department?.navPath,
      label: department?.label,
    });
  }

  if (person) {
    breadcrumbs.push({
      path: person.profileUrl,
      label: person.username,
    });
  }

  if (collection) {
    breadcrumbs.push({
      path: person ? collection.profileCollectionUrl : collection.url,
      label: collection.displayTitle,
    });
  }

  if (selectedParentCategory) {
    breadcrumbs.push({
      label: selectedParentCategory?.name,
      path: `?category=${selectedParentCategory?.url}`,
    });
  }

  if (selectedChildCategory) {
    breadcrumbs.push({
      label: selectedChildCategory?.name,
      path: `?category=${selectedChildCategory?.url}`,
    });
  }

  useEffect(() => {
    addBreadcrumbsStructure(breadcrumbs);
  }, [breadcrumbs]);

  return (
    <Container>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
    </Container>
  );
}

export default SearchBreadcrumbs;

const Container = styled(FlexBox)`
  justify-content: space-between;
  padding: 10px 20px;
  flex-wrap: wrap;
  gap: 8px;
  ${breakpoints.down("tablet")} {
    display: none;
  }
`;
