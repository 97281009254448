import { camelCase } from "lodash";
import React from "react";
import CheckboxGroup from "../../../FormControls/Checkbox/CheckboxGroup";
import CustomFormikField from "../../../FormControls/CustomFormikField/CustomFormikField";
import FormSection, { ClassesOverrides } from "../../../FormControls/FormSection/FormSection";
import TextField from "../../../FormControls/TextField/TextField";
import { GetCategories_categories_customFields } from "../queries/types/GetCategories";

interface Props {
  customField: GetCategories_categories_customFields;
  formSectionOverrides?: ClassesOverrides;
}

const getInnerComponent = (type: String) => {
  switch (type) {
    case "NumericField":
    case "TextField": {
      return TextField;
    }
    case "CheckboxField": {
      return CheckboxGroup;
    }
  }
};

const getSectionOverrides = (type: String) => {
  switch (type) {
    case "CheckboxField": {
      return {
        innerFormSection: {
          paddingTop: 0,
        },
      };
    }
  }
};

export default function CustomProductFieldSection({
  customField,
  formSectionOverrides = {},
}: Props) {
  return (
    <FormSection
      title={customField.name}
      classesOverrides={{
        ...formSectionOverrides,
        ...getSectionOverrides(customField.type),
      }}
    >
      <CustomFormikField
        fieldName={camelCase(customField.key)}
        InnerComponent={getInnerComponent(customField.type)}
        options={customField.options.map((option) => {
          return {
            value: option.key,
            label: option.title,
          };
        })}
      />
    </FormSection>
  );
}
