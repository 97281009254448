import { Avatar, Card, createStyles, IconButton, makeStyles, Theme } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import classNames from "classnames";
import React from "react";
import { File } from "./ImageUploader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: 200,
      height: 200,
      objectFit: "cover",
    },
    faded: {
      opacity: "0.3",
    },
    progress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    delete: {
      position: "absolute",
      top: 8,
      right: 8,
      backgroundColor: "#e0e0e0",
    },
    error: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    deleteButton: {
      transition: ".5s ease",
    },
  })
);

interface Props {
  file: File;
  onDeleteListingImage: (listingImageId: string) => void;
  onRemoveFile: (uuid: string) => void;
}

const ImagePreviewCard = (props: Props) => {
  const classes = useStyles();
  const { file, onDeleteListingImage, onRemoveFile } = props;
  const uploading = !file.doneUploading;
  const processing = !file.doneProcessing;
  const error = file.error;
  const showDelete = error || (!uploading && !processing && file.listingImageId);

  function handleClickDelete() {
    file.error ? onRemoveFile(file.uuid) : onDeleteListingImage(file.listingImageId);
  }

  return (
    <Card variant="elevation">
      <img
        src={file.processedURL || file.asDataURL}
        className={classNames(classes.img, {
          [classes.faded]: uploading || processing,
        })}
        data-cy={`image-${file.listingImageId || file.uuid}`}
      />
      {!error && uploading && <div className={classes.progress}>{file.progress + "%"}</div>}
      {!error && !uploading && processing && (
        <div
          className={classes.progress}
          data-cy={`image-processing-${file.listingImageId || file.uuid}`}
        >
          Processing...
        </div>
      )}
      {error && <div className={classes.error}>Error uploading image</div>}
      {showDelete && (
        <Avatar className={classes.delete}>
          <IconButton
            aria-label="delete"
            onClick={handleClickDelete}
            color="primary"
            className={classes.deleteButton}
            data-cy={`delete-image-${file.listingImageId}`}
          >
            <DeleteIcon />
          </IconButton>
        </Avatar>
      )}
    </Card>
  );
};

export default ImagePreviewCard;
