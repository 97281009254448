import PropTypes from "prop-types";
import { div, span } from "r-dom";
import { Component } from "react";
import hamburgerIcon from "./images/hamburgerIcon.svg";
import css from "./Menu.module.css";

class MenuLabel extends Component {
  render() {
    const extraClasses = this.props.extraClasses ? this.props.extraClasses : "";
    return div(
      {
        className: `MenuLabel ${css.menuLabel} ${extraClasses}`,
      },
      [
        span({
          className: css.menuLabelIcon,
          dangerouslySetInnerHTML: {
            __html: hamburgerIcon,
          },
        }),
        this.props.name,
      ]
    );
  }
}

MenuLabel.propTypes = {
  extraClasses: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default MenuLabel;
