import React from "react";
import styled from "styled-components";
import {
  InputProps as MuiInputProps,
  Input as MuiInput,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import Typography from "../Typography/Typography";
import Box from "../Box/Box";
import { Visibility, VisibilityOff } from "@material-ui/icons";

export type InputProps = MuiInputProps & {
  label?: string;
  id?: string;
  error?: boolean;
  errorMessage?: any;
  placeholderColor?: string;
  password?: boolean;
  success?: boolean;
  successMessage?: string;
};

export const Input = ({
  label,
  id,
  error,
  errorMessage,
  success,
  successMessage,
  type = "text",
  placeholderColor,
  fullWidth = false,
  password = false,
  ...props
}: InputProps) => {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <Box flexDirection="column" display="flex" width={fullWidth ? "100%" : undefined}>
      {label && <Label>{label}</Label>}
      <DefaultTextField
        $error={error}
        $placeholderColor={placeholderColor}
        id={id}
        disableUnderline={true}
        type={showPassword && password ? "text" : type}
        endAdornment={
          password && (
            <PasswordAdornment showPassword={showPassword} setShowPassword={setShowPassword} />
          )
        }
        {...props}
      />
      {error && errorMessage && (
        <Box display="flex" flexDirection="row" marginTop="4px">
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Box>
      )}
      {success && successMessage && (
        <Box display="flex" flexDirection="row" marginTop="4px">
          <SuccessMessage id="success-message">{successMessage}</SuccessMessage>
        </Box>
      )}
    </Box>
  );
};

const DefaultTextField = styled(MuiInput)<{ $error?: boolean; $placeholderColor?: string }>`
  border: 1px solid #cccccc;
  border-radius: 2px;
  box-sizing: border-box;
  height: 48px;
  padding: 8px 16px;
  background: white;
  ${({ $error }) => $error && `border-color: #FF0000;`}
  &:focus {
    border-color: #cccccc;
  }

  /* Change the autofill shade to white */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  .MuiInput-input {
    background: white;
    font-family: Arial, sans-serif;
    font-weight: 500;
    ${({ $placeholderColor }) =>
      $placeholderColor &&
      `
        &::placeholder {
          color: ${$placeholderColor};
          opacity: 1;
        }
      `}
  }
`;

const PasswordAdornment = ({
  showPassword,
  setShowPassword,
}: {
  showPassword: boolean;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge="end"
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );
};

const Label = styled(Typography)`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 4px;
  color: #111111;
`;

const ErrorMessage = styled(Typography)`
  font-size: 15px;
  font-weight: 400;
  font-family: Arial, sans-serif;
  font-style: normal;
  color: #f00;
  margin-left: 5px;
`;

const SuccessMessage = styled(Typography)`
  font-size: 15px;
  font-weight: 400;
  font-family: Arial, sans-serif;
  font-style: normal;
  color: #07963c;
  margin-left: 5px;
`;
