import React from "react";
import styled from "styled-components";
import { A, P, Span } from "../../elements/v2/Typography/Typography";
import { t } from "../../../utils/i18n";
import { HOME_PRIVACY_POLICY_URL } from "../../../utils/paths";
import { BUTTON_SIZES, OutlineButton } from "../../elements/v2/Button/Button";
import Box from "../../elements/v2/Box/Box";
import FlexBox from "../../elements/v2/Box/FlexBox";
import { breakpoints } from "../../../utils/styledHelpers";

export default function SubscribeForm() {
  return (
    <IterableSubscribeForm
      method="post"
      name="iterable_optin"
      action="//links.iterable.com/lists/publicAddSubscriberForm?publicIdString=2cdc83f4-473f-4bbc-bcc8-d2ee59100dc1"
      publicIdString="2cdc83f4-473f-4bbc-bcc8-d2ee59100dc1"
      target="_blank"
    >
      <Intro t="web.footer.subscribe_form.intro" />
      <Box h={2} />
      <Input
        id="subscribe_email"
        type="email"
        name="email"
        maxLength={256}
        required
        placeholder={t("web.footer.subscribe_form.placeholder")}
      />
      <FlexBox my={2} justifyContent="flex-end">
        <SubmitButton t="web.footer.subscribe_form.subscribe" />
      </FlexBox>
      <Disclaimer>
        <Span t="web.footer.subscribe_form.disclaimer" />
        <A href={HOME_PRIVACY_POLICY_URL} t="web.footer.privacy_policy" $underline />.
      </Disclaimer>
    </IterableSubscribeForm>
  );
}

const IterableSubscribeForm = styled.form`
  width: 100%;
  ${breakpoints.up("sm")} {
    width: 60%;
    margin: 0 auto;
  }
  ${breakpoints.up("md")} {
    width: 293px;
    margin: 0;
  }
`;

const Intro = styled(P)`
  color: #111111;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.5em;
`;

const Input = styled.input`
  font-size: 15px;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  width: 100%;
  height: 54px;
  background: #ffffff;
  border-radius: 2px;
  outline: none;
  border: 1px solid #111111;
`;

const SubmitButton = styled(OutlineButton).attrs({
  type: "submit",
  size: BUTTON_SIZES.MEDIUM,
})`
  background: white;
  ${breakpoints.down("sm")} {
    width: 100%;
  }
`;

const Disclaimer = styled(P)`
  &,
  a {
    color: #666666;
    font-weight: 500;
    font-size: 11px;
    line-height: 1.5em;
  }
`;
