import React, { useState } from "react";
import LinkBox from "../../../../../elements/v2/Box/LinkBox";
import styled from "styled-components";
import { Span } from "../../../../../elements/v2/Typography/Typography";
import Box, { BoxProps } from "../../../../../elements/v2/Box/Box";
import FontAwesomeIcon, {
  FontAwesomeIconName,
} from "../../../../../elements/FontAwesomeIcon/FontAwesomeIcon";
import ConfirmModal from "../../../../../composites/v2/ConfirmDialog/ConfirmDialog";

type Props = BoxProps & {
  href?: string | null;
  visible?: boolean;
  bold?: boolean;
  bolder?: boolean;
  iconName?: FontAwesomeIconName;
  confirm?: {
    title?: string;
    message: string;
  };
  children: React.ReactNode;
};

export default function ListingAction({
  visible = true,
  href,
  bold,
  bolder,
  iconName,
  confirm,
  children,
  ...props
}: Props) {
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  if (!visible) return null;

  const clickable = Boolean(href);

  function handleLink(e) {
    if (confirm) {
      e.preventDefault();
      setConfirmModalOpen(true);
      return false;
    }
  }

  function handleConfirm() {
    window.location.href = href;
  }

  return (
    <Container $clickable={clickable} {...props}>
      <LinkBox href={href} onClick={handleLink}>
        {iconName && <FontAwesomeIcon iconName={iconName} mr={1} />}
        <ActionName $clickable={clickable} $bold={bold} $bolder={bolder}>
          {children}
        </ActionName>
      </LinkBox>
      {confirm && (
        <ConfirmModal
          title={confirm.title}
          open={isConfirmModalOpen}
          setOpen={setConfirmModalOpen}
          onConfirm={handleConfirm}
        >
          {confirm.message}
        </ConfirmModal>
      )}
    </Container>
  );
}

const Container = styled(Box)<{ $clickable: boolean }>`
  padding: 4px 0;
  ${({ $clickable }) =>
    $clickable &&
    `
    &:hover {
      text-decoration: underline;
    }
  `}
`;

const ActionName = styled(Span)<{ $clickable?: boolean; $bold?: boolean; $bolder?: boolean }>`
  font-weight: ${({ $clickable }) => ($clickable ? 500 : 400)};
  font-size: 16px;
  ${({ $bold = false }) =>
    $bold &&
    `
    font-weight: 600;
  `}
  ${({ $bolder = false }) =>
    $bolder &&
    `
    font-weight: 700;
  `}
`;
