import { gql } from "@apollo/client";

export default gql`
  mutation RequestHistoricalOrders($input: RequestHistoricalOrdersMutationInput!) {
    requestHistoricalOrders(input: $input) {
      order {
        externalId
        number
      }
      externalId
      productType
      title
      brand
      description
      paidPrice {
        fractional
        currency {
          symbol
          code
          symbolFirst
        }
      }
      imageUrls
      isEligible
      estimatedResaleValue {
        fractional
        currency {
          symbol
          code
          symbolFirst
        }
      }
      size
      color
    }
  }
`;
