import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Box from "../../../elements/v2/Box/Box";
import { Span, P } from "../../../elements/v2/Typography/Typography";
import { HistoricalOrdersItemCard } from "../components/HistoricalOrdersItemCard/HistoricalOrdersItemCard";
import FlexBox from "../../../elements/v2/Box/FlexBox";
import { AffiliateType, HistoricalOrdersPageProps } from "../HistoricalOrdersPage";
import {
  RequestHistoricalOrders,
  RequestHistoricalOrders_requestHistoricalOrders,
} from "../mutations/types/RequestHistoricalOrders";
import { useMutation } from "@apollo/client";
import SELECT_HISTORICAL_ORDERS_MUTATION from "../mutations/SelectHistoricalOrders";
import {
  SelectHistoricalOrders,
  SelectHistoricalOrdersVariables,
} from "../mutations/types/SelectHistoricalOrders";
import { Heading } from "../components/Heading";
import { Description } from "../components/Description";
import Button, { BUTTON_SIZES, OutlineButton } from "../../../elements/v2/Button/Button";
import { getUserPath, USER_LISTINGS_PATH } from "../../../../utils/paths";
import { Errors } from "../components/Errors";
import SnackbarAlert from "../../../composites/v2/SnackbarAlert/SnackbarAlert";
import pluralize from "pluralize";
import { CheckBox } from "../../../elements/v2/Form/CheckBox";
import Events from "../../../../utils/analytics";

type SelectItemsStepProps = {
  affiliate: AffiliateType;
  username: HistoricalOrdersPageProps["username"];
  email: string;
  fetchedItems: RequestHistoricalOrders["requestHistoricalOrders"];
};

export const SelectItemsStep = ({
  affiliate,
  username,
  email,
  fetchedItems,
}: SelectItemsStepProps) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [itemsAdded, setItemsAdded] = useState([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [historicalOrderItems, setHistoricalOrderItems] = useState(fetchedItems);
  const [isSendingItems, setIsSendingItems] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(
    () =>
      setHistoricalOrderItems((currentState) =>
        currentState.filter((item) => !itemsAdded.includes(item))
      ),
    [itemsAdded]
  );

  const isItemSelected = (id: string) => selectedItems.some((item) => item.externalId === id);

  const onItemSelect = (item: RequestHistoricalOrders_requestHistoricalOrders) => {
    setSelectedItems((selectedOrders) => [...selectedOrders, item]);
  };

  const onItemDeselect = (clickedItem: RequestHistoricalOrders_requestHistoricalOrders) => {
    setSelectedItems((currentState) =>
      currentState.filter((item) => item.externalId !== clickedItem.externalId)
    );
  };

  const onToggleItem = (item) => {
    isItemSelected(item.externalId) ? onItemDeselect(item) : onItemSelect(item);
  };

  const handleToggleAllOnChange = (e) => {
    const on = Boolean(e.target.checked);
    if (on) {
      setSelectedItems([...historicalOrderItems]);
    } else {
      setSelectedItems([]);
    }
  };

  const [selectHistoricalOrders] = useMutation<
    SelectHistoricalOrders,
    SelectHistoricalOrdersVariables
  >(SELECT_HISTORICAL_ORDERS_MUTATION);

  const handleAddToAirRobe = () => {
    selectHistoricalOrders({
      variables: {
        input: {
          affiliateAppId: affiliate.appId,
          emailAddress: email,
          items: selectedItems.map(({ order, externalId }) => ({
            externalOrderId: order.externalId,
            externalId,
          })),
        },
      },
    })
      .then(() => {
        setItemsAdded((items) => [...items, ...selectedItems]);
        Events.sendHoImportSuccessful({
          allItems: fetchedItems,
          selectedItems: selectedItems,
          affiliate: affiliate,
        });
        setSelectedItems([]);
        setIsAlertOpen(true);
      })
      .catch((e) => {
        setErrors([e.message]);
        // Once we understand how these errors are show/handled, we might want to handle graphql error reporting globally
        if (e.networkError)
          throw new Error("GraphQL network error: " + e.networkError.result?.error);
        else if (e.graphQLErrors.length)
          throw new Error("GraphQL errors:" + JSON.stringify(e.graphQLErrors));
        throw e;
      })
      .finally(() => {
        setIsSendingItems(false);
      });

    Events.sendHoStep3AddToAirRobe({
      allItems: fetchedItems,
      selectedItems: selectedItems,
      affiliate: affiliate,
    });
    setIsSendingItems(true);
    setErrors([]);
  };

  const handleDismissAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setIsAlertOpen(false);
  };

  const handleExit = () => {
    window.location.href = getUserPath(username, USER_LISTINGS_PATH);
    Events.sendHoDone({
      affiliate: affiliate,
      email: email,
      fetchedItems: fetchedItems,
    });
  };

  const isAllSelected = selectedItems.length === historicalOrderItems.length;

  const isDone = itemsAdded.length > 0 && selectedItems.length === 0;

  const hasItemsToSelect = historicalOrderItems.length > 0;

  const canAddToAirRobe = !isSendingItems && selectedItems.length > 0;

  return (
    <>
      <SnackbarAlert
        open={isAlertOpen}
        onClose={handleDismissAlert}
        content={`${itemsAdded.length} ${pluralize(
          "item",
          itemsAdded.length
        )} added to your Circular Wardrobe™`}
      />

      {hasItemsToSelect ? (
        <Box>
          <Box maxWidth={800}>
            <Heading style={{ textTransform: "uppercase" }}>
              Import Past Orders To Circular Wardrobe
            </Heading>
            <Box my={2} />
            <Description>Only qualifying purchases are available to import.</Description>
          </Box>

          <CenteredWrapper mt={4}>
            <Mid flexDirection="column" $gap={2}>
              <FlexBox justifyContent="space-between">
                <TotalItemsText>
                  {historicalOrderItems.length} {pluralize("item", itemsAdded.length)}
                </TotalItemsText>
                <CheckBox
                  checked={isAllSelected}
                  data-testid="select-all-cards-checkbox"
                  onChange={handleToggleAllOnChange}
                />
              </FlexBox>

              <Cards $length={historicalOrderItems.length}>
                {historicalOrderItems.map((item) => (
                  <HistoricalOrdersItemCard
                    key={item.externalId}
                    item={item}
                    isSelected={isItemSelected(item.externalId)}
                    onSelect={() => onToggleItem(item)}
                  />
                ))}
              </Cards>
            </Mid>
          </CenteredWrapper>
        </Box>
      ) : (
        <Box mt={6}>
          <DoneMessage>
            Congratulations! You&apos;ve added all of your eligible items to your Circular Wardrobe!
          </DoneMessage>
        </Box>
      )}
      {isDone ? (
        <Box mt={3}>
          <Button size={BUTTON_SIZES.MEDIUM} onClick={handleExit}>
            Done
          </Button>
        </Box>
      ) : (
        <Mid mt={3} justifyContent="space-between">
          <OutlineButton size={BUTTON_SIZES.MEDIUM} onClick={handleExit}>
            Exit
          </OutlineButton>
          <Button
            size={BUTTON_SIZES.MEDIUM}
            disabled={!canAddToAirRobe}
            onClick={handleAddToAirRobe}
          >
            Add To AirRobe
          </Button>
        </Mid>
      )}
      <Errors errors={errors} />
    </>
  );
};

const Cards = styled(FlexBox)`
  flex-wrap: wrap;
  gap: 4px;
  justify-content: center;
`;

const TotalItemsText = styled(Span)`
  font-weight: 700;
  font-size: 13px;
  color: #000000;
  margin-bottom: 8px;
`;

const DoneMessage = styled(P)`
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  color: #222222;
`;

const CenteredWrapper = styled(FlexBox)`
  justify-content: center;
`;

const Mid = styled(FlexBox)`
  width: 100%;
  max-width: 688px;
`;
