import styled from "styled-components";
import MenuItem, { MenuItemDivider, MenuItemProps } from "../../../composites/v2/Menu/MenuItem";
import { getUserPath, isPathActive } from "../../../../utils/paths";
import React, { useContext } from "react";
import RorpContext from "../../../../contexts/RorpContext";

const MobileMenuItem = (props: MenuItemProps) => (
  <>
    <StyledMenuItem {...props} />
    <MenuItemDivider />
  </>
);

export default MobileMenuItem;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 16px;

  &:focus,
  &:hover {
    color: black;
    background-color: rgba(169, 172, 176, 0.07);
  }
`;

export const MobileMenuItemUser = ({
  path,
  username,
  exact = false,
  ...props
}: {
  path: string;
  username?: string;
  exact?: boolean;
} & MenuItemProps) => {
  const { pathname } = useContext(RorpContext);
  const href = username ? getUserPath(username, path) : path;
  const active = isPathActive(pathname, href, exact);

  return <MobileMenuItem href={href} active={active} {...props} />;
};
