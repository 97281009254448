import { useQuery } from "@apollo/client";
import { findKey } from "lodash";
import React, { useState } from "react";
import { useMedia } from "react-media";
import useQueryParam from "../../../../../../../utils/useQueryParam";
import ContentBlock from "../../../../../../composites/ContentBlock/ContentBlock";
import Modal from "../../../../../../elements/Modal/Modal";
import GET_PERSON_COLLECTIONS from "../../../../queries/GetPersonCollections";
import {
  GetPersonCollections,
  GetPersonCollectionsVariables,
} from "../../../../queries/types/GetPersonCollections";
import CardPanel from "../../../../../../composites/CardPanel/CardPanel";
import CollectionCard from "../../../../../../widgets/CollectionCard/CollectionCard";
import NewCollectionForm from "../../../../../../widgets/NewCollectionForm/NewCollectionForm";
import { BUTTON_SIZES, OutlineButton } from "../../../../../../elements/v2/Button/Button";
import { useSearchPageContext } from "../../../../SearchPageContext";

interface Props {
  pageParam: string;
}

const columnsByBreakpoint = {
  1: "(max-width: 917px)",
  2: "(min-width: 917px) and (max-width: 1177px)",
  4: "(min-width: 1177px)",
};

const collectionsPerPageByBreakpoint = {
  2: "(max-width: 917px)",
  3: "(min-width: 917px) and (max-width: 1177px)",
  6: "(min-width: 1177px)",
};

export default function CollectionsCardPanel({ pageParam }: Props) {
  const { profilePage } = useSearchPageContext();
  const person = profilePage.person;
  const perPage = Number(findKey(useMedia({ queries: collectionsPerPageByBreakpoint })) || 1);
  const page = Number(useQueryParam(pageParam) || 1);
  const variables = { personId: person.id, page, perPage };

  const { data, previousData, loading, refetch } = useQuery<
    GetPersonCollections,
    GetPersonCollectionsVariables
  >(GET_PERSON_COLLECTIONS, { variables });

  const usableData = (data || previousData)?.person.collections;
  const collections = usableData?.results || [];
  const pageCount = usableData?.pageCount || 0;

  const [isModalVisible, setModalVisible] = useState(false);
  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);

  const newCollectionLink = person.isMe ? (
    <OutlineButton onClick={openModal} size={BUTTON_SIZES.SMALL}>
      Add collection
    </OutlineButton>
  ) : null;

  // Refetch collections after creating or deleting one. Not the most efficient, but ok for now.
  const afterCreateCollection = () => {
    console.log("afterCreateCollection");
    closeModal();
    refetch();
  };

  const afterUpdateCollection = refetch;
  const afterDeleteCollection = refetch;

  return (
    <ContentBlock title="Collections" action={newCollectionLink}>
      <CardPanel
        location=""
        loading={loading}
        pageParam={pageParam}
        currentPage={page}
        totalPages={pageCount}
        columnsByBreakpoint={columnsByBreakpoint}
        hasFeaturedTopRow
      >
        {collections.map((collection) => (
          <CollectionCard
            key={`collection_${collection.id}`}
            collection={collection}
            afterUpdate={afterUpdateCollection}
            afterDelete={afterDeleteCollection}
          />
        ))}
      </CardPanel>
      {isModalVisible ? (
        <Modal handleClose={closeModal}>
          <NewCollectionForm handleCancel={closeModal} afterCreate={afterCreateCollection} />
        </Modal>
      ) : null}
    </ContentBlock>
  );
}
