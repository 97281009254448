import React from "react";
import ProfilePageContentBlock from "../../../../composites/ContentBlock/ContentBlock";
import MessageForm from "../../../../composites/MessageForm/MessageForm";
import { ConversationStartingPage } from "../../../../../types/graphql-global-types";
import { useSearchPageContext } from "../../SearchPageContext";

export default function ProfileContactPage() {
  const { profilePage } = useSearchPageContext();
  const person = profilePage.person;
  const title = `Send a message ${person ? ` to ${person.givenName || person.displayName}` : null}`;

  return (
    <ProfilePageContentBlock title={title}>
      <MessageForm personId={person.id} startingPage={ConversationStartingPage.profile} />
    </ProfilePageContentBlock>
  );
}
