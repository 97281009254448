import { CurrencyCode } from "../types/graphql-global-types";
import { GetSearchProps_myCountry_currency } from "../components/pages/SearchPage/queries/types/GetSearchProps";
import {
  ListingCardFragment,
  ListingCardFragment_author,
  ListingCardFragment_originalPrice,
  ListingCardFragment_price,
  ListingCardFragment_rrp,
} from "../graphql/fragments/types/ListingCardFragment";

type Currency = GetSearchProps_myCountry_currency;

const defaultCurrency: Currency = {
  code: CurrencyCode.AUD,
  conversionRateFromAud: 1,
  symbol: "$",
  symbolFirst: true,
  __typename: "Currency",
};

export interface ListingData {
  id: number;
  allValues: ListingCardFragment;
  brand: string | null;
  brand_key: string | null;
  isMine: boolean;
  title: string;
  isInMyCollections: boolean;
  unitType?: string;
  sold: boolean;
  url: string;
  path: string;
  editUrl: string | null;
  price: ListingCardFragment_price | null;
  originalPrice: ListingCardFragment_originalPrice | null;
  isPriceReduced: boolean;
  rrp: ListingCardFragment_rrp | null;
  previewUrl: string | null;
  priceReducedPercentageBucket: string | null;
  priceReducedPercentage: string | null;
  size?: string[];
  profileSort: number | null;
  closed?: boolean;
  author: ListingCardFragment_author["username"];
  conciergeSort?: number | null;
}

export function mapResultToListing(
  result,
  allCollectionListingIds: Array<number>,
  currency: Currency = defaultCurrency
): ListingData {
  const id = Number(result.values.id);
  const isInMyCollections = allCollectionListingIds.includes(id);
  const rrp: ListingData["rrp"] =
    result.values.rrp_aud > 0
      ? {
          __typename: "Money",
          fractional: Number(result.values.rrp_aud * currency.conversionRateFromAud) * 100,
          currency,
        }
      : null;

  return {
    id,
    allValues: result.values,
    previewUrl: result.values.image,
    url: result.values.listing_url,
    path: result.values.listing_path,
    editUrl: null,
    title: result.values.title,
    brand: result.values.brand,
    brand_key: result.values.brand_key,
    isMine: false,
    sold: isTrue(result.values.sold),
    // TODO: "unit type" is a sharetribe construct that still remains in our code, however, we may
    // choose to refator into something a little more airrobe-specific. Basically, a sharetribe
    // listing can have a custom unit type like "per day", "per week", "per night" etc. However,
    // we've "hacked" the sharetribe system such that "per-night" rentals are actually
    // "per-occasion" (without this being represented as its own "unit type"). Therefore, this field
    // is hard-coded to "per occasion" for rental items. We should properly implement a flexible
    // rental system with date ranges, and then update this code accordingly.
    unitType: result.values.item_type === "For Rent" ? "per occasion" : undefined,
    isInMyCollections,
    rrp,
    price: {
      __typename: "Money",
      fractional: Number(result.values.price_aud * currency.conversionRateFromAud) * 100,
      currency,
    },
    originalPrice: result.values.original_price_aud
      ? {
          __typename: "Money",
          fractional:
            Number(result.values.original_price_aud * currency.conversionRateFromAud) * 100,
          currency,
        }
      : null,
    isPriceReduced: isTrue(result.values.is_price_reduced),
    priceReducedPercentageBucket: result.values.price_reduced_percentage_bucket,
    priceReducedPercentage: result.values.price_reduced_percentage,
    size: result.values.size,
    profileSort: result.values.profileSort,
    author: result.values.author,
  };
}

const isTrue = (value) => value === "true";
