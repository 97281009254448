import React from "react";
import {
  CallToAction,
  CloseSection,
  Description as DefaultDescription,
  Heading,
} from "./CampaignModal";
import styled from "styled-components";
import { ImportGmailOrdersProps } from "../ImportGmailOrders/ImportGmailOrders";
import FlexBox from "../../elements/v2/Box/FlexBox";
import { Dialog, DialogContent } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { EmailCaptureConnectStartedFrom } from "../../../utils/analytics";
import GmailConnectButton from "../ImportGmailOrders/GmailConnectButton";

export interface GmailHowItWorksModalProps {
  open: boolean;
  from: EmailCaptureConnectStartedFrom;
  onClose: (e?: any) => void | boolean;
  currentUser: ImportGmailOrdersProps["currentUser"];
  minHeight?: string;
}

export default function GmailHowItWorksModal({
  open,
  from,
  onClose,
  currentUser,
  minHeight = "60vh",
}: GmailHowItWorksModalProps) {
  return (
    <Modal open={open} onClose={onClose}>
      <DialogContent>
        <CloseSection>
          <ClearIcon onClick={onClose} data-testid="close" />
        </CloseSection>
        <Content $minHeight={minHeight}>
          <UpperContent>
            <Title>How it works</Title>
            <Description>
              We search your purchase emails from trusted retailers, including The Iconic, Farfetch,
              Net-a-porter and more.
              <br />
              <br />
              Your purchases are automatically added to your private wardrobe, and you chose when
              you want to resell.
              <br />
              <br />
              We’re constantly adding new trusted retail and brand partners to help you build up
              your digital wardrobe.
              <br />
              <br />
              Connect the email that you constantly use for shopping to get started.
            </Description>
          </UpperContent>
          <CallToAction>
            <GmailConnectButton from={from} currentUser={currentUser} $transparent />
          </CallToAction>
        </Content>
      </DialogContent>
    </Modal>
  );
}

const Title = styled(Heading)`
  font-family: "Lora", serif;
  font-size: 40px;
  padding-bottom: 0;
  font-weight: 400;
  line-height: 110%;
`;
const UpperContent = styled(FlexBox).attrs({ flexDirection: "column" })`
  margin-top: 9px;
  gap: 32px;
`;
const Description = styled(DefaultDescription)`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`;

const Modal = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 15px;
    z-index: 11;
  }
  .MuiDialogContent-root {
    padding: 0;
    max-width: 391px;
    background-color: #fcebe5;
  }
`;

const Content = styled.div<{ $minHeight: string }>`
  display: flex;
  flex-direction: column;
  gap: 12vw;
  align-items: center;
  padding: 0 35px;
  margin-bottom: 60px;
  min-height: ${({ $minHeight }) => $minHeight};
`;
