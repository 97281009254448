import TransactionStatus, {
  Props,
} from "../components/widgets/TransactionStatus/TransactionStatus";
import { wrapWithApollo } from "../graphql/client";
import { initialize as initializeI18n } from "../utils/i18n";
import { railsRoutesSubset, subset, wrapWithRoutes } from "../utils/routes";
import { wrapWithThemeProvider } from "../utils/themeProvider";
import { wrapWithRorpContext } from "../contexts/RorpContext";
import wrapWithStylesProvider from "../utils/wrapWithStylesProvider";
import { flowRight } from "lodash";
import React from "react";
import ssrComponent from "../utils/ssr";

export default (props: Props, marketplaceContext) => {
  initializeI18n(
    marketplaceContext.i18nLocale,
    marketplaceContext.i18nDefaultLocale,
    process.env.NODE_ENV,
    {
      region: "Unknown",
      language: "English",
    }
  );

  const routes = subset(railsRoutesSubset, {
    locale: marketplaceContext.i18nLocale,
  });
  return ssrComponent(
    flowRight(
      wrapWithRorpContext(marketplaceContext),
      wrapWithStylesProvider,
      wrapWithThemeProvider,
      wrapWithRoutes(routes),
      wrapWithApollo
    )(() => <TransactionStatus {...props} />)
  );
};
