import { gql } from "@apollo/client";

export default gql`
  query GetCategories {
    categories {
      id
      name
      parent {
        id
      }
      customFields {
        key
        name
        type
        required
        options {
          key
          title
        }
      }
    }
  }
`;
