import React from "react";
import styled from "styled-components";
import FlexBox, { FlexBoxProps } from "../../../../elements/v2/Box/FlexBox";
import ProductHeading from "../ProductHeading/ProductHeading";
import { Pre } from "../../../../elements/v2/Typography/Typography";

type Props = FlexBoxProps & {
  children?: React.ReactNode;
};

export default function Description({ children, ...props }: Props) {
  if (!children) return null;

  return (
    <Container {...props}>
      <ProductHeading>Description</ProductHeading>
      <Pre>{children}</Pre>
    </Container>
  );
}

const Container = styled(FlexBox)`
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0px;
  gap: 16px;
`;
