import { gql, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { ConversationStartingPage } from "../../../types/graphql-global-types";
import followRedirectsWithReturnPath from "../../../utils/followRedirectsWithReturnPath";
import NoContent from "../../elements/NoContent/NoContent";
import START_CONVERSATION from "./mutations/StartConversation.graphql";
import styled from "styled-components";
import Button, { BUTTON_SIZES } from "../../elements/v2/Button/Button";
import FlexBox from "../../elements/v2/Box/FlexBox";
import Box from "../../elements/v2/Box/Box";

// Currently this form is only used for starting new conversations, e.g. on the profile page
interface Props {
  personId: string;
  startingPage: ConversationStartingPage;
}

function MessageForm({ personId, startingPage }: Props) {
  const [message, setMessage] = useState("");

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    setMessage(event.target.value);

  const [startConversation, { data: mutationData, error }] = useMutation(gql(START_CONVERSATION), {
    variables: { input: { personId, startingPage, message } },
  });

  const successMessage = "Your message has been sent.";

  followRedirectsWithReturnPath(mutationData?.startConversation.redirectTo);

  useEffect(() => {
    if (!mutationData) {
      return;
    }

    const errorMessage = error?.message || mutationData.startConversation.error;

    if (errorMessage) {
      (window as any).FlashMessage.error(`Sorry, that didn't work: ${errorMessage}`);
    } else {
      (window as any).FlashMessage.success(successMessage);
    }
  }, [mutationData]);

  if (mutationData && mutationData.startConversation.message) {
    return <NoContent text={successMessage} />;
  }

  return (
    <FlexBox flexDirection="column" $gap={2}>
      <label htmlFor="message_content">Message</label>
      <MessageInput
        id="message_content"
        className="text_area"
        value={message}
        onChange={handleTextChange}
      />
      <Box>
        <Button name="button" onClick={() => startConversation()} size={BUTTON_SIZES.MEDIUM}>
          Send message
        </Button>
      </Box>
    </FlexBox>
  );
}

export default MessageForm;

const MessageInput = styled.textarea`
  width: 100%;
  height: 120px;
  overflow: hidden;
  overflow-wrap: break-word;
  resize: horizontal;
  padding: 8px;
`;
