import styled from "styled-components";
import Chip from "./v2/Chip/Chip";
import React from "react";
import { SellerType } from "../../types/graphql-global-types";
import { Svg } from "./Svg/Svg";
import ProfessionalSellerBadge from "../../assets/icons/professional_seller_badge.svg";
import TrustedSellerBadge from "../../assets/icons/trusted_seller_badge.svg";

export const SELLER_TYPE_LABELS = {
  [SellerType.default_seller]: "Default Seller",
  [SellerType.trusted_seller]: "Trusted Seller",
  [SellerType.professional_seller]: "Professional Seller",
};

export default function SellerTypeChip({ sellerType, ...props }: { sellerType: SellerType }) {
  if (sellerType === SellerType.default_seller) return null;
  const label = SELLER_TYPE_LABELS[sellerType];

  const avatarSVG =
    sellerType === SellerType.professional_seller ? ProfessionalSellerBadge : TrustedSellerBadge;

  return <Chip avatar={<Svg content={avatarSVG} />} label={label} {...props} />;
}

export const SellerTypeChipOutline = styled(SellerTypeChip)`
  border-radius: 20px;
  padding: 2px 4px;
  margin-left: 20px;
  height: 28px;

  .MuiChip-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .MuiChip-label {
    padding-right: 6px;
    padding-left: 0;
    font-weight: bold;
    margin-left: 8px;
  }
`;
