/*
 * This file was downloaded and re-purposed from Sajari source:
 * https://github.com/sajari/sdk-react/blob/master/packages/hooks/src/URLStateSync/index.tsx
 */
import React, { useEffect, useRef } from "react";
import {
  FilterBuilder,
  Range,
  RangeFilterBuilder,
  useFilter,
  usePagination,
  useQuery,
  useRangeFilter,
  useSearchContext,
  useSorting,
} from "@sajari/react-hooks";
import { isRange, paramToRange, rangeToParam } from "./queryParams";
import useSajariQueryParam, { ParamValue } from "./useSajariQueryParam";
import isSSR from "../isSSR";

export interface FilterWatcherProps {
  filter: FilterBuilder;
  delay?: number;
  replace?: boolean;
}

export interface RangeFilterWatcherProps {
  filter: RangeFilterBuilder;
  delay?: number;
  replace?: boolean;
}

export interface QueryParam {
  key: string;
  callback?: (value: string) => void;
  defaultValue?: ParamValue;
  value?: ParamValue;
}

export interface ParamWatcherProps {
  queryParam: QueryParam;
  delay?: number;
  replace?: boolean;
}

export interface URLStateSyncProps {
  delay?: number;
  replace?: boolean;
  extendedParams?: QueryParam[];
}

const FilterWatcher = ({ filter, replace, delay }: FilterWatcherProps) => {
  const name = filter.getName();
  const { setSelected, selected } = useFilter(name);
  const setFilterParam = useSajariQueryParam(name, {
    debounce: delay,
    replace,
    callback: replace
      ? undefined
      : (value) => {
          setSelected(value ? value.split(",") : []);
        },
  });

  useEffect(() => {
    setFilterParam(selected);
  }, [selected]);

  return null;
};

const RangeFilterWatcher = ({ filter, replace, delay }: RangeFilterWatcherProps) => {
  const name = filter.getName();
  const { range, setRange, min, max, reset } = useRangeFilter(name);
  const allowSetParam = useRef(false);
  const { response, results } = useSearchContext();

  const setFilterParam = useSajariQueryParam(name, {
    debounce: delay,
    replace,
    callback: replace
      ? undefined
      : (value) => {
          const rangeValue = paramToRange(value);
          if (!isRange(rangeValue) || rangeValue[0] === rangeValue[1]) {
            if (filter.isAggregate()) {
              // if aggregate, call reset to set range "null"
              reset();
            } else {
              setRange([min, max]);
            }
          } else {
            setRange(rangeValue as Range);
          }
        },
  });

  const setMinMaxParam = useSajariQueryParam(`${name}_min_max`, {
    debounce: delay,
    // Prevent min_max from being a part of the history
    replace: true,
  });

  useEffect(() => {
    if (allowSetParam.current) {
      if (range) {
        const shouldSetNewValue = range[0] !== min || range[1] !== max;
        setFilterParam(shouldSetNewValue ? rangeToParam(range) : "");
        if (filter.isAggregate()) {
          setMinMaxParam(shouldSetNewValue ? filter.getMinMax().join(":") : "");
        }
      } else {
        setMinMaxParam("");
        setFilterParam("");
      }
    }
  }, [range]);

  useEffect(() => {
    // We don't want to populate the params in the URL when there is no interation with the app
    if (response) {
      allowSetParam.current = true;
    }
  }, [response]);

  useEffect(() => {
    if (results && filter.getFrozen()) {
      // wait for the cycle of React to end then releasing the frozen state
      setTimeout(() => {
        filter.setFrozen(false);
      });
    }
  }, [results]);

  return null;
};

const ParamWatcher = ({ delay, replace, queryParam }: ParamWatcherProps) => {
  const { key, callback, defaultValue, value } = queryParam;

  const setParam = useSajariQueryParam(key, {
    debounce: delay,
    replace,
    defaultValue,
    callback: replace ? undefined : callback,
  });

  useEffect(() => {
    setParam(value);
  }, [value]);

  return null;
};

const URLStateSync = (props: URLStateSyncProps = {}) => {
  if (isSSR()) return null;

  const { delay = 500, replace = false, extendedParams = [] } = props;
  const {
    filters: filterBuilders = [],
    config: { qParam = "q" },
  } = useSearchContext();
  const { query, setQuery } = useQuery();
  const { sorting, setSorting } = useSorting();
  const { page, setPage } = usePagination();

  const parseAndSetPage = (paramPage?: ParamValue): void => {
    let pageNumber: number = 1;

    if (
      paramPage !== undefined &&
      (typeof paramPage === "string" || typeof paramPage === "number")
    ) {
      pageNumber = parseInt(paramPage as string);
    }

    setPage(pageNumber > 0 ? pageNumber : 1);
  };

  const paramWatchers: QueryParam[] = [
    {
      key: qParam,
      value: query,
      callback: setQuery,
    },
    {
      key: "sort",
      value: sorting,
      callback: setSorting,
    },
    {
      key: "page",
      value: page,
      callback: parseAndSetPage,
    },
    ...extendedParams.filter(({ key }) => ![qParam, "sort"].includes(key)),
  ];

  return (
    <>
      {filterBuilders.map((filter) => {
        return filter instanceof FilterBuilder ? (
          <FilterWatcher key={filter.getField()} {...{ replace, delay, filter }} />
        ) : (
          <RangeFilterWatcher key={filter.getField()} {...{ replace, delay, filter }} />
        );
      })}
      {paramWatchers.map((queryParam) => (
        <ParamWatcher key={queryParam.key} {...{ replace, delay, queryParam }} />
      ))}
    </>
  );
};

export default URLStateSync;
