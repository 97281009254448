import React from "react";
import styled from "styled-components";
import { AuthenticityToken } from "../../../../../../utils/csrfHelper";
import FlexBox from "../../../../../elements/v2/Box/FlexBox";
import Button, { BUTTON_SIZES } from "../../../../../elements/v2/Button/Button";
import { Span } from "../../../../../elements/v2/Typography/Typography";
import Box, { BoxProps } from "../../../../../elements/v2/Box/Box";
import { Input } from "../../../../../elements/v2/Form/Input";
import { THE_LUX_THEORY_USERNAME } from "../../../../../../constants/person";
import { GetListing_listing_author } from "../../../queries/types/GetListing";

const DEFAULT_SELLER_COMMISSION_PERCENT = 17;
const THE_LUX_THEORY_COMMISSION_PERCENT = 3;

type Props = BoxProps & {
  route: string;
  sellerCommissionPercent: number | null;
  isApprovalPending: boolean;
  author: GetListing_listing_author;
};

const getDefaultCommissionPercent = (author: GetListing_listing_author) =>
  author.username === THE_LUX_THEORY_USERNAME
    ? THE_LUX_THEORY_COMMISSION_PERCENT
    : DEFAULT_SELLER_COMMISSION_PERCENT;

const ApprovalForm = ({
  route,
  sellerCommissionPercent,
  isApprovalPending,
  author,
  ...props
}: Props) => (
  <Container {...props}>
    <form action={route} method="post">
      <AuthenticityToken />
      <Label htmlFor="seller_commission_percent">Commission (%):</Label>
      <FlexBox mt={1} $gap={2} alignItems="flex-end">
        <CommissionInput
          defaultValue={sellerCommissionPercent ?? getDefaultCommissionPercent(author)}
          type="number"
          name="seller_commission_percent"
          id="seller_commission_percent"
        />
        <Button name="button" type="submit" size={BUTTON_SIZES.MEDIUM}>
          {isApprovalPending ? (
            <Span>Update &amp; Approve listing</Span>
          ) : (
            <Span>Update Commission</Span>
          )}
        </Button>
      </FlexBox>
    </form>
  </Container>
);

export default ApprovalForm;

const Container = styled(Box)`
  width: fit-content;
`;

const CommissionInput = styled(Input).attrs({
  inputProps: {
    step: 1,
    maxLength: 2,
    min: 0,
    max: 50,
    size: 2,
  },
})`
  height: 40px;
  .MuiInput-input {
    width: 60px;
    padding: 8px;
  }
`;

const Label = styled.label`
  font-weight: 500;
`;
