import { gql } from "@apollo/client";

export default gql`
  query GetHistoricalOrdersEnabledAffiliates {
    historicalOrdersEnabledAffiliates {
      appId
      displayName
      logoUrl
      siteUrl
    }
  }
`;
