import classNames from "classnames";
import { div, span } from "r-dom";
import { Component } from "react";
import closedIcon from "./images/dropdownTriangleClosed.svg";
import openIcon from "./images/dropdownTriangleOpen.svg";
import css from "./Menu.module.css";

// We are not using prop-types, although we keep prop-type validation in place for some legacy
// components. This component should be re-written in typescript.

/* eslint-disable react/prop-types */
class MenuLabelDropdown extends Component {
  render() {
    const extraClasses = this.props.extraClasses ? this.props.extraClasses : "";
    return div(
      {
        className: classNames("t-menu__label-dropdown", css.menuLabel, extraClasses),
      },
      [
        this.props.name,
        span({
          className: css.menuLabelDropdownIconOpen,
          dangerouslySetInnerHTML: { __html: openIcon },
        }),
        span({
          className: css.menuLabelDropdownIconClosed,
          dangerouslySetInnerHTML: { __html: closedIcon },
        }),
      ]
    );
  }
}

export default MenuLabelDropdown;
