import { Button, createStyles, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { RoutesContext } from "../../../../utils/routes";
import OffsetInfo from "../OffsetInfo/OffsetInfo";
import { GetListingFormData_listing } from "../queries/types/GetListingFormData";

const useStyles = makeStyles((theme) =>
  createStyles({
    bold: {
      fontWeight: 600,
    },
    button: {
      width: "80%",
    },
  })
);

interface Props {
  listing: GetListingFormData_listing;
}

function ListingDonationSuccess({ listing }: Props) {
  const classes = useStyles();
  const routes = useContext(RoutesContext);

  return (
    <Grid container spacing={4} justify="center" direction="column">
      <Grid item>
        <Grid container spacing={2} justify="center" direction="column">
          <Grid item>
            <Typography
              variant="h6"
              gutterBottom
              color="textPrimary"
              align="center"
              className={classes.bold}
            >
              THANK YOU FOR RECYCLING
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="subtitle1" align="center">
              For customers in Australia:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" align="center">
              If your item is in reasonable, wearable condition, you can donate your item for resale
              in The Salvation Army.&nbsp;
              <Link
                underline="always"
                href="https://www.salvosstores.com.au/postage-donation"
                target="_blank"
              >
                Click here
              </Link>
              &nbsp;to register your donation and get a free shipping label.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" align="center">
              For items in poor, unwearable condition, AirRobe has partnered with Upparel.&nbsp;
              <Link underline="always" href="https://upparel.com.au/airrobe/" target="_blank">
                Click here
              </Link>
              &nbsp;to purchase a 10kg donation box that can include clothing, linen, shoes or other
              textiles.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" align="center">
              For customers in all other countries:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" align="center">
              Please visit our&nbsp;
              <Link
                underline="always"
                href="https://airrobe.zendesk.com/hc/en-us/articles/4417340389017-Recycling-on-AirRobe"
                target="_blank"
              >
                Recycling FAQ
              </Link>
              &nbsp; for donation or recycling services recommended in your area, or contact AirRobe
              customer support for further assistance.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" align="center">
              Thanks so much for giving your item a longer life and reducing its environmental
              footprint.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" align="center">
              You have just offset:
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <OffsetInfo />
      </Grid>
      <Grid item>
        <Grid container spacing={2} justify="center" alignItems="center" direction="column">
          <Grid item className={classes.button}>
            <Button variant="contained" color="primary" fullWidth href={routes.search_path()}>
              Shop Airrobe
            </Button>
          </Grid>
          <Grid item className={classes.button}>
            <Button
              variant="outlined"
              fullWidth
              href={routes.person_listings_path({
                id: listing.author.username,
              })}
            >
              Circular wardrobe
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ListingDonationSuccess;
