import React, { useEffect, useState } from "react";
import { Route, Switch, useParams } from "react-router-dom";
import SelectEmailStep from "./Steps/SelectEmailStep";
import ConfirmEmailStep from "./Steps/ConfirmEmailStep";
import FetchOrdersStep from "./Steps/FetchOrdersStep";

import { gql, useQuery } from "@apollo/client";
import GET_AFFILIATE from "./queries/GetAffiliate.graphql";
import Box from "../../elements/v2/Box/Box";
import {
  GetAffiliate,
  GetAffiliate_affiliate,
  GetAffiliateVariables,
} from "./queries/types/GetAffiliate";
import { AFFILIATE_PATH, HistoricalOrdersPageProps } from "./HistoricalOrdersPage";
import { AffiliateLogo } from "./components/AffiliateLogo";
import { LoadingSpinner } from "../../elements/v2/LoadingSpinner/LoadingSpinner";
import { StepWrapper } from "./components/StepWrapper";

export type AffiliateType = GetAffiliate_affiliate | null;

export const AffiliateRoutes = ({ username }: HistoricalOrdersPageProps) => {
  const { affiliateAppId } = useParams();

  const [affiliate, setAffiliate] = useState<AffiliateType>(null);

  const variables: GetAffiliateVariables = { affiliateAppId };
  const { data } = useQuery<GetAffiliate>(gql(GET_AFFILIATE), {
    variables,
  });

  useEffect(() => {
    if (data?.affiliate) setAffiliate(data?.affiliate);
  }, [data]);

  if (!affiliate)
    return (
      <StepWrapper>
        <Box mb={8} p={4}>
          <LoadingSpinner />
        </Box>
      </StepWrapper>
    );

  return (
    <StepWrapper>
      <Box mb={4}>
        <AffiliateLogo affiliate={affiliate} />
      </Box>
      <Switch>
        <Route exact path={AFFILIATE_PATH}>
          <SelectEmailStep affiliate={affiliate} />
        </Route>
        <Route exact path={`${AFFILIATE_PATH}/not_found`}>
          <SelectEmailStep affiliate={affiliate} notFound />
        </Route>
        <Route exact path={`${AFFILIATE_PATH}/:email`}>
          <SelectEmailStep affiliate={affiliate} />
        </Route>
        <Route exact path={`${AFFILIATE_PATH}/confirm_email/:email`}>
          <ConfirmEmailStep />
        </Route>
        <Route exact path={`${AFFILIATE_PATH}/fetch_orders/:email`}>
          <FetchOrdersStep affiliate={affiliate} username={username} />
        </Route>
      </Switch>
    </StepWrapper>
  );
};
