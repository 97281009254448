import { gql } from "@apollo/client";

const SetTransactionShipped = gql`
  mutation SetTransactionShipped($input: SetTransactionShippedMutationInput!) {
    setTransactionShipped(input: $input) {
      transaction {
        id
        shipped
        buyerTestimonials: testimonials(from: buyer) {
          author {
            id
          }
        }
        sellerTestimonials: testimonials(from: seller) {
          author {
            id
          }
        }
        buyerSkippedFeedback
        sellerSkippedFeedback
      }
    }
  }
`;

export default SetTransactionShipped;
