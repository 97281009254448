import React from "react";
import styled from "styled-components";
import { GetListing_listing } from "../../queries/types/GetListing";
import Box, { BoxProps } from "../../../../elements/v2/Box/Box";
import { H1 } from "../../../../elements/v2/Typography/Heading";
import LinkBox from "../../../../elements/v2/Box/LinkBox";
import { ListingRoutes } from "../../ListingPage";

type Props = BoxProps & {
  listing?: GetListing_listing;
  routes: ListingRoutes;
};

export default function ListingTitle({ listing, routes, ...props }: Props) {
  return (
    <Box {...props}>
      <Heading>
        <LinkBoxHover href={routes.brandPath}>
          <Brand>{listing.brand}</Brand>
        </LinkBoxHover>
        <br />
        {listing.title}
      </Heading>
    </Box>
  );
}

const Heading = styled(H1)`
  color: black;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  font-family: "Montserrat", Helvetica, sans-serif;
`;

const Brand = styled.span`
  font-size: 40px;
`;

const LinkBoxHover = styled(LinkBox)`
  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 1.5px;
  }
`;
