import { Box, createStyles, Grid, makeStyles } from "@material-ui/core";
import { merge } from "lodash";
import React from "react";
import FormSectionHeading from "./Heading";
import HelpText from "./HelpText";

export type ClassesOverrides = {
  [key: string]: { [key: string]: string | number };
};

export interface Props {
  children: React.ReactNode;
  title: string;
  description?: string;
  helpText?: string[];
  classesOverrides?: ClassesOverrides;
}

const useStyles = (classesOverrides: ClassesOverrides) =>
  makeStyles((theme) => {
    const baseStyles = {
      formSection: {
        padding: 16,
        background: "#FFFFFF",
        borderTop: "1px solid #E0E0E0",
        borderBottom: "1px solid #E0E0E0",
      },
      innerFormSection: {
        paddingTop: 16,
        paddingLeft: 16,
        [theme.breakpoints.down("sm")]: {
          paddingLeft: 8,
        },
      },
    };
    return createStyles(merge(baseStyles, classesOverrides));
  });

export default function FormSection({
  title,
  description,
  helpText,
  children,
  classesOverrides = {},
}: Props) {
  const classes = useStyles(classesOverrides)();

  return (
    <Box className={classes.formSection}>
      <FormSectionHeading title={title} description={description} />
      <Grid container spacing={2} direction="column" className={classes.innerFormSection}>
        {React.Children.map(children, (child) => (
          <Grid item>{child}</Grid>
        ))}
        {helpText && helpText.length > 0 && (
          <Grid item>
            <HelpText paragraphs={helpText} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
