// Our card component adds a drop-shadow that becomes more accentuated on hover.
import classNames from "classnames";
import React from "react";
import css from "./Card.module.css";

// The props for this component are based on the props for a regular div
type BaseProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export type Props = BaseProps & {
  backgroundImageUrl?: string;
  backgroundColor?: string;
  href?: string;
};

function Container({ href, ...props }: Props) {
  if (href) return <a href={href} {...props} />;
  return <div {...props} />;
}

export default function Card({
  children,
  className,
  backgroundColor,
  backgroundImageUrl,
  ...rest
}: Props) {
  const hasBackground = backgroundColor || backgroundImageUrl;
  const backgroundImage = backgroundImageUrl ? `url(${backgroundImageUrl})` : null;

  return (
    <Container {...rest} className={classNames(css.root, className)}>
      {hasBackground ? (
        <div className={css.overlayImage} style={{ backgroundImage, backgroundColor }} />
      ) : null}
      {children}
    </Container>
  );
}
