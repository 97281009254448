import { Grid } from "@material-ui/core";
import React from "react";
import { ConditionWarning } from "../../../../pages/ListingFormPage/ListingFormPage";
import { S3Props } from "../../../FormControls/ImageUploader/ImageUploader";
import { GetListingFormData_listing } from "../../queries/types/GetListingFormData";
import ConditionSection from "../ConditionSection/ConditionSection";
import PriceSection from "../PriceSection/PriceSection";
import { ResellFormFields } from "../../RentForm/FormModel/listingFormModel";
import { FormikConfig, FormikErrors, FormikHandlers, FormikHelpers } from "formik";
import { ResellFormFieldValues } from "../FormModel/listingFormModel";

export interface StepProps {
  formField: ResellFormFields;
  values: ResellFormFieldValues;
  errors: FormikErrors<ResellFormFieldValues>;
  setFieldValue: FormikHelpers<ResellFormFieldValues>["setFieldValue"];
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  validationSchema: FormikConfig<ResellFormFieldValues>["validationSchema"];
}

const ConditionStep = (
  s3Props: S3Props,
  listing: GetListingFormData_listing,
  conditionWarning: ConditionWarning
) => (props: StepProps) => (
  <Grid container spacing={2} direction="column" wrap="nowrap">
    <ConditionSection
      s3Props={s3Props}
      listing={listing}
      conditionWarning={conditionWarning}
      {...props}
    />
    <PriceSection {...props} />
  </Grid>
);
const withProps = (
  s3Props: S3Props,
  listing: GetListingFormData_listing,
  conditionWarning: ConditionWarning
) => ConditionStep(s3Props, listing, conditionWarning);

export default withProps;
