import React, { useState } from "react";
import { TopbarUserProps } from "../types";
import Box from "../../../elements/v2/Box/Box";
import { breakpoints } from "../../../../utils/styledHelpers";
import styled from "styled-components";
import MobileMenuToggle, { HamburgerIcon } from "./MobileMenuToggle";
import MuiDrawer from "@material-ui/core/Drawer";
import Avatar from "../../../elements/Avatar/Avatar";
import { getUserPath, USER_PROFILE_PATH } from "../../../../utils/paths";
import SectionMobileMegaMenu from "./SectionMobileMegaMenu";
import SectionUser from "./SectionUser";
import SectionUserSettings from "./SectionUserSettings";
import SectionOther from "./SectionOther";

export default function MenuMobile({ user }: { user: TopbarUserProps }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = (e) => {
    setIsOpen(false);
  };

  const handleClickMenuArea = (e) => {
    if (e.target.closest("a")?.href) setIsOpen(false);
  };

  const { loggedInUsername, avatar, unReadMessagesCount } = user;
  const avatarUrl = loggedInUsername ? getUserPath(loggedInUsername, USER_PROFILE_PATH) : undefined;

  return (
    <Container $open={isOpen}>
      <MobileMenuToggle onClick={handleClick} notificationCount={unReadMessagesCount} />
      <Drawer open={isOpen} onClose={handleClose}>
        <Box onClick={handleClickMenuArea} mb={3}>
          <Header>
            {loggedInUsername && (
              <Box w={5.5} h={5.5}>
                <Avatar {...avatar} url={avatarUrl} imageHeight={44} />
              </Box>
            )}
          </Header>
          <SectionMobileMegaMenu />
          <SectionUser user={user} notificationCount={unReadMessagesCount} />
          <SectionUserSettings user={user} />
          <SectionOther user={user} />
        </Box>
      </Drawer>
    </Container>
  );
}

const Container = styled(Box)<{ $open: boolean }>`
  font-size: 17px;
  margin-right: 0;
  ${({ $open }) =>
    $open &&
    `
    ${HamburgerIcon} g {
      fill: black;
    }
  `}

  ${breakpoints.up("sm")} {
    margin-left: -16px;
  }

  ${breakpoints.up("md")} {
    display: none;
  }
`;

const Drawer = styled(MuiDrawer)`
  .MuiPaper-root {
    width: 288px;
    background-color: white;
  }
`;

const Header = styled(Box)`
  display: flex;
  margin: 10px 0;
  flex-shrink: 0;
  padding: 0 16px;

  ${breakpoints.up("sm")} {
    padding: 0 24px;
  }
`;
