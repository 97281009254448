// We pull this out to cleanup our component a little. Given a list of all collections, and a list

import { MouseEvent } from "react";
import { CollectionCardFragment } from "../../../../graphql/fragments/types/CollectionCardFragment";
import { MenuItemProps } from "../../../composites/v2/Menu/MenuItem";

// of listing collections, return menu items.
interface Args {
  allCollections: Array<CollectionCardFragment>;
  listingCollections: Array<CollectionCardFragment>;
  handleCollectionClick: (collection: CollectionCardFragment) => void;
  handleNewCollectionClick: () => void;
}

export default function buildMenuContent({
  allCollections,
  listingCollections,
  handleCollectionClick,
  handleNewCollectionClick,
}: Args): Array<MenuItemProps> {
  const listingCollectionIds = listingCollections.map(({ id }) => id);

  const allCollectionMenuItems = allCollections.map((collection) => ({
    active: listingCollectionIds.includes(collection.id),
    type: "menuitem",
    content: collection.displayTitle,
    onClick: (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      handleCollectionClick(collection);
    },
  }));

  const newCollectionMenuItem = {
    active: false,
    type: "menuitem",
    content: "+ Add collection",
    onClick: (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      handleNewCollectionClick();
    },
  };

  return [newCollectionMenuItem, ...allCollectionMenuItems];
}
