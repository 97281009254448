import { Dialog } from "@material-ui/core";
import { useSearchContext } from "@sajari/react-hooks";
import React, { ReactNode } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Box from "../../../elements/v2/Box/Box";
import Button, { BUTTON_SIZES, OutlineButton } from "../../../elements/v2/Button/Button";
import { useSearchPageContext } from "../SearchPageContext";
import styled from "styled-components";
import { breakpoints } from "../../../../utils/styledHelpers";

interface Props {
  children: ReactNode;
}
export default function FiltersWrapper({ children }: Props) {
  const { isMobileSidebarOpen, setMobileSidebarOpen } = useSearchPageContext();
  const { resetFilters } = useSearchContext();

  function handleClose() {
    setMobileSidebarOpen(false);
  }

  function handleReset() {
    handleClose();
    resetFilters();
  }

  return (
    <>
      <Modal fullScreen={true} open={isMobileSidebarOpen} onClose={handleClose}>
        <Box alignSelf="flex-end" marginTop="1rem" marginRight="2rem">
          <CloseIcon css="cursor: pointer;" onClick={handleClose}></CloseIcon>
        </Box>
        <Content>{children}</Content>
        <Box flexDirection="row" my={2} display="flex" justifyContent="space-evenly">
          <OutlineButton size={BUTTON_SIZES.MEDIUM} onClick={handleReset}>
            Reset
          </OutlineButton>
          <Button size={BUTTON_SIZES.MEDIUM} onClick={handleClose}>
            Apply
          </Button>
        </Box>
      </Modal>
      <HideMobile>
        <SideBar>{children}</SideBar>
      </HideMobile>
    </>
  );
}

const HideMobile = styled(Box)`
  ${breakpoints.down("tablet")} {
    display: none;
  }
`;

const Modal = styled(Dialog)`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  .MuiDialog-paper {
    width: 100%;
    margin: 0;
  }
  .MuiDialog-scrollPaper {
    align-items: flex-start;
    padding-top: 100px;
  }
  ${breakpoints.up("tablet")} {
    display: none;
  }
`;

const Content = styled(Box)`
  flex-grow: 1;
  overflow-y: scroll;
  padding: 10px;
`;

const SideBar = styled(Box)`
  width: 300px;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 80vh;
`;
