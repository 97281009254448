import classNames from "classnames";
import React, { ReactNode } from "react";
import css from "./StatusRow.module.css";

export interface Props {
  iconName?: string;
  children: ReactNode;
}

export default function StatusRow({ iconName, children }: Props) {
  return (
    <div className={css.inboxHorizontallyAlignedStatus}>
      <div className={iconName ? css.textContainerWithIcon : null}>
        {iconName ? <div className={classNames(iconName, css.iconPart)} /> : null}
        <div className={css.textPart}>{children}</div>
      </div>
    </div>
  );
}
