import { gql } from "@apollo/client";

const CREATE_LISTING_IMAGE = gql`
  mutation CreateListingImage($input: CreateListingImageMutationInput!) {
    createListingImage(input: $input) {
      uuid
      image {
        id
      }
      success
      validationErrors {
        attribute
        errorMessage
      }
    }
  }
`;

export default CREATE_LISTING_IMAGE;
