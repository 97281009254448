// When the code in this area was written, we were using version 4.0.1 of the react-dates library,
// which represented "modifiers" in the form:
// {
//   modifierName: (day) => isModifierApplicable(day)
// }
//
// However, as we upgraded our app to React 16, we needed to migrate the library to a version post
// 20.2.4, as this removes the dependency on react-addons-shallow-compare, which is deprecated and
// incompatible with React 16+.
//
// As of version 11.0.0, the modifiers for react-dates library are represented as follows:
// {
//   monthDateString: {
//     dateString: Set(['modifier1', 'modifier2'])
//   }
// }
//
// Ideally, the data store in this domain could be re-writted to match this structure - with mobx
// this would be straightforward and efficient to represent interally. However, we want to triage
// our efforts and choose the shortest path to getting this working, which in this case appears to
// be a transformation of our internal data to match the new structure.

import { each } from "lodash";
import momentBase, { Moment } from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(momentBase);

interface Modifiers {
  [modifierString: string]: Array<Moment>;
}

interface ReactDatesModifiers {
  [monthString: string]: {
    [dateString: string]: Set<string>;
  };
}

export default function transformModifiers(modifiers: Modifiers): ReactDatesModifiers {
  // We could achieve this using reduce but I think this approach is more understandable
  const memo: ReactDatesModifiers = {};

  each(modifiers, (datesArray: Array<Moment>, modifierKey: string) => {
    datesArray.forEach((day) => {
      const monthString = day.format("YYYY-MM");
      const dateString = day.format("YYYY-MM-DD");

      if (!memo[monthString]) {
        memo[monthString] = {};
      }

      if (!memo[monthString][dateString]) {
        memo[monthString][dateString] = new Set();
      }

      memo[monthString][dateString].add(modifierKey);
    });
  });

  return memo;
}

// Give an array of dates that are "past", i.e. before today
export function pastDaysInMonth(startOfMonth: Moment): Array<Moment> {
  // Starting the range -37 days helps to prepare the previous month for proper rendering, in case
  // the user moves to the previous month. It's more than one month because we also show "outside"
  // dates from the previous month.
  const maxDaysInMonth = 31;
  const maxDaysOfPreviousMonthDisplayed = 6;
  const bufferDays = maxDaysInMonth + maxDaysOfPreviousMonthDisplayed;

  const rangeStart = startOfMonth.clone().subtract(bufferDays, "days");
  const rangeEnd = moment();

  const range = moment.range(rangeStart, rangeEnd);

  return Array.from(range.by("day", { excludeEnd: true }));
}
