import { PaymentIntent, PaymentIntentOrSetupIntentResult, Stripe } from "@stripe/stripe-js";
import axios from "axios";
import { getCsrfHeaders } from "../../../../utils/csrfHelper";

export interface ProcessIntentResponse {
  data: {
    redirectUrl?: string | undefined;
    errorMsg?: string | undefined;
    success?: boolean | undefined;
  };
}

export interface PaymentIntentPartial {
  stripePaymentId: PaymentIntent["id"];
  requiresAction: boolean;
  clientSecret: PaymentIntent["client_secret"];
  confirmIntentPath: string;
  failedIntentPath: string;
}

export const handleNextStripeAction = async (
  stripeObject: Stripe,
  clientSecret: string
): Promise<PaymentIntentOrSetupIntentResult> => {
  return stripeObject.handleNextAction({
    clientSecret: clientSecret,
  });
};

export const processFailedIntent = (
  stripePaymentId: string,
  failedIntentPath: string
): Promise<ProcessIntentResponse> => {
  return axios({
    method: "post",
    url: failedIntentPath,
    data: {
      stripe_payment_id: stripePaymentId,
    },
    headers: {
      ...getCsrfHeaders(),
      "Content-Type": "multipart/form-data",
    },
    withCredentials: true,
  });
};

export const processConfirmIntent = (
  stripePaymentId: string,
  paymentIntentId: string,
  confirmIntentPath: string
): Promise<ProcessIntentResponse> => {
  return axios({
    method: "post",
    url: confirmIntentPath,
    data: {
      stripe_payment_id: stripePaymentId,
      payment_intent_id: paymentIntentId,
    },
    headers: {
      ...getCsrfHeaders(),
      "Content-Type": "multipart/form-data",
    },
    withCredentials: true,
  });
};
