import { createStyles, makeStyles, Typography } from "@material-ui/core";
import React, { Fragment } from "react";

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      fontWeight: 600,
    },
  })
);

export interface Props {
  title: string;
  description?: string;
}

const Heading = ({ title, description }: Props) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Typography variant="h6" gutterBottom color="textPrimary" className={classes.label}>
        {title}
      </Typography>
      {description && (
        <Typography variant="subtitle1" color="textPrimary">
          {description}
        </Typography>
      )}
    </Fragment>
  );
};
export default Heading;
