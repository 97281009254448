import classNames from "classnames";
import React from "react";
import css from "./ProgressWizard.module.css";

export interface Props {
  steps: Array<string>;
  lastCompletedStepIndex: number;
}

export default function ProgressWizard({ steps, lastCompletedStepIndex }: Props) {
  return (
    <div className={css.wizardProgress}>
      {steps.map((step, index) => (
        <div
          key={step}
          className={classNames(css.step, {
            [css.completed]: index === lastCompletedStepIndex,
            [css.previouslyCompleted]: index < lastCompletedStepIndex,
          })}
        >
          <div className={css.node} />
          {step}
        </div>
      ))}
    </div>
  );
}
