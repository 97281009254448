import Immutable from "immutable";
import moment from "moment";
import r from "r-dom";
import { Provider } from "react-redux";
import { applyMiddleware, combineReducers, createStore } from "redux";
import middleware from "redux-thunk";
import { EDIT_VIEW_OPEN_HASH } from "../redux/actions/ManageAvailabilityActions";
import ManageAvailabilityContainer from "../components/widgets/ManageAvailability/ManageAvailabilityContainer";
import reducers from "../redux/reducers/reducersIndex";
import { initializeLocale } from "../utils/locale";

export default (props) => {
  initializeLocale(props.i18n);

  const combinedReducer = combineReducers(reducers);
  const initialStoreState = {
    flashNotifications: Immutable.List(),
    manageAvailability: Immutable.Map({
      isOpen: window.location.hash.replace(/^#/, "") === EDIT_VIEW_OPEN_HASH,
      visibleMonth: moment().startOf("month"),
      saveInProgress: false,
      saveFinished: false,
      loadedMonths: Immutable.Set(),
      listingId: props.listing.id,
      blocked_dates: [],
      booked_dates: [],
    }),
  };

  const store = applyMiddleware(middleware)(createStore)(combinedReducer, initialStoreState);

  const containerProps = {
    availability_link: props.availability_link_id
      ? document.getElementById(props.availability_link_id)
      : null,
    header: {
      backgroundColor: props.marketplace.marketplace_color1 || "#4a90e2",
      imageUrl: props.listing.image_url,
      title: props.listing.title,
    },
    sideWinderWrapper: document.querySelector("#sidewinder-wrapper"),
  };

  return r(Provider, { store }, [r(ManageAvailabilityContainer, containerProps)]);
};
