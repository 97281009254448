import React from "react";
import { Snackbar as MuiSnackbar } from "@material-ui/core";
import styled from "styled-components";
import { Alert as MuiAlert } from "../../../elements/v2/Alert/Alert";

export interface Props {
  open: boolean;
  onClose: () => void;
  content: React.ReactNode;
  severity?: "success" | "info" | "warning" | "error";
  variant?: "outlined" | "standard" | "filled";
  $muiAlert?: boolean;
}

function SnackbarAlert({
  open,
  onClose,
  content,
  severity = "success",
  variant = "standard",
  $muiAlert = false,
  ...props
}: Props) {
  const Alert = $muiAlert ? MuiAlert : DarkAlert;

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={6000}
      onClose={onClose}
      {...props}
    >
      <Alert onClose={onClose} severity={severity} variant={variant}>
        {content}
      </Alert>
    </Snackbar>
  );
}

export default SnackbarAlert;

const Snackbar = styled(MuiSnackbar)`
  padding-top: 100px;
`;

const DarkAlert = styled(MuiAlert)`
  background-color: #000;
  color: white;
  .MuiAlert-icon {
    color: white;
  }
`;
