import { gql } from "@apollo/client";

// Everything needed to render a testimonial card
export default gql`
  fragment TestimonialCardFragment on Testimonial {
    id
    grade
    positive
    text
    author {
      givenName
      avatarImageUrl
    }
    listing {
      title
      url
    }
  }
`;
